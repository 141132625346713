import { put, call, select, take, takeEvery, takeLatest, retry } from 'redux-saga/effects';
import isAfter from 'date-fns/isAfter';
import addMinutes from 'date-fns/addMinutes';
import api from 'apis/config';
import * as selectors from 'selectors/auth';
import * as configSelectors from 'selectors/config';
import types from 'actions/authTypes';
import configTypes from 'actions/configTypes';
import jwt from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { PLATFORMS } from 'utils/constants';

export function* getConfig() {
  try {
    const { response, error } = yield call(api.getConfig);
    if (response) {
      const { tenant } = response;
      window.dataLayer && window.dataLayer.push({ event: 'tenant api resolved', client: tenant.id });
      yield put({ type: types.CONFIG_SUCCESS, response });
    } else {
      yield put({ type: types.CONFIG_FAIL, error });
    }
  } catch (error) {
    yield put({ type: types.CONFIG_FAIL, error });
  }
}

export function* cacheToken({ data: token }) {
  try {
    const data = {
      value: token,
      expiry: new Date(jwt(token).exp * 1000)
    };
    yield put({ type: types.CACHE_TOKEN_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.CACHE_TOKEN_FAILURE, error });
  }
}

export function* configureSentry() {
  const client = yield select(selectors.getMainHost);
  Sentry.configureScope((scope) => { scope.setTag('client-name', client); });
}

export function* configurePlatforms() {
  let data = [];
  const platforms = yield select(selectors.getPlatforms);

  if (platforms.length === 0) {
    data = PLATFORMS;
  } else {
    for (const p of PLATFORMS) {
      if (platforms.includes(p)) data.push(p);
    }
  }

  yield put({ type: types.SET_GLOBAL_PLATFORMS, data });
}

export function* getAuthValues(product) {
  const now = new Date();
  let tokenExp = yield select(selectors.getTokenExp);
  if (isAfter(addMinutes(now, 10), tokenExp)) {
    // If now + 10mins is after the expiry time, get a new token
    yield put({ type: types.UPDATE_TOKEN_CACHE });
    yield take(types.CACHE_TOKEN_SUCCESS);
  }

  let token = yield select(selectors.getToken);
  if (!token) {
    // token may be null, wait for it to set before making the API call
    yield take(types.CACHE_TOKEN_SUCCESS);
  }
  token = yield select(selectors.getToken);

  let hostSelector;
  switch (product) {
    case 'dive':
      hostSelector = selectors.getDiveAPI;
      break;
    case 'admin':
      hostSelector = selectors.getUserManagementAPI;
      break;
    case 'section':
      hostSelector = selectors.getSectionAPI;
      break;
    case 'tenant_config_api':
      hostSelector = selectors.getConfigAPI;
      break;
    default:
      hostSelector = selectors.getNowAPI;
  }
  const host = yield select(hostSelector); // should be called service
  const client = yield select(selectors.getMainHost); // should be called host
  const tenant = yield select(selectors.getTenantId);
  return { host, token, client, tenant };
}

export function* awaitWidgetConfig({ pageName, widgetName }) {
  let config = yield select(configSelectors.getWidgetConfig(pageName, widgetName));

  if (!config.data) {
    if (!config.loading) {
      yield put({ type: configTypes.FETCH_WIDGET_CONFIG, pageName, widgetName });
    }
    const awaitResponse = function * () {
      const response = yield take([configTypes.FETCH_WIDGET_CONFIG_SUCCESS, configTypes.FETCH_WIDGET_CONFIG_FAIL]);
      if (response.pageName !== pageName || response.widgetName !== widgetName) {
        yield awaitResponse();
      }
    };
    yield awaitResponse();
    config = yield select(configSelectors.getWidgetConfig(pageName, widgetName));
  }

  return config;
}

export function* fetchWidgetConfig({ pageName, widgetName }) {
  try {
    const { host, token } = yield call(getAuthValues, 'tenant_config_api');
    const { response } = yield retry(3, 1000, api.getWidgetConfig, host, token, pageName, widgetName);
    yield put({ type: configTypes.FETCH_WIDGET_CONFIG_SUCCESS, data: response, pageName, widgetName });
  } catch (error) {
    yield put({ type: configTypes.FETCH_WIDGET_CONFIG_FAIL, error, pageName, widgetName });
  }
}

export default function* authSaga() {
  const isConfigured = yield select(selectors.isConfigured);

  yield takeLatest(types.CONFIG_FETCH, getConfig);
  yield takeLatest(types.CACHE_TOKEN, cacheToken);
  yield takeLatest(types.CONFIG_SUCCESS, configureSentry);
  yield takeLatest(types.CONFIG_SUCCESS, configurePlatforms);
  if (!isConfigured) {
    yield put({ type: types.CONFIG_FETCH });
  }
  yield takeEvery(configTypes.FETCH_WIDGET_CONFIG, fetchWidgetConfig);
}
