import React, { useEffect, useState } from 'react';
import { Card, Cascader, Checkbox, Col, Divider, Form, InputNumber, Row, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/cascader';

import { useOverrides } from '../contexts/OverridesProvider';
import { useModalData } from '../contexts/ModalProvider';

import { CreateFormValues } from '../types';
import { cascaderFilter as filter } from '../utils/functions';
import { getRuleDescription } from '../utils/copy';

import ArticleInput from './ArticleInput';
import RuleTypeTag from './RuleTypeTag';

const OverridesForm: React.FC<{
  onSubmit: () => void;
}> = ({ onSubmit }) => {
  const { pageWidgets } = useOverrides();
  const { form, rule, reasons, setFormData } = useModalData();
  const [ maxSlotSize, setSlotSize ] = useState<number>(0);
  const [ articleIdErrors, setArticleIdErrors ] = useState<string[]>([]);
  const [selectedPages, setSelectedPages] = useState<(string|number)[][]>([]);

  useEffect(() => {
    const widget = form.getFieldValue('widgetName');
    if (rule !== 'ban' && widget) {
      setSlotSize(getSlotSize(widget));
    }
  }, []);

  function getSlotSize(widget: string | string[]): number {
    const page = pageWidgets.find(({ key }) => key === widget[0]);
    const { slotSize } = page?.children.find(({ key }) => key === widget[1]) || {};
    return slotSize || 0;
  }

  function handleChange(changed: Partial<CreateFormValues>, all: CreateFormValues) {
    if (changed.widgetName) {
      setSlotSize(getSlotSize(changed.widgetName));
    } else if (typeof all.widgetName === 'undefined') setSlotSize(0);
  }

  function handleUpdateErrors() {
    form.validateFields(['articleId'])
    .then(() => {
      setArticleIdErrors([]);
    })
    .catch(({ errorFields }) => {
      setArticleIdErrors(errorFields);
    });
  }

  function displayRender(labels: string[], selectedOptions?: DefaultOptionType[]) {
    return labels.map((title, i) => {
      const { key, slotSize, children } = selectedOptions?.[i] as DefaultOptionType;
      if (children?.length) return <span key={key}>{title} /&nbsp;</span>;
      return (
        <span key={key}>
          {title}
          &nbsp;
          { (rule === 'in' || rule === 'replace') && <Typography.Text type="secondary">(max {slotSize})</Typography.Text> }
        </span>
      );
    });
  }

  const onToggleAllPages = () => {
    if (selectedPages.length === 0) {
      const value = pageWidgets.map(({ key }) => [key]);
      form.setFieldValue("pages", value);
      setSelectedPages(value);
    } else {
      form.setFieldValue("pages", [])
      setSelectedPages([]);
    };
  };

  const dropdownRender = (menus: React.ReactNode) => (
    <div>
      <Checkbox
        onChange={onToggleAllPages}
        checked={selectedPages.length === pageWidgets.length}
        indeterminate={selectedPages.length !== pageWidgets.length && selectedPages.length > 0}
        style={{ padding: '8px 16px' }}
      >
        {selectedPages.length === 0 ? `Select all` : `Unselect all`}
      </Checkbox>
      <Divider style={{ margin: '0' }} />
      {menus}
    </div>
  );

  function handleSubmit(data: CreateFormValues) {
    setFormData(data);
    onSubmit();
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={handleChange}
      onFinish={handleSubmit}
      onFinishFailed={handleUpdateErrors}
    >
      <div style={{ marginBottom: '16px', textAlign: 'center' }}>
        <Typography.Title level={4}>New <RuleTypeTag style={{ verticalAlign: 'text-bottom' }} rule={rule}/> Override</Typography.Title>
        <Card style={{ margin: '24px 0' }} size="small">{getRuleDescription(rule)}</Card>
      </div>

      {rule === 'ban' ?
        (
          <Form.Item
            label={`Page`}
            name="pages"
            rules={[
              {
                required: true,
                message: `Please select a page`,
              }
            ]}
          >
            <Cascader
              fieldNames={{ label: 'title', value: 'key', children: 'none' }}
              options={pageWidgets}
              onChange={setSelectedPages}
              dropdownRender={dropdownRender}
              multiple
              maxTagCount="responsive"
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={`Page / Widget`}
            name="widgetName"
            rules={[
              {
                required: true,
                message: `Please select a page and widget`,
              }
            ]}
          >
            <Cascader
              fieldNames={{ label: 'title', value: 'key' }}
              options={pageWidgets}
              displayRender={displayRender}
              showSearch={{ filter }}
            />
          </Form.Item>
        )
      }

      <ArticleInput
        name="articleId"
        errors={articleIdErrors}
        onChange={handleUpdateErrors}
      />

      <Row gutter={[24,0]}>
        {
          (rule === 'in' || rule === 'replace') && (
            <Col span={12}>
              <Form.Item
                label={<>
                  Story Position&nbsp;<Typography.Text type="secondary">(max {maxSlotSize || '-'})</Typography.Text>
                </>}
                name="position"
                rules={[
                  {required: true, message: 'You must specify the position'},
                  {
                    validator: async (r, position) => {
                      if (position > maxSlotSize) throw new Error('Position must not be greater than max')
                    }
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} disabled={maxSlotSize === 0} min={1} max={maxSlotSize} inputMode="decimal"/>
              </Form.Item>
            </Col>
          )
        }
        <Col span={(rule === 'in' || rule === 'replace') ? 12 : 24}><Form.Item label="Expiration in Hours" name="expirationHourOfDay" rules={[{required: true, message: 'You must specify when the override will expire'}, {
          warningOnly: true,
          validator: async (r, hours) => {
            if (hours === 1176) throw new Error('Max hours reached');
          }
        }]}><InputNumber style={{ width: '100%' }} min={1} max={1176} inputMode="decimal"/></Form.Item></Col>
        <Col span={24}>
          <Form.Item name="overrideReasonId" label={<>Reason for override&nbsp;<Typography.Text type="secondary">(optional)</Typography.Text></>}>
            <Select options={reasons} placeholder="Select a reason" allowClear/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OverridesForm;
