import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './BreakdownCard.styles.scss';
import NoData from 'atoms/NoData';
import AnimatedNumber from 'atoms/AnimatedNumber';
import BreakdownBar from 'atoms/BreakdownBar';
import Table from 'molecules/SophiTable';

const BreakdownCard = ({ title, total, segments, displayFormat, loading }) => {
  const [hoveredBarSection, setHoveredBarSection] = useState(null);
  const [hoveredTableSection, setHoveredTableSection] = useState(null);

  const TABLE_COLUMNS = [
    {
      Header: '',
      accessor: 'color',
      Cell: ({ cell: { value }}) => (
        <span className={styles.breakdownCardLegend} style={{ backgroundColor: value }} />
      ),
      style: { borderRight: 'none', maxWidth: 50, width: 50 },
    },
    {
      Header: 'Segment',
      accessor: 'label',
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ cell: { value }}) => Math.round(value).toLocaleString(),
    },
    {
      Header: '%',
      accessor: 'value',
      id: 'percent',
      Cell: ({ cell: { value }}) => Math.round((value / total * 100) * 100) / 100,
    }
  ];

  return (
    <div className={classNames(styles.breakdownCard, {[styles.summary]: displayFormat === 'summary'})}>
      <div className={styles.breakdownCardHeader}>
        <h2>{title}</h2>
        {segments.length > 0 && <AnimatedNumber value={total} className={styles.breakdownCardTotal} />}
      </div>
      <div className={styles.breakdownCardBar} >
        <BreakdownBar
          loading={loading}
          segments={segments}
          selected={hoveredTableSection}
          total={total}
          onSegmentMouseOver={setHoveredBarSection}
        />
      </div>
      
      {(!loading && !total && segments.length === 0) && <NoData section="score" timePeriod />}

      {displayFormat === 'table' && (
        <div className={styles.breakdownCardTableContainer}>
          <Table
            variant="white"
            columns={TABLE_COLUMNS}
            onRowMouseEnter={(row) => setHoveredTableSection(row.original.label)}
            onRowMouseLeave={() => setHoveredTableSection(null)}
            loading={loading}
            data={segments}
            hideFooter
            hideDownload
            hideEditColumns
          />
        </div>
      )}
      {(displayFormat === 'summary' && !!total && segments.length > 0) && (
        <div className={styles.breakdownCardSummaryContainer}>
          {segments.map(({ value, label, color }, i) => {
            return (
              <div
                onMouseEnter={() => setHoveredTableSection(label)}
                onMouseLeave={() => setHoveredTableSection(null)}
                className={classNames(styles.breakdownCardSummaryItem, {
                  [styles.breakdownCardSelectedRow]: hoveredBarSection === label,
                })}
              >
                <span className={styles.breakdownCardLegend} style={{ backgroundColor: color }} />
                <div>
                  <div><span>{label}</span></div>
                  <div><span>{(value / total * 100).toFixed(0)}%</span></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

BreakdownCard.defaultProps = {
  displayFormat: 'table',
  loading: false,
  title: '',
  total: 0,
};

BreakdownCard.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  loading: PropTypes.bool,
  displayFormat: PropTypes.oneOf(['table', 'summary']),
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default BreakdownCard;
