// Utils
import { pathBuilder, getResource } from 'utils/api';

const FILTER_KEYS = {
  contentTypes: 'type',
  accesses: 'access',
  ownerships: 'ownership',
  creditLine: 'creditLine',
};

export const getFilterOptions = (host, client, token) => {
  const path = pathBuilder(host, {
    client,
    query: `filters`
  });

  return getResource({
    endpoint: path,
    token
  })
    .then((data) => {
      // Normalize response format for
      // Dive API sections endpoint and Now API sections endpoint
      const formatFilterOptions = (filter, data) => {

         // Now API
        if (filter === 'sections') {
          const filterOptions = [];
          data.forEach((section) => section && filterOptions.push({ section, id: section}));
          return filterOptions.sort((a, b) => a.section.localeCompare(b.section));
        }
        // Dive API
        return (data && Array.isArray(data)) ? data.filter((val) => !!val).sort() : data;
      };

      return Object.keys(data).reduce((obj, filter) => ({
        ...obj,
        [FILTER_KEYS[filter] || filter]: {
          data: formatFilterOptions(filter, data[filter]),
        }
      }), {});
    })
    .catch((error) => ({ error }));
};
