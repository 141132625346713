import { capitalize } from 'utils/string';
import { convertToSeconds } from 'utils/dateTime';
import { queryString, pathBuilder, getResource } from 'utils/api';

export const getComparisonChartData = (host, client, token, args) => {
  if (!args || !args.dateRange) throw new Error('Missing date range');

  const query = queryString({
    start: convertToSeconds(args.dateRange.startDate),
    end: convertToSeconds(args.dateRange.endDate),
  });

  const url = pathBuilder(host, {
    client,
    query: `section/chart?${query}` // TODO: Discuss fixing API structure here to be section/HOST/query like NOW APIs
  });

  return getResource({
    endpoint: url,
    token
  })
    .then((resp) => {
      // Process section values if available
      if (Array.isArray(resp)) {      
        return { response: resp.sort((a, b) => b.sophiScore - a.sophiScore) };
      }
      
      // Handle 204 noData response
      return [];
    });
};
