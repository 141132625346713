import React from 'react';
import { useSelector } from 'react-redux';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getTimezone } from 'selectors/auth';
import { convertToZonedDate } from 'utils/dateTime';

const ConvertToTimezone = ({ dateRange, onChange, children }) => {
  const timeZone = useSelector(getTimezone);
  
  const convertedDates = {...dateRange};
  
  if (dateRange.startDate) {
    convertedDates.startDate = convertToZonedDate(dateRange.startDate, timeZone);
  }

  if (dateRange.endDate) {
    convertedDates.endDate = convertToZonedDate(dateRange.endDate, timeZone);
  }

  const handleChange = (updatedRange) => {
    onChange({
      ...updatedRange,
      startDate: zonedTimeToUtc(updatedRange.startDate, timeZone),
      endDate: zonedTimeToUtc(updatedRange.endDate, timeZone),
    });
  };

  return React.cloneElement(
    children,
    { dateRange: convertedDates, onChange: handleChange, timeZone }
  );
};

export default ConvertToTimezone;
