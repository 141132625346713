/* eslint-disable jsx-a11y/no-static-element-interactions */
// Rule disabled as library handles this in spread props
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Icon from 'sophi-design-system/src/components/Icon';
import styles from './EditColumns.styles.scss';

const ColumnTile = ({
  active,
  onClear,
  onAdd,
  item,
  index,
  idSuffix,
  selected,
  showClone,
  disableTransform
}) => {
  const getStyle = (snapshot, styles) => {
    const { isDropAnimating, draggingOver } = snapshot;
    const badTransform = draggingOver === null && !active;
    
    const updatedStyles = {
      ...styles,
      transform: ((draggingOver === null && isDropAnimating) || disableTransform || badTransform) ? 'none' : styles.transform,
    };
    if ((draggingOver === null || draggingOver === 'ITEMS') && isDropAnimating) {
      updatedStyles.transitionDuration = '0.0001s';
    }
    return updatedStyles;
  };
  
  const handle = (
    <div className={styles.handle}>
      {!selected && <Icon type="basic" name="DragIndicator" size="md" color="teal" />}
    </div>  
  );

  const handleKeyDown = ({ keyCode }) => {
    if (onAdd && !active && keyCode === 13) {
      onAdd(item);
    }
  };

  return (
    <Draggable
      key={item[0]}
      draggableId={`${item[0]}${idSuffix || ''}`}
      index={index}
      isDragDisabled={selected}
    >
      {(provided, snapshot) => {
        return (
          <>
            <div
              onKeyDown={handleKeyDown}
              className={`${styles.item} ${snapshot.isDragging ? styles.dragging : ''} ${selected ? styles.selected : ''}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getStyle(snapshot, provided.draggableProps.style)}
            >
              <span>
                {handle}
                {item[1].label}
              </span>
              {onClear && (
                <button
                  title={`Remove ${item[1].textLabel || item[1].label} from selected columns`}
                  onClick={() => onClear(index)}
                >
                  <Icon type="basic" name="Clear" size="sm" color="teal" />
                </button>
              )}
            </div>
            {(showClone && snapshot.isDragging) && (
              <div className={`${styles.item} ${styles.clone}`}>
                <span>
                  {handle}
                  {item[1].label}
                </span>
              </div>
            )}
          </>
        );
      }}
    </Draggable>
  );
};

export default ColumnTile;
