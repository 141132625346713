// Root
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Utils
import Colors from 'styles/colors';

// SVG
import { ReactComponent as NotFoundIcon } from 'assets/svg/icon-not-found.svg';
import { setPageTitle } from 'utils/paths';

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      padding: '3.75rem 0',
    },
    [theme.breakpoints.up('xxl')]: {
      padding: '5.625rem 0',
    },
    padding: '2.5rem 0',
    textAlign: 'center',
    '& p': {
      color: Colors.grays[800]
    }
  },
  svg: {
    display: 'inline-block'
  },
  title: {
    color: Colors.blues[800]
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    background: '#FFFFFF',
  }
});

const NotFound = ({ classes, showButton }) => {
  useEffect(() => {
    setPageTitle('Not found');
  }, []);

  return (
    <section className={classes.container}>
      <NotFoundIcon className={classes.svg} />
      <Typography variant="h1" className={classes.title}>404 Page not found</Typography>
      <p>The requested page does not exist.</p>
      { showButton && (
        <>
          <p>Please go to the home page by clicking the button below.</p>
          <Link to={'/'} className={classes.link}>
            <Button variant="contained" className={classes.button}>
              Home
            </Button>
          </Link>
        </>
      )}
    </section>
  );
};

NotFound.defaultProps = {
  showButton: true
};

export default withStyles(styles)(NotFound);
