import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import headerReducer from './header';
import articleReducer from './article';
import contentReducer from './content';
import overviewReducer from './overview';
import diveReducer from './dive';
import homeReducer from './home';
import authReducer from './auth';
import globalReducer from './global';
import { reducer as adminReducer } from './admin';
import cacheReducer from './cache';
import configReducer from './config';

// TODO: Code split our reducers - https://github.com/sagiavinash/redux-store-manager
export default (history) => {
  return combineReducers({
    auth: authReducer,
    admin: adminReducer,
    router: connectRouter(history),
    header: headerReducer,
    article: articleReducer,
    content: contentReducer,
    overview: overviewReducer,
    dive: diveReducer,
    home: homeReducer,
    global: globalReducer,
    cache: cacheReducer,
    config: configReducer,
  });
};
