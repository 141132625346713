/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'sophi-design-system/src/components/Icon';
import debounce from 'lodash/debounce';
import styles from './TreeNavigation.styles.scss';

const SearchInput = ({ onChange, id }) => {
  const searchRef = useRef(null);
  const [filterValue, setFilterValue] = useState('');
  const [focused, setFocused] = useState(false);
  const debouncedOnChange = debounce(onChange, 500);

  const handleInputChange = ({ target: { value }}) => {
    setFilterValue(value);
    debouncedOnChange(value);
  };

  // Focus the input when the magnifying glass icon is clicked
  const handleIconClick = () => {
    if (searchRef.current) {
      searchRef.current?.focus?.();
    }
  };

  const handleClear = () => {
    setFilterValue('');
    onChange('');
  };

  return (
    <div className={classNames(styles.searchInput, { [styles.searchInputFocused]: focused })}>
      <div onClick={handleIconClick}><Icon name="Search" color="teal" /></div>
      <input
        aria-label="Filter Options"
        id={id}
        ref={searchRef}
        placeholder="Search"
        value={filterValue}
        onChange={handleInputChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {filterValue && (
        <button onClick={handleClear} title="Clear Filter">
          <Icon name="Clear" color="black" />
        </button>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default SearchInput;
