// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-vOOBVVDu7PK5_7YePP_L{container-type:inline-size}.sophi-Zc8hXI28F86rinl7KB1u{display:grid;grid-template-columns:1fr;-webkit-column-gap:calc(.5*var(--space-1));-moz-column-gap:calc(.5*var(--space-1));column-gap:calc(.5*var(--space-1));margin-bottom:calc(1*var(--space-1));-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}@container (min-width: 400px){.sophi-Zc8hXI28F86rinl7KB1u{grid-template-columns:minmax(0, 1fr) minmax(0, 1fr)}.sophi-EHE7fAB3XwE3z8o6MIK7{display:block}.sophi-EHE7fAB3XwE3z8o6MIK7,.sophi-H9wOr9nG6w0J2goOjSW5{justify-self:end;text-align:right}.sophi-puEm_PNRPRh4GUrZdnt9{width:100%;grid-column:2/3;grid-row:1/3}}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/FormItemWrapper/FormItemWrapper.styles.scss"],"names":[],"mappings":"AAAA,4BACE,0BAAA,CAGF,4BACE,YAAA,CACA,yBAAA,CACA,0CAAA,CAAA,uCAAA,CAAA,kCAAA,CACA,oCAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAGF,8BACE,4BACE,mDAAA,CAEF,4BACE,aAAA,CAEF,wDACE,gBAAA,CACA,gBAAA,CAEF,4BACE,UAAA,CACA,eAAA,CACA,YAAA,CAAA","sourcesContent":[".container {\n  container-type: inline-size;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr;\n  column-gap: calc(0.5 * var(--space-1));\n  margin-bottom: calc(1 * var(--space-1));\n  align-items: baseline;\n}\n\n@container (min-width: 400px) {\n  .grid {\n    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);\n  }\n  .label {\n    display: block;\n  }\n  .label, .hint {\n    justify-self: end;\n    text-align: right;\n  }\n  .input {\n    width: 100%;\n    grid-column: 2 / 3;\n    grid-row: 1 / 3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-vOOBVVDu7PK5_7YePP_L",
	"grid": "sophi-Zc8hXI28F86rinl7KB1u",
	"label": "sophi-EHE7fAB3XwE3z8o6MIK7",
	"hint": "sophi-H9wOr9nG6w0J2goOjSW5",
	"input": "sophi-puEm_PNRPRh4GUrZdnt9"
};
export default ___CSS_LOADER_EXPORT___;
