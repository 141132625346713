import React, { useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './DateRangePicker.styles.scss';

const DatePresets = ({ presetOptions, selectedPreset, id, onChange, altStyle }) => {
  const selectedOption = useMemo(() => presetOptions.findIndex((val) => val === selectedPreset), [selectedPreset]);
    
  return (
    <FormControl>
      <Select
        data-track-click="true"
        data-track-name={`${id}-preset-select`}                  
        id={`${id}-preset-select`}
        className={`${styles.select} ${altStyle ? styles.selectAlt : ''}`}
        value={selectedOption >= 0 ? selectedOption : 0}
        onChange={(e) => onChange(presetOptions[e.target.value])}
      >
        {presetOptions.map((preset, i) => (
          <MenuItem
            data-track-click="true"
            data-track-name={`${id}-preset-option-${preset.label}`}                    
            className={styles.item}
            key={preset.label}
            value={i}
          >
            {preset.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DatePresets;
