// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-kiwil7AqmlR5UaWwjLxO{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center}.sophi-kiwil7AqmlR5UaWwjLxO h1{line-height:1.5em}", "",{"version":3,"sources":["webpack://./sophi-design-system/src/components/PlaceholderText/PlaceholderText.styles.scss"],"names":[],"mappings":"AAAA,4BACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,iBAAA,CAEA,+BACI,iBAAA","sourcesContent":[".wrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    h1 {\n        line-height: 1.5em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "sophi-kiwil7AqmlR5UaWwjLxO"
};
export default ___CSS_LOADER_EXPORT___;
