import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
// Utils
import Colors from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  // NOTE: this is the base style, so keep it at the top for the cascade
  button: {
    height: theme.typography.pxToRem(36),
    fontFamily: `"Montserrat-SemiBold", sans-serif`,
    fontSize: theme.typography.pxToRem(13),
    textTransform: `uppercase`,
    color: Colors.grays[800],
    backgroundColor: `#FFF`,
    boxShadow: `0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)`,

    '&:hover': {
      color: Colors.blues[800],
      backgroundColor: Colors.blues[100],
    },
    '&:active': {
      color: Colors.blues[800],
      backgroundColor: Colors.blues[400],
    },
  },
  text: {
    background: `none`,
    boxShadow: `none`,
    textDecoration: `underline`,
    textTransform: 'initial',
    fontSize: theme.typography.pxToRem(15),
    '&:hover, &:focus': {
      color: Colors.blues[800],
    },
    '&:active': {
      color: Colors.grays[700]
    },

    '&&:active, &&:focus, &&:hover': { // NOTE: To have greater specificity
      background: `none`,
      textDecoration: `underline`,
    },
    '&&:focus': {
      boxShadow: `0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)`,
    }
  },
  icon: {
    height: `auto`,
    minWidth: `auto`,
    padding: 0,
    background: `none`,
    boxShadow: `none`,
  },
  action: {
    color: Colors.grays[800],
    backgroundColor: Colors.greens[200],
    '&:hover': {
      color: Colors.grays[800],
      backgroundColor: Colors.greens[300],
    },
    '&:active': {
      color: Colors.grays[800],
      backgroundColor: Colors.greens[500],
    },
  },
  contained: {
    '&$disabled': {
      color: Colors.grays[400],
      backgroundColor: `#FFF`,
      boxShadow: `0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)`,
    }
  },
  disabled: {}, // for MUI override
  large: {
    height: theme.typography.pxToRem(44),
  },
  secondary: {
    color: `#FFF`,
    backgroundColor: Colors.pinks[700],
    '&:hover': {
      color: `#FFF`,
      backgroundColor: Colors.pinks[500],
    },
    '&:active': {
      color: `#FFF`,
      backgroundColor: Colors.pinks[900],
    },
  },
  padding: {
    padding: 10
  }
}));

const MuiButton = (props) => {
  const {
    children,
    className,
    disabled,
    padding = false,
    size,
    type,
    variant,
    track,
    ...btnProps
  } = props;

  const classes = useStyles();
  let classNames = [`${classes.button}`];

  // variant
  variant === `text` ? classNames.push(`${classes.text}`) :
  variant === `icon` ? classNames.push(`${classes.icon}`)
                     : classNames.push(`${classes.contained}`);

  // type
  type === `secondary`  ? classNames.push(`${classes.secondary}`) :
  type === 'action'     ? classNames.push(`${classes.action}`)
                        : '';

  // size
  if (size === 'large' || size === 'lg') {
    classNames.push(`${classes.large}`);
  }

  // is disabled?
  if (disabled) {
    classNames.push(`${classes.disabled}`);
  }

  if (padding) {
    classNames.push(`${classes.padding}`);
  }

  if (className) {
    classNames.push(className);
  }

  const additionalProps = {};

  if (track) {
    additionalProps['data-track-click'] = 'true';
    additionalProps['data-track-name'] = btnProps.id;
  }

  return(
    <Button
      disabled={disabled}
      disableRipple
      className={classNames.join(' ')}
      variant={variant && variant !== `icon` ? variant : 'contained'}
      {...additionalProps}
      {...btnProps}
    >
      {children}
    </Button>
  );
};

MuiButton.defaultProps = {
  className: null,
  disabled: false,
  padding: false,
  size: null,
  style: {},
  type: null,
  variant: null,
};

MuiButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  padding: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'lg']),
  style: PropTypes.object,
  type: PropTypes.oneOf(['secondary', 'action']),
  variant: PropTypes.oneOf(['text', 'icon']),
};

export default MuiButton;