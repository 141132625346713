import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import subMonths from 'date-fns/subMonths';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import { isDefined } from 'utils/objects';
import { endOfTimezoneDay, startOfTimezoneDay, startOfTimezoneMonth, endOfTimezoneMonth } from 'utils/dateTime';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const calculatePresetRange = (state, opts = {}) => {
  const { publishDate } = state;
  const now = new Date();
  const getValue = (key) => state.selectedPreset ? state.selectedPreset.value[key] : null;
  const months = opts.selectedPreset ? opts.selectedPreset.months : getValue('months');
  const days = opts.selectedPreset ? opts.selectedPreset.days : getValue('days');
  const hours = opts.selectedPreset ? opts.selectedPreset.hours : getValue('hours');
  const midnight = opts.selectedPreset ? opts.selectedPreset.midnight : getValue('midnight');
  const offset = opts.selectedPreset ? opts.selectedPreset.hourOffset : getValue('hourOffset');
  const refDate = opts.selectedPreset ? opts.selectedPreset.refDate : getValue('refDate');
  const dateScope = opts.dateScope ? opts.dateScope : state.dateScope;
  const custom = opts.selectedPreset ? opts.selectedPreset.custom : getValue('custom');
  
  if (custom && typeof custom === 'function') {
    return custom(now, opts.timeZone);
  }

  /**
   * This block cannot be simplified down to (refDate === 'publish' && publishDate) 
   * because it would be execute the else statement when refDate is not 'publish'. This
   * would cause errors for 'now' and 'dateScope'
   */
  if (refDate === 'publish') {
    if (publishDate) {
      let start = publishDate;
      let end = null;
      if (isDefined(months)) {
        end = addMonths(start, months);
      } else if (isDefined(days)) {
        end = addDays(start, days);
      } else if (isDefined(hours)) {
        end = addHours(start, hours);
      } else {
        end = now;
      }
      return ({
        startDate: midnight ? startOfTimezoneDay(start, opts.timeZone) : utcToZonedTime(start, opts.timeZone),
        endDate: midnight ? endOfTimezoneDay(end, opts.timeZone) : utcToZonedTime(end, opts.timeZone),
      });
    } else {
      console.error(`The selected preset uses 'refDate' of 'publish' but no publishDate was specified`);
      return state;
    }
  }

  // When refDate === now, we assume you want the end to be now or an offset of now
  if (refDate === 'now') {
    let start = null;
    let end = new Date();
    if (isDefined(months)) {
      start = subMonths(end, months);
    } else if (isDefined(days)) { // Loose comparison since days can be undefined or null
      start = subDays(end, days);
    } else if (isDefined(hours)) {
      start = subHours(end, hours);
    }

    if (isDefined(offset)) { // Loose comparison since days can be undefined or null
      end = subHours(end, offset);
    }
    return ({
      startDate: midnight ? startOfTimezoneDay(start, opts.timeZone) : utcToZonedTime(start, opts.timeZone),
      endDate: midnight ? endOfTimezoneDay(end, opts.timeZone) : utcToZonedTime(end, opts.timeZone),
    });
  }

  //When refDate === scope, we are matching the dateScope prop
  if (refDate === 'scope') {
    if (dateScope) {
      let start = dateScope.startDate;
      let end = dateScope.endDate;
      if (isDefined(months)) {
        start = subMonths(start, months);
      } else if (isDefined(days)) {
        start = subDays(start, days);
      } else if (isDefined(hours)) {
        start = subHours(start, hours);
      }
      return ({
        startDate: midnight ? startOfTimezoneDay(start, opts.timeZone) : utcToZonedTime(start, opts.timeZone),
        endDate: midnight ? endOfTimezoneDay(end, opts.timeZone) : utcToZonedTime(end, opts.timeZone),
      });
    } else {
      console.error(`The selected preset uses 'refDate' of 'scope' but no dateScope was specified`);
      return state;
    }
  }

  if (!state.startDate) {
    state.startDate = new Date();
    state.preset = { label: 'Custom', value: { custom: true } };
  }

  if (!state.endDate) {
    state.endDate = endOfTimezoneDay(state.startDate, opts.timeZone);
    state.preset = { label: 'Custom', value: { custom: true } };
  }

  return state;
};

export const ANALYSIS_PERIOD_OPTIONS = [
  {
    label: 'Today',
    value: {
      days: 0,
      hours: null,
      midnight: true,
      refDate: 'now',
    }
  },
  {
    label: 'Yesterday',
    value: {
      days: null,
      hours: 24,
      hourOffset: 24,
      midnight: true,
      refDate: 'now',
    }
  },
  {
    label: 'Today & Yesterday',
    value: {
      days: null,
      hours: 24,
      midnight: true,
      refDate: 'now',
    }
  },
  {
    label: 'Past 7 Days',
    value: {
      days: 7,
      hours: null,
      midnight: true,
      refDate: 'now',
    }
  },
];

export const PUBLISHED_DATE_OPTIONS = [
  {
    label: 'Match Analysis Period',
    value: {
      days: null,
      hours: 0,
      midnight: false,
      refDate: 'scope',
    }
  },
  {
    label: 'Analysis Period + 2 Days Before',
    value: {
      days: 2,
      hours: null,
      midnight: true,
      refDate: 'scope',
    }
  },
  {
    label: 'Analysis Period + 3 Days Before',
    value: {
      days: 3,
      hours: null,
      midnight: true,
      refDate: 'scope',
    }
  },
  {
    label: 'Analysis Period + 6 Days Before',
    value: {
      days: 6,
      hours: null,
      midnight: true,
      refDate: 'scope',
    }
  },
];

export const DIVE_DATE_OPTIONS = [
  {
    label: 'Most Recent 7 Days',
    value: {
      custom: (now, timeZone) => {
        const localDate = utcToZonedTime(now, timeZone);
        const startDate = startOfTimezoneDay(zonedTimeToUtc(subDays(localDate, 14), timeZone), timeZone);
        const endDate = endOfTimezoneDay(zonedTimeToUtc(subDays(localDate, 7), timeZone), timeZone);
        return { startDate, endDate };
      }
    }
  },
  {
    label: 'Most Recent 30 Days',
    value: {
      custom: (now, timeZone) => {
        const localDate = utcToZonedTime(now, timeZone);
        const startDate = startOfTimezoneDay(zonedTimeToUtc(subDays(localDate, 37), timeZone), timeZone);
        const endDate = endOfTimezoneDay(zonedTimeToUtc(subDays(localDate, 7), timeZone), timeZone);
        return { startDate, endDate };
      }
    }
  },
  {
    label: 'This Month',
    value: {
      custom: (now, timeZone) => {
        const localDate = utcToZonedTime(now, timeZone);
        const startDate = startOfTimezoneMonth(new Date(now), timeZone);
        const endDate = localDate;
        return { startDate, endDate };
      }
    }
  },
  {
    label: 'Last Month',
    value: {
      custom: (now, timeZone) => {
        const localDate = utcToZonedTime(now, timeZone);
        const lastMonth = zonedTimeToUtc(subMonths(localDate, 1), timeZone);
        const startDate = startOfTimezoneMonth(lastMonth, timeZone);
        const endDate = endOfTimezoneMonth(lastMonth);
        return { startDate, endDate };
      }
    }
  },
  {
    label: 'Last 3 Months',
    value: {
      months: 3,
      midnight: true,
      refDate: 'now',
    }
  },
  {
    label: 'Last 6 Months',
    value: {
      months: 6,
      midnight: true,
      refDate: 'now',
    }
  },
  {
    label: 'Last 9 Months',
    value: {
      months: 9,
      midnight: true,
      refDate: 'now',
    }
  },
];

export const getAnalysisRange = (preset) => {
  const config = ANALYSIS_PERIOD_OPTIONS.find((opt) => opt.label === preset);
  return calculatePresetRange({ selectedPreset: config });
};

export const getPublishedRange = (preset, dateScope) => {
  const config = PUBLISHED_DATE_OPTIONS.find((opt) => opt.label === preset);
  return calculatePresetRange({ selectedPreset: config }, { dateScope });
};

export const getDiveRange = (preset, dateScope) => {
  const config = DIVE_DATE_OPTIONS.find((opt) => opt.label === preset);
  return calculatePresetRange({ selectedPreset: config });
};

export default calculatePresetRange;
