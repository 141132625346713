import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Input.styles.scss';

const Input = ({
  id,
  label,
  onChange,
  required,
  invalid,
  variant,
  value,
  inputRef,
  errorMessage,
  inputComponent,
  className,
  prefix,
  ...props
}) => {
  const [edited, setEdited] = useState(false);
  const defaultRef = useRef(null);
  const ref = inputRef || defaultRef;

  const handleBlur = () => setEdited(true);

  const inputInvalid = !ref.current?.validity?.valid || invalid;

  const inputProps = {
    ref,
    id,
    onChange,
    onBlur: handleBlur,
    type: 'text',
    required,
    value,
    ...props,
  };

  const getInput = () => {
    // This allows the input to be replaced (to support validation with rifm)
    if (inputComponent) return inputComponent(inputProps);
    return <input {...inputProps} />;
  };

  const requiredMessageMarkup = <span aria-hidden="true" className={styles.required}>*Required</span>;

  const message = (!errorMessage && required) ? 'Required' : errorMessage;
  const errorMessageMarkup = <p aria-live="assertive" aria-atomic="true" className={styles.errorMessage}>{message}</p>;

  const showError = invalid || (edited && inputInvalid);

  // Returns the form style of input (sliding label)
  if (variant === 'form') {
    return (
      <div className={classNames([styles.inputContainer, className, { [styles.edited]: edited, [styles.invalid]: invalid }])}>
        <div className={styles.labelContainer}>
          {prefix && <span className={styles.prefix}>{prefix}</span>}{getInput()}
          <label className={styles.label} htmlFor={id}>{label}</label>
        </div>
        {(required && !showError) && requiredMessageMarkup}
        {showError && errorMessageMarkup}
      </div>
    );
  }

  // Returns full size input with block label
  return (
    <div className={className}>
      <div>
        <label className={styles.label} htmlFor={id} data-required={required}>{label}</label>
        {required && requiredMessageMarkup}
      </div>
      <div className={classNames([styles.input, { [styles.edited]: edited, [styles.invalid]: invalid }])}>
        {prefix && <span className={classNames([styles.prefix, 'prefix'])}>{prefix}</span>}{getInput()}
        <span />
      </div>
      {showError && errorMessageMarkup}
    </div>
  );
};

Input.defaultProps = {
  required: false,
  invalid: false,
  variant: 'default',
  errorMessage: 'Value in this field is invalid',
  inputRef: null,
  inputComponent: null,
  className: '',
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  inputComponent: PropTypes.func, // Function must return a valid react component
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'form']),
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  prefix: PropTypes.string,
};

export default Input;
