// Date-fns
import isAfter from 'date-fns/isAfter';
import addMinutes from 'date-fns/addMinutes';

// Utils
import { convertToSeconds, convertSecondsToDate} from 'utils/dateTime';
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Internal Promotions Timeline Chart
 * @param {String} id - String representing the contentID
 * @param {Object} dateRange - Object with start and end time
 */
const getInternalPromotionsTimeline = (host, client, id, token, dateRange, timezone) => {
  if (paramsAreTruthy([id, dateRange])) {
    const { startDate, endDate } = dateRange;
    const now = new Date();
    let end = isAfter(endDate, now) ? now : endDate;

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `score-analysis/internal-timeline?start=${convertToSeconds(startDate)}&end=${convertToSeconds(end)}`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const formattedData = data.map((item) => ({
        ...item,
        start: convertSecondsToDate(item.firstAppearance, timezone),
        end: addMinutes(convertSecondsToDate(item.firstAppearance, timezone), item.minutesPromoted)
      }));
      return {
        response: {
          data: formattedData,
        },
      };
    };

    return data;
  }
};

export default getInternalPromotionsTimeline;