import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.styles.scss';

const TableFooter = ({ hideFooter, footerGroups }) => {
  if (hideFooter) return null;

  return (
    <tfoot>
      {footerGroups.map((group, i) => (
        <tr key={`footer-group-${i}`} {...group.getFooterGroupProps()} className={styles.total}>
          {group.headers.map((column) => {
            const { style, className, ...tdProps } = column.getFooterProps([
              {
                className: column.className,
                style: column.style,
              }
            ]);

            return (
              <td
                key={column.id}
                {...tdProps}
                className={`${styles.bodyCell} ${className} ${column.accessor === 'expander' ? styles.expander : ''}`}
                style={{ ...style, borderRight: undefined }} // Remove any additional right borders added in column defs
                data-content-type={column.contentType || 'text'}
              >
                {column.render('Footer')}
              </td>
            );
          })}
        </tr>
      ))}
    </tfoot>
  );
};

TableFooter.propTypes = {
  hideFooter: PropTypes.bool,
  footerGroups: PropTypes.array,
};

export default TableFooter;
