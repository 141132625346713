import { put, takeLatest, take, select, call } from 'redux-saga/effects';
import {
  ARTICLE_FETCH_DATA,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_FETCH_FAILED,
} from '../actions/article';
import { HEADER_UPDATE } from '../actions/header';
import * as contentAPI from 'apis/content/meta/meta';
import { getAuthValues } from './auth';
import contentTypes from 'actions/contentTypes';
import * as authSelectors from 'selectors/auth';
import { getId } from 'selectors/content';

export function* fetchArticle() {
  try {
    let id = yield select(getId);
    if (!id) {
      // ID may be null, wait for it to set before making the API call
      yield take(contentTypes.SET_ID_SUCCESS);
    }
    id = yield select(getId);
    const { token, client, host } = yield call(getAuthValues, 'now');
    const excludedFeatures = yield select(authSelectors.getDisabledNowProductFeatures);
    const data = yield call(contentAPI.getArticleInfo, host, client, id, token, excludedFeatures);

    if (data.noData) {
      yield put({ type: ARTICLE_FETCH_SUCCESS, data: { noData: true } });
    } else if (data.noAccess) {
      yield put({ type: ARTICLE_FETCH_FAILED, error: { noAccess: true } });
    } else {
      yield put({ type: ARTICLE_FETCH_SUCCESS, data });
      yield put({ type: HEADER_UPDATE, title: data.headline });
    }
  } catch (e) {
    yield put({ type: ARTICLE_FETCH_FAILED, error: e });
  }
}

export default function* articleSaga() {
  yield takeLatest(ARTICLE_FETCH_DATA, fetchArticle);
}
