import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'sophi-design-system/src/components/Button';
import styles from './EditColumns.styles.scss';
import DraggableColumns from './DraggableColumns';
import ClickableColumns from './ClickableColumns';
import useMedia from 'hooks/useMedia';

const EditColumns = ({
  singleColumns = new Map(),
  perDeviceColumns = new Map(),
  selectedColumns,
  requiredColumns,
  defaultColumns,
  onSubmit,
  onCancel,
}) =>  {
  const [selected, setSelected] = useState([]);
  const ITEMS = useMemo(() => singleColumns ? [...singleColumns.entries()] : [], [singleColumns]);

  const PER_DEVICE_ITEMS = useMemo(() => {
    const deviceMap = [...perDeviceColumns.entries()].reduce((map, [key, { group, platform, always: required }]) => {
      const exists = map.get(group);
      const value = { label: group, platforms: new Map() };

      if (exists) {
        const { always = [], platforms } = exists;
        // Combine new platform key with existing platforms
        platforms.set(platform.toLowerCase(), key);
        value.platforms = platforms;
        // Check if there is an always value and only include the always array if it contains values
        const alwaysArray = [ ...always ];
        if (required) alwaysArray.push(key);
        if (alwaysArray.length) value.always = alwaysArray;
      } else {
        value.platforms.set(platform.toLowerCase(), key);
        if (required) value.always = [ key ];
      }

      map.set(group, value);
      return map;
    }, new Map());
    return [...deviceMap.entries()];
  }, [perDeviceColumns]);

  // Get flattened list of options
  const ALL_ITEMS = useMemo(() => {
    const perDevice = [];
    for (const [key, value] of perDeviceColumns) {
      const {group, platform} = value;
      perDevice.push([
        key,
        {
          ...value,
          label: <span><b>{group}</b>: {platform}</span>,
        }
      ]);
    }
    return new Map([
      ...singleColumns.entries(),
      ...perDevice,
    ]);
  }, [singleColumns, perDeviceColumns]);

  // Get the required columns that will be pinned to the top of the selected list
  const PINNED = useMemo(() => {
    if (!requiredColumns || !Array.isArray(requiredColumns)) return [];

    return requiredColumns.reduce((list, col) => {
      const config = ALL_ITEMS.get(col);
      return config ? [...list, [col, config]] : list;
    }, []);
  }, [requiredColumns]);

  useEffect(() => {
    if (selectedColumns) {
      // Should remove the "required" columns from selected as all
      // columns in selected are editable
      const selectedConfig = [];
      selectedColumns.forEach((col) => {
        if (!requiredColumns || !Array.isArray(requiredColumns) || !requiredColumns.includes(col)) {
          const colDef = ALL_ITEMS.get(col);
          if (colDef) {
            selectedConfig.push([col, colDef]);
          }
        }
      });
      setSelected(selectedConfig);
    }
  }, [selectedColumns]);

  // Need breakpoint to mount correct layout
  const isMobile = useMedia(['(min-width: 1024px)'], [false], true);

  const handleSubmit = () => {
    const formattedList = selected.map(([id]) => id);
    // Make sure required columns are submitted along with user selected columns
    onSubmit && onSubmit([...requiredColumns, ...formattedList]);
  };

  const handleResetToDefaults = () => {
    const defaults = defaultColumns.reduce((list, item) => {
      // Don't add required columns as selections as they are not editable
      if (requiredColumns && requiredColumns.includes(item)) return list;

      const option = ALL_ITEMS.get(item);
      if (!option) return list;
      return [...list, [item, ALL_ITEMS.get(item)]];
    }, []);
    setSelected(defaults);
  };

  const handleClearAll = () => setSelected([]);

  // Select all of the devices of a given segment
  const handleSelectAll = (platforms) => {
    const newSelections = [];
    [...platforms.entries()].forEach(([device, key]) => {
      if (!selected.some(([k]) => k === key) && !requiredColumns.includes(key)) {
        const option = ALL_ITEMS.get(key);
        newSelections.push([key, option]);
      }
    });
    setSelected([...selected, ...newSelections]);
  };

  return (
    <div className={styles.editColumns}>
      <div className={styles.header}>
        <h2>Edit Columns</h2>
        <p>
          {isMobile
            ? 'Get started by selecting a column name in the Available Columns.'
            : 'Get started by dragging an element to the Selected section.'
          }
        </p>
      </div>
      <div className={styles.body}>
        {!isMobile && (
          <DraggableColumns
            singleOptions={ITEMS}
            perDeviceOptions={PER_DEVICE_ITEMS}
            flattenedItems={ALL_ITEMS}
            selected={selected}
            pinnedColumns={PINNED}
            onChange={setSelected}
            onClearAll={handleClearAll}
            onSelectAll={handleSelectAll}
          />
        )}
        {isMobile && (
          <ClickableColumns
            singleOptions={ITEMS}
            perDeviceOptions={PER_DEVICE_ITEMS}
            flattenedItems={ALL_ITEMS}
            selected={selected}
            pinnedColumns={PINNED}
            onChange={setSelected}
            onClearAll={handleClearAll}
            onSelectAll={handleSelectAll}
          />
        )}
      </div>
      <div className={styles.footer}>
        <div>
          {(defaultColumns && defaultColumns.length > 0) && (
            <Button
              className={styles.resetBtn}
              onClick={handleResetToDefaults}
            >
              Reset Selection
            </Button>
          )}
        </div>
        <div style={{ margin: '10px 0' }}>
          <Button size="sm" variant="secondary" onClick={onCancel}>Cancel</Button>
          <Button size="sm" variant="primary" onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </div>
  );
};

EditColumns.defaultProps = {
  requiredColumns: [],
  defaultColumns: [],
};

EditColumns.propTypes = {
  requiredColumns: PropTypes.array,
  defaultColumns: PropTypes.array,
};

export default EditColumns;
