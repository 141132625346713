"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPageConfigResponse = exports.typeLabels = void 0;
exports.typeLabels = {
    boolean: 'bool',
    date: 'date',
    int: 'int',
    long: 'long',
    string: 'string',
    stringarray: 'string-array',
};
function isPageConfigResponse(val) {
    return (typeof val === 'object' &&
        val !== null &&
        'config' in val &&
        'status' in val &&
        'widgets' in val &&
        'updatedOn' in val &&
        'createdOn' in val);
}
exports.isPageConfigResponse = isPageConfigResponse;
