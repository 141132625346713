import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  userRoles: [],
  userEditRoles: [],
  usersLoading: false,
  userAdminMessage: null,
  userActionLoading: false,
  userActionError: null,
  pagination: {
    page: 0,
    size: 25,
    count: 0,
  }
};

const sendPasswordReset = createAction('userAdmin/sendPasswordReset');

const adminSlice = createSlice({
  name: 'userAdmin',
  initialState,
  reducers: {
    getUsers: (state) => {
      state.usersLoading = true;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload.users;
      state.pagination.count = action.payload.total;
      state.usersLoading = false;
    },
    getUsersError: (state, action) => {
      state.usersLoading = false;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setUserAdminMessage: (state, action) => {
      state.userAdminMessage = action.payload;
    },
    resetUserAdminState: (state) => {
      state.userActionError = null;
    },
    createUser: (state) => {
      state.userActionLoading = true;
      state.userActionError = null;
    },
    createUserSuccess: (state) => {
      state.userActionLoading = false;
    },
    createUserError: (state, action) => {
      state.userActionLoading = false;
      state.userActionError = action.payload;
    },
    editUser: (state) => {
      state.userActionLoading = true;
      state.userActionError = null;
    },
    editUserSuccess: (state) => {
      state.userActionLoading = false;
    },
    editUserError: (state, action) => {
      state.userActionLoading = false;
      state.userActionError = action.payload;
    },
    deleteUser: (state) => {
      state.userActionLoading = true;
      state.userActionError = null;
    },
    deleteUserSuccess: (state) => {
      state.userActionLoading = false;
    },
    deleteUserError: (state, action) => {
      state.userActionLoading = false;
      state.userActionError = action.payload;
    },
    getRoles: (state) => {
      state.userActionError = null;
    },
    getRolesSuccess: (state, action) => {
      state.userRoles = action.payload.roles;
    },
    getRolesError: (state, action) => {
      state.userActionError = action.payload;
    },
    getEditRoles: (state) => {
      state.userActionError = null;
      state.userEditRoles = [];
    },
    getEditRolesSuccess: (state, action) => {
      state.userEditRoles = action.payload.roles;
    },
    getEditRolesError: (state, action) => {
      state.userActionError = action.payload;
    }
  }
});

export const actions = {
  ...adminSlice.actions,
  sendPasswordReset,
};

export const reducer = adminSlice.reducer;
