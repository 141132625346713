// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{--space-1: 10px}@media(min-width: 768px){.sophi-aWnDldB_EJAUFQ9qYsKF{padding:30px 6vw}}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/Home/Home.styles.scss"],"names":[],"mappings":"AAAA,KACE,eAAA,CAIA,yBADF,4BAEI,gBAAA,CAAA","sourcesContent":["html {\n  --space-1: 10px;\n}\n\n.container {\n  @media (min-width: 768px) {\n    padding: 30px 6vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-aWnDldB_EJAUFQ9qYsKF"
};
export default ___CSS_LOADER_EXPORT___;
