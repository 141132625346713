import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import rootSaga from '../sagas';


export const history = createBrowserHistory();

export default function store(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const logger = createLogger({
    collapsed: true,
    timestamp: false,
  });
  let middleware;
  if (process.env.NODE_ENV === 'development') {
    middleware = composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger));
  } else {
    middleware = composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware));
  }

  let store = createStore(
      rootReducer(history),
      initialState,
      compose(middleware)
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
