import React, { useMemo } from 'react';
import { Space, Typography } from 'antd';

import { FEOverride } from '../types';
import { cardDateFormatter, getStatus } from '../utils/copy';
import { useOverrides } from '../contexts/OverridesProvider';

const DetailsCardMeta: React.FC<{
  data: FEOverride[];
}> = ({ data }) => {
  const { creationDate, requestedUserName, requestId } = data[0];
  const statusOptions = useMemo(() => {
    const statuses = {
      ACTIVE: 0,
      FAILED: 0,
      PENDING: 0,
      DELETE: 0,
    }
    for (const override of data) {
      statuses[override.status] += 1;
    }
    return getStatus(statuses);
  }, [data]);

  return (
    <Space size="middle">
      <span style={{ fontSize: '2em' }}>
        {statusOptions.icon}
      </span>
      <Typography.Text>
        {statusOptions.label}
        <br/>
        <small style={{ fontWeight: 'normal' }}>Created {cardDateFormatter(creationDate)} by {requestedUserName}</small>
      </Typography.Text>
    </Space>
  );
}

export default DetailsCardMeta;
