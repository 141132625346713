import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import tableau from 'tableau-api';

import LoadingState from 'sophi-design-system/src/components/LoadingState';
import styles from './TableauWrapper.styles.scss';

const TableauWrapper = ({ url, width }) => {
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);
  const vizRef = useRef(null);

  const initViz = () => {
    if (vizRef.current) {
      vizRef.current.dispose();
    }
    vizRef.current = new window.tableau.Viz(containerRef.current, url, getOptions());
  };

  useEffect(() => {
    if (width && url) {
      setLoading(true);
      initViz();
    }
  }, [width, url]);

  const setFrameSize = (width, height) => {
    try {
      vizRef.current.setFrameSize(width, height);
    } catch {}
  };

  const getOptions = () => {
    return {
      hideTabs: true,
      hideToolbar: false,
      width,
      height: 600,
      onFirstInteractive: (e) => {
        setLoading(false);
      },
      onFirstVizSizeKnown: (e) => {
        const { sheetSize } = e.getVizSize();
        const { minSize } = sheetSize;
        setFrameSize(width, minSize.height + 50);
      }
    };
  };

  return ( <div className={classNames({[styles.loading]: loading}, styles.wrapper)}>
    <div className={classNames({[styles.loading]: loading}, styles.indicator)}>
      <LoadingState height={600} />
    </div>
    <div ref={containerRef} />
  </div> );
};

export default TableauWrapper;
