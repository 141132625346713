// Root
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

// React Table
import { useTable, useExpanded } from 'react-table';

// Utils
import { trackCustomInteraction } from '../../utils/tracking';

import ErrorState from '../ErrorState';
import Placeholder from '../Placeholder';
import LoadingState from '../LoadingState';

// Table Component
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableFooter from './TableFooter';
import TablePagination from './TablePagination';

import styles from './Table.styles.scss';

const SophiTableNext = (props) => {
  const {
    columns,
    data,
    error,
    errorAction,
    getSubRow,
    loading,
    defaultSort,
    sortable,
    totalRows,
    widths,
    id,
    showFooter,
    variant,
    pagination,
    showTopPagination,
    onPageChange,
    onPageSizeChange,
    onRowMouseEnter,
    onRowMouseLeave,
    selectedRow,
  } = props;

  // Used for sorting config
  const [sort, setSort] = useState(defaultSort);

  // Used to keep track of open sub rows between renders
  const [expandedParents, setExpandedParents] = useState([]);
  const tableColumns = useMemo(() => (columns || []), [columns]);
  const tableData = useMemo(() => (data || []), [data]);

  // React Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    columns: calcColumns,
  } = useTable({
    columns: tableColumns,
    data: tableData,
    getResetExpandedDeps: false,
  }, useExpanded);

  const noData = !loading && rows.length === 0;

  /**
   * Handler for setting sorting configuration
   * @param {Object} column - The column object from react-table
   */
  const handleSort = (column) => {
    if (column.id === 'expander') return;
    const getDirection = () => {
      if (!sort.column || sort.column !== column.id) {
        return 'desc';
      }
      return sort.direction === 'desc' ? 'asc' : 'desc';
    };

    const config = {
      column: column.id,
      direction: getDirection()
    };
    setSort(config);

    const hasData = tableData && tableData.length;
    if (sortable && hasData) {
      sortable(config);
    }
  };

  /**
   * Used to fetch subrows as user requests them. Will fire on every update of rows.
   */
  useEffect(() => {
    rows.forEach((row) => {
      if (row.canExpand && row.isExpanded && !row.original.loaded && row.original.sectionChildren) {
        getSubRow(row.original.sectionChildren);
      }
    });
  }, [getSubRow, rows]);

  /**
   * Used to control expansion state for each row based off expandedParents state.
   * Will fire on every update of rows.
   */
  useEffect(() => {
    rows.forEach((row) => {
      const { canExpand, isExpanded } = row;
      const dataLoaded = row.original.loaded;
      const shouldBeExpanded = expandedParents.includes(row.values.subsectionName);
      const toggleRowExpanded = typeof row.toggleRowExpanded === 'function' ? row.toggleRowExpanded : () => {};

      /**
       * shouldBeExpanded is the local state tracked from user interaction
       * isExpanded & canExpand is the react-table state based on the rows generated
       * This logic is solves for react-table handling expanded state
       * as index: https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useExpanded.js
       * After sorting these indices do not match the correct user selected expanded rows
       */

      // If the user has asked to see the row, the row can expand, its data is loaded and its currently not expanded, then toggle the row to be open
      if (shouldBeExpanded && canExpand && dataLoaded && !isExpanded) {
        toggleRowExpanded();
        // Otherwise if the row should be collapsed but is expanded, close the row
      } else if (!shouldBeExpanded && isExpanded) {
        toggleRowExpanded();
      }
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [rows]);

  /**
   * Method to add and remove values from the expandedParents array baed on user input
   * @param {String} name - String representing the parent subsection name
   * @param {String} column - String representing the column name
   */
  const handleUserExpand = (name, column) => {
    if (column === 'expander') {
      const newExpanded = [...expandedParents];
      if (newExpanded.includes(name)) {
        newExpanded.splice(newExpanded.indexOf(name), 1);
      } else {
        newExpanded.push(name);
      }
      setExpandedParents(newExpanded);

      trackCustomInteraction('click', `${id}-expand_row-btn`);
    }
  };

  // Render table UI
  const renderTable = () => {
    const placeholderRows = showFooter ? totalRows + 1 : totalRows;
    return (
      <div className={styles.scroll}>
        <table
          className={`${styles.root} ${styles[variant]} ${showFooter ? styles.hasFooter : ''}`}
          {...calcColumns.length > 0 ? getTableProps() : null}
        >
          {widths ? (
            <colgroup>
              {widths.map((colWidth, i) => <col key={`colWidth_${i}`} width={colWidth} />)}
            </colgroup>
          ) : null}

            <TableHeader
              id={id}
              headerGroups={headerGroups}
              sortable={sortable}
              sort={sort}
              handleSort={handleSort}
              columns={calcColumns}
          />
            <tbody
              className={styles.body}
              {...getTableBodyProps()}
            >
              {(!loading && rows.length) && rows.map((row, index) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={index}
                    row={row}
                    handleUserExpand={handleUserExpand}
                    onRowMouseEnter={onRowMouseEnter}
                    onRowMouseLeave={onRowMouseLeave}
                    selectedRow={selectedRow}
                  />
                );
              })}

            {loading && (
              [...Array(placeholderRows).keys()].map((item, index) => (
                <tr data-testid="table-placeholder-row" key={index}>
                  {columns.map((c, i) => (
                    <td className={styles.bodyCell} style={c.style} key={i}>
                      <Placeholder style={{ height: 20 }} />
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
          <TableFooter hideFooter={!showFooter || loading} footerGroups={footerGroups} />
        </table>
      </div>
    );
  };

  const renderTableBody = () => {
    if (error) {
      return <ErrorState action={errorAction} />;
    }

    if (noData) {
      return (
        <div data-testid="table-no-data-placeholder" className={styles.noDataPlaceholder}>
          <p>No data found. Try adjusting the filter or date range settings.</p>
        </div>
      );
    }
    return renderTable();
  };

  // Fall back just in case the default columns are dynamic
  if (!columns && loading) return <LoadingState />;

  return (
    <div className={styles.container}>
      {(showTopPagination && pagination && !noData) && (
        <TablePagination
          id={id}
          disabled={loading}
          pagination={pagination}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}
      {renderTableBody()}
      {(pagination && !noData) && (
        <TablePagination
          id={id}
          disabled={loading}
          pagination={pagination}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </div>
  );
};

SophiTableNext.defaultProps = {
  errorAction: () => null,
  showFooter: false,
  variant: 'gray',
  totalRows: 5,
  defaultSort: { column: 'subsectionName', direction: 'desc' },
};

SophiTableNext.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  // Total Placeholder Rows to show when loading
  totalRows: PropTypes.number,
  // Pagination Data
  showTopPagination: PropTypes.bool,
  pagination: PropTypes.shape({
    size: PropTypes.number,
    count: PropTypes.number,
  }),
  // Expandable Row Click Handler
  getSubRow: PropTypes.func,
  // Visual Config
  widths: PropTypes.array,
  showFooter: PropTypes.bool,
  // Optional Styles
  variant: PropTypes.oneOf(['gray', 'white', 'framed', 'mobile']),
  selectedRow: PropTypes.number,
  // States
  loading: PropTypes.bool,
  error: PropTypes.object,
  errorAction: PropTypes.func,
  // Sorting
  sortable: PropTypes.func,
  defaultSort: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc'])
  }),
  // Event Handlers
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
};

export default SophiTableNext;
