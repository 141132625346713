import React, { useMemo } from 'react';
import { Card, Col, Empty, Row } from 'antd';

import { useOverrides } from '../contexts/OverridesProvider';

// Components
import DetailsCard from './DetailsCard';
import { groupByRequestId } from '../utils/functions';

const List: React.FC = () => {
  const { requests } = useOverrides();

  return (
    <Row style={{ marginTop: 24 }} gutter={[24, 24]}>
      {
        requests.length ? (
          requests.map((item, i) => (
            <Col key={i} xs={24} md={12} xl={8}>
              <DetailsCard data={item}/>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Card>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            </Card>
          </Col>
        )
      }
    </Row>
  );
};

export default List;