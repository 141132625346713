import { DEFAULT_CACHE_EXPIRY } from '../actions/cacheTypes';

export const getFromCache = (state, id, key, maxAge = DEFAULT_CACHE_EXPIRY) => {
    let hit;
    const cache = state.cache[id];
    if (cache) {
        hit = cache.get(key);
    }
    const now = Date.now();
    if (hit) {
        const age = now - hit.timestamp;
        if (age > maxAge) {
            hit = undefined;
        }
    }
    return hit;
};
