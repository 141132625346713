// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-U3JSJRBMokNv2n_ScK7N{padding:10px}.sophi-U3JSJRBMokNv2n_ScK7N span{display:-webkit-box;display:-ms-flexbox;display:flex;font-size:inherit;font-weight:inherit;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.sophi-hHfQcpmYAFQ4D66jwklz{margin-left:5px;font-size:inherit;font-weight:inherit}", "",{"version":3,"sources":["webpack://./sophi-design-system/src/components/IconButton/IconButton.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CAEA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,iBAAA,CACA,mBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGF,4BACE,eAAA,CACA,iBAAA,CACA,mBAAA","sourcesContent":[".icon-button {\n  padding: 10px;\n\n  span {\n    display: flex;\n    font-size: inherit;;\n    font-weight: inherit;\n    align-items: center;\n  }\n\n  &__text {\n    margin-left: 5px;\n    font-size: inherit;\n    font-weight: inherit;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "sophi-U3JSJRBMokNv2n_ScK7N",
	"iconButtonText": "sophi-hHfQcpmYAFQ4D66jwklz"
};
export default ___CSS_LOADER_EXPORT___;
