// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-lg5VQT1w7EwVyECoQEBP{display:inline;line-height:1}.sophi-E4XNmiuCyc7vAepi5WGi{display:block;margin:0;padding:0}", "",{"version":3,"sources":["webpack://./sophi-design-system/src/components/Image/image.styles.scss"],"names":[],"mappings":"AAAA,4BACE,cAAA,CACA,aAAA,CAGF,4BACE,aAAA,CACA,QAAA,CACA,SAAA","sourcesContent":[".imageLink {\n  display: inline;\n  line-height: 1;\n}\n\n.imageImage {\n  display: block;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageLink": "sophi-lg5VQT1w7EwVyECoQEBP",
	"imageImage": "sophi-E4XNmiuCyc7vAepi5WGi"
};
export default ___CSS_LOADER_EXPORT___;
