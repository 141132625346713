import React from 'react';

import pageStyles from '../Page.styles.scss';
import styles from './PageImport.styles.scss';
import PageTitle from '../../components/PageTitle';
import FileUpload from '../../components/FileUpload';
import { isPageConfigResponse } from '../../../types';
import { Button, message } from 'antd';
import Loader from './Loader';
import PageImportContext, { useImportContext } from './context';
import { Typography, Space } from 'antd';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';

const PageImport = () => {
  const { uploaded, setUploaded, progress } = useImportContext();

  const clearUploaded = () => setUploaded(null);

  const onChange = ({ info, contents }) => {
    try {
      const parsed = JSON.parse(contents);
      if (isPageConfigResponse(parsed)) {
        setUploaded({
          contents: parsed,
          fileName: info.file.name,
        });
      } else {
        throw new Error('Not a page config');
      }
    } catch {
      message.error({
        content: 'Not a page configuration file',
        style: {
          marginTop: '58px'
        }
      });
      clearUploaded();
    }
  };

  return (
    <div className={pageStyles.container}>
      <PageTitle
        title="Site automation"
        subtitle={`Import page`}
      />

      <div className={pageStyles.body}>
        <div className={styles.grid}>
          {progress === null &&
            <>
              {uploaded === null ?
                <FileUpload
                  onChange={onChange}
                  hint={uploaded ? `` : `Drop file here or click to select`}
                />
                :
                <Space >
                  <FileTextOutlined />
                  <Typography.Text>
                    {uploaded.fileName}
                  </Typography.Text>
                  <Button
                    onClick={clearUploaded}
                    type='text'
                    icon={<DeleteOutlined />} 
                  />
                </Space>
              }
            </>
          }

          {uploaded &&
            <Loader config={uploaded.contents} />
          }
        </div>

      </div>
    </div>
  );
};

const WithContext = () => {
  return (
    <PageImportContext>
      <PageImport />
    </PageImportContext>
  );
};

export default WithContext;
