import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from 'molecules/DateRangePicker';
import subDays from  'date-fns/subDays';
import startOfDay from  'date-fns/startOfDay';
import endOfDay from  'date-fns/endOfDay';
import { getFromStorage } from 'utils/storage';
import { getDateRanges } from 'selectors/overview';
import { ANALYSIS_PERIOD_OPTIONS, PUBLISHED_DATE_OPTIONS } from 'utils/relativeDates';
import ConvertToTimezone from 'utils/ConvertToTimezone';
import styles from './OverviewDatePicker.styles.scss';

// Types
import overviewTypes from 'actions/overviewTypes';

// Get saved search configs
const savedConfigs = () => getFromStorage('now_config') || {};

// Set default analysis preset and preset options
const ANALYSIS_PERIOD_PRESETS = {
  selected: savedConfigs().analysis_date_preset || 'Today',
  options: ANALYSIS_PERIOD_OPTIONS
};

// Set default published preset and preset options
const ARTICLE_PUBLISHED_PRESETS = {
  selected: savedConfigs().published_date_preset || 'Analysis Period + 2 Days Before',
  options: PUBLISHED_DATE_OPTIONS,
};

const OverviewDatePicker = (props) => {
  const dispatch = useDispatch();
  const dateRanges = useSelector(getDateRanges);

  // Get the current default options on mount
  const [analysis, setAnalysis] = useState(dateRanges.analysis);
  const [publish, setPublish] = useState(dateRanges.publish);
  const [analysisPreset, setAnalysisPreset] = useState(ANALYSIS_PERIOD_PRESETS.options.find(({ label }) => label === ANALYSIS_PERIOD_PRESETS.selected));
  const [publishPreset, setPublishPreset] = useState(ARTICLE_PUBLISHED_PRESETS.options.find(({ label }) => label === ARTICLE_PUBLISHED_PRESETS.selected));

  /**
   * BUSINESS LOGIC
   * Content view users should only
   * be able to go back by 14 days from now
   */
  const rangeMin = useMemo(() => startOfDay(subDays(new Date(), 14)), []);
  const rangeMax = useMemo(() => endOfDay(new Date()), []);

  useEffect(() => {
    dispatch({
      type: overviewTypes.OVERVIEW_DATE_RANGE_MIN_MAX_SET,
      minMax: { startDate: rangeMin , endDate: rangeMax }
    });
  }, []);

  useEffect(() => {
    const datesValid = analysis?.startDate && analysis?.endDate && publish?.startDate && publish?.endDate;
    if (datesValid) {
      // Only post if values if ranges are valid
      const analysisRangeValid = analysis.startDate.valueOf() !== analysis.endDate.valueOf();
      const publishRangeValid = publish.startDate.valueOf() !== publish.endDate.valueOf();
      const valueUpdated = (
        analysis.startDate.getTime() !== dateRanges.analysis.startDate.getTime() ||
        analysis.endDate.getTime() !== dateRanges.analysis.endDate.getTime() ||
        publish.startDate.getTime() !== dateRanges.publish.startDate.getTime() ||
        publish.endDate.getTime() !== dateRanges.publish.endDate.getTime()
      );

      if (analysisRangeValid && publishRangeValid && valueUpdated) {
        props.dateRanges({
          analysis,
          publish,
        });
      }
    }
  }, [analysis, publish]);

  return (
    <section className={styles.overviewDatePicker}>
      <h4 className={styles.title}>Analysis Period</h4>
      <ConvertToTimezone
        onChange={(range) => {
          setAnalysis(range);
          setAnalysisPreset(range.preset);
        }}
        dateRange={analysis}
      >
        <DateRangePicker
          id="now-analysis-period-date-picker"
          presets={ANALYSIS_PERIOD_PRESETS}
          dateFormat={'eeeeee MMM dd ʼyy'}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          presetValue={analysisPreset}
          hideTime
        />
      </ConvertToTimezone>
      <h4 className={styles.title}>For Articles Published</h4>
      <ConvertToTimezone
        onChange={(range) => {
          setPublish(range);
          setPublishPreset(range.preset);
        }}
        dateRange={publish}
      >
        <DateRangePicker
          id="now-published-period-date-picker"
          presets={ARTICLE_PUBLISHED_PRESETS}
          dateScope={analysis}
          dateFormat={'eeeeee MMM dd ʼyy'}
          altStyle
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          presetValue={publishPreset}
          hideTime
        />
      </ConvertToTimezone>
    </section>
  );
};

export default OverviewDatePicker;