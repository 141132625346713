const types = {
  HOMEPAGE_TABLE_DATA_LOADING: 'HOMEPAGE_TABLE_DATA_LOADING',
  HOMEPAGE_TABLE_DATA_COMPLETE: 'HOMEPAGE_TABLE_DATA_COMPLETE',
  HOMEPAGE_TABLE_DATA_SUCCESS: 'HOMEPAGE_TABLE_DATA_SUCCESS',
  HOMEPAGE_TABLE_DATA_FAIL: 'HOMEPAGE_TABLE_DATA_FAIL',
  HOMEPAGE_POLLING: 'HOMEPAGE_POLLING',
  HOMEPAGE_POLLING_START: 'HOMEPAGE_POLLING_START',
  HOMEPAGE_POLLING_STOP: 'HOMEPAGE_POLLING_STOP',
};

export default types;
