import React, { useState } from 'react';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { FERuleType } from '../types';

// Components
import OverridesModal from './CreateOverridesModal';
import RuleTypeTag from './RuleTypeTag';
import { ModalProvider } from '../contexts/ModalProvider';

const rules: FERuleType[] = ['in', 'replace', 'out', 'ban'];

const CreateOverrideButton: React.FC = () => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [ruleType, setRuleType] = useState<FERuleType>('in');

  function openCreateModal(rule: FERuleType) {
    setRuleType(rule);
    setOpenForm(true);
  }

  const items: MenuProps['items'] = rules.map(key => ({
    key,
    label: <RuleTypeTag style={{ margin: '6px 0' }} rule={key} verbose/>,
    onClick: () => openCreateModal(key),
  }));

  return (
    <>
      <Dropdown overlayStyle={{ textAlign: 'center' }} menu={{ items }} trigger={['click']}>
        <Button type="primary" icon={<PlusOutlined/>}/>
      </Dropdown>
      <ModalProvider rule={ruleType}>
        <OverridesModal open={openForm} onOk={() => setOpenForm(false)} onCancel={() => setOpenForm(false)}/>
      </ModalProvider>
    </>
  );
};

export default CreateOverrideButton;
