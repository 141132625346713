import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Atom
import Menu from 'atoms/Menu';
// Assets
import AuthBar from 'molecules/AuthBar';
// Design System
import Logo from 'sophi-design-system/src/components/Logo';
import { default as StyledHeader } from 'sophi-design-system/src/components/Header';
import Banner from 'sophi-design-system/src/components/Banner';
// Selectors
import { getBanners, getMainHost } from 'selectors/auth';

/**
 * This component is used to wrap the banner and header components
 * so that the appropriate styles apply to both.
 */
const Wrapper = (props) => {
  const styles = {
    position: props.position ? props.position : 'sticky',
    top: 0,
    left: 0,
    zIndex: 1100
  };

  return (
    <section style={styles}>
      {props.children}
    </section>
  );
};

const Header = (props) => {
  const title = useSelector(getMainHost) || '';
  const portrait = useSelector((state) => state.content.portrait);
  const banners = useSelector(getBanners);
  const [bannerVisible, setBannerVisibility] = useState(false);

  useEffect(() => {
    if (banners) {
      setBannerVisibility(true);
    }
  },[banners]);

  return (
    <Wrapper position={!portrait && window.innerWidth > 800 ? 'sticky' : 'relative'}>
      {bannerVisible && <Banner severity={banners.type.toLowerCase()} message={banners.message} />}
      <StyledHeader position={'relative'} >
        {/* TODO: Import menu in from design system */}
        <Menu />
        <Logo layout="url"/>
        {
          title && <p style={{ margin: 0 }}>|&ensp;<b>{title.replace('www.', '')}</b></p>
        }
        {/* TODO: Import authbar in from design system */}
        <AuthBar/>
      </StyledHeader>
    </Wrapper>
  );
};

export default Header;
