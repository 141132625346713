// Root
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';

// Paths
import { setPageTitle, getRoute } from 'utils/paths';

// Atoms
import Container from 'atoms/Container';
import DynamicComponent from 'atoms/DynamicComponent';
// Action Types
import types from 'actions/diveTypes';
import { setTitle } from 'actions/header';
import { resetDashboardValues } from 'actions/dive';
// Selectors
import { getMainHostID } from 'selectors/auth';
// Utils
import { getCellFormatter } from 'utils/table';

const useStyles = makeStyles((theme) => ({
  articlesContainer: {
    marginBottom: 30,
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: 60,
    },
    [theme.breakpoints.up('xxl')]: {
      marginBottom: 90,
    },
  }
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const mainHost = useSelector(getMainHostID);

  useEffect(() => {
    setPageTitle('Historical - Home');
    dispatch(setTitle(''));
    return () => dispatch(resetDashboardValues());
  }, [dispatch]);

  const getTopTableCellFormatter = (col) => getCellFormatter(col, true, { host: mainHost, dive: true });

  return (
    <>
      <DynamicComponent
        id="dive-comparison-chart"
        placeholder={<Container loading={true}><h2>Section</h2></Container>}
        component={() => import('organisms/DiveComparisonChart')}
        containerStyles={{}}
      />
      {/* Article Top Table */}
      <div className={classes.articlesContainer}>
        <DynamicComponent
          id="dive-top-articles-table"
          placeholder={<Container loading={true}>Top Articles - Loading</Container>}
          component={() => import('organisms/TopTable')}
          // Any props needed by the component can be placed as a prop of the Dynamic Component
          title={'Articles'}
          titleLink={getRoute('diveArticles')}
          action={types.FETCH_TOP_ARTICLES}
          store={(state) => (state.dive.dashboard.articles)}
          columns={[
            {
              Header: 'Rank',
              accessor: 'rank',
              Cell: ({ row : { index }}) => index + 1
            },
            {
              Header: 'Headline',
              accessor: 'headline',
              Cell: getTopTableCellFormatter('headline'),
              style: { minWidth: 200 },
            },
            {
              Header: 'Section',
              accessor: 'sectionName',
              style: { textTransform: 'capitalize' },
            },
            {
              Header: 'Byline',
              accessor: 'byline',
              Cell: getTopTableCellFormatter('byline'),
              style: { textTransform: 'capitalize' },
            },
            {
              Header: 'Score',
              accessor: 'sophiScore',
              Cell: getTopTableCellFormatter('noDecimals'),
              contentType: 'number'
            },
            {
              Header: '',
              id: 'ranking-bar',
              accessor: 'sophiScore',
              style: { width: 160 },
              Cell: getTopTableCellFormatter('scoreBar'),
            }
          ]}
        />
      </div>
      {/* Author Top Table */}
      <DynamicComponent
        id="dive-top-authors-table"
        placeholder={<Container loading={true}>Top Authors - Loading</Container>}
        component={() => import('organisms/TopTable')}
        // Any props needed by the component can be placed as a prop of the Dynamic Component
        title={'Authors'}
        titleLink={getRoute('diveAuthors')}
        action={types.FETCH_TOP_AUTHORS}
        store={(state) => (state.dive.dashboard.authors)}
        columns={[
          {
            Header: 'Rank',
            accessor: 'rank',
            Cell: ({ row : { index }}) => index + 1
          },
          {
            Header: 'Byline',
            accessor: 'author',
            Cell: getTopTableCellFormatter('author'),
            style: { textTransform: 'capitalize', minWidth: 200 },
          },
          {
            Header: '# of Articles',
            accessor: 'articles',
            contentType: 'number'
          },
          {
            Header: 'Score',
            accessor: 'sophiScore',
            Cell: getTopTableCellFormatter('noDecimals'),
            contentType: 'number'
          },
          {
            Header: 'Score / Article',
            accessor: 'sophiScorePerArticle',
            Cell: getTopTableCellFormatter('noDecimals'),
            contentType: 'number'
          },
          {
            Header: '',
            id: 'ranking-bar',
            accessor: 'sophiScorePerArticle',
            style: { width: 160 },
            Cell: getTopTableCellFormatter('scorePerArticleBar'),
          }
        ]}
      />
    </>
  );
};

Dashboard.propTypes = {
  // PropTypes
};

export default Dashboard;
