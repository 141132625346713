export const Colors = {
  blues: {
    900: '#094C63',
    800: '#136987',
    700: '#17819B',
    600: '#74B6C8',
    500: '#9BCEDB',
    400: '#D0E6EC',
    300: '#DCEFF3',
    200: '#E7F4F7',
    100: '#F2F9FB',
    alt: {
      800: '#1B69B1',
      600: '#69ACEA',
      500: '#54CECF',
      400: '#B7D7F4',
      300: '#D9EAF9',
      200: '#EFF6FD',
      100: '#F5FAFE',
      50: '#F7FBFE',
    },
  },
  grays: {
    900: '#101010',
    800: '#3C3C3C',
    700: '#6E6E6E',
    400: '#BDBDBD',
    300: '#D6D6D6',
    200: '#EAEAEA',
    100: '#F7F7F7',
  },
  greens: {
    500: `#2EA399`,
    300: '#5ED29B',
    200: '#B0E8CC',
    50: '#F5FAF9',
  },
  pinks: {
    900: '#620133',
    800: '#8C054B',
    700: '#BF0866',
    600: '#C73065',
    500: '#DF638F',
    400: '#E88DAD',
    300: '#F5CBDA',
    200: '#FBE9F0',
    100: '#FDF4F7',
    alt: {
      800: '#EB3E5C',
      600: '#F28C9D',
      400: '#F8B7C2',
      200: '#FDE6EA',
    },
  },
  white: '#FFFFFF',
  areaChart: {
    acquisition: '#54CC90',
    affiliate: '#F45C1C',
    app: '#FC8319',
    desktop: '#17819B',
    direct: '#DC534B',
    email: '#FDE956',
    internal: '#4140C8',
    loyalty: '#BF0866',
    mobile: '#DF638F',
    newsletter: '#753706',
    retention: '#29998E',
    search: '#949FFC',
    social: '#7E45CB',
    tablet: '#4140C8',
    unknown: '#6E6E6E',
  },
  barChart: {
    traffic: {
      desktop: '#17819B',
      tablet: '#4140C8',
      mobile: '#DF638F',
    },
    desktop: {
      background: '#E7F4F7'
    },
    tablet: {
      background: '#E3E3F6'
    },
    mobile: {
      background: '#FBE9F0'
    },
    acquisition: {
      mobile: '#3B9363',
      tablet: '#54CC90',
      desktop: '#B0E8CC',
      background: '#DDEDEB',
    },
    affiliate: {
      mobile: '#EE380C',
      tablet: '#F45C1C',
      desktop: '#FBC0A6',
      background: '#FEF2ED',
    },
    retention: {
      mobile: '#12645A',
      tablet: '#29998E',
      desktop: '#B0D4D1',
      background: '#DDEDEB',
    },
    internal: {
      mobile: '#121195',
      tablet: '#4140C8',
      desktop: '#B6B6EA',
      background: '#E3E3F6',
    },
    direct: {
      mobile: '#DC534B',
      tablet: '#F47E73',
      desktop: '#FBD3D0',
      background: '#FEF8F8',
    },
    email: {
      mobile: '#FBCA3F',
      tablet: '#FDE956',
      desktop: '#FFF3A0',
      background: '#FFF9CF',
    },
    newsletter: {
      mobile: '#753706',
      tablet: '#A4570A',
      desktop: '#D9BA9B',
      background: '#F9F2ED',
    },
    search: {
      mobile: '#5460FA',
      tablet: '#949FFC',
      desktop: '#CED3FE',
      background: '#E6E8FE',
    },
    social: {
      mobile: '#371197',
      tablet: '#7E45CB',
      desktop: '#CBB4EA',
      background: '#EDE5F8',
    },
    unknown: {
      mobile: '#3C3C3C',
      tablet: '#6E6E6E',
      desktop: '#D6D6D6',
      background: '#EAEAEA',
    },
  },
  chartStripes: ['#FFFFFF', '#F7F7F7'],
  chartColors: {
    acquisition: '#54CC90',
    affiliate: '#F45C1C',
    app: '#FC8319',
    desktop: '#17819B',
    direct: '#DC534B',
    email: '#FDE956',
    internal: '#4140C8',
    loyalty: '#BF0866',
    mobile: '#DF638F',
    newsletter: '#753706',
    retention: '#29998E',
    search: '#949FFC',
    social: '#7E45CB',
    tablet: '#4140C8',
    unknown: '#6E6E6E',
    stripes: ['#FFFFFF', '#F7F7F7'],
    trafficSource: {
      desktop: '#17819B',
      tablet: '#4140C8',
      mobile: '#DF638F',
      app: '#FC8319'
    },
  },
  bigBoardCard: 'rgba(19, 15, 53, 0.45)',
};

const createPropertiesObject = (obj, prefix) => {
  return Object.keys(obj).map((o) => {
    if (typeof obj[o] === 'object') {
      return createPropertiesObject(obj[o], prefix ? `${prefix}-${o}` : o);
    } else {
      return ({
        name: prefix ? `--${prefix}-${o}` : `--${o}`,
        value: obj[o]
      });
    }
  }).flat();
};

export const createCustomProps = () => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  
  const colors = createPropertiesObject(Colors);
  let css = ':root {';
  colors.forEach((color, index) => {
    css += `${color.name}: ${color.value}; ${index === colors.length - 1 ? '}' : '\n' }`;
  });
  style.appendChild(document.createTextNode(css));
};

export default Colors;