import React from 'react';
// MUI
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// Styles
import Colors from 'styles/colors';
// Utils
import { hexToRgba } from 'utils/colors';

const breakpoints = {
  keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: {
    xxs: 0,
    xs: 360,
    sm: 600,
    md: 840,
    lg: 1024,
    xl: 1440,
    xxl: 1920,
  },
};
const defaultTheme = createTheme({
  breakpoints,
  typography: { useNextVariants: true },
});

const theme = createTheme({
  breakpoints,
  mixins: {
    gutters: (styles = {}) => ({
      paddingLeft: 10,
      paddingRight: 10,
      ...styles,
      [defaultTheme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      [defaultTheme.breakpoints.up('sm')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [defaultTheme.breakpoints.up('lg')]: {
        paddingLeft: 60,
        paddingRight: 60,
      },
      [defaultTheme.breakpoints.up('xl')]: {
        paddingLeft: 90,
        paddingRight: 90,
      },
      [defaultTheme.breakpoints.up('xxl')]: {
        paddingLeft: 200,
        paddingRight: 200,
      },
    }),
  },
  palette: {
    background: {
      default: 'var(--gray-500)',
    },
    primary: {
      light: Colors.blues[200],
      main: Colors.blues[500],
      dark: Colors.blues[800],
    },
    secondary: {
      light: '#cb3984',
      main: '#BF0866',
      dark: '#850547',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: `"Montserrat-Medium", sans-serif`,
    body1: {
      lineHeight: 1,
    },
    body2: {
      lineHeight: 1,
    },
    h1: {
      marginTop: 0,
      marginBottom: 40,
      color: Colors.grays[900],
      fontFamily: `"Montserrat-SemiBold", sans-serif`,
      fontSize: defaultTheme.typography.pxToRem(19),
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: defaultTheme.typography.pxToRem(22),
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: defaultTheme.typography.pxToRem(26),
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: defaultTheme.typography.pxToRem(32),
      },
      [defaultTheme.breakpoints.up('xxl')]: {
        fontSize: defaultTheme.typography.pxToRem(50),
      },
    },
    h2: {
      marginBottom: 20,
      fontFamily: `"Montserrat-SemiBold", sans-serif`,
      fontSize: defaultTheme.typography.pxToRem(22),
      lineHeight: defaultTheme.typography.pxToRem(22),
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: defaultTheme.typography.pxToRem(26),
        lineHeight: defaultTheme.typography.pxToRem(26),
      },
      [defaultTheme.breakpoints.up('xl')]: {
        marginBottom: 40,
        fontSize: defaultTheme.typography.pxToRem(32),
        lineHeight: defaultTheme.typography.pxToRem(32),
      },
      [defaultTheme.breakpoints.up('xxl')]: {
        marginBottom: 50,
        fontSize: defaultTheme.typography.pxToRem(50),
        lineHeight: defaultTheme.typography.pxToRem(50),
      },
    },
    h3: {
      marginBottom: 10,
      fontFamily: `"Montserrat-SemiBold", sans-serif`,
      fontSize: defaultTheme.typography.pxToRem(19),
      lineHeight: defaultTheme.typography.pxToRem(19),
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: defaultTheme.typography.pxToRem(26),
        lineHeight: defaultTheme.typography.pxToRem(26),
      },
      [defaultTheme.breakpoints.up('xxl')]: {
        fontSize: defaultTheme.typography.pxToRem(32),
        lineHeight: defaultTheme.typography.pxToRem(32),
      },
    },
    h4: {
      marginTop: 0,
      marginBottom: 25,
      fontFamily: `"Montserrat-SemiBold", sans-serif`,
      textTransform: 'uppercase',
      fontSize: defaultTheme.typography.pxToRem(16),
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: defaultTheme.typography.pxToRem(11),
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: defaultTheme.typography.pxToRem(13),
      },
      [defaultTheme.breakpoints.up('xxl')]: {
        fontSize: defaultTheme.typography.pxToRem(16),
      },
    },
    useNextVariants: true,
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: hexToRgba(Colors.blues[600], 0.2),
      }
    },
    MuiButton: {
      label: {
        pointerEvents: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
      label: {
        pointerEvents: 'none',
      },
    },
    MuiListItem: {
      button: {
        '&:focus': {
          backgroundColor: Colors.blues[100],
        },
        '&:hover': {
          backgroundColor: Colors.blues[100],
        },
      },
      root: {
        '&$selected': {
          backgroundColor: Colors.blues[200],
          '&:hover': {
            backgroundColor: Colors.blues[200],
          },
          '&:focus': {
            backgroundColor: Colors.blues[200],
          },
        },
      },
    },
    MuiTabs: {
      root: {
        boxSizing: 'border-box',
      },
      scroller: {
        // target this one specifically as there's another flexContainer
        '&> $flexContainer': {
          height: `100%`,
          borderBottom: `1px solid ${Colors.grays[300]}`,
        },
      },
      indicator: {
        height: 1,
        background: '#FFF',
      },
    },
    MuiTab: {
      root: {
        width: `100%`,
        padding: `0`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `stretch`,
        flex: 1,
        textAlign: 'left',
        backgroundColor: Colors.blues[100],
        boxSizing: 'border-box',
        borderTop: `1px solid ${Colors.grays[300]}`,
        borderLeft: `1px solid ${Colors.grays[300]}`,
        transition: 'all ease 320ms',
        '&:last-child': {
          borderRight: `1px solid ${Colors.grays[300]}`,
        },
        '&$selected': {
          background: '#FFF',
          cursor: 'default',
          pointerEvents: 'none',
          '& p:first-child': { // see: `src/atoms/StackedTab/index.js`
            color: Colors.blues[800]
          },
        },
        '&:not(selected)': {
          opacity: 1,
        },
        '&:not($selected):hover, &:not($selected):focus': {
          background: Colors.blues[300],
          '& p:first-child': { // see: `src/atoms/StackedTab/index.js`
            color: Colors.blues[800]
          },
        },
        '&:not($selected):hover:active': {
          background: Colors.blues[500],
          '& p:first-child': {
            color: Colors.grays[800]
          },
        },
        '&:disabled': {
          background: Colors.grays[200],
          '&:not(.Mui-selected):hover:active': {
            background: Colors.grays[200],
          },
          '&:hover': {
            background: Colors.grays[200],
          },
          '&:focus': {
            background: Colors.grays[200],
          }
        }
      },
      wrapper: {
        width: `100%`,
        flex: `1 1 100%`,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [defaultTheme.breakpoints.up('xxs')]: {
          padding: 10,
        },
        [defaultTheme.breakpoints.up('xl')]: {
          padding: `10px 20px`,
        },
        [defaultTheme.breakpoints.up('xxl')]: {
          padding: 20,
        },
      }
    },
    MuiSlider: {
      rail: {
        color: Colors.blues[500]
      },
      track: {
        color: Colors.blues[700]
      },
      thumb: {
        color: Colors.blues[700]
      }
    }
  },
});

function ThemeRoot(Component) {
  function ThemeRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return ThemeRoot;
}

export default ThemeRoot;
