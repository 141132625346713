import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from 'sophi-design-system/src/components/Button';

// Molecules
import NewFilters from 'molecules/Filters';
import FilterContext from 'molecules/Filters/FilterContext';

// Organisms
import DiveDatePicker from 'organisms/DiveDatePicker';

// Selectors
import { getDiveFilters, getDiveFilterAutosuggest } from 'selectors/dive';
import { getDisabledDiveProductFeatures } from 'selectors/auth';
import {
  refetchDashboardData,
  fetchDiveAuthors,
  fetchDiveArticles,
  fetchDiveAuthorArticles,
  setFilterValue,
  clearDiveAutosuggest,
  fetchDiveAutosuggest,
  fetchDiveFilterOptions,
  fetchDiveSections
 } from 'actions/dive';
 import types from 'actions/diveTypes';
// Filter Schema
import { setDiveFilters, getFromStorage } from 'utils/storage';
import styles from './DiveFilterHeader.styles.scss';

const filterContextValue = {
  selectors: {
    getFilters: getDiveFilters,
    getOptions: (state) => state.dive.global.filterOptions.validOptions,
    getAutosuggest: getDiveFilterAutosuggest,
    getRowCount: () => ({}),
    getDisabledFeatures: getDisabledDiveProductFeatures,
  },
  actions: {
    clearAutosuggest: clearDiveAutosuggest,
    fetchSections: null,
    fetchOwnership: null,
    fetchSubtypes: null,
    fetchCreditLine: null,
    fetchAccess: null,
    fetchType: null,
    fetchResultsTotal: null,
    fetchAutosuggest: fetchDiveAutosuggest,
    setFilters: setFilterValue,
  }
};

const getSearchCallback = ({ pathname, ...rest }, params) => {
  if (pathname.includes('/historical/authors')) {
    return fetchDiveAuthors;
  } else if (pathname.includes('/historical/articles')) {
    return fetchDiveArticles;
  } else if (pathname.includes('/historical/author/')) {
    return fetchDiveAuthorArticles;
  } else if (pathname.includes('/historical/sections')) {
    return fetchDiveSections;
  } else if (pathname.includes('/historical')) {
    return refetchDashboardData;
  }
};

const formatStoredFilters = (filters) => {
  return Object.keys(filters).reduce((obj, filter) => {
    if (Array.isArray(filters[filter])) {
      return { ...obj, [filter]: filters[filter]};
    } else if (filters[filter].selection) {
      return { ...obj, [filter]: filters[filter].selection};
    }
    return obj;
  }, {});
};

const DiveFilterHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDiveFilterOptions());
    // Create filter state when this component is mounted
    const storedFilters = getFromStorage('dive_config') || {};
    if (storedFilters.filters) {

      dispatch({
        type: types.DIVE_SET_PREVIOUS_FILTERS,
        data: formatStoredFilters(storedFilters.filters)
      });
    }
  }, []);

  const currentFilters = useSelector(getDiveFilters);
  const handleSearchClick = () => {
    dispatch(getSearchCallback(location)(location));
    setDiveFilters(currentFilters);
  };

  const [renderFilters, setRenderFilters] = useState(true);

  const hideFilters = [];
  if (location.pathname.includes('/historical/author/')) {
    hideFilters.push('authors');
  }

  useEffect(() => {
    setRenderFilters( !location.pathname.includes('sections') );
  },[location]);

  return (
    <div className={styles.diveFilterHeader}>
      <DiveDatePicker />
      <div className={styles.btnContainer}>
        <div className={styles.btnGroup}>
          {renderFilters && (
            <FilterContext.Provider value={filterContextValue}>
              <NewFilters />
            </FilterContext.Provider>
          )}
          <Button
            variant="primary"
            onClick={handleSearchClick}
            id="dive-run-search-btn"
            data-track-click="true"
            data-track-name="dive-run-search-btn"
          >
            Run Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiveFilterHeader;
