import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingState.styles.scss';

const LoadingState = ({ height }) => (
  <div
    style={{ height }}
    className={styles.loadingState}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="98"
      className={styles.svg}
    >
      <circle
        cx="47.4"
        cy="48.9"
        r="9.1"
        className={styles.partFour}
      />
      <path
        className={styles.partThree}
        d="M27 8.4L5.9 23c-7.7 5.3-7.7 14 0 19.2l9.7 6.7 7.1-4.9a29 29 0 0 1 4.5-35.6z"
      />
      <path
        className={styles.partTwo}
        d="M29.5 39.3l27.7-19c7.6-5.3 20-5.4 27.7-.2L61.4 4a26.6 26.6 0 0 0-28 0l-6.3 4.4A29 29 0 0 0 22.6 44l7-4.8z"
      />
      <path
        className={styles.partFive}
        d="M89 55.5L79.5 49l-7.1 4.9a29 29 0 0 1-4.5 35.6l21.3-14.6c7.7-5.3 7.7-14 0-19.3z"
      />
      <path
        className={styles.partSix}
        d="M72.3 53.8l-7 4.7-27.6 19a26.6 26.6 0 0 1-28 0l23.7 16.3a26.6 26.6 0 0 0 28 0l6.4-4.4a29 29 0 0 0 4.5-35.7z"
      />
      <path
        className={styles.partOne}
        d="M36.3 34.5c1.1-.7 2-1.8 3-2.5l18-12a23 23 0 0 1 8-3.3 28.3 28.3 0 0 1 18 2l1.5 1.1a84 84 0 0 1 4.8 3.5c1.6 1.3 3 2.9 3.7 4.8a9.3 9.3 0 0 1 .7 3.6 9 9 0 0 1-1 4l-1.5 2.2c-1 1-2 2-3.3 2.8L80.5 46l-1.6 1h-.3A1285.6 1285.6 0 0 0 62 36c-.6-.3-1.2-.8-1.8-1l-1.5-.9a24.6 24.6 0 0 0-12.6-2.4c-3.1.2-6.6.6-8.7 2.2l-1.2.8v-.1z"
      />
      <path
        id="7"
        className={styles.partSeven}
        d="M1.4 69.1c1.1-.7 2-1.7 3-2.5l18-12a23 23 0 0 1 8-3.3 28.3 28.3 0 0 1 17.9 2l1.6 1.1a84 84 0 0 1 4.7 3.5c1.7 1.4 3 3 3.8 4.9a9.3 9.3 0 0 1 .7 3.6 9 9 0 0 1-1 3.9c-.4.8-1 1.5-1.5 2.2-1 1.1-2 2-3.3 2.9l-7.8 5.1-1.5 1a1285.6 1285.6 0 0 0-16.8-11l-1.8-1-1.6-.9a24.6 24.6 0 0 0-12.5-2.4c-3.1.2-6.6.6-8.7 2.2l-1.2.8c-.1.1-.1.1 0 0z"
        transform="rotate(179 30.2 66.2)"
      />
    </svg>
  </div>
);

LoadingState.propTypes = {
  height: PropTypes.number,
};

export default LoadingState;
