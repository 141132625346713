// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-pu1hnODczRgMjyeaux8a{display:grid;grid-row-gap:2rem}.sophi-GKlt9UMXDf55g2Nnj4uF{display:grid;grid-template-columns:1fr;gap:calc(.5*var(--space-1))}.sophi-rZtNpOsPxSmrp0zxtIg2{display:inline-block}.sophi-QxpYspfd0KkfLDejaSop{margin-bottom:.2em}.sophi-TKWctvPIXQYepveQD0v_{display:inline;margin-right:calc(.5*var(--space-1))}.sophi-ZDDpd62w4YrhWrtRlSTG{display:inline}.sophi-HQ7grne4z2h0zEQrx8IO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;gap:var(--space-1)}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/CurationPreview/PagePreview.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,iBAAA,CAGF,4BACE,YAAA,CACA,yBAAA,CACA,2BAAA,CAGF,4BACE,oBAAA,CAGF,4BACE,kBAAA,CAGF,4BACE,cAAA,CACA,oCAAA,CAGF,4BACE,cAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,kBAAA","sourcesContent":[".items {\n  display: grid;\n  grid-row-gap: 2rem;\n}\n\n.fields {\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: calc(0.5 * var(--space-1));\n}\n\n.inline {\n  display: inline-block;\n}\n\n.title {\n  margin-bottom: 0.2em;\n}\n\n.label {\n  display: inline;\n  margin-right: calc(0.5 * var(--space-1));\n}\n\n.value {\n  display: inline;\n}\n\n.controls {\n  display: flex;\n  justify-content: space-between;\n  gap: var(--space-1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"items": "sophi-pu1hnODczRgMjyeaux8a",
	"fields": "sophi-GKlt9UMXDf55g2Nnj4uF",
	"inline": "sophi-rZtNpOsPxSmrp0zxtIg2",
	"title": "sophi-QxpYspfd0KkfLDejaSop",
	"label": "sophi-TKWctvPIXQYepveQD0v_",
	"value": "sophi-ZDDpd62w4YrhWrtRlSTG",
	"controls": "sophi-HQ7grne4z2h0zEQrx8IO"
};
export default ___CSS_LOADER_EXPORT___;
