import { put } from 'redux-saga/effects';
import types from '../actions/cacheTypes';

export function* addToCache(id, key, value) {
    try {
        yield put({ type: types.ADD_TO_CACHE, id, key, value });
    } catch (error) {
        yield put({ type: types.ADD_TO_CACHE_FAIL, error });
    }
};

export function* cleanCache(id) {
    try {
        yield put({ type: types.CLEAN_CACHE, id });
    } catch (error) {
        yield put({ type: types.CLEAN_CACHE_FAIL, error });
    }
};

export default {
    addToCache,
    cleanCache,
};
