// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-DHMdbkvckdqrIgDpBfkp{display:grid;grid-template-columns:1fr;grid-gap:1rem}.sophi-SNx7tJfc5FwPuyfvqWyJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/PageImport/PageImport.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,yBAAA,CACA,aAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-gap: 1rem;\n}\n\n.upload {\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "sophi-DHMdbkvckdqrIgDpBfkp",
	"upload": "sophi-SNx7tJfc5FwPuyfvqWyJ"
};
export default ___CSS_LOADER_EXPORT___;
