import React, { useEffect } from 'react';
import ConfigUIHome from '../config-ui/src/pages/Home';
import { useAuth0 } from 'utils/Auth0Provider';
import { getTenantId, getCuratorAuomationAPI, getMainHost, getEnv } from 'selectors/auth';
import { useSelector } from 'react-redux';
import { setPageTitle } from 'utils/paths';

export default () => {
  const env = useSelector(getEnv);
  const tenant = useSelector(getTenantId);
  const host = useSelector(getMainHost);
  const service = useSelector(getCuratorAuomationAPI);
  const { user, getTokenSilently } = useAuth0();

  useEffect(() => {
    setPageTitle('Site Automation - Config');
  }, []);

  return (
    <ConfigUIHome
      env={env}
      tenant={tenant}
      host={host}
      service={service}
      user={user}
      getToken={getTokenSilently}
    />
  );
};
