import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import Checkbox from 'sophi-design-system/src/components/Checkbox';
import Icon from 'sophi-design-system/src/components/Icon';
import Tag from 'sophi-design-system/src/components/Tag';
import TreeNavigation from 'atoms/TreeNavigation';
import RangeSlider from 'atoms/RangeSlider';
import Autocomplete from 'atoms/Autocomplete';
import LoadingState from 'sophi-design-system/src/components/LoadingState';
import FilterContext from './FilterContext';
import styles from './FilterModal.styles.scss';

const CHECKBOX_LIST = 'checkbox';
const TREE_VIEW = 'tree_view';
const AUTOCOMPLETE = 'autocomplete';
const RANGE_SLIDER = 'range';

const FilterPanel = ({ loading, type, selected, id, onChange, data }) => {
  const dispatch = useDispatch();
  const {
    selectors: { getAutosuggest },
    actions: { fetchAutosuggest }
  } = useContext(FilterContext);

  const autosuggest = useSelector(getAutosuggest);

  // Filter Panel Event Handlers
  const handleAutocompleteChange = (value) => {
    const trimmedValue = value ? value.trim() : '';
    if (trimmedValue) {
      dispatch(fetchAutosuggest({ field: id, term: trimmedValue }));
    }
  };
  
  const debouncedAutocompleteChange = debounce(handleAutocompleteChange, 250, { maxWait: 1000 });
  
  const handleAutocompleteSubmit = (val) => {
    const trimmedValue = val.trim();
    if (trimmedValue) {
      onChange(id, trimmedValue);
    }
  };

  const handleFilterSelection = (checked, id) => {
    const [filter, value] = id.split('_');
    onChange(filter, value, checked);
  };

  const handleSliderUpdate = (range) => onChange(id, range);

  return (
    <div data-testid={`${id}-panel`} style={{ height: '100%', width: '100%' }}>
      {/* Placeholder States */}
      {loading && <div className={styles.modalPlaceholder}><LoadingState /></div>}
      {/* No Data State */}
      {(data && (data.noData || (Array.isArray(data) && data.length === 0))) && (
        <div className={styles.noDataMessage}>
          <span><Icon name="Clear" type="circle" color="teal" /></span>
          <p>There is no data available. Contact us if you have any questions.</p>
        </div>
      )}
      {/* Tree Selection */}
      {(type === TREE_VIEW && Array.isArray(data)) && (
        <div style={{ width: '100%' }}>
          <TreeNavigation
            data={data ? data: []}
            childrenKey="subsections"
            labelKey="section"
            valueKey="id"
            addTopLevel
            selected={selected}
            onChange={(data) => {
              onChange(id, data);
            }}
          />
        </div>
      )}
      {/* Checkbox Selection */}
      {(type === CHECKBOX_LIST && Array.isArray(data)) && (
        <ul className={styles.checkboxList}>
          {data.map((d) => (
            <li key={d}>
              <Checkbox
                key={d}
                checked={selected && selected.includes(d)}
                label={d}
                onChange={handleFilterSelection}
                value={d}
                id={`${id}_${d}`}
              />
            </li>
          ))}
        </ul>
      )}
      {/* Autocomplete Field */}
      {type === AUTOCOMPLETE && (
        <div style={{ padding: 20, width: '100%' }}>
          <Autocomplete
            key={id}
            id={`${id}-input`}
            results={Array.isArray(autosuggest) ? autosuggest : []}
            onSubmit={handleAutocompleteSubmit}
            onChange={debouncedAutocompleteChange}
            label={`Search for ${id}`}
          />
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {selected && selected.map((val) => (
              <li style={{ margin: '10px 0' }} key={val}>
                <Tag
                  removeProps={{
                    onClick: () => onChange(id, val, false)
                  }}
                >
                  {val}
                </Tag>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Range Selection Field */}
      {type === RANGE_SLIDER && (
        <div style={{ padding: 20 }}>
          <RangeSlider
            onChange={handleSliderUpdate}
            data={selected}
            max={99999}
            id={id} 
          />
        </div>
      )}
    </div>
  );
};

export default FilterPanel;
