// Root
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import styles from './ErrorState.styles.scss';

const ErrorState = ({ link, action, onReportIssueClick }) => {
  const handleClick = () => {
    if (action && typeof action === 'function') {
      action();
    }
  };

  const secondaryAction = link
    ? <span> or <a className={styles.errorStateLink} href={link.url}>{link.text}</a></span>
    : ' or contact your administrator';

  return (
    <section className={styles.errorState}>
      <div className={styles.errorStateHeader} />
      <h2 className={styles.errorStateTitle}>Oops!</h2>
      <p>It looks like we have our wires crossed</p>
      <p>Try refreshing{secondaryAction}.</p>
      <div style={{ marginTop: 25 }}>
        {onReportIssueClick && (
          <Button
            variant="primary"
            style={{ marginRight: 10 }}
            onClick={onReportIssueClick}
            track
            id="error-report_issue-btn"
          >
            Report Issue
          </Button>
        )}
        <Button
          className="error-state__button"
          onClick={() => handleClick()}
          track
          id="error-retry-btn"
        >
          Refresh
        </Button>
      </div>
    </section>
  );
};

ErrorState.propTypes = {
  link: PropTypes.object,
  action: PropTypes.func,
  onReportIssueClick: PropTypes.func,
};

export default ErrorState;
