import types, { DEFAULT_CACHE_EXPIRY, MAX_CACHE_SIZE } from '../actions/cacheTypes';

const initialState = {};

export default function cacheReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_TO_CACHE: {
      const { id, key, value } = action;
      const timestamp = Date.now();
      value.timestamp = timestamp;

      const cache = state[id] || new Map();
      cache.set(key, value);
      return {
        ...state,
        [id]: cache
      };
    }
    case types.CLEAN_CACHE: {
      const maxAge = action.maxAge || DEFAULT_CACHE_EXPIRY;
      const { id } = action;
      const cache = state[id];
      if (cache) {
        const size = cache.size;
        const overflow = size - MAX_CACHE_SIZE;
        let current = 1;
        for (const [key, value] of cache) {
          if (overflow > 0 && current <= overflow) {
            cache.delete(key);
          } else {
            const age = Date.now() - value.timestamp;
            if (age > maxAge || current > MAX_CACHE_SIZE) {
              cache.delete(key);
            };
          }
          current++;
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
