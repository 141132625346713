/**
 * Helper function to convert Hex Value to RGBA
 * @param {string} hex - String representing a hexadecimal color value
 * @param {integer} alpha - The alpha value
 * @return {string} The passed in color in as a rgba() string
 */
export const hexToRgba = (hex, alpha) => {
  const shorthand = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthand, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    result ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${alpha})` : null
  );
};