// Utils
import { getResource } from 'utils/api';
import { generateTableConfigResponse } from './widgets/table';

const url = window._env_['REG_API'] || ENV.REG_API;

export const WIDGET_NAME_MAP = {
  AUTHOR_COLUMNS: 'AuthorColumns',
  ARTICLES_COLUMNS: 'ArticlesColumns',
  SECTION_COLUMNS: 'SectionsColumns',
  NOW_COLUMNS: 'NowColumns',
  SEVEN_DAY_STATS: 'SevenDayStats',
  ALL_TIME_STATS: 'AllTimeStats',
};

const getConfigFromRegistry = () => {
  return getResource(
    {
      endpoint: `${url}/v2/webApps/me`,
      noToken: true,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

const getWidgetConfig = (host, token, pageName, widgetName) => {
  const resource = `${host}/v1/apps/dashboard/pages/${pageName}/widgets/${widgetName}`;
  const init = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };

  return fetch(resource, init)
    .then(async (response) => {
      switch (response.status) {
        case 200: {
          const json = await response.json();
          return { response: json };
        }
        case 401:
        case 404:
          return { response: getDefaultResponse(widgetName) };
        default:
          throw response; // triggers retry()
        }
    });
};

const getDefaultResponse = (widgetName) => {
  if ([
    WIDGET_NAME_MAP.AUTHOR_COLUMNS,
    WIDGET_NAME_MAP.ARTICLES_COLUMNS,
    WIDGET_NAME_MAP.SECTION_COLUMNS,
    WIDGET_NAME_MAP.NOW_COLUMNS,
  ].includes(widgetName)) {
    return generateTableConfigResponse(widgetName);
  }
  else return { noData: true };
};

export default {
  getConfig: getConfigFromRegistry,
  getWidgetConfig,
  WIDGET_NAME_MAP
};
