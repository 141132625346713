// Lodash
import find from 'lodash/find';

// Utils
import { PERFORMANCE_BREAKDOWN_LABELS } from 'utils/constants';
import { capitalize } from 'utils/string';
import { getResource, getUniqByKey, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Traffic Source Chart
 * @param {String} id - String representing the contentID
 */

const HAS_APP_DATA = ['internal', 'direct', 'unknown'];

const getTrafficSourceData = (host, client, id, token, platforms) => {
  if (paramsAreTruthy([id])) {

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `traffic-analysis/traffic-mediums`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const allMediums = getUniqByKey(data.data, 'medium');
      const graph = {};

      allMediums.forEach((medium) => {
        const label = PERFORMANCE_BREAKDOWN_LABELS[medium] || medium;
        graph[label] = {};
        platforms.forEach((device) => {
          const showValue = device !== 'app' || HAS_APP_DATA.includes(medium);
          if (showValue) {
            const obj = find(data.data, { 'medium': medium, 'platform': capitalize(device) });
            graph[label][device] = obj ? obj['total'] : 0;
          }
        });
      });

      const title = PERFORMANCE_BREAKDOWN_LABELS[data.title] || data.title;
      
      const graphData = Object.entries(graph)
        .map(([segment, values]) => {
          const total = Object.values(values).reduce((total, val) => total + val, 0);
          return { ...values, total, segment };
        })
        .sort((a, b) => {
          if (a.total > b.total) return -1;
          if (a.total < b.total) return 1;
          return 0;
        });

      return {
        response: {
          graph: graphData,
          title
        }
      };
    };

    return data;
  }
};

export default getTrafficSourceData;