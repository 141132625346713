import React from 'react';
import { Tag } from 'antd';
import { statusLabels } from '../../copy';

const PageStatus = ({ checkable, checked, status, onClick, className = '' }) => {
  let label, color;

  switch(status) {
    case 'DRAFT':
      label = statusLabels[status];
      color = 'default';
      break;
    case 'VALIDATED':
      label = statusLabels[status];
      color = 'cyan';
      break;
    case 'ACTIVE':
      label = statusLabels[status];
      color = 'green';
      break;
  }

  const onChange = (status) => (checked) => onClick({ option: status, checked });

  return checkable
    ? <Tag.CheckableTag className={className} checked={checked} onChange={onChange(status)}>
        {label}
      </Tag.CheckableTag>
    : <Tag className={className} color={color} onClick={onClick}>{label}</Tag>;
};

export default PageStatus;
