import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import Button from '../Button';
import Select from '../Select';
import styles from './Table.styles.scss';
import useMedia from '../../hooks/useMedia';

const PAGE_SIZES = [5, 10, 25, 50];

const TablePagination = ({
  disabled,
  pagination,
  onPageChange,
  onPageSizeChange,
  id,
}) => {
  const showExtraPages = useMedia(['(min-width: 768px)'], [true], false);

  const totalPages = pagination.count && pagination.size
    ? Math.ceil(pagination.count / pagination.size) - 1
    : 0;

  const visiblePages = useMemo(() => {
    const currentPage = parseInt(pagination.page);

    let defaultPages = [0, totalPages];
    if (currentPage < 2) {
      defaultPages = showExtraPages ? defaultPages.concat([1, 2, 3, 4]) : defaultPages.concat([1, 2]);
    } else if (showExtraPages && currentPage >= totalPages - 5) {
      defaultPages = defaultPages.concat([totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1]);
    } else if (currentPage >= totalPages - 3) {
      defaultPages = defaultPages.concat([totalPages - 2, totalPages - 1]);
    }

    let pages = [...defaultPages, currentPage - 1, currentPage, currentPage + 1];
    if (showExtraPages) {
      pages = [...defaultPages, currentPage - 2, ...pages, currentPage + 2];
    }

    const list = new Set([]);
    pages.forEach((page) => {
      if (page >= 0 && page <= totalPages) {
        list.add(page);
      }
    });

    const sorted = [...list].sort((a, b) => a - b);
    const size = showExtraPages ? 6 : 4;
    return uniq(sorted.slice(0, size).concat(sorted[sorted.length - 1]));
  }, [pagination, totalPages]);

  return (
    <div className={styles.pagination}>
      <Select
        label="Show:"
        styles={{
          control: (provided) => ({
            ...provided,
            width: 75,
            height: 25,
            padding: 0,
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
          })
        }}
        className={styles.paginationSelect}
        value={{ value: pagination.size, label: `${pagination.size}` }}
        options={PAGE_SIZES.map((size) => ({ value: size, label: `${size}` }))}
        onChange={({ value }) => onPageSizeChange(value)}
        isDisabled={disabled}
        id={`${id}-page-select`}
      />
      <div className={styles.pageNavigation}>
        <Button
          className={styles.footerBtn}
          title="To Previous Page"
          onClick={() => onPageChange(pagination.page - 1)}
          disabled={disabled || pagination.page === 0}
        >
          <span className={styles.leftBtn} />
        </Button>
        {visiblePages.map((page, i) => {
          const btn = (
            <button
              title={`Page ${page + 1}`}
              key={`page-${page}`}
              className={`${styles.pageBtn} ${page === pagination.page ? styles.currentPage : ''}`}
              onClick={() => onPageChange(page)}
            >
              {page + 1}
            </button>
          );
          if (i > 0 && page - visiblePages[i - 1] > 1) {
            return [<span key={`page-expanded-${page}`}>⋯</span>, btn];
          }

          return btn;
        })}
        <Button
          className={styles.footerBtn}
          title="To Next Page"
          onClick={() => onPageChange(pagination.page + 1)}
          disabled={disabled || pagination.page === totalPages}
        >
          <span className={styles.rightBtn} />
        </Button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    count: PropTypes.number,
    size: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,

};

export default TablePagination;
