import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// Atom
import Picker from './picker';
import DatePresets from './DatePresets';

// Import preset logic
import getDatesFromPreset from 'utils/relativeDates';
import { setInStorage } from 'utils/storage';
import styles from './DateRangePicker.styles.scss';
import { zonedTimeToUtc } from 'date-fns-tz';

const DateRangePicker = ({
  id,
  dateFormat,
  onChange,
  dateScope,
  presets,
  publishDate,
  rangeMax,
  rangeMin,
  style,
  hideTime,
  altStyle,
  dateRange,
  presetValue,
  timeZone,
}) => {
  useEffect(() => {
    if (presetValue) {
      handlePresetChange(presetValue);
    }
  }, [dateScope]);

  const presetOptions = useMemo(() => {
    if (!presets) return [];
    return  [{ label: 'Custom', value: { custom: true } }, ...presets.options];
  }, [presets]);

  const handlePresetChange = (selection) => {
    if (selection && selection.value) {
      const { startDate, endDate } = getDatesFromPreset({ publishDate, dateScope }, { selectedPreset: selection.value, timeZone });
      onChange({ startDate, endDate, preset: selection });
      setInStorage(`${id}-preset`, selection.label);
    }
  };

  const handleDateChange = (newDate) => {
    // Keep track of the last valid date in local state
    onChange({...newDate, preset: { label: 'Custom', value: { custom: true } } });
    if (newDate.startDate && newDate.endDate) {
      setInStorage(`${id}-range`, {
        startDate: zonedTimeToUtc(newDate.startDate, timeZone),
        endDate: zonedTimeToUtc(newDate.endDate, timeZone)
      });
      setInStorage(`${id}-preset`, 'Custom');
    }
  };

  return (
    <div className={styles.container} style={style}>
      <DatePresets
        presetOptions={presetOptions}
        selectedPreset={presetValue}
        id={id}
        onChange={handlePresetChange}
        altStyle={altStyle}
      />
      <Picker
        id={id}
        value={dateRange}
        dateFormat={dateFormat}
        publishDate={publishDate}
        rangeMin={rangeMin}
        rangeMax={rangeMax}
        hideTime={hideTime}
        altStyle={altStyle}
        onChange={handleDateChange}
      />
    </div>
  );
};

DateRangePicker.defaultProps = {
  dateFormat: 'eee MMM dd ʼyy  h:mm aaaa',
  onChange: (e) => console.info('No \'onChange\' prop:\n', e),
  dateRange: {},
};

DateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  dateRange: PropTypes.object,
};

export default DateRangePicker;