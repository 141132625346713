import React from 'react';
import styles from './ListItem.styles.scss';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import ButtonLink from '../ButtonLink';
import PageStatus from '../PageStatus';
import { useHistory } from 'react-router-dom';
import { Typography, Card } from 'antd';

const ListItem = ({ name, status, widgets }) => {
  const history = useHistory();
  const handleCardClick = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName: name }));
  };

  return (
    <li className={styles.container}>
      <Card onClick={handleCardClick} size="small">
        <div className={styles.item}>
          <ButtonLink
            to={getRoute(ROUTE_NAMES.curatorPageView, { pageName: name })}
            className={styles.name}
            type="text"
            onClick={(e) => e.stopPropagation()}
          >
            <Typography.Text strong>
              {name}
            </Typography.Text>
          </ButtonLink>

          <PageStatus status={status} className={styles.status} />

          <div className={styles.actions}>
            <ButtonLink
              to={getRoute(ROUTE_NAMES.curatorPageEdit, { pageName: name })}
              type="text"
              onClick={(e) => e.stopPropagation()}
            >
              Edit
            </ButtonLink>
            {(status === 'DRAFT' || status === 'VALIDATED') && widgets.length === 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorWidgetCreate, { pageName: name })}
                type="primary"
                ghost={true}
                onClick={(e) => e.stopPropagation()}
              >
                Add widget
              </ButtonLink>
            }
            {widgets.length !== 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPagePreview, { pageName: name })}
                type={status === 'ACTIVE' ? 'primary' : 'text'}
                ghost={status === 'ACTIVE' ? true : false}
                onClick={(e) => e.stopPropagation()}
              >
                Preview
              </ButtonLink>
            }
            {(status === 'DRAFT' || status === 'VALIDATED') && widgets.length !== 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPagePublish, { pageName: name })}
                type="primary"
                ghost={true}
                onClick={(e) => e.stopPropagation()}
              >
                Publish
              </ButtonLink>
            }
            {status === 'ACTIVE' &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPageUnpublish, { pageName: name })}
                type="text"
                onClick={(e) => e.stopPropagation()}
              >
                Unpublish
              </ButtonLink>
            }
          </div>

          {widgets.length === 0 &&
            <div className={styles.widgets}>
              <Typography.Text type="secondary">No widgets</Typography.Text>
            </div>
          }
          {widgets.length !== 0 &&
            <ul className={styles.widgets}>
              {widgets.map(({config: {name: widgetName}}) => (
                <li key={widgetName} className={styles.widget}>
                  <ButtonLink
                    to={getRoute(ROUTE_NAMES.curatorWidgetEdit, { pageName: name, widgetName })}
                    type="text"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {widgetName}
                  </ButtonLink>
                </li>
              ))}
            </ul>
          }

        </div>
      </Card>
    </li>
  );
};

export default ListItem;
