import sortBy from 'lodash/sortBy';
import { isDefined } from 'utils/objects';

const SUBROW_KEY = 'subRows';

/**
 * Get an array with a specific order of strings for Performance or Traffic
 * @param {array} unorderedKeys - Array of string values that match either
 *                                performance segments or traffic platforms
 * @param {*} order - Default sort order
 */
export const getOrderedArray = (
  unorderedKeys,
  order = [
    // Performance
    'unknown',
    'social',
    'search',
    'internal',
    'newsletter',
    'email',
    'affiliate',
    'direct',
    'retention',
    'acquisition',
    'app',
    'mobile',
    'tablet',
    'desktop',
    'loyalty',
  ]) => {
  return order.filter((ordered) => unorderedKeys.includes(ordered) ? ordered : null);
};

/**
 * Sorts an array and any sub-arrays by the key specified and global SUBROW_KEY
 * @param {Array} arr - Array of objects
 * @param {Object} config - Object of { column, direction } indicating sorting configuration
 */
export const sortArrayByKey = (arr, config) => {
  const { column, direction } = config || {};
  const sortedRows = sortBy(arr, (o) => o[column]);
  if (direction === 'asc') {
    sortedRows.reverse();
  };
  arr.forEach((entry) => {
    if (entry[SUBROW_KEY]) {
      entry[SUBROW_KEY] = sortArrayByKey(entry[SUBROW_KEY], config);
    }
  });
  return sortedRows;
};

/**
 * List formatting that loosely follows the pattern outlined in Int.ListFormat
 * @param {Array}   list            List of strings to join
 * @param {String}  [type='unit']   And | Or | " " Joiner
 * @param {String}  [style='long']  How to join (with shortened or no word)
 */
export const listToString = (list, type = 'unit', style = 'long') => {
  if (list.length === 1) return list[0];

  let joinSymbol = ' ';

  if (type === 'conjunction') {
    joinSymbol = ' and ';
    if (style === 'short') {
      joinSymbol = ' & ';
    } else if (style === 'narrow') {
      joinSymbol = ' ';
    }
  }

  if (type === 'disjunction') {
    joinSymbol = ' or ';
    if (style === 'narrow') {
      joinSymbol = ' ';
    }
  }

  const simpleJoin = (type === 'unit' && style === 'narrow') || list.length === 2;
  
  if (simpleJoin) return list.join(joinSymbol);
    
  return list.reduce((str, item, i) => {
    if (i === list.length -1) return `${str}${joinSymbol}${item}`;
    if (i === 0) return `${item},`;
    return `${str} ${item},`;
  }, '');
};

/**
 * Trims objects from start of a list that don't have a given key defined
 * @param {Array} data  List of data
 * @param {String} key  Key that must be defined in the object
 * @returns {Array}     List with no leading invalid objects
 */
export const trimStart = (data, key) => {
  if (!key || typeof key !== 'string') {
    console.error('Invalid key value provided to trim function');
    return data;
  }
  if (!Array.isArray(data)) {
    console.error('Attempted to trim object that is not an array');
    return data;
  }
  if (data.length === 0 || (data[0] && isDefined(data[0][key]))) return data;
  return trimStart(data.slice(1), key);
};

/**
 * Trims objects from end of a list that don't have a given key defined
 * @param {Array} data  List of data
 * @param {String} key  Key that must be defined in the object
 * @returns {Array}     List with no trailing invalid objects
 */
export const trimEnd = (data, key) => {
  if (!key || typeof key !== 'string') {
    console.error('Invalid key value provided to trim function');
    return data;
  }
  if (!Array.isArray(data)) {
    console.error('Attempted to trim object that is not an array');
    return data;
  }

  if (data.length === 0 || (data[data.length - 1] && isDefined(data[data.length - 1][key]))) return data;
  return trimEnd(data.slice(0, -1), key);
};

