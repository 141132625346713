import React, { useRef, useState, useEffect, useMemo }  from 'react';
import styles from './TableauPageTemplate.styles.scss';
import PageTitle from 'atoms/PageTitle';
import TableauWrapper from 'atoms/TableauWrapper';
import ResizeObserver from 'resize-observer-polyfill';
import useEventListener from 'hooks/useEventListener';
import { getTableauDashboardUrl } from 'selectors/auth';
import { setTitle } from 'actions/header';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteName, ROUTE_NAMES as ROUTES, setPageTitle } from 'utils/paths';
import { useLocation, useParams } from 'react-router-dom';

const routeDashboardMap = {
  [ROUTES.diveBetaHome]: 'TABLEAU_DIVE_HOME',
  [ROUTES.diveBetaSections]: 'TABLEAU_DIVE_SECTIONS',
  [ROUTES.diveBetaAuthors]: 'TABLEAU_DIVE_AUTHORS',
  [ROUTES.diveBetaAuthorPage]: 'TABLEAU_DIVE_ARTICLES',
  [ROUTES.diveBetaArticles]: 'TABLEAU_DIVE_ARTICLES',
  [ROUTES.reportsPop]: 'POP_REPORT',
  [ROUTES.reportsPromo]: 'PROMO_REPORT',
};

const getPageTitle = (routeName, { author }) => {  
  switch (routeName) {
    case ROUTES.diveBetaHome:
      return 'Historical Beta - Home';
    case ROUTES.diveBetaSections:
      return 'Historical Beta - Sections';
    case ROUTES.diveBetaAuthors:
      return 'Historical Beta - Authors';
    case ROUTES.diveBetaAuthorPage:
      return `Historical Beta - Author - ${author}`;
    case ROUTES.diveBetaArticles:
      return 'Historical Beta - Articles';
    case ROUTES.reportsPop:
      return 'Reports - Pop';
    case ROUTES.reportsPromo:
      return 'Reports - Promo';
    default:
      return '';
  }
};

const getSubtitle = (routeName, { author }) => {  
  switch (routeName) {
    case ROUTES.diveBetaHome:
      return 'Home';
    case ROUTES.diveBetaSections:
      return 'Sections';
    case ROUTES.diveBetaAuthors:
      return 'Authors';
    case ROUTES.diveBetaAuthorPage:
      return `Author: ${author}`;
    case ROUTES.diveBetaArticles:
      return 'Articles';
    case ROUTES.reportsPop:
      return 'Pop';
    case ROUTES.reportsPromo:
      return 'Promo';
    default:
      return '';
  }
};

const breakpoints = {
  lg: 1280,
  md: 1024,
};

const TableauPageTemplate = ({ title }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const routeName = getRouteName(pathname);

  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);

  const urlStem = useSelector(getTableauDashboardUrl(routeDashboardMap[routeName]));
  const [device, setDevice] = useState('');
  const subtitle = getSubtitle(routeName, params);
  const pageTitle = getPageTitle(routeName, params);

  useEffect;

  const { author } = params;
  const authorParam = author ? `&Author=${author}` : '' ;

  const getUrl = (device) => {
    return urlStem + device + authorParam;
  };
  // Prevent url from recalculating as user resizes
  const url = useMemo(() => getUrl(device), [device, routeName]);

  const getDevice = (width) => {
    const deviceStem = '&:device=';
    if (width >= breakpoints.lg) {
      return `${deviceStem}desktop`;
    } else if (width >= breakpoints.md) {
      return `${deviceStem}tablet`;
    } else {
      return `${deviceStem}phone`;
    }
  };

  const updateDevice = () => {
    const width = window.innerWidth;
    const device = getDevice(width);
    setDevice(device);
  };

  useEventListener('resize', () => {
    updateDevice();
  });

  useEffect(() => {
    updateDevice();
    dispatch(setTitle(''));
  }, []);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([chartContainer]) => {
      setWidth(chartContainer.contentRect.width);
    });

    resizeObserver.observe(containerRef.current);
    () => resizeObserver.disconnect();
  }, []);

  return (
    <div className={styles.tableauPage}>
      <div className={styles.tableauPageCardContainer}>
        <PageTitle title={title} subtitle={subtitle} />
        <div ref={containerRef} className={styles.tableauPageCard}>
          <TableauWrapper width={width} url={url} />
        </div>
      </div>
    </div>
  );
};

export default TableauPageTemplate;
