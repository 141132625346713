// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-gNOnBJS5EWRkmRu9q5AP{padding:0.9375rem 0.75rem;margin:0;background-color:var(--grays-200);color:var(--grays-800);font-size:0.6875rem}.sophi-gNOnBJS5EWRkmRu9q5AP a{color:inherit}.sophi-gNOnBJS5EWRkmRu9q5AP a:hover{color:var(--blues-600)}", "",{"version":3,"sources":["webpack://./src/atoms/Version/Version.styles.scss"],"names":[],"mappings":"AAEA,4BACE,yBAAA,CACA,QAAA,CACA,iCAAA,CACA,sBAAA,CACA,mBAAA,CAGF,8BACE,aAAA,CAEF,oCACE,sBAAA","sourcesContent":["@import 'styles/util';\n\n.version {\n  padding: pxToRem(15) pxToRem(12);\n  margin: 0;\n  background-color: var(--grays-200);\n  color: var(--grays-800);\n  font-size: pxToRem(11);\n}\n\n.version a {\n  color: inherit;\n}\n.version a:hover {\n  color: var(--blues-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"version": "sophi-gNOnBJS5EWRkmRu9q5AP"
};
export default ___CSS_LOADER_EXPORT___;
