// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-Z0KC69VqkGgtcy0fMPlP{display:block;margin-bottom:calc(1*var(--space-1))}.sophi-ZZ8AS3ce6oWcFxtogaiA{margin-top:calc(1*var(--space-1));color:var(--gray-900);font-size:.9em}.sophi-KJlVkNA5ClnnLkjk21XI{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;gap:calc(.5*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/JSONForm/JSONForm.styles.scss"],"names":[],"mappings":"AAAA,4BACE,aAAA,CACA,oCAAA,CAGF,4BACE,iCAAA,CACA,qBAAA,CACA,cAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,2BAAA","sourcesContent":[".label {\n  display: block;\n  margin-bottom: calc(1 * var(--space-1));\n}\n\n.validation {\n  margin-top: calc(1 * var(--space-1));\n  color: var(--gray-900);\n  font-size: 0.9em;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: calc(0.5 * var(--space-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "sophi-Z0KC69VqkGgtcy0fMPlP",
	"validation": "sophi-ZZ8AS3ce6oWcFxtogaiA",
	"buttons": "sophi-KJlVkNA5ClnnLkjk21XI"
};
export default ___CSS_LOADER_EXPORT___;
