import React, { useContext, useEffect, useRef } from 'react';
import styles from './Tabs.styles.scss';
import { TabStateContext } from './context';

const Tab = ({ id, index, panelId, children }) => {
  const { selected, focused, onClick, onKeyDown, onKeyUp } = useContext(TabStateContext);
  const tabRef = useRef(null);
  const active = selected === id;

  useEffect(() => {
    // Handle focus via keyboard navigation
    if (focused === id) {
      tabRef.current.focus();
    }
  }, [focused, id]);
  
  return (
    <button
      ref={tabRef}
      role="tab"
      className={styles.tab}
      aria-selected={active}
      aria-controls={panelId}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      id={id}
      tabIndex={active ? 0 : -1}
      index={index}
    >
      {children}
    </button>
  );
};

Tab.displayName = 'Tab';

export default Tab;
