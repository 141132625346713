"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImportContext = exports.PageImportContext = void 0;
const react_1 = __importStar(require("react"));
const initialValue = {
    viewCode: false,
    uploaded: null,
    progress: null,
    existing: null,
    setViewCode: (viewCode) => { },
    setUploaded: (config) => { },
    setProgress: (progress) => { },
    setExisting: (config) => { },
};
const Context = react_1.default.createContext(initialValue);
const Provider = Context.Provider;
const PageImportContext = ({ children }) => {
    const [existing, setExisting] = (0, react_1.useState)(null);
    const [uploaded, setUploaded] = (0, react_1.useState)(null);
    const [viewCode, setViewCode] = (0, react_1.useState)(false);
    const [progress, setProgress] = (0, react_1.useState)(null);
    return (react_1.default.createElement(Provider, { value: {
            viewCode,
            uploaded,
            progress,
            existing,
            setViewCode,
            setUploaded,
            setProgress,
            setExisting,
        } }, children));
};
exports.PageImportContext = PageImportContext;
const useImportContext = () => (0, react_1.useContext)(Context);
exports.useImportContext = useImportContext;
exports.default = exports.PageImportContext;
