export const getUserList = async (host, client, token, pagination) => {
  const url = new URL(`${host}/${client}/users`);
  url.search = new URLSearchParams(pagination).toString();

  const headers = { Authorization: 'Bearer ' + token };
  const fetchParams = { method: 'GET', headers };
  const response = await fetch(url.toString(), fetchParams);

  const total = parseInt(response.headers.get('total'));
  const users = await response.json();

  const body = {
    users,
    total
  };

  return { ok: response.ok, body };
};

export const sendPasswordReset = async (host, client, token, email) => {
  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const fetchParams = { method: 'POST', headers, body: JSON.stringify({ email }) };
  const response = await fetch(`${host}/${client}/password-reset`, fetchParams);
  if (response.ok) {
    return 'An email has been sent to the user to reset their password.';
  }
  return 'Could not send password reset email. Please try again.';
};

export const createNewUser = async (host, client, token, user) => {
  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const requestBody = {
    name: user.name,
    email: user.email,
    password: user.password,
  };
  const fetchParams = { method: 'POST', headers, body: JSON.stringify(requestBody) };
  const response = await fetch(`${host}/${client}/user`, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};

export const updateUser = async (host, client, token, userID, user) => {
  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const requestBody = {
    name: user.name,
  };
  const encodedID = encodeURIComponent(userID);
  const fetchParams = { method: 'PATCH', headers, body: JSON.stringify(requestBody) };
  const response = await fetch(`${host}/${client}/user/${encodedID}`, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};

export const deleteUser = async (host, client, token, userID) => {
  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const fetchParams = { method: 'DELETE', headers };
  const encodedID = encodeURIComponent(userID);
  const response = await fetch(`${host}/${client}/user/${encodedID}`, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};

export const getRoles = async (host, client, token, userID) => {
  const encodedID = encodeURIComponent(userID);
  const url = new URL(`${host}/${client}/user/${encodedID}/roles`);
  url.search = new URLSearchParams({ page: 0, per_page: 25 }).toString();

  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const fetchParams = { method: 'GET', headers };

  const response = await fetch(url, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};

export const assignRole = async (host, client, token, userID, roles) => {
  const encodedID = encodeURIComponent(userID);
  const url = new URL(`${host}/${client}/user/${encodedID}/roles`);

  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const requestBody = { roles };
  const fetchParams = { method: 'PATCH', headers, body: JSON.stringify(requestBody) };

  const response = await fetch(url, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};

export const unassignRole = async (host, client, token, userID, roles) => {
  const encodedID = encodeURIComponent(userID);
  const url = new URL(`${host}/${client}/user/${encodedID}/roles`);

  const headers = { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' };
  const requestBody = { roles };
  const fetchParams = { method: 'DELETE', headers, body: JSON.stringify(requestBody) };

  const response = await fetch(url, fetchParams);
  const body = await response.json();
  return { ok: response.ok, body };
};
