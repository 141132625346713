// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-IJayaI4eKX5jA0KqBacg{position:relative}.sophi-IJayaI4eKX5jA0KqBacg.sophi-GtFow7tMhA0_hPkPcS0i{max-height:600px}.sophi-k5uCRWm_2KggaL5Im3ll{display:none;width:100%;height:600px;position:absolute;background-color:var(--white)}.sophi-k5uCRWm_2KggaL5Im3ll.sophi-GtFow7tMhA0_hPkPcS0i{display:inline}", "",{"version":3,"sources":["webpack://./src/atoms/TableauWrapper/TableauWrapper.styles.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAA,CAEA,uDACI,gBAAA,CAGR,4BACI,YAAA,CACA,UAAA,CACA,YAAA,CACA,iBAAA,CACA,6BAAA,CAEA,uDACI,cAAA","sourcesContent":[".wrapper { \n    position: relative;\n\n    &.loading {\n        max-height: 600px;\n    }\n}\n.indicator {\n    display: none;\n    width: 100%;\n    height: 600px;\n    position: absolute; \n    background-color: var(--white);\n    \n    &.loading {\n        display: inline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "sophi-IJayaI4eKX5jA0KqBacg",
	"loading": "sophi-GtFow7tMhA0_hPkPcS0i",
	"indicator": "sophi-k5uCRWm_2KggaL5Im3ll"
};
export default ___CSS_LOADER_EXPORT___;
