import React from 'react';
import PropTypes from 'prop-types';
import Table from 'molecules/SophiTable';
import styles from './TopStoriesCard.styles.scss';

const TopStoriesCard = ({ data, loading, id, columns, title, widths }) => {
  return (
    <div className={styles.topStoriesCard}  data-track-impression="true" data-track-name={id}>
      <Table
        totalRows={10}
        title={title}
        columnOptions={{}}
        widths={widths}
        columns={columns}
        data={data}
        hideEditColumns
        hideDownload
        hideFooter
        variant="framed"
        loading={loading}
      />
    </div>
  );
};

TopStoriesCard.defaultProps = {
  data: [],
  loading: false,
};

TopStoriesCard.propTypes = {
  keyMetricKey: PropTypes.string.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default TopStoriesCard;
