// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-_O7Pj3hnB3jUwMJOa0Lf{container-type:inline-size}.sophi-LqCt5Nvz7T6ZcXAt8XK7{display:grid;grid-template-columns:1fr;-webkit-column-gap:calc(.5*var(--space-1));-moz-column-gap:calc(.5*var(--space-1));column-gap:calc(.5*var(--space-1));row-gap:calc(.5*var(--space-1));margin-bottom:calc(1*var(--space-1));-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}.sophi-zq4_2KTwyY__c5DFQKdB{grid-column:1/2}.sophi-LeoTWyPjh8pAB8ab1_iX{grid-column:2/3}.sophi-hvWBcjRtzFGbG2vOU6eG{grid-column:1/-1;display:-webkit-box;display:-ms-flexbox;display:flex;gap:calc(.5*var(--space-1));-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}@container (min-width: 400px){.sophi-LqCt5Nvz7T6ZcXAt8XK7{grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/Filters/Form.styles.scss"],"names":[],"mappings":"AAAA,4BACE,0BAAA,CAGF,4BACE,YAAA,CACA,yBAAA,CACA,0CAAA,CAAA,uCAAA,CAAA,kCAAA,CACA,+BAAA,CACA,oCAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAGF,4BACE,eAAA,CAEF,4BACE,eAAA,CAGF,4BACE,gBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAGF,8BACE,4BACE,6CAAA,CAAA,qCAAA,CAAA","sourcesContent":[".container {\n  container-type: inline-size;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr;\n  column-gap: calc(0.5 * var(--space-1));\n  row-gap: calc(0.5 * var(--space-1));\n  margin-bottom: calc(1 * var(--space-1));\n  align-items: baseline;\n}\n\n.label {\n  grid-column: 1 / 2;\n}\n.input {\n  grid-column: 2 / 3;\n}\n\n.matchCase {\n  grid-column: 1 / -1;\n  display: flex;\n  gap: calc(0.5 * var(--space-1));\n  justify-content: flex-end;\n  align-items: baseline;\n}\n\n@container (min-width: 400px) {\n  .grid {\n    grid-template-columns: max-content 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-_O7Pj3hnB3jUwMJOa0Lf",
	"grid": "sophi-LqCt5Nvz7T6ZcXAt8XK7",
	"label": "sophi-zq4_2KTwyY__c5DFQKdB",
	"input": "sophi-LeoTWyPjh8pAB8ab1_iX",
	"matchCase": "sophi-hvWBcjRtzFGbG2vOU6eG"
};
export default ___CSS_LOADER_EXPORT___;
