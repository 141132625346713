"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSymbols = exports.diversityRuleLimitTypeLabels = exports.switchLabels = exports.filterLabels = exports.formatWildcardSymbol = exports.getMetaFieldDataType = exports.getMetaFieldLabel = exports.getMetaField = exports.diversityRuleMetadata = exports.metadata = exports.diversityRuleMetadataFields = exports.metadataFields = exports.decodeHtml = exports.capitalizeFirstLetter = exports.sortAlphabetically = void 0;
const types_1 = require("../../types");
const sortAlphabetically = (a, b) => {
    if (typeof a !== 'string' || typeof b !== 'string')
        return 0;
    if (a.toLocaleLowerCase() > b.toLocaleLowerCase())
        return 1;
    if (a.toLocaleLowerCase() < b.toLocaleLowerCase())
        return -1;
    return 0;
};
exports.sortAlphabetically = sortAlphabetically;
const capitalizeFirstLetter = (s) => {
    if (typeof s === 'string' && s.length)
        return s[0].toLocaleUpperCase() + s.slice(1);
    return '';
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
const decodeHtml = (html) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
};
exports.decodeHtml = decodeHtml;
exports.metadataFields = ['accessCategory', 'allSections', 'byline', 'canonicalBreadcrumb', 'canonicalPath', 'canonicalRootSectionName', 'classificationCode', 'collectionName', 'creditLine', 'dataSource', 'editorialAccessName', 'embeddedImagesCount', 'firstPublishedAt', 'headline', 'host', 'id', 'lastModifiedAt', 'lastPublishedAt', 'mediaIds', 'mediaIdsCount', 'ownership', 'promotionalImageUri', 'redirectToUrl', 'seoKeywords', 'size', 'slug', 'sponsored', 'subtype', 'tags', 'thumbnailImageUri', 'type', 'wordCount'];
exports.diversityRuleMetadataFields = ['accessCategory', 'allSections', 'byline', 'canonicalBreadcrumb', 'canonicalPath', 'canonicalRootSectionName', 'classificationCode', 'collectionName', 'contentId', 'creditLine', 'dataSource', 'datePublished', 'editorialAccessName', 'embeddedImagesCount', 'hasImage', 'headline', 'host', 'mediaIds', 'mediaIdsCount', 'ownership', 'redirectToUrl', 'score', 'seoKeywords', 'size', 'sponsored', 'subtype', 'tags', 'thumbnailImageUri', 'type', 'updatedOn', 'wordCount'];
/*
  Synonyms:
    datePublished: 'firstPublishedAt',
    id: 'contentId',
    updatedOn: 'lastModifiedAt',
*/
const fieldLabels = {
    accessCategory: 'access category',
    allSections: 'all sections',
    byline: 'byline',
    canonicalBreadcrumb: 'canonical breadcrumb',
    canonicalPath: 'canonical path',
    canonicalRootSectionName: 'canonical root section name',
    classificationCode: 'classification code',
    collectionName: 'collection name',
    contentId: 'content ID',
    creditLine: 'credit line',
    dataSource: 'data source',
    datePublished: 'first published',
    editorialAccessName: 'editorial access name',
    embeddedImagesCount: 'number of embedded images',
    firstPublishedAt: 'first published',
    hasImage: 'has image',
    headline: 'headline',
    host: 'host',
    id: 'content ID',
    lastModifiedAt: 'last modified',
    lastPublishedAt: 'last published',
    mediaIds: 'media IDs',
    mediaIdsCount: 'number of media IDs',
    ownership: 'ownership',
    promotionalImageUri: 'promotional image URL',
    redirectToUrl: 'redirect-to URL',
    score: 'score',
    seoKeywords: 'SEO keywords',
    size: 'size',
    slug: 'slug',
    sponsored: 'sponsored',
    subtype: 'subtype',
    tags: 'tags',
    thumbnailImageUri: 'thumbnail image URI',
    type: 'type',
    updatedOn: 'last modified',
    wordCount: 'word count',
};
const fieldTypes = {
    accessCategory: types_1.typeLabels.string,
    allSections: types_1.typeLabels.stringarray,
    byline: types_1.typeLabels.stringarray,
    canonicalBreadcrumb: types_1.typeLabels.string,
    canonicalPath: types_1.typeLabels.string,
    canonicalRootSectionName: types_1.typeLabels.string,
    classificationCode: types_1.typeLabels.string,
    collectionName: types_1.typeLabels.string,
    contentId: types_1.typeLabels.string,
    creditLine: types_1.typeLabels.string,
    dataSource: types_1.typeLabels.string,
    datePublished: types_1.typeLabels.date,
    editorialAccessName: types_1.typeLabels.string,
    embeddedImagesCount: types_1.typeLabels.int,
    firstPublishedAt: types_1.typeLabels.date,
    hasImage: types_1.typeLabels.boolean,
    headline: types_1.typeLabels.string,
    host: types_1.typeLabels.string,
    id: types_1.typeLabels.string,
    lastModifiedAt: types_1.typeLabels.date,
    lastPublishedAt: types_1.typeLabels.date,
    mediaIds: types_1.typeLabels.stringarray,
    mediaIdsCount: types_1.typeLabels.int,
    ownership: types_1.typeLabels.string,
    promotionalImageUri: types_1.typeLabels.string,
    redirectToUrl: types_1.typeLabels.string,
    score: types_1.typeLabels.long,
    seoKeywords: types_1.typeLabels.stringarray,
    size: types_1.typeLabels.long,
    slug: types_1.typeLabels.string,
    sponsored: types_1.typeLabels.boolean,
    subtype: types_1.typeLabels.string,
    tags: types_1.typeLabels.stringarray,
    thumbnailImageUri: types_1.typeLabels.string,
    type: types_1.typeLabels.string,
    updatedOn: types_1.typeLabels.date,
    wordCount: types_1.typeLabels.int,
};
exports.metadata = exports.metadataFields
    .map(key => ({ key, type: fieldTypes[key], label: fieldLabels[key] }))
    .sort(({ label: a }, { label: b }) => (0, exports.sortAlphabetically)(a, b));
exports.diversityRuleMetadata = exports.diversityRuleMetadataFields
    .map(key => ({ key, type: fieldTypes[key], label: fieldLabels[key] }))
    .sort(({ label: a }, { label: b }) => (0, exports.sortAlphabetically)(a, b));
const getMetaField = (key) => {
    if (fieldLabels.hasOwnProperty(key) && fieldTypes.hasOwnProperty(key)) {
        const label = fieldLabels[key];
        const type = fieldTypes[key];
        return { key, type, label };
    }
    return { key, type: '', label: '' };
};
exports.getMetaField = getMetaField;
const getMetaFieldLabel = (key) => {
    if (fieldLabels.hasOwnProperty(key)) {
        return fieldLabels[key];
    }
    return '';
};
exports.getMetaFieldLabel = getMetaFieldLabel;
const getMetaFieldDataType = (key) => {
    if (fieldTypes.hasOwnProperty(key)) {
        return fieldTypes[key];
    }
    return '';
};
exports.getMetaFieldDataType = getMetaFieldDataType;
const formatWildcardSymbol = (value, wildcard) => {
    return value.replace(/%/g, wildcard);
};
exports.formatWildcardSymbol = formatWildcardSymbol;
exports.filterLabels = {
    EXISTS: 'present',
    EQUALITY: 'equal to',
    LIKE: 'similar to',
    BETWEEN: 'between'
};
exports.switchLabels = {
    'true': 'yes',
    'false': 'no',
};
exports.diversityRuleLimitTypeLabels = {
    at_most: 'no more than',
    at_least: 'at least',
    at_most_from_any: 'up to (per category)',
};
exports.filterSymbols = {
    EQUALITY: {
        "false": '=',
        "true": '≠'
    },
    LIKE: {
        "false": '≈',
        "true": '≉'
    }
};
