import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
// Atoms
import Section from 'atoms/Section';
import DynamicComponent from 'atoms/DynamicComponent';
// Actions
import {
  setSelectedDiveArticlesColumns,
  setDiveArticlePaginationValue,
  setDiveArticleSort,
  fetchDiveArticlesColumns
} from 'actions/dive';
import types from 'actions/diveTypes';
import { setTitle } from 'actions/header';
// Selectors
import { getDiveArticlesAllColumns, getDiveArticlesColumns } from 'selectors/dive';
import { getDiveDownloadsDisabled } from 'selectors/global';
import { getPlatforms, getTimezone, getMainHostID } from 'selectors/auth';
// Utils
import { setPageTitle } from 'utils/paths';
import { setColumnObject } from 'utils/storage';
import { formatColumnsConfig, getCellFormatter } from 'utils/table';

const Articles = () => {
  const dispatch = useDispatch();
  const selectedColumns = useSelector(getDiveArticlesColumns);
  const downloadDisabled = useSelector(getDiveDownloadsDisabled);
  const platforms = useSelector(getPlatforms);
  const timezone = useSelector(getTimezone);
  const mainHost = useSelector(getMainHostID);
  const requestedColumns = useSelector((state) => state.dive.articles.table.columns);
  const articleColumns = formatColumnsConfig(useSelector(getDiveArticlesAllColumns), platforms);

  useEffect(() => {
    setPageTitle('Historical - Articles');
    dispatch(setTitle(''));
    dispatch(fetchDiveArticlesColumns());
  }, []);

  // Event Handlers
  const handleColumnChange = (cols) => {
    dispatch(setSelectedDiveArticlesColumns(cols));
    setColumnObject('dive-articles-columns', cols);
  };
  const handlePaginationChange = (type, value) => dispatch(setDiveArticlePaginationValue(type, value));
  const handleSortChange = ({ column, direction }) => {
    dispatch(setDiveArticleSort({ sort: column, sortOrder: direction }));
  };

  const columnConfig = useMemo(() => {
    const formattedColumns = uniq([ 'sophiScore', ...selectedColumns ]);
    return formattedColumns.map((column) => {
      const articleColumn = articleColumns.nonDevice.get(column) || articleColumns.deviceTop.get(column);
      if (articleColumn) {
        return {
          accessor: column,
          Cell: getCellFormatter(column, requestedColumns && requestedColumns.includes(column), { host: mainHost, timezone, dive: true }),
          Header: column === 'sophiScore' ? 'Score' : articleColumn.label,
          ...articleColumn,
        };
      }
    }).filter((c) => c);
  }, [articleColumns, selectedColumns, requestedColumns]);

  return (
    <div>
      <DynamicComponent
        placeholder={<Section title={'Articles - Loading'} loading={true}>{null}</Section>}
        component={() => import('organisms/DiveTable')}
        // Any props needed by the component can be placed as a prop of the Dynamic Component
        action={types.FETCH_DIVE_ARTICLES}
        tableValues={(state) => (state.dive.articles.table)}
        count={(state) => (state.dive.articles.count)}
        selectedColumns={getDiveArticlesColumns}
        tableColumns={articleColumns}
        onColumnChange={handleColumnChange}
        onPaginationChange={handlePaginationChange}
        onSort={handleSortChange}
        pageType="article"
        id="dive-articles"
        columns={columnConfig}
        downloadConfig={{
          handleDownload: () => dispatch({ type: types.FETCH_DIVE_ARTICLES_REPORT }),
          disabled: downloadDisabled,
          info: 'Adjust the date-published range to download up to 31 days’ worth of data.'
        }}
        frozenColumn="headline"
      />
    </div>
  );
};

export default Articles;
