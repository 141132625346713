import React, { useEffect, useMemo } from 'react';
import useLocalStored from './useLocalStored';

const useTypeFilter = ({ list, accessor, storageKey }) => {
  const available = useMemo(() => {
    return list.reduce((acc, item) => {
        const value = accessor instanceof Function ? accessor(item) : item[accessor];
        return acc.indexOf(value) === -1 ? [...acc, value] : acc;
      }, []).sort();
  }, [list]);
  
  const initialValue = available.reduce((acc, val) => ({ ...acc, [val]: true }), {});
  const [selected, setSelected] = useLocalStored({ key: storageKey, initialValue });
  
  const filtered = list.filter(item => {
    const value = accessor instanceof Function ? accessor(item) : item[accessor];
    return selected[value];
  });

  useEffect(() => {
    let modified = false;
    const updated = { ...selected };
    for (const key in selected) {
      if (available.indexOf(key) === -1) {
        delete updated[key];
        modified = true;
      }
    }
    for (const key of available) {
      if (!updated.hasOwnProperty(key)) {
        updated[key] = true;
        modified = true;
      }
    }
    if (modified) setSelected(updated);
  }, [available])

  const onClick = ({ option, checked }) => {
    setSelected({
      ...selected,
      [option]: checked
    });
  };

  const reset = () => setSelected(initialValue);

  return {
    available,
    selected,
    filtered,
    onClick,
    reset
  }
};

export default useTypeFilter;
