import React, { useEffect, useMemo, useState } from 'react';
import { AutoComplete, Col, Form, FormItemProps, Input, Row, Select, SelectProps, theme, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import format from 'date-fns/format';

import { useModalData } from '../contexts/ModalProvider';
import { useOverrides } from '../contexts/OverridesProvider';

import { debounce } from '../utils/functions';
import { Article } from '../types';

const width = '110px';

const ArticleInput: React.FC<{
  name: string;
  errors: string[];
  onChange?: (value: string) => void;
} & FormItemProps> = ({ name, errors, onChange, ...props }) => {
  const { api } = useOverrides();
  const { article, form, setArticle, articleInputType: type, setArticleInputType: setType } = useModalData();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ data, setData ] = useState<Article[]>([]);
  const [ searchResults, setSearchResults ] = useState<SelectProps<Article>['options']>([]);
  const handleHeadlineSearch = debounce(searchForArticle, 300);

  const help = useMemo(() => {
    if (errors.length === 0 && article) return (
      <small>
        {article.articleUrl && <Typography.Link target="_blank" href={`https://${article.articleUrl}`}><LinkOutlined/>&nbsp;</Typography.Link>}
        <span dangerouslySetInnerHTML={{ __html: article.headline || '' }}/>
        {!!(article.publishedDate && article.publishedDate > 0) && <span>&nbsp;&mdash;&nbsp;{format(new Date(article.publishedDate), 'MMM dd, yyyy')}&emsp;</span>}
      </small>
    );
    return undefined;
  }, [ article, errors ]);

  useEffect(() => {
    form.setFieldValue(name, article?.articleId);
    if (article?.articleId) form.validateFields([name]);
  }, [ article ])

  function handleHeadlineSelect(value: string) {
    const result = data.find(({ headline }) => headline === value);
    if (result) {
      setArticle(result);
    }
  }

  function handleTypeChange(value: 'headline' | 'id') {
    setType(value);
  }

  async function handleInputChange({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (target.value !== article.articleId) {
      setArticle({ articleId: target.value });
    }
  }

  async function searchForArticle(value: string) {
    if (value.length > 2) {
      setIsLoading(true);
      return await api.searchForArticle(value).then((response) => {
        if (response.length > 0) {
          setData(response);
          setSearchResults([
            {
              label: <span>Articles</span>,
              options: response.map(({ articleId, articleUrl, headline }) => ({
                value: headline,
                label: (
                  <Row justify="space-between" key={articleId}>
                    <Col span={22}><Typography.Text ellipsis><span dangerouslySetInnerHTML={{ __html: headline || '' }}/></Typography.Text></Col>
                    <Col flex="none">
                      <a target="_blank" href={`https://${articleUrl}`}><LinkOutlined/></a>
                    </Col>
                  </Row>
                ),
              })),
            }
          ]);
        } else { setSearchResults(noResults(value)); }
      }).catch(() => { setSearchResults(noResults(value)); })
      .finally(() => { setIsLoading(false); });
    }
  }

  return (<>
    <Form.Item
      label="Article"
      help={help}
      name={name}
      rules={[
        {required: true, message: 'You must provide an article in order to proceed.', validateTrigger: 'onFinish'},
        ...(type === 'id' ? [{ pattern: /^[A-Za-z0-9\_\-]+$/, message: 'Invalid article id format.' }] : [])
      ]}
      {...props}
    >
      <Input.Group compact>
        <Select style={{ width }} defaultValue={type} onChange={handleTypeChange}
          options={[
            { value: 'headline', label: 'Headline' },
            { value: 'id', label: 'Article ID' },
          ]}
        />
        <Form.Item noStyle>
          {type === 'headline' && (
            <AutoComplete style={{ width: `calc(100% - ${width})` }} options={searchResults} onSearch={handleHeadlineSearch} onSelect={handleHeadlineSelect} defaultValue={article.headline}>
              <Input.Search  placeholder="Enter 3 or more characters to seach" loading={isLoading} allowClear/>
            </AutoComplete>
          )}
          {type === 'id' && <Input style={{ width: `calc(100% - ${width})` }} defaultValue={article.articleId} onChange={handleInputChange} allowClear/>}
        </Form.Item>
      </Input.Group>
    </Form.Item>
  </>);
}

function noResults(value: string = '') {
  return [
    { label: <span>Articles <b style={{ float: 'right' }}>No results found</b></span>, options: [] }
  ];
}

export default ArticleInput;
