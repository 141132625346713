export const DEFAULT_CACHE_EXPIRY = 60 * 1000; // 60 seconds
export const MAX_CACHE_SIZE = 10;

const types = {
  ADD_TO_CACHE: 'ADD_TO_CACHE',
  ADD_TO_CACHE_FAIL: 'ADD_TO_CACHE_FAIL',
  CLEAN_CACHE: 'CLEAN_CACHE',
  CLEAN_CACHE_FAIL: 'CLEAN_CACHE_FAIL',
};
  
export default types;
  