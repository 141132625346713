import React from 'react';
import { render } from 'react-dom';
import ErrorBoundary from 'molecules/ErrorBoundary';
import * as Sentry from '@sentry/browser';
import { default as configureStore, history } from './redux/store';
import ProviderWrapper from './redux/ProviderWrapper';
import App from './App.js';
import packageJson from '../package.json';
import Modal from 'react-modal';

const version = packageJson.version;

// Add meta tag
const appMeta = document.createElement('meta');
appMeta.name = 'app-version';
appMeta.content = version;
document.getElementsByTagName('head')[0].appendChild(appMeta);

// Initialize store
const store = configureStore();

const isProd = ENV.APP_ENVIRONMENT === 'production';

// Initialize Sentry
if (isProd) {
  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    environment: ENV.APP_ENVIRONMENT || 'production',
  });
  Sentry.configureScope((scope) => { scope.setTag('app-version', version); });
  // Further sentry configuration happens in the configureSentry() saga.
}

if (isProd) {
  import('react-gtm-module')
    .then(({ default: TagManager }) => {
      const tagManagerArgs = {
        gtmId: ENV.GTM_ID,
        dataLayer: {
          product: 'dashboard',
          version
        }
      };

      TagManager.initialize(tagManagerArgs);
    });
}

const rootElement = document.getElementById('sophi-app');

Modal.setAppElement(rootElement);

render(
  <ProviderWrapper history={history} store={store}>
    <ErrorBoundary showHeader={true} >
      <App />
    </ErrorBoundary>
  </ProviderWrapper>,
  rootElement,
);
