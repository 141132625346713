"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isErrorResponse(val) {
    return (typeof val === 'object' &&
        val !== null &&
        'errors' in val &&
        Array.isArray(val.errors)
    // check next level if necessary
    );
}
const formatErrors = async (data) => {
    const formatted = [];
    if (isErrorResponse(data)) {
        for (const e of data.errors) {
            if (e && typeof e.message === 'string') {
                formatted.push(e.message);
            }
        }
    }
    return formatted;
};
exports.default = formatErrors;
