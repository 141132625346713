import React, { useEffect } from 'react';

import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import Colors from 'styles/colors';

import { ReactComponent as NotFoundIcon } from 'assets/svg/icon-not-found.svg';
import { setPageTitle } from 'utils/paths';

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      padding: '3.75rem 0',
    },
    [theme.breakpoints.up('xxl')]: {
      padding: '5.625rem 0',
    },
    padding: '2.5rem 0',
    textAlign: 'center',
    '& p': {
      color: Colors.grays[800]
    }
  },
  svg: {
    display: 'inline-block'
  },
  title: {
    color: Colors.blues[800]
  }
});

const Empty = ({ classes }) => {
  useEffect(() => {
    setPageTitle('Empty');
  }, []);

  return (
    <section className={classes.container}>
      <NotFoundIcon className={classes.svg} />
      <Typography variant="h1" className={classes.title}>
        Welcome to Sophi!
      </Typography>
      <p>Your Sophi configuration tools will appear here</p>
    </section>
  );
};

export default withStyles(styles)(Empty);
