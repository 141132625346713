import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useParams, Redirect } from 'react-router-dom';

import pageStyles from '../Page.styles.scss';
import styles from './PageEdit.styles.scss';

import ErrorList from '../../components/ErrorList';
import PageTitle from '../../components/PageTitle';
import PageConfigForm from '../../components/PageConfig';
import { useHistory } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { message, Spin } from 'antd';
import ButtonLink from '../../components/ButtonLink';
import { UploadOutlined } from '@ant-design/icons';
import { getPage, putPage } from '../../api/pages';

const PageEdit = () => {
  const queryClient = useQueryClient();
  const { pageName } = useParams();
  let history = useHistory();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(putPage);
  const { isLoading: isSaving, error, mutate } = useMutation({
    mutationFn,
    onMutate: async (request) => {
      await queryClient.cancelQueries({ queryKey: [`page`, pageName] });

      const previous = queryClient.getQueryData({ queryKey: [`page`, pageName] });

      queryClient.setQueryData([`page`, pageName], (old) => {
        return {
          ...old,
          config: {
            ...old.config,
            ...request.body.config
          }
        };
      });

      return { previous };
    },
    onError: (err, request, context) => {
      queryClient.setQueryData([`page`, pageName], context.previous);
    },
    onSuccess: () => {
      const route = data.widgets.length ? ROUTE_NAMES.curatorPagePublish : ROUTE_NAMES.curatorPageView;
      history.push(getRoute(route, { pageName }));
      message.success({
        content: `Saved`,
        style: {
          marginTop: '58px'
        }
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [`page`, pageName] });
    }
  });
  
  const handleSubmit = (config) => {
    mutate({ body: { ...data, config }, pageName });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Edit page ${pageName}`} />
      <div className={pageStyles.body}>
        <div className={styles.import}>
          <ButtonLink
            to={getRoute(ROUTE_NAMES.curatorPageImport)}
            icon={<UploadOutlined/>}
          >
            Import
          </ButtonLink>
        </div>
        {error?.cause?.status === 404 &&
          <Redirect to={getRoute(ROUTE_NAMES.curatorPagesList)} />
        }
        {isLoading && !data  && <Spin />}

        {data &&
          <PageConfigForm
            onSubmit={handleSubmit}
            onCancel={history.goBack}
            disabled={isSaving}
            config={data.config}
            widgets={data.widgets}
            showWidgetDuplicationStrategy={true}
          />
        }
        {error?.messages &&
          <ErrorList messages={error.messages} />
        }
      </div>
    </>
  );
};

export default PageEdit;
