// Utils
import { getResource, pathBuilder, queryString } from 'utils/api';
import { convertToSeconds, getMostRecentTime } from 'utils/dateTime';
import { saveBlob } from 'utils/download';

const constructQuery = (params, pagination, opts) => {
  const { dateRanges, table, timeZone } = opts;

  // [API QUERY PARAMS]
  // Score Range

  /**
   * The API current does not accept dates in the future, however this is what most
   * accurately would describe the users selection. Until the API can be fixed
   * or GraphQL 🙏 implemented, we are forced to augment the end times to be the
   * most recent instead of what is accurate.
   */
  const start = convertToSeconds(dateRanges.publish.startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), dateRanges.publish.endDate));
  const analysisStart = convertToSeconds(dateRanges.analysis.startDate);
  const analysisEnd = convertToSeconds(getMostRecentTime(new Date(), dateRanges.analysis.endDate));

  // Pagination
  const page = typeof pagination.page === 'number' ? pagination.page : 0;
  const size = pagination.size || null;
  const sort = table.sort || null;
  const order = table.sortOrder || null;
  // Table Columns
  const columns = table.columns;

  const qs = {
    start, // required
    end, // required
    analysisStart, // kinda required
    analysisEnd, // kinda required
    page,
  };

  if (timeZone) {
    qs.tzName = timeZone;
  }
  if (size) {
    qs.size = size;
  }

  if (sort) {
    qs.sort = sort;
  }

  if (order) {
    qs.order = order;
  }

  // [API BODY]
  // Filters
  const bylines = params[`authors`] || [];
  const headlines = params[`headlines`] || [];
  const keywords = params[`keywords`] || [];
  const sections = params[`sections`] || [];
  const subtypes = params[`subtypes`] || [];
  const creditLines = params[`creditLine`] || [];
  const ownership = params[`ownership`] || [];
  const contentTypes = params['type'] || [];
  const access = params['access'] || [];

  let formattedSections = null;
  if (sections.length) {
    formattedSections = {
      section: sections.join(','),
      expand: 'none',
      exclusions: 'none',
    };
  }

  let body = new FormData();
  body.append('columns', JSON.stringify(columns));
  body.append('bylines', JSON.stringify(bylines));
  body.append('headlines', JSON.stringify(headlines));
  body.append('keywords', JSON.stringify(keywords));
  body.append('subTypes', JSON.stringify(subtypes));
  body.append('creditLines', JSON.stringify(creditLines));
  body.append('ownerships', JSON.stringify(ownership));
  if (formattedSections) {
    body.append('sectionsQueryParameters', JSON.stringify(formattedSections));
  }

  if (params['score']) {
    // make sure the score min/max are always Integers
    body.append('scoreMin', JSON.stringify(parseInt(params['score'][0])));
    // Set max to max + 0.999999 to capture all values in the given range
    // If max of int 12 is provided, BE will only return scores up to 12.000000
    body.append('scoreMax', JSON.stringify(parseInt(params['score'][1]) + 0.999999));
  }

  body.append('contentTypes', JSON.stringify(contentTypes));
  body.append('accesses', JSON.stringify(access));

  return { body, columns, qs };
};

const getOverview = (host, client, token, params, pagination, opts) => {
  // Error Handling
  const options = Object.keys(opts);
  if (!options.includes('dateRanges')) {
    throw new Error('DateRanges required but not specified');
  } else {
    if (
      !opts.dateRanges.analysis.startDate ||
      !opts.dateRanges.analysis.endDate ||
      !opts.dateRanges.publish.startDate ||
      !opts.dateRanges.publish.endDate
    ) {
      return { error: 'Some or all dateRange values are invalid' };
    }
  }

  const { body, qs } = constructQuery(params, pagination, opts);

  const formatResponse = (data) => {
    if (Object.keys(data).includes('noData')) {
      return ({ noData: true });
    }

    return data;
  };

  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `view?${queryString(qs)}`
    }
  );

  return getResource({
    endpoint: path,
    method: 'POST',
    token,
    body
  }).then((data) => {
    return {
      response: {
        data: opts.raw ? data : formatResponse(data),
        columns: opts.table.columns,
      }
    };
  })
    .catch((error) => ({ error }));
};

const getOverviewReport = (host, client, token, params, opts) => {
  // Error Handling
  const options = Object.keys(opts);
  if (!options.includes('dateRanges')) {
    throw new Error('DateRanges required but not specified');
  } else {
    if (
      !opts.dateRanges.analysis.startDate ||
      !opts.dateRanges.analysis.endDate ||
      !opts.dateRanges.publish.startDate ||
      !opts.dateRanges.publish.endDate
    ) {
      return { error: 'Some or all dateRange values are invalid' };
    }
  }

  const formattedOptions = {
    ...opts,
    table: {
      ...opts.table,
      columns: [...opts.table.columns, 'contentId'],
    }
  };

  const { body, qs } = constructQuery(params, { size: -1 }, formattedOptions);
  delete qs.page;
  qs.responseFormat = 'csv';

  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `view?${queryString(qs)}`
    }
  );

  const headers = {
    Authorization: 'Bearer ' + token,
  };

  const fetchParams = {
    method: 'POST',
    headers,
    body
  };

  return fetch(path, fetchParams)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) throw new Error('No Valid Response');
      return response.text();
    })
    .then((blob) => saveBlob(opts.fileName, blob));
};

const getOverviewCount = (host, client, token, params, opts) => {
  // Error Handling
  const options = Object.keys(opts);
  if (!options.includes('dateRanges')) {
    throw new Error('DateRanges required but not specified');
  }

  const { body, qs } = constructQuery(params, {}, opts);
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `count?${queryString(qs)}`
    }
  );

  return getResource({
    endpoint: path,
    method: 'POST',
    token,
    body
  })
    .then((data) => data)
    .catch((error) => ({ error }));
};

const getOverviewSections = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('dateRange is invalid');
  }
  const start = convertToSeconds(dateRange.startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), dateRange.endDate));

  const path = pathBuilder(
    host,
    {
      section: 'host',
      client,
      query: `sections/root?expand=subsections&exclusions=none&fromDatePublished=${start}&toDatePublished=${end}`,
    }
  );
  const formatResponse = (data) => ({ response: data });
  const data = getResource({
    endpoint: path,
    token,
  }).then(formatResponse).catch((error) => ({ error }));
  return data;
};

const getOverviewSubtypes = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('dateRange is invalid');
  }
  const start = convertToSeconds(dateRange.startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), dateRange.endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `subtypes?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: data });
  return data;
};

const getOverviewCreditLine = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('dateRange is invalid');
  }
  const start = convertToSeconds(dateRange.startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), dateRange.endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `credit-lines?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: data });
  return data;
};

const getOverviewOwnership = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('dateRange is invalid');
  }
  const start = convertToSeconds(dateRange.startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), dateRange.endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `ownership-categories?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: data });
  return data;
};

const getOverviewScore = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('No Date Range');
  }
  const { startDate, endDate } = dateRange;
  const start = convertToSeconds(startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `score-range?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: Math.trunc(data) });
  return data;
};

const getOverviewType = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('No Date Range');
  }
  const { startDate, endDate } = dateRange;
  const start = convertToSeconds(startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `content-types?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: data });
  return data;
};

const getOverviewAccess = (host, client, token, dateRange) => {
  if (!dateRange) {
    return new Error('No Date Range');
  }
  const { startDate, endDate } = dateRange;
  const start = convertToSeconds(startDate);
  const end = convertToSeconds(getMostRecentTime(new Date(), endDate));
  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `access-categories?start=${start}&end=${end}`
    }
  );
  const data = getResource({
    endpoint: path,
    token,
  }).then((resp) => formatResponse(resp)).catch((error) => ({ error }));
  const formatResponse = (data) => ({ response: data });
  return data;
};

/**
 *
 * @param {Object} object A object the contains the field
 * @returns {Array} An array of the results for term in the field, limited by the given params
 */
const getOverviewAutosuggest = (host, client, token, { field, term, limit }) => {
  const qs = {
    searchField: getSearchField(field),
    searchTerm: term,
    limit: 5
  };

  const path = pathBuilder(
    host,
    {
      section: 'content-view',
      client,
      query: `view/autosuggest?${queryString(qs)}`
    }
  );
  const formatResponse = (data) => ({ response: data });
  const data = getResource({
    endpoint: path,
    token,
  })
    .then(formatResponse)
    .catch((error) => ({ error }));

  return data;
};

/**
 *  Normalizes the fields that the API can understand
 * @param {string} field the field to pool auto suggestions from
 * @returns {string} the normalized API property from the given field
 */
function getSearchField(field) {
  const normalizeMap = {
    authors: 'byline',
    headlines: 'headline',
    keywords: 'keywords'
  };

  return normalizeMap[field] ? normalizeMap[field] : field;
}

export default {
  getOverview,
  getOverviewCount,
  getOverviewAutosuggest,
  getOverviewSections,
  getOverviewSubtypes,
  getOverviewCreditLine,
  getOverviewOwnership,
  getOverviewScore,
  getOverviewType,
  getOverviewAccess,
  getOverviewReport,
};
