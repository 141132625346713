import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import differenceBy from 'lodash/differenceBy';
import Input from 'sophi-design-system/src/components/Input';
import Select from 'sophi-design-system/src/components/Select';
import Button from 'sophi-design-system/src/components/Button';
import IconButton from 'sophi-design-system/src/components/IconButton';
import LoadingSpinner from 'atoms/LoadingSpinner';
import styles from './Admin.styles.scss';
import { SHOW_DELETE, SHOW_CREATE, SHOW_EDIT } from './constants';

const AdminModal = ({
  active,
  onClose,
  onSubmit,
  onPasswordReset,
  selectedUser,
  roles = [],
  submitLoading,
  error,
}) => {
  const [user, setUser] = useState({ roles: [] });
  useEffect(() => {
    if(selectedUser && (active === SHOW_EDIT)) {
      setUser({ ...selectedUser });
    } else {
      setUser({});
    }
  }, [active, selectedUser]);

  const handleInputChange = (key, value) => {
    setUser({
      ...user,
      [key]: value,
    });
  };

  const title = useMemo(() => {
    switch (active) {
      case SHOW_CREATE:
        return 'Add New User';
      case SHOW_DELETE:
        return `Are you sure you want to delete ${selectedUser.email}?`;
      case SHOW_EDIT:
        return 'Edit User';
      default:
        return '';
    }
  }, [active, selectedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = false;
    if (active === SHOW_CREATE) {
      isValid = user.name && user.email && user.roles.length > 0;
    } else if (active === SHOW_EDIT) {
      isValid = user.name.trim().length > 0;
    }
    if (isValid) {
      const editType = active === SHOW_CREATE ? 'create' : 'edit';
      const tempPass = (Math.random().toFixed(8)) * 100000000;
      const deletedRoles = differenceBy(selectedUser.roles, user.roles, 'value');
      const userObj = {
        name: user.name,
        email: user.email,
        roles: user.roles.map(({ value }) => value),
        ...(active === SHOW_CREATE && { password: `Pass!${tempPass}` }),
        ...((active === SHOW_EDIT && deletedRoles.length > 0) && { deletedRoles: deletedRoles.map(({ value }) => value) })
      };
      onSubmit(editType, userObj);
    }
  };

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={!!active}
      onRequestClose={onClose}
    >
      <div className={`${styles.modalBody} ${styles[active]}`}>
        <IconButton
          onClick={onClose}
          className={styles.closeBtn}
          title="Close Modal"
          color="teal"
          icon="Clear"
          size="lg"
        />
        <h1>{title}</h1>
        <div className={styles.inputContainer}>
        {active === SHOW_DELETE && (
          <div>
            <p>This action cannot be undone.</p>
            {error && <p className="error">{error}</p>}
            <div className={styles.modalFooter}>
              <Button size="sm" variant="secondary" onClick={onClose}>Cancel</Button>
              <Button size="sm" onClick={() => onSubmit('delete', selectedUser)} variant="primary">
                {submitLoading ? <LoadingSpinner className={styles.loadingSpinner} /> : 'Delete'}
              </Button>
            </div>
          </div>
        )}
        {(active === SHOW_EDIT || active === SHOW_CREATE) && (
          <form
            onSubmit={handleSubmit}
          >
            <Input
              onChange={({target: { value }}) => handleInputChange('name', value)}
              value={user.name || ''}
              label="Name"
              id="edit-user-name"
              placeholder="Full Name"
              required
            />
            <Input
              onChange={({target: { value }}) => handleInputChange('email', value)}
              type="email"
              value={user.email || ''}
              label="Email Address"
              id="edit-email"
              placeholder="email@example.com"
              required={active === SHOW_CREATE}
              disabled={active === SHOW_EDIT}
            />
            {active === SHOW_EDIT && (
              <div>
                <button
                  className={styles.linkBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    onPasswordReset(selectedUser.email);
                  }}
                >
                  Reset Password
                </button>
              </div>
            )}

            {(active === SHOW_EDIT || active === SHOW_CREATE) && (
              <div>
                <Select
                  id="basic-select"
                  className={styles.rolesSelect}
                  label="User Role"
                  options={roles.length ? roles.map((role) => ({ value: role.id, label: role.name })) : [{ value: '', label: 'No roles available', isDisabled: true }]}
                  name="roles"
                  placeholder="Select Role"
                  required={active === SHOW_CREATE}
                  onChange={(roles) => { handleInputChange('roles', roles ? roles : []);}}
                  value={user.roles}
                  isMulti
                />
              </div>
            )}

            {error && <p className="error">{error}</p>}

            <div className={styles.modalFooter}>
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                  setUser({});
                }}
                variant="secondary"
                disabled={submitLoading}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                type="submit"
                variant="primary"
                disabled={submitLoading}
              >
                {submitLoading
                  ? <LoadingSpinner className={styles.loadingSpinner} />
                  : 'Save'
                }
              </Button>
            </div>
          </form>
        )}
        </div>
      </div>
    </Modal>
  );
};

export default AdminModal;
