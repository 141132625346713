import React from 'react';

const TreeNavigationContext = React.createContext({
  setForceBranchesClosed: () => null,
  forceBranchesClosed: false,
  setForceBranchesOpen: () => null,
  forceBranchesOpen: false,
});

export default TreeNavigationContext;
