import React from 'react';
import TableauPageTemplate from 'molecules/TableauPageTemplate';

const Reports = () => {
  return (
    <TableauPageTemplate
      title="Reports"
    />
  );
};

export default Reports;
