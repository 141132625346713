// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-cUe6FY4rI6z3KvyoH0oV{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;gap:calc(1*var(--space-1));margin:0 0 calc(3*var(--space-1)) 0}.sophi-cUe6FY4rI6z3KvyoH0oV .sophi-JMB6pZmr8nzBhamDa_U1{display:-webkit-box;display:-ms-flexbox;display:flex;gap:calc(1*var(--space-1))}.sophi-cUe6FY4rI6z3KvyoH0oV .sophi-DcSVK0uxZEiTXRcL1TTD{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;gap:calc(1*var(--space-1))}.sophi-yeB_zQHA0RQEP8sW8Ewx{margin:0 0 calc(2*var(--space-1)) 0}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/PageView/PageView.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,0BAAA,CACA,mCAAA,CAEA,wDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAEF,wDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,0BAAA,CAIJ,4BACE,mCAAA","sourcesContent":[".actions {\n  display: flex;\n  justify-content: space-between;\n  gap: calc(1 * var(--space-1));\n  margin: 0 0 calc(3 * var(--space-1)) 0;\n\n  .leftAligned {\n    display: flex;\n    gap: calc(1 * var(--space-1));\n  }\n  .rightAligned {\n    display: flex;\n    justify-content: flex-end;\n    gap: calc(1 * var(--space-1));\n  }\n}\n\n.addWidget {\n  margin: 0 0 calc(2 * var(--space-1)) 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "sophi-cUe6FY4rI6z3KvyoH0oV",
	"leftAligned": "sophi-JMB6pZmr8nzBhamDa_U1",
	"rightAligned": "sophi-DcSVK0uxZEiTXRcL1TTD",
	"addWidget": "sophi-yeB_zQHA0RQEP8sW8Ewx"
};
export default ___CSS_LOADER_EXPORT___;
