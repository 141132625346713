import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery } from '@tanstack/react-query';

import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import pageStyles from '../Page.styles.scss';
import styles from './PagesList.styles.scss';

import ButtonLink from '../../components/ButtonLink';
import PageTitle from '../../components/PageTitle';
import FilteredPageList from '../../components/PageList';
import { Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { listPages } from '../../api/pages';

const PagesList = () => {
  const params = { size: 500, expand: true };
  const { fn: queryFn } = useCuratorConfigApi(listPages, params);
  const { data, isLoading } = useQuery({
    queryKey: [`pages`],
    queryFn
  });

  return (
    <>
      <PageTitle title="Site automation" subtitle="Pages"/>
      <div className={pageStyles.body}>
        <div className={styles.add}>
          <ButtonLink
            to={getRoute(ROUTE_NAMES.curatorPageCreate, {})}
            icon={<PlusOutlined />}
          >
            Add page
          </ButtonLink>
        </div>

        {isLoading  && <Spin />}

        {data?.items && 
          <FilteredPageList list={data?.items} total={data?.total} />
        }
      </div>
    </>
  );
};

export default PagesList;
