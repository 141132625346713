import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'sophi-design-system/src/components/Icon';
import styles from './DownloadCenter.styles.scss';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';
import { ReactComponent as CaretUp } from 'assets/svg/caret-up.svg';
import LoadingSpinner from 'atoms/LoadingSpinner';

const DownloadCenter = () => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const downloads = useSelector((state) => state.global.downloads);

  useEffect(() => {
    if (downloads.length > 0) {
      setActive(true);
    } else {
      setActive(false);
      setOpen(false);
    }
  }, [downloads]);
  
  const getFileIcon = (filename) => {
    const [, type] = filename.toLowerCase().split('.');
    if (type === 'csv') return 'CSV';
    if (type === 'xlsx') return 'Excel';
    if (type === 'xls') return 'Excel';
    return 'File';
  };

  if (active) {
    return (
      <div className={styles.downloadCenter}>
        <div className={styles.header}>
          <p>Recent Downloads ({downloads.length})</p>
          <button
            title={open ? 'Collapse Download Information' : 'Expand Download Information'}
            style={{ height: 30, width: 30, color: '#FFF' }}
            onClick={() => setOpen(!open)}
          >
            {open ? <CaretDown /> : <CaretUp />}
          </button>
        </div>
        {open && (
          <div className={styles.body}>
            {downloads.map((download) => (
              <Fragment key={download.hash}>
                <div className={styles.cell}>
                  <Icon type="basic" name={getFileIcon(download.query.fileName)} color="black" />
                </div>
                <div className={styles.cell}>{download.query.fileName}</div>
                {download.loading && (
                  <div className={styles.cell}>
                    <i title="File Download Loading">
                      <LoadingSpinner />
                    </i>
                  </div>
                )}
                {download.complete && (
                  <div className={styles.cell}>
                    <i title="File Download Complete">
                      <Icon type="basic" name="Done" color="teal" size="md" />
                    </i>
                  </div>
                )}
                {download.error && (
                  <div className={`${styles.cell} ${styles.error}`}>
                    <i title="File Download Error">
                      <Icon type="basic" name="Clear" color="inherit" size="md" />
                    </i>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default DownloadCenter;
