
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import isBefore from 'date-fns/isBefore';

import { CuratorApi } from '../api/curator';

import { FilterValuesType, Override, CreateRequest, OverridesProvider as OverridesProviderInterface } from '../types';
import { groupByRequestId, minToMs, overrideFilter, requestFilter } from '../utils/functions';

const KEYS = {
  filters: 'overrides.filters',
};

const OverridesContext = createContext<OverridesProviderInterface>({} as OverridesProviderInterface);

const QUERY_DEFAULTS = {
  retry: false,
  staleTime: minToMs(30),
}

export const OverridesProvider: React.FC<{
  user: any;
  host: string;
  client: string;
  getToken: () => Promise<string>;
  children: React.ReactNode;
}> = ({ user, host, client, getToken, children }) => {
  const api = new CuratorApi(host, client, getToken);

  const { data: pageWidgets = [] } = useQuery(['pageWidgets'], () => api.getPageWidgets(), QUERY_DEFAULTS);
  const {
    data: overrides = [],
    dataUpdatedAt: updatedAt,
    refetch: refreshOverrides
  } = useQuery({
    ...QUERY_DEFAULTS,
    queryKey: ['overrides'],
    queryFn: () => api.getAllOverrides(),
    refetchInterval: (data, query) => {
      const pending = data && data.some(override => override.status === 'PENDING');
      if (pending) return 2000;
      return false;
    },
  });
  const { data: reasons } = useQuery(['reasons'], () => api.getOverrideReasons(), QUERY_DEFAULTS);

  const [ filters, setOverrideFilters ] = useState<FilterValuesType>({} as FilterValuesType);
  const [ sort, setSortBy ] = useState<{asc: boolean, by: 'expirationDate' | 'creationDate'}>({ asc: true, by: 'expirationDate' });

  useEffect(() => {
    refreshOverrides();
  }, [client]);

  const requests = useMemo(() => {
    const grouped = groupByRequestId(overrides);

    return requestFilter(grouped, filters).sort(([a], [b]) => {
      const valA = a[sort.by];
      const valB = b[sort.by];

      if (isBefore(new Date(valA), new Date(valB))) return sort.asc ? 1 : -1 ;
      return sort.asc ? -1 : 1 ;
    });
  }, [sort, filters, overrides]);

  function setFilters(values: FilterValuesType) {
    localStorage.setItem(KEYS.filters, JSON.stringify(values));
    setOverrideFilters(values);
  }

  function getFilters(): FilterValuesType {
    const localFilters = localStorage.getItem(KEYS.filters);
    try {
      return JSON.parse(localFilters || '{}') as FilterValuesType;
    } catch(err) {
      return {} as FilterValuesType;
    }
  }

  async function validateArticleId(id: string) {
    return await api.validateArticleId(id);
  }

  async function createOverride(data: CreateRequest): Promise<Override[]> {
    return await api.createOverride(data);
  }

  return (
    <OverridesContext.Provider value={{
      api,
      user,
      overrides,
      requests,
      allReasons: reasons?.items || [],
      updatedAt,
      refreshOverrides,
      setFilters,
      getFilters,
      setSortBy,
      pageWidgets,
      validateArticleId,
      createOverride,
    }}>
      {children}
    </OverridesContext.Provider>
  );
}

export const useOverrides = () => useContext(OverridesContext);
