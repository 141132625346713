import React,{ useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Atoms
import Container from 'atoms/Container';
import DynamicComponent from 'atoms/DynamicComponent';

// Actions
import { fetchDiveSections, fetchDiveSectionsColumns } from 'actions/dive';
import { setTitle } from 'actions/header';

// Selectors
import { getPlatforms } from 'selectors/auth';
import { getDiveSectionsAllColumns, getDiveSectionsColumns } from 'selectors/dive';
import { setPageTitle } from 'utils/paths';
import { formatColumnsConfig } from 'utils/table';

const Sections = (props) => {
  const dispatch = useDispatch();
  const platforms = useSelector(getPlatforms);
  const columns = useSelector(getDiveSectionsColumns);
  const columnOptions = formatColumnsConfig(useSelector(getDiveSectionsAllColumns), platforms);

  useEffect(() => {
    setPageTitle('Historical - Sections');
    dispatch(fetchDiveSections());
    dispatch(setTitle(''));
    dispatch(fetchDiveSectionsColumns());
  }, [columns]);

  return (
    <Container title="Sections">
      <DynamicComponent
        placeholder={
          <Container
            title={'Sections - Loading'}
            loading={true}>
            {null}
          </Container>
        }
        component={() => import('organisms/DiveTable/next.js')}
        // Any props needed by the component can be placed as a prop of the Dynamic Component
        columnOptions={columnOptions}
        id="dive-sections"
      />
    </Container>
  );

};

Sections.propTypes = {
  // PropTypes
};

export default Sections;
