const types = {
  REFETCH_DASHBOARD_DATA: 'REFETCH_DASHBOARD_DATA',
  RESET_DASHBOARD_VALUES: 'RESET_DASHBOARD_VALUES',
  SET_SELECTED_DIVE_SECTIONS_COLUMNS: 'SET_SELECTED_DIVE_SECTIONS_COLUMNS',
  SET_SELECTED_DIVE_AUTHORS_COLUMNS: 'SET_SELECTED_DIVE_AUTHORS_COLUMNS',
  SET_SELECTED_DIVE_ARTICLES_COLUMNS: 'SET_SELECTED_DIVE_ARTICLES_COLUMNS',
  // Date Range
  DIVE_DATE_RANGE_SET: 'DIVE_DATE_RANGE_SET',
  // Top Articles
  FETCH_TOP_ARTICLES: 'FETCH_TOP_ARTICLES',
  FETCH_TOP_ARTICLES_SUCCESS: 'FETCH_TOP_ARTICLES_SUCCESS',
  FETCH_TOP_ARTICLES_FAIL: 'FETCH_TOP_ARTICLES_FAIL',
  // Top Authors
  FETCH_TOP_AUTHORS: 'FETCH_TOP_AUTHORS',
  FETCH_TOP_AUTHORS_SUCCESS: 'FETCH_TOP_AUTHORS_SUCCESS',
  FETCH_TOP_AUTHORS_FAIL: 'FETCH_TOP_AUTHORS_FAIL',
  // Comparison Chart
  DIVE_COMPARISON_CHART: 'DIVE_COMPARISON_CHART',
  DIVE_COMPARISON_CHART_SUCCESS: 'DIVE_COMPARISON_CHART_SUCCESS',
  DIVE_COMPARISON_CHART_FAILURE: 'DIVE_COMPARISON_CHART_FAILURE',
  // Filters
  DIVE_FILTER_SET: 'DIVE_FILTER_SET',
  FILTER_RESET: 'FILTER_RESET',
  DIVE_FILTER_AUTOSUGGEST: 'DIVE_FILTER_AUTOSUGGEST',
  DIVE_FILTER_AUTOSUGGEST_SUCCESS: 'DIVE_FILTER_AUTOSUGGEST_SUCCESS',
  CLEAR_DIVE_AUTOSUGGEST: 'CLEAR_DIVE_AUTOSUGGEST',
  DIVE_FILTER_OPTIONS_FETCH: 'DIVE_FILTER_OPTIONS_FETCH',
  DIVE_FILTER_OPTIONS_SUCCESS: 'DIVE_FILTER_OPTIONS_SUCCESS',
  DIVE_FILTER_OPTIONS_FAIL: 'DIVE_FILTER_OPTIONS_FAIL',
  DIVE_REMOVE_APPLIED_FILTERS:'DIVE_REMOVE_APPLIED_FILTERS',
  DIVE_SET_PREVIOUS_FILTERS: 'DIVE_SET_PREVIOUS_FILTERS',
  // Sections
  SECTIONS_SET: 'SECTIONS_SET',
  FETCH_SECTIONS: 'FETCH_SECTIONS',
  FETCH_SECTIONS_SUCCESS: 'FETCH_SECTIONS_SUCCESS',
  FETCH_SECTIONS_FAIL: 'FETCH_SECTIONS_FAIL',
  FETCH_SUBSECTION: 'FETCH_SUBSECTION',
  FETCH_SUBSECTION_SUCCESS: 'FETCH_SUBSECTION_SUCCESS',
  FETCH_SUBSECTION_FAIL: 'FETCH_SUBSECTION_FAIL',
  UPDATE_DIVE_SECTIONS_SORT: 'UPDATE_DIVE_SECTIONS_SORT',
  DIVE_SECTIONS_SORT_SUCCESS: 'DIVE_SECTIONS_SORT_SUCCESS',
  DIVE_SECTIONS_SORT_FAIL: 'DIVE_SECTIONS_SORT_FAIL',
  // Authors Page
  FETCH_DIVE_AUTHORS: 'FETCH_DIVE_AUTHORS',
  FETCH_DIVE_AUTHORS_SUCCESS: 'FETCH_DIVE_AUTHORS_SUCCESS',
  FETCH_DIVE_AUTHORS_FAIL: 'FETCH_DIVE_AUTHORS_FAIL',  
  UPDATE_DIVE_AUTHOR_PAGINATION: 'UPDATE_DIVE_AUTHOR_PAGINATION',
  UPDATE_DIVE_AUTHOR_SORT: 'UPDATE_DIVE_AUTHOR_SORT',
  FETCH_DIVE_AUTHORS_REPORT: 'FETCH_DIVE_AUTHORS_REPORT',
  FETCH_DIVE_AUTHORS_REPORT_LOADING: 'FETCH_DIVE_AUTHORS_REPORT_LOADING',
  FETCH_DIVE_AUTHORS_REPORT_SUCCESS: 'FETCH_DIVE_AUTHORS_REPORT_SUCCESS',
  FETCH_DIVE_AUTHORS_REPORT_CLEAR: 'FETCH_DIVE_AUTHORS_REPORT_CLEAR',
  FETCH_DIVE_AUTHORS_REPORT_ERROR: 'FETCH_DIVE_AUTHORS_REPORT_ERROR',
  // Articles Page
  FETCH_DIVE_ARTICLES: 'FETCH_DIVE_ARTICLES',
  FETCH_DIVE_ARTICLES_SUCCESS: 'FETCH_DIVE_ARTICLES_SUCCESS',
  FETCH_DIVE_ARTICLES_FAIL: 'FETCH_DIVE_ARTICLES_FAIL',  
  UPDATE_DIVE_ARTICLES_PAGINATION: 'UPDATE_DIVE_ARTICLES_PAGINATION',
  UPDATE_DIVE_ARTICLES_SORT: 'UPDATE_DIVE_ARTICLES_SORT',
  UPDATE_DIVE_ARTICLE_COUNT: 'UPDATE_DIVE_ARTICLE_COUNT',
  FETCH_DIVE_ARTICLES_REPORT: 'FETCH_DIVE_ARTICLES_REPORT',
  FETCH_DIVE_ARTICLES_REPORT_LOADING: 'FETCH_DIVE_ARTICLES_REPORT_LOADING',
  FETCH_DIVE_ARTICLES_REPORT_SUCCESS: 'FETCH_DIVE_ARTICLES_REPORT_SUCCESS',
  FETCH_DIVE_ARTICLES_REPORT_CLEAR: 'FETCH_DIVE_ARTICLES_REPORT_CLEAR',
  FETCH_DIVE_ARTICLES_REPORT_ERROR: 'FETCH_DIVE_ARTICLES_REPORT_ERROR',
  // Author Page
  FETCH_DIVE_AUTHOR_ARTICLES: 'FETCH_DIVE_AUTHOR_ARTICLES',
};

export default types;