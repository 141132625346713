import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

import SophiLogo from '../../assets/svg/sophi-logo-icon.svg?inline';
import SophiLogoUrl from '../../assets/svg/sophi-logo-url.svg?inline';
import styles from './logo.styles.scss';

const Logo = ({
  link,
  layout,
  style,
  alt
}) => (
  <Image
    link={link}
    images={{
      '1x': layout === 'url' ? SophiLogoUrl : SophiLogo
    }}
    alt={alt}
    classes={[styles.logo]}
    style={style}
  />
);

Logo.defaultProps = {
  link: '/',
  alt: 'Sophi Logo',
  layout: 'icon',
};

Logo.propTypes = {
  link: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  layout: PropTypes.oneOf(['url', 'icon']),
};

export default Logo;
