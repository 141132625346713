import get from 'lodash/get';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';

import { DefaultOptionType } from 'antd/lib/select';
import { DataNode } from 'antd/lib/tree';

import { FEOverride, FERuleType, FilterValuesType, Override, Request } from '../types';
import { v4 as uuid } from 'uuid';

export const widgetDelimiter = '::'

export function debounce<T extends any[]>(
  fn: (...args: T) => any,
  timeout: number,
): (...args: T) => void {
  let timer: ReturnType<typeof setTimeout>;
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, timeout)
  }
}

export function partition<T>(arr: T[], filter: (item: T) => boolean): [T[], T[]] {
  const trueItems: T[] = [];
  const falseItems: T[] = [];

  const len = arr.length;

  for (let i = 0; i < len; i++) {
    const item = arr[i];
    if (filter(item)) trueItems.push(item);
    else falseItems.push(item);
  }

  return [ trueItems, falseItems ];
}

export function range(start: number, end: number): number[] {
  const result: number[] = [];
  for(let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function minToMs(num: number): number {
  return 1000 * 60 * num;
}

export function textFilter(item: string, input: string, startsWith: boolean = false) {
  if (startsWith) return item.toLowerCase().startsWith(input.toLowerCase());
  return item.toLowerCase().indexOf(input.toLowerCase()) > -1;
}

export function cascaderFilter(inputValue: string, path: (DataNode | DefaultOptionType)[]) {
  return path.some(
    option => textFilter(option.title as string, inputValue)
  );
}

export function groupByRequestId (overrides: FEOverride[]): FEOverride[][] {
  const requests: Record<string, FEOverride[]> = {};
  for (const item of overrides) {
    const requestId = item.feRequestId;
    if (requests[requestId]) requests[requestId] = [...requests[requestId], item]
    else requests[requestId] = [item];
  }
  return Object.values(requests);
}

export function requestFilter(requests: Request[], filters: FilterValuesType) {
  const filtered: Request[] = [];
  for (const request of requests) {
    if (overrideFilter(request, filters).length) filtered.push(request);
  };
  return filtered;
}

export function overrideFilter(allOverrides: FEOverride[], { expirationDate, ...filters }: FilterValuesType) {
  const [start, end] = expirationDate || [0,8];
  let pageFilter: string[] = [];
  let widgetFilter: { page: string, widgetName: string }[] = [];
  if (filters?.pageWidget && filters.pageWidget.length) {
    const [widgetStrings, pageStrings] = partition<string>(filters.pageWidget, str => str.indexOf(widgetDelimiter) > -1);
    pageFilter = pageStrings;
    widgetFilter = widgetStrings.map(str => {
      const [page, widgetName] = str.split(widgetDelimiter);
      return { page, widgetName };
    });
  }

  return allOverrides.filter(override => {
    let meetsCriteria = false;

    if (widgetFilter.length) {
      for (const widget of widgetFilter) {
        if (widget.page === override.page && widget.widgetName === override.widgetName) meetsCriteria = true;
      }
    }
    if (pageFilter.length && pageFilter.includes(override.page || '')) {
      meetsCriteria = true;
    }

    if (!pageFilter.length && !widgetFilter.length) {
      meetsCriteria = true;
    }

    if ( start > 0 || end < 8 ) {
      const expiration = new Date(override.expirationDate);
      const startDate = add(new Date(), { days: start });
      const endDate =  add(new Date(), { days: end });

      if (end === 8) meetsCriteria = isAfter(expiration, startDate);
      else {
        meetsCriteria = isWithinInterval(expiration, {
          start: startDate,
          end: endDate,
        })
      }
    }

    if (
      (filters?.ruleType) && !filters.ruleType.includes(override.ruleType) ||
      (filters?.articleId && !textFilter(override.articleId, filters.articleId)) ||
      (filters?.requestedUserName && !textFilter(override.requestedUserName, filters.requestedUserName))
    ) {
      meetsCriteria = false;
    }

    return meetsCriteria;
  });
}

export function sortAlphabetically<T>(parseValue: (a: T) => any) {
  return (a: T, b: T) => {
    const aVal = parseValue(a);
    const bVal = parseValue(b);
    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  };
}

const inferFERuleType = (override: Override): FERuleType => {
  if (override.ruleType === 'out') {
    if (override.widgetName) return 'out';
    return 'ban';
  };
  return override.ruleType;
}

export function transformToFEOverride(override: Override): FEOverride {
  return {
    ...override,
    ruleType: inferFERuleType(override),
    feRequestId: override.requestId || uuid(),
  }
}
