import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Title } from '../Typography';
import styles from './button.styles.scss';

const Button = ({
  className,
  variant,
  children,
  size,
  ...props
}) => {
  const classes = classNames([
    styles.btn,
    styles[variant],
    styles[size],
    className
  ]);

  return (
    <button className={classes} {...props}>
      <Title tag="span" className={styles.btnLabel}>{children}</Title>
    </button>
  );
};

Button.defaultProps = {
  variant: 'default',
  className: null,
};

Button.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary', 'secondary', 'clear']),
  size: PropTypes.oneOf(['natural', 'xs', 'sm', 'md', 'lg']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
