import unionBy from 'lodash/unionBy';
import { ALL_ROUTES, ROUTE_NAMES } from './paths';

export const parseOrganization = (organization = {}, services = []) => {
  let data = {};
  const { id, timezone, hosts = [] } = organization || {};
  if (hosts.length) {
    const { fqdn: mainHost, products, links, banners, platforms } = hosts[0];
    const mergedServices = unionBy(links, services, 'identifier');

    data = {
      orgId: id,
      mainHost,
      products,
      ...(services.length && { services: mergedServices }),
      banners,
      ...(platforms.length && { platforms }),
    };
  }
  return {
    timezone,
    ...data,
  };
};

export const getOrganizationFromHost = (host, organizations) => {
  return organizations.find((org) => {
    return host === parseOrganization(org).mainHost;
  });
};

export const hostSelectEnabled = (pathname) => {
  return [
    ALL_ROUTES[ROUTE_NAMES.root],
    ALL_ROUTES[ROUTE_NAMES.welcome],
    ALL_ROUTES[ROUTE_NAMES.nowHome],
    ALL_ROUTES[ROUTE_NAMES.nowContentView],
    ALL_ROUTES[ROUTE_NAMES.diveHome],
    ALL_ROUTES[ROUTE_NAMES.diveSections],
    ALL_ROUTES[ROUTE_NAMES.diveArticles],
    ALL_ROUTES[ROUTE_NAMES.diveAuthors],
    ALL_ROUTES[ROUTE_NAMES.curatorOverrides],
  ].includes(pathname);
};
