import uniq from 'lodash/uniq';
import findIndex from 'lodash/findIndex';
import format from 'date-fns/format';
import { isDefined } from 'utils/objects';
import { formatShortDateTime } from 'utils/dateTime';

/**
 * Will create a new block from the data passed in and download it to the clients computer
 * @param {String} data - Content of the fine in the form of a string
 * @param {*} filename - The filename excluding the extension
 * @param {*} opts - Optional object of keys 'fileType' and 'ext' (defaults to CSV)
 */
export const fileDownload = (data, filename, opts) => {
  if (!filename) { console.error(`File download initiated but no filename is specified!`); return; }
  const { fileType, ext } = opts || {};
  const blob = new Blob([data], { type: `${fileType ? fileType : 'text/csv'};charset=utf-8;` });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.${ext ? ext : 'csv'}`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Returns a string representing the array in the form of a csv
 * @param {Array} data - Array of objects you wish to create a CSV string of
 */
export const objectToCsv = (data) => {
  const headings = uniq(data.map((d) => Object.keys(d)).flat());
  const rows = data.map((d) => {
    let row = '';
    headings.forEach((h) => {
      row += d[h] ? `"${d[h]}",` : `" ",`;
    });
    return `${row.slice(0, -1)}\n`;
  }).flat().join('').replace(/[\u2018\u2019]/g, `'`).replace(/[\u201C\u201D]/g, `"`);
  return `${headings}\n${rows}`;
};

/**
 * Returns a string representing the combined headings and rows in the form of a csv
 * @param {Array} headings - An array of strings that represent the headings in the order that the rows arrays are formatted
 * @param {Array} data - An array of arrays that represent the rows of the csv file
 */
export const arrayToCsv = (headings, data) => {
  if (!headings) { console.error(`arrayToCsv headings not defined`); return; }
  if (!data) { console.error(`arrayToCsv rows not defined`); return; }
  const dateIndex = findIndex(headings, {displayType: 'date'}) || null;
  const publishIndex = headings.findIndex((col) => col.displayType === 'link');
  const head = headings.map((h) => `"${h.title}"`).join(',');
  const rows = data.map((d) => {
    return d.map((r, i) => {
      if (publishIndex !== null && i === publishIndex) return `"${r.name}"`;
      if (dateIndex !== null && i === dateIndex) return `"${format(new Date(r.epoch * 1000), 'yy-MM-dd HH:mm')}"`;
      if (typeof r === 'object') return null;
      if (r) return `"${r}"`;
      return '';
    }).join(',').replace(/[\u2018\u2019]/g, `'`).replace(/[\u201C\u201D]/g, `"`);
  });
  return `${head}\n${rows.join('\n').replace(`'`,)}`;
};

/**
 * Returns a string representing the combined columns and rows in the form of a csv
 * @param {Array} columns - An array of strings that represent the headings in the order that the rows arrays are formatted
 * @param {Array} data - An array of arrays that represent the rows of the csv file
 */
export const nextArrayToCsv = (columns, rows) => {
  const validColumns = columns.filter((col) => !!col.Header);
  const head = validColumns.map((c) => c.Header ? `"${c.Header}"` : `""`).join(',');
  const body = rows.map((row) => {
    return validColumns.map((col) => {
      return (col.Header && isDefined(row[col.accessor])) ? `"${row[col.accessor]}"` : `""`;
    }).join(',');
  });
  return `${head}\n${body.join('\n').replace(`'`,)}`;
};

export function saveBlob(fileName, csvText) {
  const actualText = !csvText || csvText.length === 0
    ? 'There is no data available for the filters chosen in this date range.'
    : csvText;
  var formattedBlob = new Blob(['\uFEFF'+actualText], {
    type: 'text/csv; charset=utf-8'
  });

  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(formattedBlob);
    return;
  }

  // For other browsers: create a link pointing to the ObjectURL containing the blob.
  const objUrl = window.URL.createObjectURL(formattedBlob);

  let link = document.createElement('a');
  link.href = objUrl;
  link.download = fileName;
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
}

// Temporary table rows to csv convertor until proper csv download is released
export const convertTableDataToCsv = ({ fileName, columns, rows, timezone }) => {
  const columnNames = columns.map(({ Header }) => ({ title: Header }));
  const accessors = columns.reduce((list, column) => {
    return (column.accessor && column.Header) ? list.concat(column.accessor) : list;
  }, []);
  const rowValues = rows.map((row, i) => {
    return accessors.map((key) => {
      if (Array.isArray(row[key])) {
        return row[key].join(', ');
      } else if (key === 'datePublished' || key === 'publishedDate') {
        return formatShortDateTime(new Date(row[key] * 1000), timezone);
      } else if (key === 'scoreDriver') {
        return row[key].replace('|', ' ').replace('_', ' ');
      }
      return row[key];
    });
  });
  fileDownload(arrayToCsv(columnNames, rowValues), fileName);
};
