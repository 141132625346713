import React, { useEffect } from 'react';
import OverridesUI from '@sophi/overrides-ui';
import { useAuth0 } from 'utils/Auth0Provider';
import { getToken, getCuratorAPI, getMainHost} from 'selectors/auth';
import { useSelector, useDispatch } from 'react-redux';
import types from 'actions/authTypes';
import { setPageTitle } from 'utils/paths';

export default () => {
  const dispatch = useDispatch();
  const { user, getTokenSilently } = useAuth0();
  const token = useSelector(getToken);
  const host = useSelector(getCuratorAPI);
  const client = useSelector(getMainHost);
  const requestTokenUpdate = () => dispatch({ type: types.UPDATE_TOKEN_CACHE });

  useEffect(() => {
    setPageTitle('Site Automation - Overrides');
  }, []);

  return (
    <div>
      <OverridesUI
        user={user}
        getToken={getTokenSilently}
        token={token}
        host={host}
        client={client}
        requestTokenUpdate={requestTokenUpdate}
      />
    </div>
  );
};
