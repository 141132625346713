"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const pageConfigLabels = {
    name: 'Name',
    deduplicationPaths: 'Paths',
    scoreType: 'Score type',
    optimizationTarget: 'Optimization strategy',
    pageFilters: 'Filters',
    acquisitionWeight: 'Acquisition weight',
    readerFocusedWeight: 'Readership weight',
    retentionWeight: 'Retention weight',
    loyaltyWeight: 'Loyalty weight',
    manualWidgetNames: 'Deduplication rules',
    widgetDuplicationStrategy: 'Duplication rules',
    isFreshnessBoostEnabled: 'Boost recent content',
    isFreshnessPenaltyEnabled: 'Penalize stale content',
    scoreScalingLookupIntervalInHrs: 'Scaling interval',
};
exports.default = pageConfigLabels;
