/**
 * TODO:
 * - Add in useBlockLayout and CSS grid for table layout
 * - Add in optional header flag for cases where there is no edit columns or download
 * - Add in Drag and Drop so that columns can be re-ordered and resized
 */

// Root
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

// Atoms
import Button from 'sophi-design-system/src/components/Button';
import Icon from 'sophi-design-system/src/components/Icon';
import Table from 'sophi-design-system/src/components/Table';
import FrozenColumnTable from 'sophi-design-system/src/components/Table/FrozenColumnTable';
import LoadingState from 'sophi-design-system/src/components/LoadingState';
import Tooltip from 'atoms/Tooltip';

// Organisms
import EditColumns from 'organisms/EditColumns';

// SVG
import styles from './SophiTable.styles.scss';

const SophiTableNext = (props) => {
  const {
    columnOptions,
    columns,
    loading,
    selectedColumns,
    title,
    id,
    hideEditColumns,
    downloadConfig,
    frozenColumn,
  } = props;
  const [modalActive, setModalActive] = useState(false);

  const handleColumnChange = (columns) => {
    setModalActive(false);
    props.columnSet(columns);
  };

  const renderTableHeader = () => {
    if (!title && hideEditColumns && !downloadConfig) return null;
    return (
      <div className={styles.tableHeader}>
        <div>
          {title && <h2 className={styles.title}>{title}</h2>}
        </div>
        <div className={styles.tableHeaderBtnContainer}>
          {(!hideEditColumns && columnOptions) && (
            <>
              <Button variant="primary" onClick={() => setModalActive(true)}>Edit Columns</Button>
              <Modal
                isOpen={modalActive}
                className={styles.modal}
                overlayClassName={styles.overlay}      
              >
                <EditColumns
                  id={`${id}-edit-columns`}
                  singleColumns={columnOptions.nonDevice}
                  perDeviceColumns={columnOptions.deviceTop}
                  selectedColumns={selectedColumns}
                  requiredColumns={columnOptions.requiredColumns}
                  defaultColumns={columnOptions.defaultColumns}
                  onSubmit={handleColumnChange}
                  onCancel={() => setModalActive(false)}
                />
              </Modal>
            </>
          )}
          {!hideEditColumns && columnOptions && downloadConfig && (
            <span className={styles.divider} />
          )}
          {downloadConfig && (
            <div className={styles.downloadContainer}>
              {downloadConfig.info && (
                <Tooltip placement="bottom-start" content={downloadConfig.info}>
                  <div style={{ height: 'fit-content', margin: '0 10px' }}>
                    <Icon type="circle" name="Info" color="teal" size="sm" />
                  </div>
                </Tooltip>
              )}
              <span>Download as:</span>
              <Button
                onClick={() => downloadConfig.handleDownload(columns, props.data)}
                variant="default"
                data-track-click="true"
                data-track-name={`${id}-download_csv-btn`}
                disabled={downloadConfig.disabled}
              >
                .CSV
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  // Fall back just in case the default columns are dynamic
  if (!columns && loading) return <LoadingState />;

  return (
    <div className={styles.container}>
      {renderTableHeader()}
      {frozenColumn
        ? <FrozenColumnTable {...props} />
        : <Table {...props} />
      }
    </div>
  );
};

SophiTableNext.defaultProps = {
  errorAction: () => null,
  hideEditColumns: false,
  hideDownload: false,
  hideFooter: false,
  variant: 'gray',
  totalRows: 5,
  defaultSort: { column: 'subsectionName', direction: 'desc' },
};

SophiTableNext.proptypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  sortable: PropTypes.bool,
  errorAction: PropTypes.func,
  hideEditColumns: PropTypes.bool,
  hideDownload: PropTypes.bool,
  hideFooter: PropTypes.bool,
  variant: PropTypes.oneOf(['gray', 'white', 'framed', 'mobile']),
  totalRows: PropTypes.number,
  defaultSort: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc'])
  }),
};

export default SophiTableNext;