import React, { useEffect } from 'react';
import { useAuth0 } from 'utils/Auth0Provider';
import throttle from 'lodash/throttle';

import Button from 'atoms/Button';

import report from 'assets/img/report.svg?inline';
import arrow from 'assets/img/arrow.svg?inline';
import styles from './Logout.styles.scss';

import { mapToRange } from 'utils/number';
import { setPageTitle } from 'utils/paths';

const Logout = (props) => {
  const { isAuthenticated, loading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    setPageTitle('Logged out');
  }, []);

  // If a user is logged in, they should never see this page
  // This might happen if they login from the logout page
  useEffect(() => {
    if (isAuthenticated && !loading) {
      props.history.push('/');
    }
  }, [isAuthenticated, loading]);

  const updateXY = (e) => {
    const { clientX } = e;
    const w = window.innerWidth;
    const h = window.innerHeight;
    const factor = mapToRange(
      Math.abs((clientX - (w / 2))),
      0,
      (w / 2),
      0,
      0.2
    );
    document.documentElement.style.setProperty('--mouse-x', factor);
  };

  const throttledEventHandler = (...args) => {
    const throttled = throttle(...args);
    return (e) => {
      e.persist();
      return throttled(e);
    };
  };

  return (
    <section className={styles.layout} onMouseMove={throttledEventHandler(updateXY, 50)}>
      <div className={styles.center}>
        <div className={styles.page}>
          <h2>Know the Story<br />Behind Your Stories.</h2>
          <p>When you know more about the content that your audience is most interested in and how they discover it, you can more effectively deliver stories that they find most&nbsp;valuable.</p>
          <Button
            size={'large'}
            onClick={() => loginWithRedirect({})}
          >
            Sign In
          </Button>
        </div>
      </div>
      {window.innerWidth > 375 && (
        <>
          <img className={styles.arrow} src={arrow} alt={'arrow'} />
          <div className={styles.center}>
            <div className={styles.reports}>
              <img src={report} alt={'Illustration of report page in the background'} />
              <img src={report} alt={'Illustration of report page in the foreground'} />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Logout;
