// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-iJ2mnBVPO5JzyvEpezpA{-webkit-padding-start:0;padding-inline-start:0;list-style:none}.sophi-u5QRqs9WiayrQArP8g5E{padding:calc(.2*var(--space-1));margin-bottom:calc(.5*var(--space-1));display:-webkit-box;display:-ms-flexbox;display:flex;gap:calc(.5*var(--space-1));-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/ErrorList/ErrorList.styles.scss"],"names":[],"mappings":"AAAA,4BACE,uBAAA,CAAA,sBAAA,CACA,eAAA,CAGF,4BACE,+BAAA,CACA,qCAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".list {\n  padding-inline-start: 0;\n  list-style: none;\n}\n\n.item {\n  padding: calc(0.2 * var(--space-1));\n  margin-bottom: calc(0.5 * var(--space-1));\n  display: flex;\n  gap: calc(0.5 * var(--space-1));\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "sophi-iJ2mnBVPO5JzyvEpezpA",
	"item": "sophi-u5QRqs9WiayrQArP8g5E"
};
export default ___CSS_LOADER_EXPORT___;
