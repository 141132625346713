import types from '../actions/overviewTypes';
import subDays from 'date-fns/subDays';
import { getFromStorage, getColumnObject } from 'utils/storage';
import { getAnalysisRange, getPublishedRange } from 'utils/relativeDates';
import { OVERVIEW_COLUMN_CONFIG } from 'utils/table';
import { startOfTimezoneDay } from 'utils/dateTime';
import { zonedTimeToUtc } from 'date-fns-tz';

const {
  analysis_date_range,
  published_date_range,
  analysis_date_preset,
  published_date_preset,
  filters = {}
} = getFromStorage('now_config') || {};

const columns = getColumnObject('now-columns', OVERVIEW_COLUMN_CONFIG.default);

const initFilters = {
  sections: null,
  subtypes: null,
  creditLine: null,
  authors: null,
  ownership: null,
  type: null,
  access: null,
  keywords: null,
  headlines: null,
  score: null,
};

const defaultTimezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;

const getDefaultAnalysisRange = (timeZone = defaultTimezone) => {
  // Set custom range if available
  if (analysis_date_range) {
    return {
      startDate: new Date(analysis_date_range.startDate),
      endDate: new Date(analysis_date_range.endDate),
    };
  }
  // Set selected relative date range if available
  if (analysis_date_preset && analysis_date_preset !== 'Custom') {
    return getAnalysisRange(analysis_date_preset);
  }

  // Only process custom range if we can find one in storage
  if (analysis_date_preset === 'Custom' && analysis_date_range) {
    return getAnalysisRange(analysis_date_preset);
  }

  // Set default to today
  return {
    // Feed the current date string to Date constructor
    startDate: zonedTimeToUtc(startOfTimezoneDay(new Date(), timeZone), timeZone),
    endDate: new Date(),
  };
};

const getDefaultPublishedRange = (analysisRange, timeZone) => {
  // Set custom range if available
  if (published_date_range) {
    return {
      startDate: new Date(published_date_range.startDate),
      endDate: new Date(published_date_range.endDate),
    };
  }
  // Set selected relative date range if available
  if (published_date_preset && published_date_preset !== 'Custom') {
    return getPublishedRange(published_date_preset, analysisRange);
  }
  // Only process custom range if we can find one in storage
  if (published_date_preset === 'Custom' && published_date_range) {
    return getPublishedRange(published_date_preset, analysisRange);
  }
  // Set default to Analysis period + 2 days prior
  return {
    startDate: zonedTimeToUtc(startOfTimezoneDay(subDays(new Date(), 2), timeZone), timeZone),
    endDate: new Date(),
  };
};

const defaultAnalysis = getDefaultAnalysisRange();

export const initialState = {
  title: 'Overview',
  dateRanges: {
    analysis: null,
    publish: null,
    minMax: null,
  },
  options: {
    sections: {
      loading: false,
      error: false,
      data: null
    },
    subtypes: {
      loading: false,
      error: false,
      data: null
    },
    creditLine: {
      loading: false,
      error: false,
      data: null
    },
    ownership: {
      loading: false,
      error: false,
      data: null
    },
    score: {
      loading: false,
      error: false,
      data: null
    },
    type: {
      loading: false,
      error: false,
      data: null
    },
    access: {
      loading: false,
      error: false,
      data: null
    }
  },
  dates: {
    start: null,
    end: null,
  },
  filters: {
    ...initFilters,
    ...filters,
  },
  newRequest: false,
  pagination: {
    page: 0, // zero-index
    size: 25,
  },
  results: {
    data: null,
    error: null,
    loading: false,
  },
  table: {
    columns,
    sort: `score`,
    sortOrder: `desc`,
  },
  potentialResults: {
    loading: false,
    error: false,
    total: '',
  },
};

export default function overviewReducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIG_SUCCESS': {
      const { timezone } = action.response?.tenant || {};

      return {
        ...state,
        dateRanges: {
          analysis: getDefaultAnalysisRange(timezone),
          publish: getDefaultPublishedRange(defaultAnalysis, timezone),
        },

      };
    }
    case types.OVERVIEW_FETCH: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.opts && action.opts.pagination ? action.opts.pagination : undefined,
        },
        results: {
          data: null,
          error: null,
          loading: true,
        },
        table: {
          ...state.table,
          ...action.table ? action.table : undefined,
        }
      };
    }
    case types.OVERVIEW_FETCH_SUCCESS: {
      return {
        ...state,
        newRequest: false,
        results: {
          ...state.results,
          data: action.response.data,
          columns: action.response.columns,
          error: null,
          loading: false,
        },
      };
    }
    case types.OVERVIEW_FETCH_FAIL: {
      return {
        ...state,
        results: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    }

    case types.OVERVIEW_FETCH_COUNT_SUCCESS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.response,
        },
      };
    }

    // Get information for filters
    case types.OVERVIEW_SECTIONS_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          sections: {
            ...state.options.sections,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_SECTIONS_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          sections: {
            ...state.options.sections,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_SECTIONS_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          sections: {
            ...state.options.sections,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }
    case types.OVERVIEW_SUBTYPES_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          subtypes: {
            ...state.options.subtypes,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_SUBTYPES_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          subtypes: {
            ...state.options.subtypes,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_SUBTYPES_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          subtypes: {
            ...state.options.subtypes,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }
    case types.OVERVIEW_CREDITLINE_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          creditLine: {
            ...state.options.creditLine,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_CREDITLINE_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          creditLine: {
            ...state.options.creditLine,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_CREDITLINE_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          creditLine: {
            ...state.options.creditLine,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }
    case types.OVERVIEW_OWNERSHIP_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          ownership: {
            ...state.options.ownership,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_OWNERSHIP_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          ownership: {
            ...state.options.ownership,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_OWNERSHIP_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          ownership: {
            ...state.options.ownership,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }
    case types.OVERVIEW_TYPE_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          type: {
            ...state.options.type,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_TYPE_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          type: {
            ...state.options.type,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_TYPE_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          type: {
            ...state.options.type,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }
    case types.OVERVIEW_ACCESS_FETCH: {
      return {
        ...state,
        options: {
          ...state.options,
          access: {
            ...state.options.access,
            loading: true,
          }
        }
      };
    }
    case types.OVERVIEW_ACCESS_SUCCESS: {
      return {
        ...state,
        options: {
          ...state.options,
          access: {
            ...state.options.access,
            loading: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case types.OVERVIEW_ACCESS_FAIL: {
      return {
        ...state,
        options: {
          ...state.options,
          access: {
            ...state.options.access,
            loading: false,
            error: true,
            data: null
          }
        }
      };
    }

    // DateRange
    case types.OVERVIEW_DATE_RANGE_SET: {
      return {
        ...state,
        newRequest: true,
        dateRanges: {
          ...state.dateRanges,
          analysis: action.dateRanges.analysis,
          publish: action.dateRanges.publish,
        }
      };
    }

    case types.OVERVIEW_DATE_RANGE_MIN_MAX_SET: {
      return {
        ...state,
        dateRanges: {
          ...state.dateRanges,
          minMax: action.minMax,
        },
      };
    }

    // Filters
    // TODO: DRY
    case types.SET_OVERVIEW_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case types.OVERVIEW_FILTER_RESET: {
      return {
        ...state,
        newRequest: true,
        filters: initFilters,
        options: initialState.options
      };
    }
    case types.OVERVIEW_FILTER_SET: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    }
    case types.OVERVIEW_AUTOSUGGEST: {
      return {
        ...state,
        autosuggest: undefined
      };
    }
    case types.OVERVIEW_AUTOSUGGEST_SUCCESS: {
      return {
        ...state,
        autosuggest: action.data
      };
    }
    case types.CLEAR_OVERVIEW_AUTOSUGGEST: {
      return {
        ...state,
        autosuggest: undefined,
      };
    }
    // Edit Columns
    case types.OVERVIEW_COLS_SET: {
      return {
        ...state,
        table: {
          ...state.table,
          columns: action.columns
        }
      };
    }
    // Filter Override Actions
    case types.OVERVIEW_FILTER_ACCESS: {
      return {
        ...state,
        newRequest: true,
        filters: {
          ...state.filters,
          access: action.filter,
        }
      };
    }
    case types.OVERVIEW_FILTER_OWNERSHIP: {
      return {
        ...state,
        newRequest: true,
        filters: {
          ...state.filters,
          ownership: action.filter,
        }
      };
    }
    // Filter Potential Result Count
    case types.OVERVIEW_FETCH_POTENTIAL_RESULTS: {
      return {
        ...state,
        potentialResults: {
          loading: true,
          error: false,
          total: '',
        },
      };
    }
    case types.OVERVIEW_FETCH_POTENTIAL_RESULTS_SUCCESS: {
      return {
        ...state,
        potentialResults: {
          loading: false,
          error: false,
          total: action.response,
        },
      };
    }
    case types.OVERVIEW_FETCH_POTENTIAL_RESULTS_FAIL: {
      return {
        ...state,
        potentialResults: {
          loading: false,
          error: true,
          total: '',
        },
      };
    }

    default: {
      return state;
    }
  }
};
