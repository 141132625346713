import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from 'utils/Auth0Provider';
import { getRoute } from 'utils/paths';
import { setDiveFilters, setInStorage, setNowFilters } from 'utils/storage';
import { getOrganizationFromHost, hostSelectEnabled, parseOrganization } from 'utils/multiHost';
// MUI
import makeStyles from '@material-ui/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
// Atoms
import Button from 'atoms/Button';
import Version from 'atoms/Version';
// Icons
import { ReactComponent as IconClose } from 'assets/svg/icon-close.svg';
import { ReactComponent as IconMenu } from 'assets/svg/icon-menu.svg';
// Components
import Select from 'sophi-design-system/src/components/Select';
import Logo from 'sophi-design-system/src/components/Logo';
// Style
import Colors from 'styles/colors';
// Selectors
import * as authSelectors from 'selectors/auth';
// Types
import authTypes from 'actions/authTypes';
import { ROUTE_NAMES } from '../../utils/paths';

const useStyles = makeStyles((theme) => ({
  '@keyframes twist': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '30%': {
      transform: 'rotate(-15deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    },
  },
  columnWrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actionBar: {
    minHeight: '64px',
    padding: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 64px',
    gridTemplateRows: '2fr',
    gridTemplateAreas: '"auth close"',
    '& a': {
      padding: `0 ${theme.typography.pxToRem(10)}`,
      alignSelf: 'center',
    }
  },
  container: {
    position: 'relative'
  },
  drawerPaper: {
    width: theme.typography.pxToRem(275),
  },
  icon: {
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)} 0`,
    '& svg': {
      transition: 'all ease 320ms',
    },
    '&:hover': {
      '& svg': {
        transform: 'scale(1.2)',
      },
      background: 'transparent',
      boxShadow: 'none'
    }
  },
  closeButton: {
    gridArea: 'close',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      '& svg': {
        animation: '$twist 400ms cubic-bezier(.4,.0,.23,1)',
      }
    }
  },
  navList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  navSelect: {
    display: `block`,
    padding: `1.33em ${theme.typography.pxToRem(10)}`,
    background: 'var(--grays-100)',
    fontSize: theme.typography.pxToRem(12)
  },
  navLabel: {
    display: `block`,
    margin: `1.33em 0`,
    fontWeight: `bold`,
    padding: `0 ${theme.typography.pxToRem(10)}`,
    textTransform: 'uppercase',
    color: Colors.blues[800],
    fontSize: theme.typography.pxToRem(12)
  },
  navItem: {
    width: '100%',
    '& > a': {
      padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
      display: 'block',
      textDecoration: 'none',
      color: Colors.grays[800],
      transition: 'all ease 320ms',
      '&:hover': {
        backgroundColor: Colors.blues[100],
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(25)}`,
      }
    },
    '&:last-of-type': {
      marginBottom: theme.typography.pxToRem(30),
    }
  },
  navActive: {
    '& > a': {
      backgroundColor: Colors.blues[200],
      padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(25)}`,
      pointerEvents: 'none',
    }
  },
  menuFooter: {
    width: '100%',
    padding: theme.typography.pxToRem(12),
    minHeight: theme.typography.pxToRem(72),
    display: 'grid',
    gridTemplateColumns: '1.5fr 2fr',
    alignItems: 'center',

    '& > button': {
      background: '#d7f0f7', // TODO: This color is new, replace it when we add in the new colors + branding
      boxShadow: 'none',
      transition: 'all ease 320ms',

      '&:hover': {
        color: 'inherit',
        background: '#3b8ea2', // TODO: This color is new, replace it when we add in the new colors + branding
      }
    },

    '& a': {
      color: 'var(--blues-800)',
      textDecoration: 'underline',
      textDecorationSkipInk: 'auto',
    }
  },
  avatar: {
    gridArea: 'auth',
    float: 'left',
    maxWidth: 45,
    height: 'auto',
    borderRadius: '100%',
    margin: theme.typography.pxToRem(15),
    marginBottom: 0,
  },
  welcome: {
    gridArea: 'welcome',
    wordBreak: 'break-all',
    gridColumnStart: 1,
    fontWeight: 'bold',
    lineHeight: 1.5,
    margin: theme.typography.pxToRem(15),
  },
}));

const Menu = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [picture, setPicture] = useState('#');
  const [showAuth, setShowAuth] = useState(window.innerWidth < 660 ? true : false);

  const { isAuthenticated, loginWithRedirect, logout, user, scopes } = useAuth0();

  const pathname = useSelector((state) => state.router.location.pathname);
  const hudLink = useSelector(authSelectors.getHudLink);
  const products = useSelector(authSelectors.getAvailableProducts);
  const allowedOrganizations = useSelector(authSelectors.getAllowedOrganizations) || [];
  const organizations = useSelector(authSelectors.getOrganizations) || [];
  const mainHost = useSelector(authSelectors.getMainHost) || '';
  const getMainHostID = useSelector(authSelectors.getMainHostID) || '';
  const services = useSelector(authSelectors.getServices);
  const reports = useSelector(authSelectors.getAvailableReports);
  const enabledCuratorFeatures = useSelector(authSelectors.getEnabledCuratorProductFeatures);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setShowAuth(window.innerWidth < 660 ? true : false);
    });
  }, []);

  const menuItems = useMemo(() => {
    return [
      {
        key: 'now',
        label: 'Live',
        available: products.includes('now'),
        links: [
          {
            key: 'now-home',
            label: 'Home',
            url: getRoute('nowHome'),
            available: true
          },
          {
            key: 'now-content-view',
            label: 'Content View',
            url: getRoute('nowContentView'),
            available: true
          },
        ],
      },
      {
        key: 'dive',
        label: 'Historical',
        available: products.includes('dive'),
        links: [
          {
            key: 'dive-home',
            label: 'Home',
            url: getRoute('diveHome'),
            available: true
          },
          {
            key: 'dive-sections',
            label: 'Sections',
            url: getRoute('diveSections'),
            available: true
          },
          {
            key: 'dive-articles',
            label: 'Articles',
            url: getRoute('diveArticles'),
            available: true
          },
          {
            key: 'dive-authors',
            label: 'Authors',
            url: getRoute('diveAuthors'),
            available: true
          },
        ]
      },
      {
        key: 'divebeta',
        label: 'Historical BETA',
        available: products.includes('dive') && scopes.includes('read:divebeta'),
        links: [
          {
            key: 'dive-home',
            label: 'Home',
            url: getRoute('diveBetaHome'),
            available: true
          },
          {
            key: 'dive-sections',
            label: 'Sections',
            url: getRoute('diveBetaSections'),
            available: true
          },
          {
            key: 'dive-articles',
            label: 'Articles',
            url: getRoute('diveBetaArticles'),
            available: true
          },
          {
            key: 'dive-authors',
            label: 'Authors',
            url: getRoute('diveBetaAuthors'),
            available: true
          },
        ]
      },
      {
        key: 'reports',
        label: 'Reports',
        available: true,
        links: [
          {
            key: 'pop',
            label: 'Pop',
            url: '/reports/pop',
            available: reports.includes('pop') && scopes.includes('reports:pop'),
          },
          {
            key: 'promo',
            label: 'Promo',
            url: '/reports/promo',
            available: reports.includes('promo') && scopes.includes('reports:promo'),
          },
        ]
      },
      {
        key: 'curator',
        label: 'Site automation',
        available: products.includes('curator'),
        links: [
          {
            key: 'curator-configuration',
            label: 'Configuration',
            url: getRoute('curatorPagesList'),
            available: enabledCuratorFeatures.includes('CONFIG_UI') && scopes.includes('configurator:pages:create')
          },
          {
            key: 'curator-overrides',
            label: 'Overrides',
            url: getRoute(ROUTE_NAMES.curatorOverrides),
            available: enabledCuratorFeatures.includes('OVERRIDE_UI') && scopes.includes('override:read')
          }
        ]
      },
      {
        key: 'other',
        label: 'Other',
        available: true,
        links: [
          {
            key: 'downloads',
            label: 'Downloads',
            url: hudLink,
            external: true,
            available: hudLink && products.includes('now'),
          },
          {
            key: 'docs',
            label: 'Resources',
            url: 'https://resources.sophi.io',
            external: true,
            available: true
          }
        ]
      }
    ];
  }, [products, scopes, reports, hudLink]);

  useEffect(() => {
    if (user) {
      setUsername(user.name.split('@')[0]);
      setPicture(user.picture);
    }
  }, [user]);

  const setMainHost = ({ value }) => {
    if (value !== mainHost) {
      const organization = getOrganizationFromHost(value, organizations);
      const data = parseOrganization(organization, services);
      history.replace({ search: `?org=${data.orgId}` });
      dispatch({ type: authTypes.UPDATE_CONFIG, data });
      setNowFilters({});
      setDiveFilters({});
      setInStorage('mainHost', value);
    }
    toggleMenu();
  };

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const renderLink = (navItem) => {
    if (navItem.external) {
      return (
        <a
          data-track-click="true"
          data-track-name={`nav-menu-${navItem.key}`}
          href={navItem.url}
          onClick={toggleMenu}
          rel={'noopener'}
          target={'_blank'}
        >
          {navItem.label}
        </a>
      );
    } else {
      return (
        <Link
          data-track-name={`nav-menu-${navItem.key}`}
          data-track-click="true"
          onClick={toggleMenu}
          to={{ pathname: navItem.url, search: `?org=${getMainHostID}` }}
        >
          {navItem.label}
        </Link>
      );
    }
  };

  const renderMenuItems = (nav) => {
    return nav.map((section) => {
      if (section.available && section.links.some((l) => l.available)) {
        return (
          <li key={section.key}>
            <span className={classes.navLabel}>
              {section.label}
            </span>
            <ul className={classes.navList}>
              {section.links
                .filter((l) => l.available)
                .map((link) => (
                  <li
                    key={link.key}
                    className={`${classes.navItem}
                    ${pathname === link.url ? classes.navActive : null}`}
                  >
                    {renderLink(link)}
                  </li>
                )
              )}
            </ul>
          </li>
        );
      }
    });
  };

  const renderDraw = () => {
    return (
      <Drawer
        anchor='left'
        classes={{ paper: classes.drawerPaper }}
        onClose={toggleMenu}
        open={isOpen}
        data-track-click="true"
        data-track-name="toggle-menu"
      >
        <div className={classes.columnWrapper}>
          <div>
            <div className={classes.actionBar}>
              {
                showAuth && isAuthenticated ? (
                  <>
                    <img src={picture} alt={'avatar'} className={classes.avatar} />
                    <span className={classes.welcome}>
                      Welcome,<br />{username}
                    </span>
                  </>
                ) : <Logo layout="url"/>
              }
              <Button
                className={classes.closeButton}
                onClick={toggleMenu}
                variant={'icon'}
                id="close-nav-menu"
                padding
                track
              >
                <IconClose />
              </Button>
            </div>
            <ul className={classes.navList}>
              <span className={classes.navSelect}>
                <Select
                  id="host-select"
                  label="HOST"
                  options={allowedOrganizations.map((org) => ({ value: org, label: org }))}
                  name="organization"
                  onChange={setMainHost}
                  value={{ value: mainHost, label: mainHost }}
                  isDisabled={!hostSelectEnabled(pathname)}
                />
              </span>
              {renderMenuItems(menuItems)}
            </ul>
          </div>
          <div>
            {showAuth && (
              <footer className={classes.menuFooter}>
                {
                  isAuthenticated ? (
                    <Button onClick={() => logout({
                      returnTo: `${window.location.origin}/logout`
                    })} >Sign Out</Button>
                  ) : (
                    <Button onClick={() => loginWithRedirect({})}>Sign In</Button>
                  )
                }
              </footer>
            )}
            <Version />
          </div>
        </div>

      </Drawer>
    );
  };

  return (<div className={classes.container}>
    <Button
      className={classes.icon}
      onClick={toggleMenu}
      variant={'icon'}
      track
      id="open-nav-menu"
    >
      <IconMenu />
    </Button>
    {renderDraw()}
  </div>);
};

export default Menu;
