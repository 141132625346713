// Root
import React from 'react';
import PropTypes from 'prop-types';

import Header from 'sophi-design-system/src/components/Typography/Header';
import Subheader from 'sophi-design-system/src/components/Typography/Subheader';
import styles from './PageTitle.styles.scss';

const PageTitle = ({ title, subtitle }) => (
  <div className={styles.pageTitle}>
    <Header tag="h1">{title}</Header>
    {subtitle && <div style={{ margin: '5px 0' }}><Subheader tag="h2">{subtitle}</Subheader></div>}
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
