import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Affix, Button, Col, ConfigProvider, Drawer, Layout, message, Row, Space, Typography } from 'antd';

import { OverridesProvider } from './contexts/OverridesProvider';

// Components
import CreateOverrideButton from './components/CreateOverrideButton';
import ListFilters from './components/ListFilters';
import List from './components/List';
import SortBy from './components/SortBy';
import Reload from './components/Reload';
import DownloadCsv from './components/DownloadCsv';

import theme from './utils/theme';
import './styles';
import FeedbackProvider from './contexts/FeedbackProvider';

const queryClient = new QueryClient();

const App: React.FC<{
  user: {
    nickname: string;
  };
  host: string;
  client: string;
  getToken: () => Promise<string>;
}> = ({ host, client, getToken, user }) => {

  const [isSiderHidden, setIsSiderHidden] = useState<boolean>(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);

  message.config({
    top: 75
  });

  return (
    <QueryClientProvider client={queryClient}>
      <OverridesProvider user={user} host={host} client={client} getToken={getToken}>
        <ConfigProvider theme={theme}>
          <FeedbackProvider>
            <div style={{ padding: '24px' }}>
              <Typography.Title style={{ margin: '0' }} level={2}>Site Automation</Typography.Title>
              <Typography.Title style={{ margin: '.5em 0 1.2em', fontWeight: 'normal' }} level={4}>Overrides</Typography.Title>
              <Row gutter={[16,16]} justify="space-between">
                <Col>
                  <Space>
                    <CreateOverrideButton/>
                    <DownloadCsv/>
                    <Reload/>
                  </Space>
                </Col>
                <Col>
                  <SortBy>
                    {isSiderHidden && <Button type="primary" onClick={()=>setOpenFilterDrawer(true)}>Filters</Button>}
                  </SortBy>
                </Col>
              </Row>
              <Layout style={{ backgroundColor: 'transparent' }}>
                <Layout.Content style={{ overflowY: 'auto', overflowX: 'hidden' }}><List/></Layout.Content>
                <Layout.Sider
                  style={{ backgroundColor: 'transparent' }}
                  breakpoint="lg"
                  collapsedWidth="0"
                  trigger={null}
                  width={280}
                  onBreakpoint={broken => {
                    setIsSiderHidden(broken);
                    setOpenFilterDrawer(false);
                  }}
                >
                  {!isSiderHidden && (
                    <Affix offsetTop={60}>
                      <ListFilters style={{
                        height: 'calc(100vh - 60px)',
                        padding: '24px 0 24px 24px',
                        overflowY: 'scroll',
                        overflowX: 'visible',
                      }}/>
                    </Affix>
                  )}
                </Layout.Sider>
              </Layout>
            </div>
          </FeedbackProvider>
          {isSiderHidden && (
            <Drawer open={openFilterDrawer} onClose={()=>setOpenFilterDrawer(false)}>
              <ListFilters/>
            </Drawer>
          )}
        </ConfigProvider>
      </OverridesProvider>
    </QueryClientProvider>
  )
};

export default App;
