export const types = {
  Add: 'Add',
  Article: 'Article',
  AutoRenew: 'AutoRenew',
  Backup: 'Backup',
  Block: 'Block',
  Bookmark: 'Bookmark',
  Build: 'Build',
  Call: 'Call',
  CaretDown: 'CaretDown',
  CaretLeft: 'CaretLeft',
  CaretRight: 'CaretRight',
  Clear: 'Clear',
  Cloud: 'Cloud',
  Communication: 'Communication',
  Create: 'Create',
  DataUsage: 'DataUsage',
  Delete: 'Delete',
  Done: 'Done',
  Download: 'Download',
  DragIndicator: 'DragIndicator',
  Event: 'Event',
  Extension: 'Extension',
  Favourite: 'Favourite',
  Filter: 'Filter',
  Folder: 'Folder',
  Graph: 'Graph',
  Home: 'Home',
  Info: 'Info',
  Insights: 'Insights',
  Link: 'Link',
  Location: 'Location',
  Lock: 'Lock',
  Mail: 'Mail',
  More: 'More',
  Notification: 'Notification',
  OpenNewTab: 'OpenNewTab',
  Piechart: 'Piechart',
  Profile: 'Profile',
  Refresh: 'Refresh',
  Remove: 'Remove',
  Search: 'Search',
  Settings: 'Settings',
  Share: 'Share',
  Security: 'Security',
  Storage: 'Storage',
  TableChart: 'TableChart',
  TableRows: 'TableRows',
  Time: 'Time',
  Timeline: 'Timeline',
  TrendingDown: 'TrendingDown',
  TrendingFlat: 'TrendingFlat',
  TrendingUp: 'TrendingUp',
  People: 'People',
  Visibility: 'Visibility',
  Wifi: 'Wifi',
  File: 'File',
  Excel: 'Excel',
  CSV: 'CSV',
};

export const basicIcons = {
  [types.Add]: () => import('../../assets/svg/basic-icon-set/Add.svg'),
  [types.Article]: () => import('../../assets/svg/basic-icon-set/Article.svg'),
  [types.AutoRenew]: () => import('../../assets/svg/basic-icon-set/AutoRenew.svg'),
  [types.Backup]: () => import('../../assets/svg/basic-icon-set/Backup.svg'),
  [types.Block]: () => import('../../assets/svg/basic-icon-set/Block.svg'),
  [types.Bookmark]: () => import('../../assets/svg/basic-icon-set/Bookmark.svg'),
  [types.Build]: () => import('../../assets/svg/basic-icon-set/Build.svg'),
  [types.Call]: () => import('../../assets/svg/basic-icon-set/Call.svg'),
  [types.CaretDown]: () => import('../../assets/svg/basic-icon-set/CaretDown.svg'),
  [types.CaretLeft]: () => import('../../assets/svg/basic-icon-set/CaretLeft.svg'),
  [types.CaretRight]: () => import('../../assets/svg/basic-icon-set/CaretRight.svg'),
  [types.Clear]: () => import('../../assets/svg/basic-icon-set/Clear.svg'),
  [types.Cloud]: () => import('../../assets/svg/basic-icon-set/Cloud.svg'),
  [types.Communication]: () => import('../../assets/svg/basic-icon-set/Communication.svg'),
  [types.Create]: () => import('../../assets/svg/basic-icon-set/Create.svg'),
  [types.DataUsage]: () => import('../../assets/svg/basic-icon-set/DataUsage.svg'),
  [types.Delete]: () => import('../../assets/svg/basic-icon-set/Delete.svg'),
  [types.Done]: () => import('../../assets/svg/basic-icon-set/Done.svg'),
  [types.Download]: () => import('../../assets/svg/basic-icon-set/Download.svg'),
  [types.DragIndicator]: () => import('../../assets/svg/basic-icon-set/DragIndicator.svg'),
  [types.Event]: () => import('../../assets/svg/basic-icon-set/Event.svg'),
  [types.Extension]: () => import('../../assets/svg/basic-icon-set/Extension.svg'),
  [types.Favourite]: () => import('../../assets/svg/basic-icon-set/Favourite.svg'),
  [types.Filter]: () => import('../../assets/svg/basic-icon-set/Filter.svg'),
  [types.Folder]: () => import('../../assets/svg/basic-icon-set/Folder.svg'),
  [types.Graph]: () => import('../../assets/svg/basic-icon-set/Graph.svg'),
  [types.Home]: () => import('../../assets/svg/basic-icon-set/Home.svg'),
  [types.Info]: () => import('../../assets/svg/basic-icon-set/Info.svg'),
  [types.Insights]: () => import('../../assets/svg/basic-icon-set/Insights.svg'),
  [types.Link]: () => import('../../assets/svg/basic-icon-set/Link.svg'),
  [types.Location]: () => import('../../assets/svg/basic-icon-set/Location.svg'),
  [types.Lock]: () => import('../../assets/svg/basic-icon-set/Lock.svg'),
  [types.Mail]: () => import('../../assets/svg/basic-icon-set/Mail.svg'),
  [types.More]: () => import('../../assets/svg/basic-icon-set/More.svg'),
  [types.Notification]: () => import('../../assets/svg/basic-icon-set/Notification.svg'),
  [types.OpenNewTab]: () => import('../../assets/svg/basic-icon-set/OpenNewTab.svg'),
  [types.Piechart]: () => import('../../assets/svg/basic-icon-set/Piechart.svg'),
  [types.Profile]: () => import('../../assets/svg/basic-icon-set/Profile.svg'),
  [types.Refresh]: () => import('../../assets/svg/basic-icon-set/Refresh.svg'),
  [types.Remove]: () => import('../../assets/svg/basic-icon-set/Remove.svg'),
  [types.Search]: () => import('../../assets/svg/basic-icon-set/Search.svg'),
  [types.Settings]: () => import('../../assets/svg/basic-icon-set/Settings.svg'),
  [types.Share]: () => import('../../assets/svg/basic-icon-set/Share.svg'),
  [types.Security]: () => import('../../assets/svg/basic-icon-set/Security.svg'),
  [types.Storage]: () => import('../../assets/svg/basic-icon-set/Storage.svg'),
  [types.TableChart]: () => import('../../assets/svg/basic-icon-set/TableChart.svg'),
  [types.TableRows]: () => import('../../assets/svg/basic-icon-set/TableRows.svg'),
  [types.Time]: () => import('../../assets/svg/basic-icon-set/Time.svg'),
  [types.Timeline]: () => import('../../assets/svg/basic-icon-set/Timeline.svg'),
  [types.TrendingDown]: () => import('../../assets/svg/basic-icon-set/TrendingDown.svg'),
  [types.TrendingFlat]: () => import('../../assets/svg/basic-icon-set/TrendingFlat.svg'),
  [types.TrendingUp]: () => import('../../assets/svg/basic-icon-set/TrendingUp.svg'),
  [types.People]: () => import('../../assets/svg/basic-icon-set/People.svg'),
  [types.Visibility]: () => import('../../assets/svg/basic-icon-set/Visibility.svg'),
  [types.Wifi]: () => import('../../assets/svg/basic-icon-set/Wifi.svg'),
  [types.File]: () => import('../../assets/svg/basic-icon-set/File.svg'),
  [types.Excel]: () => import('../../assets/svg/basic-icon-set/ExcelFile.svg'),
  [types.CSV]: () => import('../../assets/svg/basic-icon-set/CSVFile.svg'),
};

export const circleIcons = {
  [types.Add]: () => import('../../assets/svg/circle-icon-set/Add.svg'),
  [types.Article]: () => import('../../assets/svg/circle-icon-set/Article.svg'),
  [types.AutoRenew]: () => import('../../assets/svg/circle-icon-set/AutoRenew.svg'),
  [types.Backup]: () => import('../../assets/svg/circle-icon-set/Backup.svg'),
  [types.Block]: () => import('../../assets/svg/circle-icon-set/Block.svg'),
  [types.Bookmark]: () => import('../../assets/svg/circle-icon-set/Bookmark.svg'),
  [types.Build]: () => import('../../assets/svg/circle-icon-set/Build.svg'),
  [types.Call]: () => import('../../assets/svg/circle-icon-set/Call.svg'),
  [types.Clear]: () => import('../../assets/svg/circle-icon-set/Clear.svg'),
  [types.Cloud]: () => import('../../assets/svg/circle-icon-set/Cloud.svg'),
  [types.Communication]: () => import('../../assets/svg/circle-icon-set/Communication.svg'),
  [types.Create]: () => import('../../assets/svg/circle-icon-set/Create.svg'),
  [types.DataUsage]: () => import('../../assets/svg/circle-icon-set/DataUsage.svg'),
  [types.Done]: () => import('../../assets/svg/circle-icon-set/Done.svg'),
  [types.Download]: () => import('../../assets/svg/circle-icon-set/Download.svg'),
  [types.Event]: () => import('../../assets/svg/circle-icon-set/Event.svg'),
  [types.Extension]: () => import('../../assets/svg/circle-icon-set/Extension.svg'),
  [types.Favourite]: () => import('../../assets/svg/circle-icon-set/Favourite.svg'),
  [types.Filter]: () => import('../../assets/svg/circle-icon-set/Filter.svg'),
  [types.Folder]: () => import('../../assets/svg/circle-icon-set/Folder.svg'),
  [types.Graph]: () => import('../../assets/svg/circle-icon-set/Graph.svg'),
  [types.Home]: () => import('../../assets/svg/circle-icon-set/Home.svg'),
  [types.Info]: () => import('../../assets/svg/circle-icon-set/Info.svg'),
  [types.Insights]: () => import('../../assets/svg/circle-icon-set/Insights.svg'),
  [types.Link]: () => import('../../assets/svg/circle-icon-set/Link.svg'),
  [types.Location]: () => import('../../assets/svg/circle-icon-set/Location.svg'),
  [types.Lock]: () => import('../../assets/svg/circle-icon-set/Lock.svg'),
  [types.Mail]: () => import('../../assets/svg/circle-icon-set/Mail.svg'),
  [types.More]: () => import('../../assets/svg/circle-icon-set/More.svg'),
  [types.Notification]: () => import('../../assets/svg/circle-icon-set/Notification.svg'),
  [types.OpenNewTab]: () => import('../../assets/svg/circle-icon-set/OpenNewTab.svg'),
  [types.Piechart]: () => import('../../assets/svg/circle-icon-set/Piechart.svg'),
  [types.Profile]: () => import('../../assets/svg/circle-icon-set/Profile.svg'),
  [types.Refresh]: () => import('../../assets/svg/circle-icon-set/Refresh.svg'),
  [types.Remove]: () => import('../../assets/svg/circle-icon-set/Remove.svg'),
  [types.Search]: () => import('../../assets/svg/circle-icon-set/Search.svg'),
  [types.Settings]: () => import('../../assets/svg/circle-icon-set/Settings.svg'),
  [types.Share]: () => import('../../assets/svg/circle-icon-set/Share.svg'),
  [types.Security]: () => import('../../assets/svg/circle-icon-set/Security.svg'),
  [types.Storage]: () => import('../../assets/svg/circle-icon-set/Storage.svg'),
  [types.TableChart]: () => import('../../assets/svg/circle-icon-set/TableChart.svg'),
  [types.TableRows]: () => import('../../assets/svg/circle-icon-set/TableRows.svg'),
  [types.Time]: () => import('../../assets/svg/circle-icon-set/Time.svg'),
  [types.Timeline]: () => import('../../assets/svg/circle-icon-set/Timeline.svg'),
  [types.TrendingDown]: () => import('../../assets/svg/circle-icon-set/TrendingDown.svg'),
  [types.TrendingFlat]: () => import('../../assets/svg/circle-icon-set/TrendingFlat.svg'),
  [types.TrendingUp]: () => import('../../assets/svg/circle-icon-set/TrendingUp.svg'),
  [types.People]: () => import('../../assets/svg/circle-icon-set/People.svg'),
  [types.Visibility]: () => import('../../assets/svg/circle-icon-set/Visibility.svg'),
  [types.Wifi]: () => import('../../assets/svg/circle-icon-set/Wifi.svg'),
  [types.File]: () => import('../../assets/svg/circle-icon-set/File.svg'),
  [types.Excel]: () => import('../../assets/svg/circle-icon-set/ExcelFile.svg'),
  [types.CSV]: () => import('../../assets/svg/circle-icon-set/CSVFile.svg'),
};
