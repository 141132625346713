import React from 'react';

const FilterContext = React.createContext({
  selectors: {
    getFilters: () => null,
    getOptions: () => null,
    getAutosuggest: () => null,
    getRowCount: () => null,
    getDisabledFeatures: () => null,
  },
  actions: {
    clearAutosuggest: () => null,
    fetchSections: () => null,
    fetchSubtypes: () => null,
    fetchCreditLine: () => null,
    fetchOwnership: () => null,
    fetchAccess: () => null,
    fetchType: () => null,
    fetchResultsTotal: () => null,
    fetchAutosuggest: () => null,
    setFilters: () => null,
  }
});

export default FilterContext;
