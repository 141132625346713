import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import getMinutes from 'date-fns/getMinutes';
import setMinutes from 'date-fns/setMinutes';
import startOfMinute from 'date-fns/startOfMinute';
import compareAsc from 'date-fns/compareAsc';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { format, utcToZonedTime } from 'date-fns-tz';
import formatISO from 'date-fns/formatISO';

export const convertToZonedDate = (date, timeZone) => {
  const formattedDate = format(utcToZonedTime(date, timeZone), 'yyyy,MM,dd,HH,mm,ss', { timeZone });
  const parts = formattedDate.split(',').map((val) => parseInt(val));
  parts[1] -= 1;
  return new Date(...parts);
};

/**
 * Convert utc value into given timezone and format with format string
 * @param {Object|Integer} date       Date Object or date in ms
 * @param {String}         formatStr  Date Format String (ex. yyyy-MM-dd)
 * @param {String}         timeZone   Timezone string in {Region}/{City} format
 * @returns {String}       Date time string formatted in given timezone
 */
export const formatDate = (date, formatStr, timeZone) => {
  const formattedDate = utcToZonedTime(date, timeZone);
  return format(formattedDate, formatStr, { timeZone });
};

/**
 * Gets the date time in the given timezone and gets the start of that day in local time
 * @param {Object|Integer} date       Date Object or date in ms
 * @param {String}         timeZone   Timezone string in {Region}/{City} format
 * @returns {Object}        Returns start of day (local computer time)
 */
export const startOfTimezoneDay = (date, timeZone) => startOfDay(convertToZonedDate(date, timeZone));

/**
 * Gets the date time in the given timezone and gets the end of that day in local time
 * @param {Object|Integer} date       Date Object or date in ms
 * @param {String}         timeZone   Timezone string in {Region}/{City} format
 * @returns {Object}        Returns end of day (local computer time)
 */
export const endOfTimezoneDay = (date, timeZone) => endOfDay(convertToZonedDate(date, timeZone));

export const startOfTimezoneMonth = (date, timeZone) => startOfMonth(convertToZonedDate(date, timeZone));

export const endOfTimezoneMonth = (date, timeZone) => endOfMonth(convertToZonedDate(date, timeZone));
export const subLocalDays = (date, daysToSub, timezone) => {
  const localDate = utcToZonedTime(date, timezone);
  return subDays(localDate, daysToSub);
};

/**
 * Format the given String date to display in short format
 * @param {String} yyyyddmm - Date to convert in `yyyy-dd-mm` format
 * @param {Object} [locale={}] - A date-fns/locale object for localization
 * @example "2019-04-17"
 * @returns {String} Date in {MMM d} format
 */
export function formatShortDate(yyyyddmm) {
  if (!yyyyddmm) {
    return ``;
  }

  /*
  TODO:
  We assume the Date is based on the user's current timezone setting
  If we want to change it, then we'll need a timezone lib
  */
  return format(parseISO(yyyyddmm), 'MMM d');
}

/**
 * Format the given data to display in short date and time format
 * @param {Number} ms - The date to format in milliseconds
 * @param {String} timezone - Timezone to convert datetime stamp to
 * @returns {String} Date in {MMM. d, h:mmaaaa} format
 */
export function formatShortDateTime(date, timezone) {
  if (!date) return ``;

  if (!timezone) {
    console.warn('No timezone provided to formatShortDateTime');
  }
  try {
    return formatDate(date, "MMM d ''yy, p", timezone); /* eslint-disable-line */
  } catch (err) {
    return '';
  }
}

/**
 * Helper function that rounds a given time to the nearest specified minutes
 * @param {Object} date - A date object
 * @param {integer} interval - The number of minutes to round to
 * @param {boolean} roundUp - Whether to round up or down
 */
export const roundToNearestMinutes = (date, interval, roundUp = false) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  var roundedMinutes = Math[roundUp ? 'ceil' : 'floor'](getMinutes(parsedDate) / interval) * interval;
  return setMinutes(startOfMinute(parsedDate), roundedMinutes);
};

/**
 * Helper function to convert Date object to seconds
 * @param {Object} date - A Date object
 */
export const convertToSeconds = (date) => {
  if (isValid(date)) {
    return Math.floor(date / 1000);
  }
  return null;
};

/**
 * Helper function to convert seconds to a Date Object
 * @param {integer} seconds - Integer representing UTC seconds
 */
export const convertSecondsToDate = (seconds, timezone) => {
  return utcToZonedTime(seconds * 1000, timezone);
};

/**
 * Helper function to format date for chart hovers
 * @param {Object} date - Date Object
 */
export const formatDateTimeForHovers = (date) => {
  const shortMonth = format(date, 'MMM');
  const fullMonth = format(date, 'MMMM');
  return format(date, `K:mm a eee. ${shortMonth === fullMonth ? 'MMM' : 'MMM.'} d`);
};

/**
 * Helper function that will return the most recent Date between the two dates passed in
 * @param {Date Object} reference - Date Object to be referenced
 * @param {Date Object} comparison - Date Object to be compared
 */
export const getMostRecentTime = (reference, comparison) => {
  const result = compareAsc(reference, comparison);
  switch (result) {
    case -1: return reference;
    case 0: return reference;
    case 1: return comparison;
  }
};

export const validateDateRange = (startDate, endDate, preferStart) => {
  const datesObj = { startDate, endDate };

  if (startDate && endDate) {
    if (!isAfter(datesObj.endDate, datesObj.startDate)) {
      if (preferStart) {
        datesObj.endDate = addDays(startDate, 1);
      } else {
        datesObj.startDate = subDays(endDate, 1);
      }
    }
  }
  return datesObj;
};

/**
 * Takes in a date and returns that date as a string in the format YYYY-MM-DD
 * @param {Date} date - an instance of the Date object
 * @return {String} - string, for example "2021-02-27"
 */
export const toISODate = (d) => {
  return formatISO(d, { representation: 'date' });
};
