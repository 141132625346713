import React, { useState } from 'react';
import subDays from 'date-fns/subDays';
import { useDispatch, useSelector } from 'react-redux';

import { setNewDateRange } from 'actions/dive';
import { getDateRange } from 'selectors/dive';
import { getTimezone } from 'selectors/auth';
import DateRangePicker from 'molecules/DateRangePicker';

import { getFromStorage } from 'utils/storage';
import { endOfTimezoneDay } from 'utils/dateTime';
import { DIVE_DATE_OPTIONS } from 'utils/relativeDates';
import ConvertToTimezone from 'utils/ConvertToTimezone';
import styles from './DiveDatePicker.styles.scss';

// Get saved search configs
const savedFilters = () => getFromStorage('dive_config') || {};

// Set default preset and preset options
const divePresets = {
  selected: savedFilters().dive_date_preset || 'Most Recent 30 Days',
  options: DIVE_DATE_OPTIONS,
};

const DiveDatePicker = () => {
  const dispatch = useDispatch();
  const dateRange = useSelector(getDateRange);
  const timeZone = useSelector(getTimezone);
  const [selectedPreset, setSelectedPreset] = useState(divePresets.options.find((option) => option.label === divePresets.selected));

  return (
    <div className={styles.diveDatePicker}>
      <label
        className={styles.label}
        htmlFor="dive-date-picker"
      >
        For Articles Published
      </label>
      <ConvertToTimezone
        onChange={({ preset, ...range }) => {
          dispatch(setNewDateRange(range));
          setSelectedPreset(preset);
        }}
        dateRange={dateRange}
      >
        <DateRangePicker
          id="dive-date-picker"
          dateFormat="EEEEEE MMM dd ''yy"
          presets={divePresets}
          hideTime
          altStyle
          rangeMax={endOfTimezoneDay(subDays(new Date(), 7), timeZone)}
          presetValue={selectedPreset}
        />
      </ConvertToTimezone>
    </div>
  );
};

export default DiveDatePicker;
