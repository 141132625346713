import React, { useState } from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useMutation, useQuery } from '@tanstack/react-query';

import pageStyles from '../Page.styles.scss';
import styles from './PageCreate.styles.scss';

import PageConfigForm from '../../components/PageConfig';
import ErrorList from '../../components/ErrorList';
import PageTitle from '../../components/PageTitle';
import { useHistory, useParams } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import ButtonLink from '../../components/ButtonLink';
import { UploadOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { getPage, postPage } from '../../api/pages';
import { postWidget } from '../../api/widgets';

const PageCreate = () => {
  const history = useHistory();
  const { pageName } = useParams();
  const [isSaving, setIsSaving] = useState(false);

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data: pageConfig, isInitialLoading } = useQuery({
    enabled: !!pageName,
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      const content = `Could not copy settings from ${pageName}. ${e?.cause?.status === 404 ? 'Page not found.' : ''}`;
      message.warning({
        content,
        style: {
          marginTop: '58px'
        },
      });
    }
  });

  const { fn: postWidgetFn } = useCuratorConfigApi(postWidget);
  const { mutateAsync: createWidgetAsync } = useMutation({
    mutationFn: postWidgetFn,
  });

  const { fn: postPageFn } = useCuratorConfigApi(postPage);
  const { error, mutate: createPage } = useMutation({
    mutationFn: postPageFn,
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: (request) => {
      const widgetCreateRequests = [];
      if (pageName) {
        for (const widget of pageConfig.widgets) {
          const body = {
            status: 'DRAFT',
            config: widget.config,
          };
          widgetCreateRequests.push(createWidgetAsync({ body, pageName: request?.config.name }));
        }
      }

      Promise.allSettled(widgetCreateRequests).then((requests) => {
        const errors = requests.filter((r) => r.status === 'rejected');

        if (errors.length > 0) {
          message.warning({
            content: `Page ${request?.config.name} created. ${errors.length} widget${errors.length > 1 ? 's' : ''} failed to copy.`,
            style: {
              marginTop: '58px'
            },
          });
        } else {
          message.success({
            content: pageConfig ? `Copied` : `Saved`,
            style: {
              marginTop: '58px'
            },
          });
        }
        
        setIsSaving(true);
        history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName: request?.config.name }));
      });
    },
    onError: (e) => {
      setIsSaving(false);
    },
  });

  const base = pageConfig?.config ? {
    ...pageConfig.config,
    name: '',
  } : undefined;

  const handleSubmit = (config) => {
    createPage({ body: { config }, pageName: config.name });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Add a page`} />
      <div className={pageStyles.body}>
        {isInitialLoading ? (
          <Spin />
        ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ flexBasis: '600px' }}>
            <div className={styles.import}>
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPageImport)}
                icon={<UploadOutlined/>}
              >
                Import
              </ButtonLink>
            </div>
            <PageConfigForm
              onSubmit={handleSubmit}
              onCancel={history.goBack}
              disabled={isSaving}
              showWidgetDuplicationStrategy={false}
              config={base}
            />
            {error?.messages &&
              <ErrorList messages={error.messages} />
            }
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default PageCreate;
