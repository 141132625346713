import React from 'react';
import BranchOptions from './BranchOptions';

const Branch = ({ section, filterValue, onChange, selected }) => {
  if (!section.children) {
    if (!filterValue || section.label.match(filterValue)) {
      return <BranchOptions onChange={onChange} selected={selected} {...section} />;
    }
    return null;
  }

  if (!filterValue || section.flattenedChildren.some(({ label }) => label.match(filterValue))) {
    return (
      <BranchOptions {...section} hasChildren onChange={onChange} selected={selected}>
        <ul>{section.children.map((child) => <Branch key={child.value} section={child} filterValue={filterValue} onChange={onChange} selected={selected} />)}</ul>
      </BranchOptions>
    );
  }

  return null;
};

export default Branch;
