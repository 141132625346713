// Meta
import getArticleInfo from './meta/meta';
import allTimeStats from './meta/allTime';
import diveStats from './meta/diveStats';

// Performance
import getPerformanceData from './performance/score';
import getSocialPromotionsTimeline from './performance/socialPromotionsTimeline';
import getInternalPromotionsTimeline from './performance/internalPromotionsTimeline';
import getPerformanceBreakdown from './performance/scoreBreakdown';
import getTrafficData from './performance/traffic';

// Traffic
import getTopReferrerData from './traffic/topReferrer';
import getTrafficSourceData from './traffic/trafficSource';
import getMostReaderData from './traffic/mostReaders';

// Engagement
import getMinutesReading from './engagement/minutesReading';
import getMostEngaged from './engagement/mostEngaged';

// Promotion
import getPromotionSocial from './promotion/social';
import getPromotionInternal from './promotion/internal';

// Visitors
import visitorsThatStay from './visitors/visitorsThatStay';


export default {
  // Meta
  getArticleInfo,
  allTimeStats,
  diveStats,
  // Performance
  getPerformanceData,
  getSocialPromotionsTimeline,
  getInternalPromotionsTimeline,
  getPerformanceBreakdown,
  getTrafficData,
  // Traffic
  getTopReferrerData,
  getTrafficSourceData,
  getMostReaderData,
  // Engagement
  getMinutesReading,
  getMostEngaged,
  // Promotion
  getPromotionSocial,
  getPromotionInternal,
  // Visitors
  visitorsThatStay
};