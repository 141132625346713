import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
//Atoms
import Container from 'atoms/Container';
import DynamicComponent from 'atoms/DynamicComponent';
// Actions
import {
  setSelectedDiveAuthorsColumns,
  setDiveAuthorPaginationValue,
  setDiveAuthorSort,
  fetchDiveAuthorsColumns
} from 'actions/dive';
import types from 'actions/diveTypes';
import { setTitle } from 'actions/header';
// Selectors
import { getDiveAuthorsAllColumns, getDiveAuthorsColumns } from 'selectors/dive';
import { getMainHostID, getPlatforms } from 'selectors/auth';
import { getDiveDownloadsDisabled } from 'selectors/global';

// Utils
import { setPageTitle } from 'utils/paths';
import { setColumnObject } from 'utils/storage';
import { formatColumnsConfig, getCellFormatter } from 'utils/table';

const Authors = () => {
  const dispatch = useDispatch();
  const platforms = useSelector(getPlatforms);
  const mainHost = useSelector(getMainHostID);
  const selectedColumns = useSelector(getDiveAuthorsColumns);
  const requestedColumns = useSelector((state) => state.dive.authors.table.columns);
  const downloadDisabled = useSelector(getDiveDownloadsDisabled);
  const authorsColumns = formatColumnsConfig(useSelector(getDiveAuthorsAllColumns), platforms);

  useEffect(() => {
    setPageTitle('Historical - Authors');
    dispatch(setTitle(''));
    dispatch(fetchDiveAuthorsColumns());
  }, []);

  // Event Handlers
  const handleColumnChange = (columns) => {
    dispatch(setSelectedDiveAuthorsColumns(columns));
    setColumnObject('dive-authors-columns', columns);
  };
  const handlePaginationChange = (type, value) => dispatch(setDiveAuthorPaginationValue(type, value));
  const handleSortChange = ({ column, direction }) => {
    dispatch(setDiveAuthorSort({ sort: column, sortOrder: direction }));
  };

  const columnConfig = useMemo(() => {
    const formattedColumns = uniq([ 'author', 'sophiScore', ...selectedColumns ]);
    return formattedColumns.map((column) => {
      const authorsColumn = authorsColumns.nonDevice.get(column) || authorsColumns.deviceTop.get(column);
      if (authorsColumn) {
        return {
          accessor: column,
          Cell: getCellFormatter(column, requestedColumns && requestedColumns.includes(column), { host: mainHost, dive: true }),
          Header: column === 'sophiScore' ? 'Score' : authorsColumn.label,
          ...authorsColumn,
        };
      }
    }).filter((c) => c);
  }, [authorsColumns, selectedColumns, requestedColumns]);

  return (
    <div>
      <DynamicComponent
        placeholder={<Container loading={true}><h2>Authors</h2></Container>}
        component={() => import('organisms/DiveTable')}
        // Any props needed by the component can be placed as a prop of the Dynamic Component
        action={types.FETCH_DIVE_AUTHORS}
        tableValues={(state) => state.dive.authors.table}
        count={(state) => state.dive.authors.count}
        selectedColumns={getDiveAuthorsColumns}
        tableColumns={authorsColumns}
        onColumnChange={handleColumnChange}
        onPaginationChange={handlePaginationChange}
        onSort={handleSortChange}
        pageType="author"
        id="dive-authors"
        columns={columnConfig}
        downloadConfig={{
          handleDownload: () => dispatch({ type: types.FETCH_DIVE_AUTHORS_REPORT }),
          disabled: downloadDisabled,
          info: 'Adjust the date-published range to download up to 31 days’ worth of data.',
        }}
      />
    </div>
  );
};

export default Authors;
