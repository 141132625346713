import React from 'react';
import styles from './EntityName.styles.scss';

const EntityName = ({ name }) => {
  return (
    <span className={styles.entityName}>
      {name}
    </span>
  );
};

export default EntityName;
