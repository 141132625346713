import React, { useEffect, useMemo, useState } from 'react';

import styles from './FilteredPageList.styles.scss';

import useListSort from '../../hooks/useListSort';
import useTextFilter from '../../hooks/useTextFilter';
import useTypeFilter from '../../hooks/useTypeFilter';

import ListItem from './ListItem';
import { Form, Input, Button, Select } from 'antd';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import PageStatus from '../PageStatus';
import { Pagination } from 'antd';
import useLocalStored from '../../hooks/useLocalStored';
import Typography from 'antd/es/typography/Typography';
import { statusLabels } from '../../copy';

const FilteredPageList = ({ list, total }) => {
  const [ form ] = Form.useForm();
  const [page, setPage] = useLocalStored({ key: 'sa.pages.pagination.page', initialValue: 1 });
  const [pageSize, setPageSize] = useLocalStored({ key: 'sa.pages.pagination.pageSize', initialValue: 20 });

  const {
    filtered: filteredByStatus,
    available: availableStatuses,
    selected: selectedStatuses,
    onClick: handleStatusInput,
    reset: resetStatusFilter
  } = useTypeFilter({
    list,
    accessor: 'status',
    storageKey: 'sa.pages.filter.status'
  });

  const {
    onChange: handleSearchInput,
    query,
    filtered: filteredByName
  } = useTextFilter({
    list: filteredByStatus,
    accessor: (item) => item.config.name + ' ' + item.widgets.map((widget) => widget.config.name).join(' '),
    storageKey: 'sa.pages.filter.name',
    label: 'Name',
    name: 'nameSearch'
  });

  const sortOptions = [
    { label: 'Date created', accessor: 'createdOn' },
    { label: 'Last updated', accessor: (item) => item.updatedOn || item.createdOn },
    { label: 'Status', accessor: (item) => statusLabels[item.status] },
    { label: 'Name', accessor: (item) => item.config.name },
  ];

  const selectOptions = sortOptions.map(({ label }, index) => ({ value: index, label }));

  const {
      onSortByChange,
      onAscendingChange,
      ascending,
      sorted,
      selected: selectedSort
  } = useListSort({ list: filteredByName, options: sortOptions, storageKey: 'sa.pages.filter.sort' });

  const reset = () => {
    handleSearchInput('');
    resetStatusFilter();
  };

  const initialValues ={ sortBy: selectedSort, nameSearch: query };

  const paginated = useMemo(() => {
    if (!Array.isArray(sorted)) return [];
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    return sorted.slice(start, end);
  }, [sorted, page, pageSize]);

  const pageSizeOptions = [10, 20, 50];
  if (pageSizeOptions[pageSizeOptions - 1] < sorted.length) pageSizeOptions.push(sorted.length);

  useEffect(() => {
    // In case list length changed since page was last set
    const smallestPageSize = pageSizeOptions[0];
    const largestPageSize = pageSizeOptions[pageSizeOptions.length - 1];
    let lastPage = Math.ceil(sorted.length / pageSize);

    if (pageSize < smallestPageSize) {
      setPageSize(smallestPageSize);
      lastPage = Math.ceil(sorted.length / smallestPageSize);
    }

    if (pageSize > largestPageSize) {
      setPageSize(largestPageSize);
      lastPage = Math.ceil(sorted.length / largestPageSize);
    }

    if (page === 0 && lastPage > 0) {
      setPage(lastPage);
    };

    if (page > lastPage) {
      setPage(lastPage);
    };
  }, [sorted]);

  const onChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const showTotal = (total, range) => `${range[0].toLocaleString()} to ${range[1].toLocaleString()} of ${total.toLocaleString()}`;

  return (
    <>
      <Form form={form} initialValues={initialValues}>
        <div className={styles.controls}>

          <Form.Item label="Sort by" colon={false}>
            <Input.Group compact>
              <Form.Item name="sortBy" noStyle>
                <Select
                  options={selectOptions}
                  onChange={onSortByChange}
                  style={{ width: '150px' }}
                />
              </Form.Item>
              <Button
                onClick={onAscendingChange}
                icon={ascending ? <SortAscendingOutlined/> : <SortDescendingOutlined/> }
              />
            </Input.Group>
          </Form.Item>

          <div className={styles.filters}>
            <Form.Item label="Name" name="nameSearch" colon={false}>
              <Input allowClear suffix={ <SearchOutlined/> } onChange={handleSearchInput} />
            </Form.Item>

            <Form.Item label="Status" name="statusFilter" colon={false}>
              <Input.Group>
                {availableStatuses.map((status) =>
                  <PageStatus
                    key={status}
                    status={status}
                    checkable={true}
                    checked={selectedStatuses[status]}
                    onClick={handleStatusInput}
                  />
                )}
              </Input.Group>
            </Form.Item>
          </div>

        </div>
      </Form>

      <div className={styles.container}>
        <ul className={styles.list}>
          {paginated.map(({ config: { name }, status, widgets }) => {
            return <ListItem name={name} status={status} widgets={widgets} key={name} />;
          })}
        </ul>
      </div>

      {list.length < total &&
        <div className={styles.warning}>
          <Typography.Text type="secondary">Additional pages may not be displayed.</Typography.Text>
        </div>
      }

      <div className={styles.pagination}>
        <Pagination
          total={sorted.length}
          showTotal={showTotal}

          current={page}
          onChange={onChange}

          showSizeChanger={true}
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}

        />
      </div>
    </>
  );
};

export default FilteredPageList;
