import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Placeholder from 'sophi-design-system/src/components/Placeholder';
import styles from './BreakdownBar.scss';

const BreakdownBar = ({ loading, segments, selected, total, onSegmentMouseOver }) => {
  if (loading) {
    return <Placeholder style={{ height: '25px' }} />;
  }

  return (
    <div className={styles.breakdownCardBar}>
      {segments.map(({ value, color, label }) => (
        <div
          key={`${label}BarSegment`}
          className={classNames(
            styles.breakdownCardSegment,
            {
              [styles.breakdownCardSegmentNotSelected]: selected && selected !== label,
            }
          )}
  
          style={{
            width: `${(value / total * 100)}%`,
            backgroundColor: color
          }}
          onMouseEnter={() => onSegmentMouseOver(label)}
          onMouseLeave={() => onSegmentMouseOver(null)}
        />
      ))}
    </div>
  );
};

BreakdownBar.defaultProps = {
  segments: [],
  selected: null,
  total: 100,
  onSegmentMouseOver: () => null,
};

BreakdownBar.propTypes = {
  onSegmentMouseOver: PropTypes.func,
  total: PropTypes.number,
  selected: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default BreakdownBar;
