// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-ikXuSklNHdrObSzZVUiE{width:100%;height:auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:1.25rem}.sophi-ikXuSklNHdrObSzZVUiE p{margin:0;text-align:center;font-size:.8125rem;color:var(--grays-800)}.sophi-ikXuSklNHdrObSzZVUiE a{color:var(--blues-800);text-decoration:underline;-webkit-text-decoration-skip:ink;text-decoration-skip-ink:auto}", "",{"version":3,"sources":["webpack://./src/atoms/Footer/Footer.styles.scss"],"names":[],"mappings":"AAAA,4BACE,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,eAAA,CAEA,8BACE,QAAA,CACA,iBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,8BACE,sBAAA,CACA,yBAAA,CACA,gCAAA,CAAA,6BAAA","sourcesContent":[".footer {\n  width: 100%;\n  height: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1.25rem;\n\n  & p {\n    margin: 0;\n    text-align: center;\n    font-size: 0.8125rem;\n    color: var(--grays-800);\n  }\n\n  & a {\n    color: var(--blues-800);\n    text-decoration: underline;\n    text-decoration-skip-ink: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "sophi-ikXuSklNHdrObSzZVUiE"
};
export default ___CSS_LOADER_EXPORT___;
