// Root
import React, { useState, useEffect } from 'react';
import isWithinInterval from 'date-fns/isWithinInterval';
import addDays from 'date-fns/addDays';

// Atoms
import Banner from 'atoms/Banner';

// Utils
import { setInStorage, getFromStorage } from 'utils/storage';

// Global
const STORE_KEY = 'privacy-notice';
const now = new Date();

const PrivacyPolicyNotice = () => {
  const [dismissed, setDismissed] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isWithinInterval(now, {
      start: new Date('4 March 2020 00:00:00'),
      end: addDays(new Date('4 March 2020 00:00:00'), 15)
    })) {
      setShouldRender(!getFromStorage(STORE_KEY));
      setDismissed(getFromStorage(STORE_KEY));
    }
  }, [now]);

  const handleDismiss = (bool) => {
    setDismissed(bool);
    setInStorage(STORE_KEY, bool);
  };

  if (shouldRender) {
    return (
      <Banner dismissed={dismissed} accept={'Accept'} action={(e) => handleDismiss(true)}>
        Our privacy policy was updated as of March 4, 2020 to reflect new services and provide you with more information on how we use, collect and share your personal&nbsp;information. <br /><a target="_blank" rel="noopener" href="https://www.theglobeandmail.com/privacy-terms/privacy-policy/">Visit the privacy policy</a> for a summary of changes and to learn&nbsp;more.
    </Banner>
    );
  }

  return null;

};

export default PrivacyPolicyNotice;