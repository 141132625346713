// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-sAGcdvpar0LEhuEqP2mr{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap}.sophi-lo_ji9HNsHRwPlYsEs5Y{-ms-flex-preferred-size:60px;flex-basis:60px;min-width:60px;max-width:70px;padding-right:10px}.sophi-D6bkuWowgWZzCByFqPv4{-ms-flex-preferred-size:200px;flex-basis:200px;min-width:200px;max-width:300px;padding-right:15px}.sophi-FHSEhnmnCniSuQdYz95a{-ms-flex-preferred-size:175px;flex-basis:175px;-ms-flex-negative:0;flex-shrink:0;-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/WeightInput/WeightInput.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAAA,cAAA,CAGF,4BACE,4BAAA,CAAA,eAAA,CACA,cAAA,CACA,cAAA,CACA,kBAAA,CAEF,4BACE,6BAAA,CAAA,gBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAEF,4BACE,6BAAA,CAAA,gBAAA,CACA,mBAAA,CAAA,aAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA","sourcesContent":[".sliderInput {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.gauge {\n  flex-basis: 60px;\n  min-width: 60px;\n  max-width: 70px;\n  padding-right: 10px;\n}\n.slider {\n  flex-basis: 200px;\n  min-width: 200px;\n  max-width: 300px;\n  padding-right: 15px;\n}\n.input {\n  flex-basis: 175px;\n  flex-shrink: 0;\n  flex-grow: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderInput": "sophi-sAGcdvpar0LEhuEqP2mr",
	"gauge": "sophi-lo_ji9HNsHRwPlYsEs5Y",
	"slider": "sophi-D6bkuWowgWZzCByFqPv4",
	"input": "sophi-FHSEhnmnCniSuQdYz95a"
};
export default ___CSS_LOADER_EXPORT___;
