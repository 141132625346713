import React from 'react';
import FilterPanel from './FilterPanel';
import { KEY_CODES } from 'sophi-design-system/src/utils/constants';
import styles from './FilterModal.styles.scss';

const VerticalFilterTabs = ({ filterList, pendingFilters, options, selectedFilter, config, scoreFilterSet, onTabClick, onChange }) => {
  const selectedFilterLoading = options?.[selectedFilter]?.loading;
  const selectedFilterType = config?.[selectedFilter]?.type || '';

  const handleTabClick = ({ target }) => {
    const filter = target.getAttribute('data-filter');
    onTabClick(filter);
  };

  return (
    <>
      <div className={styles.modalRail} data-testid="vertical-filter-tabs" >
        {filterList.map((filter, i) => (
          <button
            id={`${filter}-tab`}
            role="tab"
            aria-selected={filter === selectedFilter}
            key={filter}
            onClick={handleTabClick}
            onKeyUp={(e) => {
              if (e.keyCode === KEY_CODES.DOWN) {
                const nextTab = filterList[i + 1] || filterList[0];
                document.getElementById(`${nextTab}-tab`).focus();
              } else if (e.keyCode === KEY_CODES.UP) {
                const nextTab = filterList[i - 1] || filterList[filterList.length -1];
                document.getElementById(`${nextTab}-tab`).focus();
              }
            }}
            data-filter={filter}
            data-selected={`${filter === selectedFilter}`}
            tabIndex={filter === selectedFilter ? 0 : -1}
          >
            {filter === 'score'
              ? `${config[filter]?.label || filter} ${scoreFilterSet ? '(1)' : ''}`
              : `${config[filter]?.label || filter} ${(pendingFilters?.[filter] && pendingFilters?.[filter].length) ? `(${pendingFilters?.[filter].length})` : ''}`
            }
          </button>
        ))}
      </div>
      <div className={styles.modalContents}>
        {!selectedFilterType && <div className={styles.modalPlaceholder}>Select a filter to get started</div>}
        <FilterPanel
          loading={selectedFilterLoading}
          type={selectedFilterType}
          selected={pendingFilters?.[selectedFilter] || []}
          id={selectedFilter}
          onChange={onChange}
          data={options?.[selectedFilter]?.data}
        />
      </div>
    </>
  );
};

export default VerticalFilterTabs;
