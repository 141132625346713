// Action types
import types from '../actions/diveTypes';
import nowTypes from '../actions/overviewTypes';
const initialState = {
  downloads: []
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DIVE_AUTHORS_REPORT_LOADING:
    case types.FETCH_DIVE_ARTICLES_REPORT_LOADING:
    case nowTypes.OVERVIEW_FETCH_REPORT_LOADING: {
      return {
        ...state,
        downloads: [
          ...state.downloads,
          action.downloadMeta
        ]
      };
    }
    case types.FETCH_DIVE_AUTHORS_REPORT_SUCCESS:
    case types.FETCH_DIVE_ARTICLES_REPORT_SUCCESS:
    case nowTypes.OVERVIEW_FETCH_REPORT_SUCCESS: {
      const downloadIndex = state.downloads.findIndex((download) => download.hash === action.hash);
      if (downloadIndex < 0) return state;

      const updatedDownloads = [...state.downloads];
      updatedDownloads[downloadIndex].loading = false;
      updatedDownloads[downloadIndex].complete = true;
      
      return {
        ...state,
        downloads: updatedDownloads
      };
    }
    case types.FETCH_DIVE_AUTHORS_REPORT_CLEAR:
    case types.FETCH_DIVE_ARTICLES_REPORT_CLEAR:
    case nowTypes.OVERVIEW_FETCH_REPORT_CLEAR: {
      const downloadIndex = state.downloads.findIndex((download) => download.hash === action.hash);
      if (downloadIndex < 0) return state;

      const updatedDownloads = [...state.downloads];
      updatedDownloads.splice(downloadIndex, 1);
      
      return {
        ...state,
        downloads: updatedDownloads
      };
    }
    case types.FETCH_DIVE_AUTHORS_REPORT_ERROR:
    case types.FETCH_DIVE_ARTICLES_REPORT_ERROR:
    case nowTypes.OVERVIEW_FETCH_REPORT_ERROR: {
      const downloadIndex = state.downloads.findIndex((download) => download.hash === action.hash);
      if (downloadIndex < 0) return state;

      const updatedDownloads = [...state.downloads];
      updatedDownloads[downloadIndex].loading = false;
      updatedDownloads[downloadIndex].error = true;
      
      return {
        ...state,
        downloads: updatedDownloads
      };
    }  
    default: {
      return state;
    }
  }
}
