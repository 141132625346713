import { HEADER_UPDATE } from 'actions/header';

const initialState = {
  title: ''
};

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case HEADER_UPDATE: {
      return {
        ...state,
        title: action.title
      };
    }
    default: {
      return state;
    }
  }
};