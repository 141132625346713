"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// @ts-ignore
const storage_1 = require("../../../utils/storage");
const useLocalStored = ({ key, initialValue }) => {
    const [stored, setStored] = (0, react_1.useState)(() => {
        return (0, storage_1.getFromStorage)(key) || initialValue;
    });
    const store = (value) => {
        setStored(value);
        (0, storage_1.setInStorage)(key, value);
    };
    return [
        stored,
        store
    ];
};
exports.default = useLocalStored;
