// Lodash
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

// Date-fns
import isAfter from 'date-fns/isAfter';

// Utils
import { convertToSeconds, convertSecondsToDate } from 'utils/dateTime';
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';
import { trimChartData } from 'utils/chart';

/**
 * API call for Score Performance Chart
 * @param {String} id - String representing the contentID
 * @param {Object} dateRange - Object with start and end time
 */
const getPerformanceData = (host, client, id, token, dateRange, timezone) => {
  if (paramsAreTruthy([id, dateRange])) {
    const { startDate, endDate } = dateRange;
    const now = new Date();
    const end = isAfter(endDate, now) ? now : endDate;

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `score-analysis/performance?start=${convertToSeconds(startDate)}&end=${convertToSeconds(end)}`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const getUniqComponents = (data) => {
      const allComponents = [];
      data.forEach((d) => {
        const components = uniqBy(d['components'], 'component');
        components.forEach((c) => {
          allComponents.push(c['component']);
        });
      });
      return uniq(allComponents);
    };

    const formatResponse = (response) => {
      if (Object.keys(response).includes('noData')) return ({ noData: true });
      const formattedResponse = response;
      const allComponents = getUniqComponents(response);
      if (allComponents.length < 1) return ({ noData: true });
      const data = [];

      const trimmed = trimChartData(formattedResponse, 'value');
      if (trimmed.length === 0) return { noData: true };

      trimmed.forEach((d) => {
        const date = convertSecondsToDate(d.timestamp, timezone);
        const obj = {};
        allComponents.forEach((c) => {
          const r = d.components ? find(d.components, { component: c }) : null;
          obj[c] = r ? r.score : 0;
        });
        data.push({
          ...obj,
          date,
        });
      });
      return {
        response: {
          data,
        },
      };
    };
    return data;
  }
};

export default getPerformanceData;