import React, { useContext, useEffect, useState, useMemo } from 'react';
import intersection from 'lodash/intersection';
import Checkbox from 'sophi-design-system/src/components/Checkbox';
import { ReactComponent as ChevronRight } from 'assets/svg/icon-arrow-right.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/icon-arrow-down.svg';
import TreeNavigationContext from './TreeNavigationContext';
import styles from './TreeNavigation.styles.scss';
import 'wicg-inert';

const BranchOptions = ({ label, value, hasChildren, flattenedChildren, children, onChange, selected }) => {
  const [show, setShow] = useState(false);
  const { forceBranchesOpen, setForceBranchesOpen, forceBranchesClosed, setForceBranchesClosed } = useContext(TreeNavigationContext);
  
  useEffect(() => {
    // When force state is detected, toggle the branch
    if (forceBranchesOpen && !show) {
      setShow(true);
    } else if (forceBranchesClosed && show) {
      setShow(false);
    }
  }, [forceBranchesOpen, forceBranchesClosed]);
  
  const childValues = useMemo(() => hasChildren ? flattenedChildren.map(({ value}) => value) : [], [flattenedChildren]);
  
  let isSelected = selected.includes(value);
  let checkType = 'check';
  if (hasChildren || !isSelected) {
    const childSelected = intersection([...selected], childValues);
    if (childSelected) {
      isSelected = childSelected.length > 0;
      checkType = childSelected.length === childValues.length ? 'check' : 'dash';
    }
  }

  const handleChange = () => {
    const values = hasChildren ? childValues : [value];
    onChange(values, hasChildren);
  };

  const handleToggle = () => {
    // Remove any force states
    if (forceBranchesClosed) {
      setForceBranchesClosed(false);
    }
    if (forceBranchesOpen) {
      setForceBranchesOpen(false);
    }
    setShow(!show);
  };

  const id = hasChildren ? `${value}-toggle` : value;

  return (
    <li style={{ backgroundColor: show ? '#f5f5f5' : 'transparent'}}>
      <div className={styles.branchOptions}>
        {hasChildren && (
          <button
            tabIndex={0}
            className={styles.toggle}
            onClick={handleToggle}
            aria-label={`${show ? 'Collapse' : 'Expand'} ${label} section`}
          >
            {show ? <ChevronDown /> : <ChevronRight />}
          </button>
        )}
        <div className={styles.checkbox}>
          <Checkbox checked={isSelected} label={label} id={id} onChange={handleChange} type={checkType} />
        </div>
      </div>
      {show && (
        <div hidden={!show} inert={show ? null : 'true'} className={`${styles.branchOptionsChildren} ${show ? `${styles.open}` : ''}`}>
          {children}
        </div> 
      )}
    </li>
  );
};

export default BranchOptions;
