const types = {
  OVERVIEW_FETCH:                   'OVERVIEW_FETCH',
  OVERVIEW_FETCH_SUCCESS:           'OVERVIEW_FETCH_SUCCESS',
  OVERVIEW_FETCH_CACHE_FAIL:        'OVERVIEW_FETCH_CACHE_FAIL',
  OVERVIEW_FETCH_FAIL:              'OVERVIEW_FETCH_FAIL',
  OVERVIEW_TOGGLE_FILTERS:          'OVERVIEW_TOGGLE_FILTERS',
  OVERVIEW_FETCH_COUNT_SUCCESS:     'OVERVIEW_FETCH_COUNT_SUCCESS',
  OVERVIEW_FETCH_COUNT_FAIL:        'OVERVIEW_FETCH_COUNT_FAIL',
  OVERVIEW_FETCH_COUNT_CACHE_FAIL:  'OVERVIEW_FETCH_COUNT_CACHE_FAIL',

  OVERVIEW_FETCH_REPORT:        'OVERVIEW_FETCH_REPORT',
  OVERVIEW_FETCH_REPORT_LOADING:'OVERVIEW_FETCH_REPORT_LOADING',
  OVERVIEW_FETCH_REPORT_SUCCESS:'OVERVIEW_FETCH_REPORT_SUCCESS',
  OVERVIEW_FETCH_REPORT_CLEAR:  'OVERVIEW_FETCH_REPORT_CLEAR',
  OVERVIEW_FETCH_REPORT_ERROR:  'OVERVIEW_FETCH_REPORT_ERROR',

  SET_OVERVIEW_FILTERS:         'SET_OVERVIEW_FILTERS',
  OVERVIEW_FILTER_RESET:        'OVERVIEW_FILTER_RESET',
  OVERVIEW_FILTER_SET:          'OVERVIEW_FILTER_SET', // shallow set of the filters
  // Sections Options
  OVERVIEW_SECTIONS_FETCH:      'OVERVIEW_SECTIONS_FETCH',
  OVERVIEW_SECTIONS_SUCCESS:    'OVERVIEW_SECTIONS_SUCCESS',
  OVERVIEW_SECTIONS_FAIL:       'OVERVIEW_SECTIONS_FAIL',
  // Subtypes Options
  OVERVIEW_FILTER_SUBTYPES:   'OVERVIEW_FILTER_SUBTYPES',
  OVERVIEW_SUBTYPES_FETCH:    'OVERVIEW_SUBTYPES_FETCH',
  OVERVIEW_SUBTYPES_SUCCESS:  'OVERVIEW_SUBTYPES_SUCCESS',
  OVERVIEW_SUBTYPES_FAIL:     'OVERVIEW_SUBTYPES_FAIL',
  // Credit Line Options
  OVERVIEW_FILTER_CREDITLINE:   'OVERVIEW_FILTER_CREDITLINE',
  OVERVIEW_CREDITLINE_FETCH:    'OVERVIEW_CREDITLINE_FETCH',
  OVERVIEW_CREDITLINE_SUCCESS:  'OVERVIEW_CREDITLINE_SUCCESS',
  OVERVIEW_CREDITLINE_FAIL:     'OVERVIEW_CREDITLINE_FAIL',
  // Ownership Options
  OVERVIEW_FILTER_OWNERSHIP:    'OVERVIEW_FILTER_OWNERSHIP',
  OVERVIEW_OWNERSHIP_FETCH:     'OVERVIEW_OWNERSHIP_FETCH',
  OVERVIEW_OWNERSHIP_SUCCESS:   'OVERVIEW_OWNERSHIP_SUCCESS',
  OVERVIEW_OWNERSHIP_FAIL:      'OVERVIEW_OWNERSHIP_FAIL',
  // Type Options
  OVERVIEW_TYPE_FETCH:          'OVERVIEW_TYPE_FETCH',
  OVERVIEW_TYPE_SUCCESS:        'OVERVIEW_TYPE_SUCCESS',
  OVERVIEW_TYPE_FAIL:           'OVERVIEW_TYPE_FAIL',
  // Access Options
  OVERVIEW_ACCESS_FETCH:        'OVERVIEW_ACCESS_FETCH',
  OVERVIEW_ACCESS_SUCCESS:      'OVERVIEW_ACCESS_SUCCESS',
  OVERVIEW_ACCESS_FAIL:         'OVERVIEW_ACCESS_FAIL',
  // Date Range
  OVERVIEW_DATE_RANGE_SET:      'OVERVIEW_DATE_RANGE_SET',
  OVERVIEW_DATE_RANGE_MIN_MAX_SET:      'OVERVIEW_DATE_RANGE_MIN_MAX_SET',

  // Autosuggest
  OVERVIEW_AUTOSUGGEST:         'OVERVIEW_AUTOSUGGEST',
  OVERVIEW_AUTOSUGGEST_SUCCESS: 'OVERVIEW_AUTOSUGGEST_SUCCESS',
  CLEAR_OVERVIEW_AUTOSUGGEST:   'CLEAR_OVERVIEW_AUTOSUGGEST',
  // Add/Remove Columns
  OVERVIEW_COLS_SET:          'OVERVIEW_COLS_SET',
  // Filter Results
  OVERVIEW_FETCH_POTENTIAL_RESULTS:         'OVERVIEW_FETCH_POTENTIAL_RESULTS',
  OVERVIEW_FETCH_POTENTIAL_RESULTS_SUCCESS: 'OVERVIEW_FETCH_POTENTIAL_RESULTS_SUCCESS',
  OVERVIEW_FETCH_POTENTIAL_RESULTS_FAIL:    'OVERVIEW_FETCH_POTENTIAL_RESULTS_FAIL',
};

export default types;
