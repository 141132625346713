import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';

// Components
import NotFound from 'pages/NotFound';
import Forbidden from 'pages/Forbidden';
import PermissionDenied from 'pages/PermissionDenied';
import ErrorBoundary from 'molecules/ErrorBoundary';
import AppLoading from 'molecules/AppLoading';

// Helpers
import { useAuth0 } from 'utils/Auth0Provider';
import { setInStorage } from 'utils/storage';
import * as authSelectors from 'selectors/auth';

const ProtectedRoute = ({ component: Component, fallback: Fallback, isAvailable, path, requiredScope, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, scopes } = useAuth0(); // 🐴 From the horse's mouth
  const allowedOrganizations = useSelector(authSelectors.getAllowedOrganizations) || [];

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const autoLogin = async () => {
      setInStorage('lastRoute', window.location.pathname);
      await loginWithRedirect({});
    };
    autoLogin();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const hasScope = requiredScope ? (scopes || []).includes(requiredScope) : true;
  const hasDashboardPermission = allowedOrganizations.length > 0;

  return (
    <ErrorBoundary>
      <Route
        path={path}
        render={(props) => {
          if (isAuthenticated && !hasDashboardPermission) return <PermissionDenied />;
          if (isAuthenticated && isAvailable && hasScope) return <Component {...props} />;
          if (isAuthenticated && Fallback) return <Fallback />;
          if (isAuthenticated && !isAvailable ) return <NotFound />;
          if (isAuthenticated && !hasScope) return <Forbidden />;
          if (!isAuthenticated) return <AppLoading />;
          return null;
        }}
        {...rest}
      />
    </ErrorBoundary>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  fallback: PropTypes.func,
};

export default ProtectedRoute;
