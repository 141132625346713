// Root
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from 'atoms/Tabs';
import Tab from 'atoms/Tabs/Tab';
import TabHeader from 'atoms/Tabs/TabHeader';
import TabBody from 'atoms/Tabs/TabBody';
import BreakdownCard from 'atoms/BreakdownCard';
import TabPanels from './TabPanels';

// Utils
import useMedia from 'hooks/useMedia';
import formatBreakdownData from './formatBreakdownData';
import types from 'actions/homeTypes';
import { getPlatforms } from 'selectors/auth';
import { setPageTitle } from 'utils/paths';

import styles from './Live.styles.scss';

/**
 * Get the correct dataset for a given selected tab
 * @param {Object} data      Data keyed by key metric 
 * @param {String} selected   
 */
const getDisplayData = (data, selected) => {
  const dataKeys = {
    score: 'score',
    internal: 'internalTotal',
    search: 'searchTotal',
    social: 'socialTotal',
    app: 'appGlobeScore',
  };
  return data[dataKeys[selected]];
};

const Home = () => {
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState('score');
  // Only show the breakdown as a separate card for tablet size and up
  const showBreakdownTable = useMedia(['(min-width: 768px)'], [true], false);
  const tableData = useSelector((state) => state.home.tables);
  const platforms = useSelector(getPlatforms);
  const showApp = platforms.includes('app');

  useEffect(() => {
    setPageTitle('Home');
    dispatch({ type: types.HOMEPAGE_POLLING_START });
    return () => dispatch({ type: types.HOMEPAGE_POLLING_STOP });
  }, []);
 
  const selectedTabData = getDisplayData(tableData, openTab);
  const { breakdownData, total } = formatBreakdownData(selectedTabData?.noData ? null : selectedTabData);
  return (
    <div className={styles.container}>
      <h2 className={styles.homeHeader}>Top Ten Today</h2>
      <section className={styles.topStoriesSection}>
        <Tabs onChange={(id) => setOpenTab(id)}>
          <TabHeader label="Top Scores by Key Metric">
            <Tab panelId="score-tab" id="score">Overall</Tab>
            <Tab panelId="internal-tab" id="internal">Internal</Tab>
            <Tab panelId="search-tab" id="search">Search</Tab>
            <Tab panelId="social-tab" id="social">Social</Tab>
            {showApp && <Tab panelId="app-tab" id="app">App</Tab>}
          </TabHeader>
          <TabBody>
            <TabPanels
              id="score-tab"
              tabId="score"
              data={tableData.score}
              keyMetricName="Overall"
              keyMetricKey="score"
              showBreakdown={!showBreakdownTable}
              loading={tableData.loading}
            />
            <TabPanels
              id="internal-tab"
              tabId="internal"
              data={tableData.internalTotal}
              keyMetricName="Internal"
              keyMetricKey="internalTotal"
              showBreakdown={!showBreakdownTable}
              loading={tableData.loading}
            />
            <TabPanels
              id="search-tab"
              tabId="search"
              data={tableData.searchTotal}
              keyMetricName="Search"
              keyMetricKey="searchTotal"
              showBreakdown={!showBreakdownTable}
              loading={tableData.loading}
            />
            <TabPanels
              id="social-tab"
              tabId="social"
              data={tableData.socialTotal}
              keyMetricName="Social"
              keyMetricKey="socialTotal"
              showBreakdown={!showBreakdownTable}
              loading={tableData.loading}
            />
            {showApp && (
              <TabPanels
                id="app-tab"
                tabId="app"
                data={tableData.appGlobeScore}
                keyMetricName="App"
                keyMetricKey="appGlobeScore"
                showBreakdown={!showBreakdownTable}
                loading={tableData.loading}
              />
            )}
          </TabBody>
        </Tabs>
      </section>
      {showBreakdownTable && (
        <div className={styles.scoreBreakdownContainer}>
          <BreakdownCard
            title="Score Breakdown"
            segments={breakdownData}
            total={total}
            loading={tableData.loading && (!selectedTabData)}
          />
        </div>
      )}
    </div>
  );
};

export default Home;