import { queryString, pathBuilder, getResource } from 'utils/api';

/**
 *  Normalizes the fields that the API can understand
 * @param {string} field the field to pool auto suggestions from
 * @returns {string} the normalized API property from the given field
 */
function getSearchField(field) {
  const normalizeMap = {
    authors: 'bylines',
    headlines: 'headlines',
    keyword: 'keywords',
    creditLine: 'creditLines',
  };

  return normalizeMap[field] ? normalizeMap[field] : field;
}

export const getFilterAutosuggest = (host, client, token, { field, term, limit = 5 }) => {
  if (!field || !term) throw new Error('Missing required fields');

  const qs = { searchTerm: term, limit };

  const path = pathBuilder(host, {
    client,
    query: `filters/${getSearchField(field)}?${queryString(qs)}`
  });

  return getResource({
    endpoint: path,
    token
  })
    .then(({ data }) => ({ response: data }));
};
