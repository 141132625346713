import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styles from './Typography.styles.scss';

const BodyText = ({ tag, className, ...props }) => {
  const bodyTextProps = {
    ...props,
    className: `${styles.body} ${className || ''}`,
  };

  return <Base tag={tag} {...bodyTextProps} />;
};

BodyText.defaultProps = {
  tag: 'p',
  className: '',
};

BodyText.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  className: PropTypes.string,
};

export default BodyText;
