import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { isDefined } from 'utils/objects';

const TAG = `[SOPHI]`;

/**
 * Will produce a URL in the shape: host/version/section/client/contentId/query
 * @param {String} host - The API host (Required)
 * @param {String} Object.version - The version of the API (Optional)
 * @param {String} Object.section - The section of the API (Optional)
 * @param {String} Object.client - The client of the API (Optional)
 * @param {String} Object.contentId - The contentId of the API (Optional)
 * @param {String} Object.query - Everything else that needs to be in the api
 */
export const pathBuilder = (host, { version, section, client, contentId, query }) => {
  // Order is important here
  let endpoint = host;
  if (version) {
    endpoint = `${endpoint}/${version}`;
  }
  if (section) {
    endpoint = `${endpoint}/${section}`;
  }
  if (client) {
    endpoint = `${endpoint}/${client}`;
  }
  if (contentId) {
    endpoint = `${endpoint}/${contentId}`;
  }
  if (query) {
    endpoint = `${endpoint}/${query}`;
  }
  return endpoint;
};


/**
 * Method to access an API
 * @param {String} Object.method='GET' - Valid for 'POST' (Optional)
 * @param {String} Object.endpoint - The URL requested (Required)
 * @param {String} Object.token - Access token (Required)
 * @param {Object} Object.header - Any valid header parameter (Optional)
 * @param {Object} Object.body - Any valid body parameter (Optional)
 * @param {Object} Object.[signal] - Abort controller (Optional)
 */
export const getResource = async ({ method, endpoint, token, header, body, signal, noToken }) => {
  return new Promise((resolve, reject) => {
    if (!token && !noToken) {
      reject(`Request does not contain an access token`);
      return false;
    }

    const headers = {
      Authorization: 'Bearer ' + token,
      ...header,
    };

    const fetchParams = {};
    method ? fetchParams.method = method : fetchParams.method = 'GET';
    headers && !noToken ? fetchParams.headers = headers : null;
    body ? fetchParams.body = body : null;
    signal ? fetchParams.signal = signal : null;

    fetch(endpoint, fetchParams)
      .then((response) => {
        switch (response.status) {
          case 200: {
            resolve(response.json());
          }
            break;
          case 204: {
            resolve({ noData: true });
          }
            break;
          case 401: {
            resolve({ noAccess: true });
          }
            break;
          case 404:
            reject(`${TAG} \n Content not found at: ${endpoint}`);
            break;
          case 500:
            reject(`${TAG} \n Internal server error at endpoint: ${endpoint}`);
            break;
          case 503:
            reject(`${TAG} \n Service unavailable: ${endpoint}`);
            break;
          default:
            reject(`${TAG} \n Failed to fetch from: ${endpoint}`);
        }
      })
      .catch((error) => reject(error));
  });
};

/**
 * Helper function that handles making an API request and returns the appropriate response
 * @param {string} path - The API you wish to fetch from
 */
export const fetchFromApi = async (path) => {
  const response = await fetch(path);
  if (response.ok) {
    switch (response.status) {
      case 200: {
        const data = await response.json();
        return data;
      }
      case 204: {
        const data = { noData: true };
        return data;
      }
    }
  }
  if (!response.ok) {
    switch (response.status) {
      case 404:
        console.error(`${TAG} \n Content not found at: ${path}`);
        break;
      case 500:
        console.error(`${TAG} \n Internal server error at endpoint: ${path}`);
        break;
      case 503:
        console.error(`${TAG} \n Service unavailable: ${path}`);
        break;
      default:
        console.error(`${TAG} \n Failed to fetch from: ${path}`);
    }
    throw new Error(response.status);
  }
};

/**
 * Helper function that handles making an API request and returns the appropriate response
 * @param {string} path - The API you wish to fetch from
 * @param {object} body - The body for your request
 * @param {object} [controllerSignal] - (Optional) Abort controller
 */
export const fetchFromApiViaPost = async (path, body, controllerSignal, encodedHeader) => {
  const signal = controllerSignal ? controllerSignal : null;
  const config = {
    method: 'POST',
    body,
    signal,
  };

  if (encodedHeader) {
    config.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  };

  const response = await fetch(path, config);

  if (response.ok) {
    switch (response.status) {
      case 200: {
        const data = await response.json();
        return data;
      }
      case 204: {
        const data = { noData: true };
        return data;
      }
    }
  }

  if (!response.ok) {
    switch (response.status) {
      case 400:
        console.error(`${TAG} \n Bad Request: ${path}`);
        break;
      case 404:
        console.error(`${TAG} \n Content not found at: ${path}`);
        break;
      case 500:
        console.error(`${TAG} \n Internal server error at endpoint: ${path}`);
        break;
      case 503:
        console.error(`${TAG} \n Service unavailable: ${path}`);
        break;
      default:
        console.error(`${TAG} \n UNKNOWN: ${path}`);
    }
    throw new Error(response.status);
  }
};

/**
 * Helper function to get all the platforms from an API response
 * @param {array} rows - Array of objects representing rows of a table
 */
export const getDevices = (rows) => {
  const allDevices = [];
  // Get all  possible devices
  rows.forEach((row) => {
    const rowPlatforms = uniqBy(row['platforms'], 'platform');
    rowPlatforms.forEach((p) => {
      allDevices.push(p['platform']);
    });
  });
  // Return only the unique ones
  return uniq(allDevices);
};

export const getUniqByKey = (rows, key) => {
  const allValues = [];
  rows.forEach((row) => {
    allValues.push(row[key]);
  });
  return uniq(allValues);
};

/**
 * Helper function that will return any matching objects from an array objects
 * @param {array} haystack - The array of objects to be searched
 * @param {object} needle - The keys in form of an object to be matched against
 */
export const getMatchingObject = (haystack, needle) => find(haystack, needle);

/**
 * Helper function that tests that all method params are truthy
 * @param {Array} args - Array of argument values to be tested
 */
export const paramsAreTruthy = (args) => {
  let value = true;
  args.forEach((arg, index) => {
    if (isNil(arg) === true) {
      console.error(`Argument ${index + 1} is either null, undefined`);
      value = false;
    }
  });
  return value;
};

export const queryString = (params) => (
  Object.keys(params)
    .reduce((list, key) => {
      if (isDefined(params[key])) {
        return list.concat(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
      return list;
    }, [])
    .join('&')
);

/**
 * Create FormData object to send as body of table data request with filters
 * @param {Object} opts    Request options
 * @param {Object} params  Request Filters
 * @returns {FormData}
 */
export const formatTableRequestBody = (opts, params) => {
  let body = new FormData();
  if (opts) {
    const columns = opts.displayColumns;
    columns.length && body.append('columns', JSON.stringify(columns));
  }

  if (params) {
    const bylines = params.authors || [];
    const headlines = params.headlines || [];
    const keywords = params.keywords || [];
    const sections = params.sections || [];
    const ownership = params.ownership || [];
    const contentTypes = params.type || [];
    const access = params.access || [];
    const score = params.score || [];
    const creditLines = params.creditLine || [];
    const subtypes = params.subtypes || [];

    body.append('bylines', JSON.stringify(bylines));
    body.append('headlines', JSON.stringify(headlines));
    body.append('keywords', JSON.stringify(keywords));
    body.append('subsections', JSON.stringify(sections));
    body.append('ownerships', JSON.stringify(ownership));
    body.append('creditLines', JSON.stringify(creditLines));
    body.append('subtypes', JSON.stringify(subtypes));

    if (score.length) {
      // make sure the score min/max are always Integers
      body.append('scoreMin', JSON.stringify(parseInt(score[0])));
      // Set max to max + 0.999999 to capture all values in the given range
      // If max of int 12 is provided, BE will only return scores up to 12.000000
      body.append('scoreMax', JSON.stringify(parseInt(score[1]) + 0.999999));
    }

    body.append('contentTypes', JSON.stringify(contentTypes));
    body.append('accesses', JSON.stringify(access));
  }
  return body;
};


export const formatObjectToRequestBody = (obj) => {
  const formatValue = (value) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      // If array of arrays
      if (Array.isArray(value[0])) {
        return `[${value.map((v) => `[${v.map((w) => `"${w}"`)}]`)}]`;
      }
      return `[${value.map((v) => `"${v}"`)}]`;
    } else {
      return `${value}`;
    }
  };
  return Object.keys(obj).map((k) => `${k}=${formatValue(obj[k])}`).join('&');
};
