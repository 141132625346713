import { matchPath } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from 'utils/paths';

export const getSearch = (state) => state.router.location.search;
export const getPathname = (state) => state.router.location.pathname;
export const getRouteName = (state) => {
  const { pathname } = state?.router?.location || {};
  return Object.keys(ROUTE_NAMES).find((route) => {
    const match = matchPath(pathname, { path: getRoute(route), exact: true });
    return !!(match);
  });
};
