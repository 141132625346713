import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styles from './Typography.styles.scss';

const Subtitle = ({ className, tag, ...props }) => {
  const subtitleProps = {
    ...props,
    className: `${styles.subtitle} ${className || ''}`
  };

  return <Base tag={tag} {...subtitleProps} />;
};

Subtitle.defaultProps = {
  tag: 'p',
  className: '',
};

Subtitle.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  className: PropTypes.string,
};

export default Subtitle;
