// Action types
import types from '../actions/diveTypes';

// Utils
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import uniq from 'lodash/uniq';

import { getFromStorage, setInStorage, removeFromStorage } from 'utils/storage';
import { getDiveRange } from 'utils/relativeDates';
import { DIVE_SECTIONS_CONFIG, DIVE_AUTHORS_CONFIG, DIVE_ARTICLES_CONFIG } from 'utils/table';

const { dive_date_range, dive_date_preset } = getFromStorage('dive_config') || {};

const getDefaultDateRange = () => {
  // Set custom range if available
  if (dive_date_range) {
    return {
      startDate: new Date(dive_date_range.startDate),
      endDate: new Date(dive_date_range.endDate),
    };
  }

  // Set relative range if preset is available
  if (dive_date_preset && dive_date_preset !== 'Custom') {
    return getDiveRange(dive_date_preset);
  }
  // Only process custom preset if range is also in storage
  if (dive_date_preset === 'Custom' && dive_date_range) {
    return getDiveRange(dive_date_preset);
  }

  // Default to last 30 days (from 7 days start)
  return {
    startDate: startOfDay(subDays(new Date(), 37)),
    endDate: endOfDay(subDays(new Date(), 7)),
  };
};

// Copy previous list of selected columns to new per page selected columns lists
const convertLegacyStorage = () => {
  const diveColumns = getFromStorage('dive-columns');

  if (diveColumns && !getFromStorage('dive-sections-columns')) {
    setInStorage('dive-sections-columns', diveColumns);
  }

  if (diveColumns && !getFromStorage('dive-articles-columns')) {
    setInStorage('dive-articles-columns', diveColumns);
  }

  if (diveColumns && !getFromStorage('dive-authors-columns')) {
    setInStorage('dive-authors-columns', diveColumns);
  }

  removeFromStorage('dive-columns');
};

const legacyColumnLookup = {
  section: 'sectionName',
  access: 'accessCategory',
  subscriptions: 'adjustedSubscriptionCount',
  wallEncounters: 'wallEventCountTotal',
  timeSpent: 'medianTimeSpent',
  recirculation: 'recirculationRatio',

  // Renamed dive sections columns (SAN-3755)
  visitCountInternal: 'readerFocusedInternal',
  visitCountDesktopInternal: 'readerFocusedDesktopInternal',
  visitCountTabletInternal: 'readerFocusedTabletInternal',
  visitCountMobileInternal: 'readerFocusedMobileInternal',
  visitCountAppInternal: 'readerFocusedAppInternal',
  visitCountDirect: 'readerFocusedDirect',
  visitCountDesktopDirect: 'readerFocusedDesktopDirect',
  visitCountTabletDirect: 'readerFocusedTabletDirect',
  visitCountMobileDirect: 'readerFocusedMobileDirect',
  visitCountAppDirect: 'readerFocusedAppDirect',
  visitCountEmail: 'readerFocusedEmail',
  visitCountDesktopEmail: 'readerFocusedDesktopEmail',
  visitCountTabletEmail: 'readerFocusedTabletEmail',
  visitCountMobileEmail: 'readerFocusedMobileEmail',
  visitCountNewsletter: 'readerFocusedNewsletter',
  visitCountDesktopNewsletter: 'readerFocusedDesktopNewsletter',
  visitCountTabletNewsletter: 'readerFocusedTabletNewsletter',
  visitCountMobileNewsletter: 'readerFocusedMobileNewsletter',
  visitCountAffiliate: 'readerFocusedAffiliate',
  visitCountDesktopAffiliate: 'readerFocusedDesktopAffiliate',
  visitCountTabletAffiliate: 'readerFocusedTabletAffiliate',
  visitCountMobileAffiliate: 'readerFocusedMobileAffiliate',
  visitCountSearch: 'readerFocusedSearch',
  visitCountDesktopSearch: 'readerFocusedDesktopSearch',
  visitCountTabletSearch: 'readerFocusedTabletSearch',
  visitCountMobileSearch: 'readerFocusedMobileSearch',
  visitCountSocial: 'readerFocusedSocial',
  visitCountDesktopSocial: 'readerFocusedDesktopSocial',
  visitCountTabletSocial: 'readerFocusedTabletSocial',
  visitCountMobileSocial: 'readerFocusedMobileSocial',
};

convertLegacyStorage();

export const generateInitialState = () => {
  // If there are columns in storage, use them as the default columns for this session
  // If the list is empty, use the above defaults as something has gone very wrong (should always have values)

  // Sections Columns
  let diveSectionColumns = DIVE_SECTIONS_CONFIG.default;
  const savedSectionColumns = getFromStorage('dive-sections-columns');
  if (savedSectionColumns && Array.isArray(savedSectionColumns) && savedSectionColumns.length) {
    const savedCols = savedSectionColumns.map((col) => {
      return legacyColumnLookup[col] || col;
    });
    diveSectionColumns = uniq(DIVE_SECTIONS_CONFIG.required.concat(savedCols));
  }

  // Articles Columns
  let diveArticlesColumns = DIVE_ARTICLES_CONFIG.default;
  const savedArticlesColumns = getFromStorage('dive-articles-columns');
  if (savedArticlesColumns && Array.isArray(savedArticlesColumns) && savedArticlesColumns.length) {
    const savedCols = savedArticlesColumns.map((col) => {
      return legacyColumnLookup[col] || col;
    });
    diveArticlesColumns = uniq(DIVE_ARTICLES_CONFIG.required.concat(savedCols));
  }

  // Authors Columns
  let diveAuthorsColumns = DIVE_AUTHORS_CONFIG.default;
  const savedAuthorsColumns = getFromStorage('dive-authors-columns');
  if (savedAuthorsColumns && Array.isArray(savedAuthorsColumns) && savedAuthorsColumns.length) {
    const savedCols = savedAuthorsColumns.map((col) => {
      return legacyColumnLookup[col] || col;
    });
    diveAuthorsColumns = uniq(DIVE_AUTHORS_CONFIG.required.concat(savedCols));
  }

  const defaultRange = getDefaultDateRange();

  return {
    // TODO: These values should be replaced in SOPHI-1649
    global: {
      dateRange: {
        dateSet: false,
        downloadDisabled: differenceInDays(defaultRange.endDate, defaultRange.startDate) > 30,
        ...defaultRange,
      },
      filters: {},
      filterOptions: {
        getDataFor: null,
        loading: false,
        error: false,
        validOptions: {
          access: { loading: false },
          ownership: { loading: false },
          sections: { loading: false },
          type: { loading: false },
        },
      },
      showRunSearch: false,
    },
    authors: {
      selectedColumns: diveAuthorsColumns,
      table: {
        rows: null,
        columns: null,
        loading: false,
        error: false,
        page: 0,
        size: 10,
        sortConfig: { sort: 'articles', sortOrder: 'desc' },
      },
      count: 100,
    },
    articles: {
      selectedColumns: diveArticlesColumns,
      table: {
        rows: null,
        columns: null,
        loading: false,
        error: false,
        page: 0,
        size: 10,
        sortConfig: { sort: 'sophiScore', sortOrder: 'desc' },
      },
      count: 0,
    },
    dashboard: {
      articles: {
        rows: null,
        columns: null,
        loading: false,
        error: false,
      },
      authors: {
        rows: null,
        columns: null,
        loading: false,
        error: false,
      },
      comparisonChart: {
        data: null,
        loading: false,
        error: false,
        noData: false,
      },
    },
    sections: {
      selectedColumns: diveSectionColumns,
      status: {
        loading: false,
        error: false,
      },
      table: {
        columns: null,
        rows: null,
        lastFetched: null,
      },
      sortConfig: { column: 'section', direction: 'desc' },
    }
  };
};

export const initialState = generateInitialState();

export default function diveReducer(state = initialState, action) {
  switch (action.type) {
    case types.DIVE_DATE_RANGE_SET: {
      return {
        ...state,
        global: {
          ...state.global,
          dateRange: {
            dateSet: true,
            downloadDisabled: differenceInDays(action.dateObject.endDate, action.dateObject.startDate) > 30,
            ...action.dateObject,
          },
          showRunSearch: true,
        }
      };
    }
    // Dashboard > Top Articles
    case types.FETCH_TOP_ARTICLES: {
      return {
        ...state,
        global: {
          ...state.global,
          showRunSearch: false,
        },
        dashboard: {
          ...state.dashboard,
          articles: {
            ...state.dashboard.articles,
            loading: true,
          }
        }
      };
    }
    case types.FETCH_TOP_ARTICLES_SUCCESS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          articles: {
            ...state.dashboard.articles,
            rows: action.response.data,
            columns: action.response.columns,
            loading: false,
            error: false,
          }
        }
      };
    }
    case types.FETCH_TOP_ARTICLES_FAIL: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          articles: {
            ...state.dashboard.articles,
            rows: null,
            columns: null,
            loading: false,
            error: true,
          }
        }
      };
    }
    // Dashboard > Top Authors
    case types.FETCH_TOP_AUTHORS: {
      return {
        ...state,
        global: {
          ...state.global,
          showRunSearch: false,
        },
        dashboard: {
          ...state.dashboard,
          authors: {
            ...state.dashboard.authors,
            loading: true,
          }
        }
      };
    }
    case types.FETCH_TOP_AUTHORS_SUCCESS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          authors: {
            ...state.dashboard.authors,
            rows: action.response.data,
            loading: false,
            error: false,
          }
        }
      };
    }
    case types.FETCH_TOP_AUTHORS_FAIL: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          authors: {
            ...state.dashboard.authors,
            rows: null,
            columns: null,
            loading: false,
            error: true,
          }
        }
      };
    }
    case types.DIVE_COMPARISON_CHART: {
      return {
        ...state,
        global: {
          ...state.global,
          showRunSearch: false,
        },
        dashboard: {
          ...state.dashboard,
          comparisonChart: {
            data: [],
            loading: true,
            error: false,
            noData: false,
          }
        },
      };
    }
    case types.DIVE_COMPARISON_CHART_SUCCESS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          comparisonChart: {
            ...state.dashboard.comparisonChart,
            data: action.data,
            loading: false,
            noData: !action.data || action.data.length === 0
          }
        },
      };
    }
    case types.DIVE_COMPARISON_CHART_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          comparisonChart: {
            ...state.dashboard.comparisonChart,
            loading: false,
            error: true,
          }
        },
      };
    }
    // Called to clear dashboard values on unmount
    case types.RESET_DASHBOARD_VALUES: {
      return {
        ...state,
        dashboard: {
          articles: {
            rows: null,
            columns: null,
            loading: false,
            error: false,
          },
          authors: {
            rows: null,
            columns: null,
            loading: false,
            error: false,
          },
          comparisonChart: {
            data: null,
            loading: false,
            error: false,
            noData: false,
          },
        },
      };
    }
    case types.DIVE_SET_PREVIOUS_FILTERS: {
      return {
        ...state,
        global: {
          ...state.global,
          filters: action.data,
        }
      };
    }
    case types.DIVE_FILTER_OPTIONS_FETCH: {
      const updatedOptions = Object.keys(state.global.filterOptions.validOptions)
        .reduce((lu, key) => ({ ...lu, [key]: { loading: true }}), {});

      return {
        ...state,
        global: {
          ...state.global,
          filterOptions: {
            getDataFor: action.filter,
            error: false,
            validOptions: updatedOptions,
          }
        }
      };
    }
    case types.DIVE_FILTER_OPTIONS_SUCCESS: {
      const { filterToFetch: filter, response } = action;
      return {
        ...state,
        global: {
          ...state.global,
          filterOptions: {
            error: false,
            validOptions: response
          }
        }
      };
    }
    case types.DIVE_FILTER_OPTIONS_FAIL: {
      const { error } = action;
      return {
        ...state,
        global: {
          ...state.global,
          filterOptions: {
            loading: false,
            error: error.message,
            validOptions: {},
          }
        }
      };
    }
    case types.DIVE_FILTER_SET: {
      return {
        ...state,
        global: {
          ...state.global,
          filters: action.data,
          showRunSearch: true,
        }
      };
    }
    case types.FILTER_RESET: {
      const { filter = [] } = action;
      // Reset all filters
      const allFilters = {};
      filter.forEach((f) => {
        allFilters[f] = [];
      });
      return {
        ...state,
        global: {
          ...state.global,
          filters: {
            ...state.global.filters,
            ...allFilters
          },
          showRunSearch: true,
        }
      };
    }
    case types.DIVE_REMOVE_APPLIED_FILTERS: {
      const resetFilters = { ...state.global.filters };
      Object.keys(resetFilters).forEach((k) => {
        resetFilters[k] = [];
      });
      return {
        ...state,
        global: {
          ...state.global,
          filters: {
            ...state.global.filters,
            ...resetFilters
          },
          showRunSearch: false,
        }
      };
    }
    case types.DIVE_FILTER_AUTOSUGGEST_SUCCESS: {
      const { response } = action.data;
      return {
        ...state,
        global: {
          ...state.global,
          filterOptions: {
            ...state.global.filterOptions,
            autosuggest: response,
          }
        }
      };
    }
    case types.CLEAR_DIVE_AUTOSUGGEST: {
      return {
        ...state,
        global: {
          ...state.global,
          filterOptions: {
            ...state.global.filterOptions,
            autosuggest: [],
          }
        }
      };
    }
    // Authors
    case types.FETCH_DIVE_AUTHORS: {
      return {
        ...state,
        global: {
          ...state.global,
          showRunSearch: false,
        },
        authors: {
          ...state.authors,
          table: {
            ...state.authors.table,
            loading: true,
          }
        }
      };
    }
    case types.FETCH_DIVE_AUTHORS_SUCCESS: {
      return {
        ...state,
        authors: {
          ...state.authors,
          table: {
            ...state.authors.table,
            ...action.updatedConfig,
            rows: action.response.data,
            columns: action.response.columns,
            loading: false,
            error: false,
          },
          count: action.response.count
        }
      };
    }
    case types.FETCH_DIVE_AUTHORS_FAIL: {
      return {
        ...state,
        authors: {
          ...state.authors,
          table: {
            ...state.authors.table,
            rows: null,
            loading: false,
            error: true,
          }
        }
      };
    }
    // Articles
    case types.FETCH_DIVE_AUTHOR_ARTICLES:
    case types.FETCH_DIVE_ARTICLES:
    case types.UPDATE_DIVE_ARTICLES_SORT:
    case types.UPDATE_DIVE_ARTICLES_PAGINATION: {
      return {
        ...state,
        global: {
          ...state.global,
          showRunSearch: false,
        },
        articles: {
          ...state.articles,
          table: {
            ...state.articles.table,
            loading: true,
          }
        }
      };
    }
    case types.FETCH_DIVE_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          count: action.response.count,
          table: {
            ...state.articles.table,
            ...action.updatedConfig,
            rows: action.response.data,
            columns: action.response.columns,
            loading: false,
            error: false,
          }
        }
      };
    }
    case types.FETCH_DIVE_ARTICLES_FAIL: {
      return {
        ...state,
        articles: {
          ...state.articles,
          table: {
            ...state.articles.table,
            rows: null,
            columns: null,
            loading: false,
            error: true,
          }
        }
      };
    }
    case types.UPDATE_DIVE_ARTICLE_COUNT: {
      return {
        ...state,
        articles: {
          ...state.articles,
          count: action.count,
        },
      };
    }
    // Sections
    case types.FETCH_SECTIONS: {
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: true,
            error: false,
          }
        }
      };
    }
    case types.FETCH_SECTIONS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: false,
            error: false,
          },
          table: {
            ...state.sections.table,
            ...data,
          }
        },
        global: {
          ...state.global,
          showRunSearch: false,
        }
      };
    }
    case types.FETCH_SECTIONS_FAIL: {
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: false,
            error: true,
          },
          table: {
            ...state.sections.table,
          }
        },
        global: {
          ...state.global,
          showRunSearch: true,
        }
      };
    }
    case types.FETCH_SUBSECTION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: false,
            error: false,
          },
          table: {
            ...state.sections.table,
            rows: [...data.rows],
            lastFetched: data.lastFetched,
          }
        },
        global: {
          ...state.global,
          showRunSearch: false,
        }
      };
    }
    case types.FETCH_SUBSECTION_FAIL: {
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: false,
            error: true,
          },
          table: {
            ...state.sections.table,
          }
        },
        global: {
          ...state.global,
          showRunSearch: true,
        }
      };
    }
    case types.SET_SELECTED_DIVE_SECTIONS_COLUMNS: {
      return {
        ...state,
        sections: {
          ...state.sections,
          selectedColumns: action.columns,
        }
      };
    }
    case types.SET_SELECTED_DIVE_AUTHORS_COLUMNS: {
      return {
        ...state,
        authors: {
          ...state.authors,
          selectedColumns: action.columns,
        }
      };
    }
    case types.SET_SELECTED_DIVE_ARTICLES_COLUMNS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          selectedColumns: action.columns,
        }
      };
    }
    case types.UPDATE_DIVE_SECTIONS_SORT: {
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: true,
            error: false,
          },
          sortConfig: action.config
        }
      };
    }
    case types.DIVE_SECTIONS_SORT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        sections: {
          ...state.sections,
          status: {
            ...state.sections.status,
            loading: false,
            error: false,
          },
          table: {
            ...state.sections.table,
            ...data,
          }
        },
      };
    }
    default: {
      return state;
    }
  }
}
