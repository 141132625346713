import React, { useState } from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useMutation } from '@tanstack/react-query';

import { useHistory, useParams } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import pageStyles from '../Page.styles.scss';
import styles from './PagePublish.styles.scss';

import PageTitle from '../../components/PageTitle';
import CurationPreview from '../../components/CurationPreview';
import { Button, message, Typography, Spin } from 'antd';
import EntityName from '../../components/EntityName';
import ErrorList from '../../components/ErrorList';
import { curatePage, getPage, patchPage } from '../../api/pages';

const PagePublish = () => {
  const { pageName } = useParams();
  let history = useHistory();
  const [curationResultAge, setCurationResultAge] = useState(0);

  const { fn: patchPageMutationFn } = useCuratorConfigApi(patchPage);

  const { fn: curatePageMutationFn } = useCuratorConfigApi(curatePage,  { pageName });
  const { mutate: curate, isLoading: isCurationLoading, data: curationData, error: curationError } = useMutation({
    mutationFn: curatePageMutationFn,
    onSuccess: () => patchPageMutationFn({ body: { status: 'VALIDATED' }, pageName }),
    onError: () => patchPageMutationFn({ body: { status: 'DRAFT' }, pageName }),
    onSettled: () => setCurationResultAge(Date.now())
  });

  const { fn: getPageQueryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data: page, isLoading: isPageLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn: getPageQueryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    },
    onSuccess: (data) => {
      if (data?.status === 'ACTIVE') {
        history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      } else if (data.widgets.length === 0) {
        history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      } else if (Date.now() - curationResultAge > 1000 * 60 * 3) curate();
    }
  });

  const { isLoading: isSaving, mutate: publishPage } = useMutation({
    mutationFn: patchPageMutationFn,
    onSuccess: () => {
      history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      message.success({
        content: `Published`,
        style: {
          marginTop: '58px'
        }
      });
    }
  });
  
  const handleSubmit = () => {
    publishPage({ body: { status: 'ACTIVE' }, pageName });
  };

  const goToEdit = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPageEdit, { pageName }));
  };

  const goBack = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPagesList));
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Publish page ${pageName}`}/>
      <div className={pageStyles.body}>
        <div className={styles.layout}>
          {isPageLoading  && <Spin />}

          {!isPageLoading && page &&
            <>
              {isCurationLoading && 
                <>
                  <Typography.Text strong>
                    Working
                  </Typography.Text>
                  <Typography.Paragraph>
                    Generating preview of page contents. This may take up to a minute.
                  </Typography.Paragraph>
                  <Spin />
                  <div className={styles.actions}>
                    <Button onClick={goBack}>Cancel</Button>
                  </div>
                </>
              }

              {!isCurationLoading && curationData && 
                <>
                  <Typography.Text strong>
                    Preview
                  </Typography.Text>
                  <Typography.Text>
                    Widgets may be displayed in a different order on your page.
                  </Typography.Text>

                  <CurationPreview curationData={curationData} pageName={pageName} />

                  <Typography.Paragraph>
                    Do you want to publish this page?
                  </Typography.Paragraph>
                  <div className={styles.actions}>
                    <Button disabled={isSaving} onClick={goBack}>Cancel</Button>
                    <Button disabled={isSaving} onClick={handleSubmit} type="primary">Publish</Button>
                  </div>
                </>
              }

              {!isCurationLoading && curationError && 
                <>
                  <Typography.Text strong>
                    Preview unavailable
                  </Typography.Text>
                  <Typography.Text>
                    Unable to generate content for <EntityName name={pageName} />.
                  </Typography.Text>
                  {curationError?.messages &&
                    <ErrorList messages={curationError.messages} />
                  }
                  <Typography.Text>
                    Do you want to edit page settings?
                  </Typography.Text>

                  <div className={styles.actions}>
                    <Button onClick={goBack}>Cancel</Button>
                    <Button onClick={goToEdit} type="primary">Edit</Button>
                  </div>
                </>
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

export default PagePublish;
