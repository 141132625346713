// Date-fns
import isAfter from 'date-fns/isAfter';

// Utils
import { convertToSeconds, convertSecondsToDate } from 'utils/dateTime';
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Social Promotions Timeline Chart
 * @param {String} id - String representing the contentID
 * @param {Object} dateRange - Object with start and end time
 */
const getSocialPromotionsTimeline = (host, client, id, token, dateRange, timezone) => {
  if (paramsAreTruthy([id, dateRange])) {    
    const { startDate, endDate } = dateRange;
    const now = new Date();
    let end = isAfter(endDate, now) ? now : endDate;

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `score-analysis/social-timeline?start=${convertToSeconds(startDate)}&end=${convertToSeconds(end)}`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (response) => {
      if (Object.keys(response).includes('noData')) return ({ noData: true });
      const data = response.map((resp) => {
        return {
          ...resp,
          postedAt: convertSecondsToDate(resp.postedAt, timezone),
        };
      });
      return {
        response: data,
      };
    };

    return data;
  }
};

export default getSocialPromotionsTimeline;