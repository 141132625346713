import React from 'react';

import style from './Footer.styles.scss';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <p>&copy; {new Date().getFullYear()} Sophi</p>
    </footer>
  );
};

export default Footer;