import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import isBefore from 'date-fns/isBefore';
import { Badge, Button, Card, Col, Descriptions, List, Row, Skeleton, Space, Statistic, Tooltip, Typography } from 'antd';
import { CommentOutlined, InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';

import { useOverrides } from '../contexts/OverridesProvider';

import { FEOverride } from '../types';
import { getRuleDescription } from '../utils/copy';

import DetailsCardActions from './DetailsCardActions';
import RuleTypeTag from './RuleTypeTag';
import DetailsCardMeta from './DetailsCardMeta';
import DetailsCardPages from './DetailsCardPages';
import ExpirationDate from './ExpirationDate';

const DetailsCard: React.FC<{
  data: FEOverride[];
  preview?: boolean;
}> = ({ data, preview }) => {
  const { articleId, creationDate, expirationDate, status, requestedUserName, id, ruleType, widgetName, position, overrideReasonId, requestId } = data[0];

  const { api, allReasons, overrides } = useOverrides();
  const isExpired = isBefore(new Date(), new Date(expirationDate));
  const [isActive, setIsActive] = useState<boolean>(isExpired);

  const { data: article, isLoading } = useQuery({
    queryKey: [`headline_${articleId}`],
    queryFn: () => api.validateArticleId(articleId),
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const [showAll, setShowAll] = useState<boolean>(data.length > 3 ? false : true);


  const pages = useMemo(() => {
    const output: string[] = [];
    for (let i = 0; i < data.length; i++) {
      output.push(data[i].page);
      if (!showAll && i === 2) break;
    }
    return output;
  }, [data, showAll]);

  function handleFinish() {
    setIsActive(false);
  }

  return (
    <Card
      className={`override-details-card ${isActive ? '' : 'expired'}`}
      title={!preview && <DetailsCardMeta data={data} />}
      extra={!!(!preview && id) && <DetailsCardActions data={data}/>}
      size="small"
    >
      <Skeleton loading={isLoading} active>
        <Space direction="vertical">
          <small>
            {article ? 'Article' : 'Article ID'}
            <Tooltip title={article ? 'Verified' : 'Unverified'} placement="right">
              &ensp;<Badge status={article ? 'success' : 'default'}/>
            </Tooltip>
          </small>
          <Typography.Title level={4} ellipsis={{ rows: 3 }}>
            {article && <a href={`http://${article.articleUrl}`} target="_blank"><LinkOutlined/>&nbsp;</a>}
            <span dangerouslySetInnerHTML={{ __html: (article ? article.headline : articleId) || '' }}/>
          </Typography.Title>
        </Space>
      </Skeleton>

      <Descriptions
        labelStyle={{ fontSize: '.85em' }}
        size="small"
        layout="vertical"
        colon={false}
        column={{ xs: 3 }}
      >
        <Descriptions.Item
          style={{
            width: '90px'
          }}
          label={
            <span
              style={{
                alignItems: 'center'
              }}
            >
              Type
              &ensp;
              <Tooltip
                placement="right"
                title={getRuleDescription(ruleType)}
              >
                <InfoCircleOutlined/>
              </Tooltip>
            </span>
          }
        >
          <RuleTypeTag
            style={{
              marginBottom: '8px'
            }}
            rule={ruleType}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Expires" span={2}>
          <ExpirationDate data={data} editable={!!(!preview && isActive && data[0].id)}/>
        </Descriptions.Item>
      </Descriptions>


      <Row>
        <Col span={ruleType === 'ban' ? 24 : 9}>
          <DetailsCardPages data={data}></DetailsCardPages>
        </Col>
        <Col span={(ruleType === 'ban' || ruleType === 'out') ? 12 : 9}>
          <Descriptions
            labelStyle={{ fontSize: '.85em' }}
            size="small"
            layout="vertical"
            colon={false}
          >
            {widgetName && <Descriptions.Item label="Widget">{widgetName}</Descriptions.Item>}
          </Descriptions>
        </Col>
        <Col span={(ruleType === 'ban' || ruleType === 'out') ? 12 : 6}>
          <Descriptions
            labelStyle={{ fontSize: '.85em' }}
            size="small"
            layout="vertical"
            colon={false}
          >
            {position && <Descriptions.Item label="Position">{position}</Descriptions.Item>}
          </Descriptions>
        </Col>
      </Row>

      {overrideReasonId && (
        <Typography.Text type="secondary" italic>
          <small>
            <CommentOutlined/>
            &ensp;
            {allReasons.find(({ id }) => id === overrideReasonId)?.displayLabel}
          </small>
        </Typography.Text>
      )}

      <Statistic.Countdown style={{ display: 'none' }} value={expirationDate} onFinish={handleFinish} />
    </Card>
  );
};

export default DetailsCard;
