import Colors from 'styles/colors';

const DEFAULT_KEYS = [
  'acquisition','retention','internalTotal','directTotal','emailTotal','newsletterTotal',
  'affiliateTotal','searchTotal','socialTotal','unknownTotal','loyalty'
];
const DEFAULT_VALUES = DEFAULT_KEYS.reduce((lu, key) => ({ ...lu, [key]: 0 }), {});

/**
 * Converts table data (one entry per article) into breakdown data (all rows summed by segment type)
 * @param   {Array} data Table style article data
 * @returns {Object}     The total score and the score break by segment
 */
const formatBreakdownData = (data) => {
  if (!data || data.noData) return { breakdownData: [], total: 0 };

  const summedValues = data.reduce((lu, row) => {
    const updated = { ...lu };
    DEFAULT_KEYS.forEach((key) => {
      updated[key] += row[key];
    });
    return updated;
  }, { ...DEFAULT_VALUES });
  
  const total = Object.values(summedValues).reduce((sum, val) => sum + val, 0);
  
  const breakdownData = [
    { label: 'Acquisition', color: Colors.chartColors.acquisition, value: summedValues.acquisition },
    { label: 'Retention', color: Colors.chartColors.retention, value: summedValues.retention },
    { label: 'Internal', color: Colors.chartColors.internal, value: summedValues.internalTotal },
    { label: 'Direct', color: Colors.chartColors.direct, value: summedValues.directTotal },
    { label: 'Email', color: Colors.chartColors.email, value: summedValues.emailTotal },
    { label: 'Newsletter', color: Colors.chartColors.newsletter, value: summedValues.newsletterTotal },
    { label: 'Affiliate', color: Colors.chartColors.affiliate, value: summedValues.affiliateTotal },
    { label: 'Search', color: Colors.chartColors.search, value: summedValues.searchTotal },
    { label: 'Social', color: Colors.chartColors.social, value: summedValues.socialTotal },
    { label: 'Other', color: Colors.chartColors.unknown, value: summedValues.unknownTotal },
    { label: 'Loyalty', color: Colors.chartColors.loyalty, value: summedValues.loyalty },
  ];
  
  const formattedBreakdownData = breakdownData
    .filter((row) => row.value > 0)
    .sort((a, b) => b.value - a.value);

  return { breakdownData: formattedBreakdownData, total };
};

export default formatBreakdownData;

