// Utils
import {
  getResource,
  getMatchingObject,
  getUniqByKey,
  pathBuilder,
  paramsAreTruthy
} from 'utils/api';
import { PERFORMANCE_BREAKDOWN_LABELS } from 'utils/constants';

const HAS_APP_VALUES = ['internal', 'direct', 'unknown'];
/**
 * API call for Most Engaged Chart
 * @param {String} id - String representing the contentID
 */
const getMostEngaged = (host, client, id, token) => {
  if (paramsAreTruthy([id])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `engagement-analysis/most-engaged`
      }
    );

    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const allDevices = getUniqByKey(data.data, 'platform');
      const allMediums = getUniqByKey(data.data, 'medium');

      const graph = {};
      allMediums.forEach((mediums) => {
        const label = PERFORMANCE_BREAKDOWN_LABELS[mediums] || mediums;
        graph[label] = {};
        allDevices.forEach((device) => {
          const shouldShowValue = device !== 'App' || HAS_APP_VALUES.includes(mediums);
          if (shouldShowValue) {
            const value = getMatchingObject(data.data, {
              medium: mediums,
              platform: device,
            });
  
            graph[label][device.toLowerCase()] = value ? value.total : 0;
          }
        });
      });

      const title = PERFORMANCE_BREAKDOWN_LABELS[data.title] || data.title;

      const graphData = Object.entries(graph)
        .map(([segment, values]) => {
          const valueList = Object.values(values);
          const total = valueList.reduce((total, val) => total + val, 0);
          return { ...values, avg: total / valueList.length, segment };
        })
        .sort((a, b) => {
          if (a.avg > b.avg) return -1;
          if (a.avg < b.avg) return 1;
          return 0;
        });
      
      return {
        response: {
          graph: graphData,
          title,
        },
      };
    };

    return data;
  }
};

export default getMostEngaged;