// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-iM6ahiZBj_uDHa2Ou_WM{border-radius:2px;display:-webkit-box;display:-ms-flexbox;display:flex;overflow:hidden;width:100%}.sophi-dd_VJt_YscpO9FVRI2Zb{min-width:3px;-webkit-transition:200ms ease all;transition:200ms ease all;height:1.5625rem}.sophi-pRJGjLm9lJcL_etnbllb{-webkit-filter:grayscale(100%);filter:grayscale(100%);opacity:.5}@media(min-width: 1920px){.sophi-dd_VJt_YscpO9FVRI2Zb{height:1.875rem}}", "",{"version":3,"sources":["webpack://./src/atoms/BreakdownBar/BreakdownBar.scss"],"names":[],"mappings":"AAEA,4BACE,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CACA,UAAA,CAGF,4BACE,aAAA,CACA,iCAAA,CAAA,yBAAA,CACA,gBAAA,CACA,4BACE,8BAAA,CAAA,sBAAA,CACA,UAAA,CAEF,0BARF,4BASI,eAAA,CAAA","sourcesContent":["@import 'styles/util';\n\n.breakdown-card__bar {\n  border-radius: 2px;\n  display: flex;\n  overflow: hidden;\n  width: 100%;\n}\n\n.breakdown-card__segment {\n  min-width: 3px;\n  transition: 200ms ease all;\n  height: pxToRem(25);\n  &--not-selected {\n    filter: grayscale(100%);\n    opacity: 0.5;\n  }\n  @media(min-width: $xl) {\n    height: pxToRem(30);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakdownCardBar": "sophi-iM6ahiZBj_uDHa2Ou_WM",
	"breakdownCardSegment": "sophi-dd_VJt_YscpO9FVRI2Zb",
	"breakdownCardSegmentNotSelected": "sophi-pRJGjLm9lJcL_etnbllb"
};
export default ___CSS_LOADER_EXPORT___;
