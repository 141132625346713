const token = {
  borderRadius: 2,
  colorError: '#be131b',
  colorInfo: '#3b8ea2',
  colorPrimary: '#3b8ea2',
  colorSuccess: '#5dcba7',
  colorTextBase: '#101010',
  colorWarning: '#fc8319',
  fontFamily: `'Montserrat', sans-serif`,
};


const theme = {
  token
};

export default theme;
