import { put, takeLatest, call, select, delay, cancel } from 'redux-saga/effects';
// import * as actions from '../actions/admin';
import * as apis from 'apis/admin';
import { getAuthValues } from './auth';
import { getConnection } from 'selectors/auth';
import { getPagination } from 'selectors/admin';
import { actions } from '../reducers/admin';

function* getUserAdminAuth() {
  const { token, host } = yield call(getAuthValues, 'admin');
  const client = yield select(getConnection);
  return { host, token, client };
}

export function* getUserList() {
  try {
    const { token, host, client } = yield call(getUserAdminAuth);
    const { page, size } = yield select(getPagination);
    const pagination = {
      per_page: size,
      page: page,
    };
    const response = yield call(apis.getUserList, host, client, token, pagination);
    if (response.ok) {
      yield put(actions.getUsersSuccess(response.body));
    } else {
      yield put(actions.getUsersError(response.body.message));
    }
  } catch (error) {
    yield put(actions.getUsersError(error.message));
  }
}

function* dispatchPasswordReset({ payload: email }) {
  try {
    const { token, host, client } = yield call(getUserAdminAuth);
    const message = yield call(apis.sendPasswordReset, host, client, token, email);
    yield put(actions.setUserAdminMessage(message));
    yield delay(5000);
    yield put(actions.setUserAdminMessage(null));
  } catch (error) {
    yield put(actions.setUserAdminMessage('Could not send password reset email. Please try again.'));
    yield delay(5000);
    yield put(actions.setUserAdminMessage(null));
  }
}

function* createNewUser({ payload: user }) {
  try {
    if (!user || !user.name || !user.email || user.roles.length === 0) {
      yield put(actions.createUserError('Missing required fields'));
      yield cancel();
    }

    const { token, host, client } = yield call(getUserAdminAuth);
    const response = yield call(apis.createNewUser, host, client, token, user);

    if (response.ok) {
      const responseRoles = yield call(apis.assignRole, host, client, token, response.body.user_id, user.roles);
      if (responseRoles.ok) {
        yield put(actions.createUserSuccess());
        yield call(apis.sendPasswordReset, host, client, token, user.email);
        yield call(getUserList);
      } else {
        yield put(actions.createUserError(responseRoles?.body?.message || 'Unable to assign user roles. Please try again'));
      }
    } else {
      yield put(actions.createUserError(response?.body?.message || 'Unable to create user. Please try again'));
    }
  } catch (error) {
    yield put(actions.createUserError('Unable to create user. Please try again'));
  }
}

function* updateUser({ payload: { user, userID } }) {
  try {
    if (!user || !Object.keys(user).length || !userID) {
      yield put(actions.editUserError('Missing required fields'));
      yield cancel();
    }

    const { token, host, client } = yield call(getUserAdminAuth);
    const response = yield call(apis.updateUser, host, client, token, userID, user);
    if (response.ok) {
      yield put(actions.editUserSuccess());
      yield call(getUserList);
    } else {
      yield put(actions.editUserError(response?.body?.message || 'Unable to update user. Please try again'));
    }
  } catch (error) {
    yield put(actions.editUserError('Unable to update user. Please try again'));
  }
}

function* updateUserRoles({ payload: { user, userID } }) {
  try {
    if (!user || !Object.keys(user).length || !userID) {
      yield put(actions.editUserError('Missing required fields'));
      yield cancel();
    }
    const { token, host, client } = yield call(getUserAdminAuth);
    yield call(apis.assignRole, host, client, token, userID, user.roles);
    if (user.deletedRoles) {
      yield call(apis.unassignRole, host, client, token, userID, user.deletedRoles);
    }
  } catch (error) {
    yield put(actions.editUserError(error || 'Unable to update user roles. Please try again'));
  }
}

function* deleteUser({ payload: userID }) {
  try {
    if (!userID) {
      yield put(actions.deleteUserError('Missing user ID'));
      yield cancel();
    }

    const { token, host, client } = yield call(getUserAdminAuth);
    const response = yield call(apis.deleteUser, host, client, token, userID);
    if (response.ok) {
      yield put(actions.deleteUserSuccess());
      yield call(getUserList);
    } else {
      yield put(actions.deleteUserError(response?.body?.message || 'Unable to delete user. Please try again'));
    }
  } catch (error) {
    yield put(actions.deleteUserError('Unable to delete user. Please try again'));
  }
}

function* getRoles({ payload: userID }) {
  try {
    if (!userID) {
      yield put(actions.getRolesError('Missing user ID'));
      yield cancel();
    }
    const { token, host, client } = yield call(getUserAdminAuth);
    const response = yield call(apis.getRoles, host, client, token, userID);
    if (response.ok) {
      yield put(actions.getRolesSuccess(response.body));
    } else {
      yield put(actions.getRolesError(response.body.message));
    }
  } catch (error) {
    yield put(actions.getRolesError('Unable to get roles for specified user. Please try again.'));
  }
}

function* getEditRoles({ payload: userID }) {
  try {
    if (!userID) {
      yield put(actions.getEditRolesError('Missing user ID'));
      yield cancel();
    }
    const { token, host, client } = yield call(getUserAdminAuth);
    const response = yield call(apis.getRoles, host, client, token, userID);
    if (response.ok) {
      yield put(actions.getEditRolesSuccess({
        ...response.body,
        userID
      }));
    } else {
      yield put(actions.getEditRolesError(response.body.message));
    }
  } catch (error) {
    yield put(actions.getEditRolesError('Unable to get roles for specified user. Please try again.'));
  }
}

export default function* adminSaga() {
  yield takeLatest(actions.getUsers.toString(), getUserList);
  yield takeLatest(actions.sendPasswordReset.toString(), dispatchPasswordReset);
  yield takeLatest(actions.createUser.toString(), createNewUser);
  yield takeLatest(actions.editUser.toString(), updateUser);
  yield takeLatest(actions.editUser.toString(), updateUserRoles);
  yield takeLatest(actions.deleteUser.toString(), deleteUser);
  yield takeLatest(actions.getRoles.toString(), getRoles);
  yield takeLatest(actions.getEditRoles.toString(), getEditRoles);
}
