import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery } from '@tanstack/react-query';

import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { useHistory, useParams } from 'react-router-dom';

import pageStyles from '../Page.styles.scss';
import styles from './PageView.styles.scss';

import ButtonLink from '../../components/ButtonLink';
import PageTitle from '../../components/PageTitle';
import WidgetsList from '../../components/WidgetList';
import PageStatus from '../../components/PageStatus';
import PageConfigDisplay from '../../components/PageConfig/Display';
import { Typography, Spin } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import DownloadButton from '../../components/DownloadButton';
import format from 'date-fns/format';
import { useParentContext } from '../../contexts/parent';
import { getPage } from '../../api/pages';

const PageView = () => {
  const { pageName } = useParams();
  const history = useHistory();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    },
  });
  const { env } = useParentContext();
  const fileName = () => {
    return `${pageName}_${env}_${format(new Date(), 'yyyy-MM-dd_HH-mm')}.sophi.json`;
  };

  return (
    <div className={pageStyles.container}>
      <PageTitle title="Site automation" subtitle={`Page ${pageName}`} />
      <div className={pageStyles.body}>
        <div className={styles.actions}>
          <div className={styles.leftAligned}>
            <ButtonLink
              to={getRoute(ROUTE_NAMES.curatorPagesList, { pageName })}
              icon={<ArrowLeftOutlined />}
              type="default"
            >
              All pages
            </ButtonLink>
          </div>
          <div className={styles.rightAligned}>
            {data && 
              <DownloadButton
                fileName={fileName}
                content={JSON.stringify(data, null, 2)}
              >
                Download
              </DownloadButton>
            }
            <ButtonLink to={getRoute(ROUTE_NAMES.curatorPageEdit, { pageName })}>
              Edit
            </ButtonLink>
            <ButtonLink to={getRoute(ROUTE_NAMES.curatorPageCreate, { pageName })}>
              Copy
            </ButtonLink>
            {(data && (data.status === 'DRAFT' || data.status === 'VALIDATED')) && data.widgets.length === 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorWidgetCreate, { pageName })}
                type={data.widgets.length === 0 ? 'primary' : 'default'}
              >
                Add widget
              </ButtonLink>
            }
            {data && data.widgets.length !== 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPagePreview, { pageName })}
                type={data.status === 'ACTIVE' ? 'primary' : 'default'}
              >
                Preview
              </ButtonLink>
            }
            {(data && (data.status === 'DRAFT' || data.status === 'VALIDATED')) && data.widgets.length !== 0 &&
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorPagePublish, { pageName })}
                type="primary"
              >
                Publish
              </ButtonLink>
            }
            {(data &&  data.status === 'ACTIVE') &&
              <ButtonLink to={getRoute(ROUTE_NAMES.curatorPageUnpublish, { pageName })}>
                Unpublish
              </ButtonLink>
            }
            <ButtonLink
              to={getRoute(ROUTE_NAMES.curatorPageDelete, { pageName })}
              danger={true}
            >
              Delete
            </ButtonLink>
          </div>
        </div>

        {isLoading  && <Spin />}

        {!isLoading && data &&
          <>
            <Typography.Title level={3}>Configuration</Typography.Title>
            <Typography.Paragraph>
              <PageStatus status={data.status} />
            </Typography.Paragraph>
            <PageConfigDisplay config={data.config} />
            
            <Typography.Title level={3}>Widgets</Typography.Title>
            <div className={styles.addWidget}>
              <ButtonLink
                to={getRoute(ROUTE_NAMES.curatorWidgetCreate, { pageName })}
                icon={<PlusOutlined />}
              >
                Add widget
              </ButtonLink>
            </div>
            <WidgetsList
              pageName={pageName}
              data={data.widgets}
            />
          </>
        }
      </div>
    </div>
  );
};

export default PageView;
