import React, { useMemo, useState } from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import { message, Button, Checkbox, Spin } from 'antd';
import { isAPIError } from '../../api/handleResponse';
import { useHistory } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { useImportContext } from './context';
import styles from './Preview.styles.scss';
import ProgressDisplay from './ProgressDisplay';
import DiffPreview from './DiffPreview';
import { getPage, postPage, putPage } from '../../api/pages';
import { postWidget, putWidget, patchWidget } from '../../api/widgets';

const UpdateHandler = ({ config, existing }) => {
  const { progress, setProgress } = useImportContext();
  const history = useHistory();
  const { config: pageConfig, widgets } = config;
  const { name: pageName } = pageConfig;
  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const updateStatus = (type, name, action, status) => {
    setProgress((previous) => ({
      ...previous,
      [`${type}/${name}`]: {
        type,
        name,
        action,
        status,
      },
    }));
  };

  const handleRemoveChange = (e) => {
    setRemove(e.target.checked);
  };

  const goBack = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPagesList));
  };

  const goToNext = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPagePublish, { pageName }));
  };

  const removedWidgets = useMemo(() => {
    if (!existing) return [];
    const { widgets: existingWidgets } = existing;
    const { widgets: updatedWidgets } = config;
    const removed = existingWidgets.filter((e) => !updatedWidgets.find((u) => u.config.name === e.config.name));
    return removed;
  }, [existing, config]);

  const removedWidgetNames = useMemo(() => {
    return removedWidgets.map((r) => r.config.name).join(', ');
  }, [removedWidgets]);

  const { fn: postPageFn } = useCuratorConfigApi(postPage);
  const { mutateAsync: postPageMutation } = useMutation({
    mutationFn: postPageFn,
    onMutate: async (variables) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'create', 'processing');
    },
    onSuccess: (data, variables) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'create', 'success');
    },
    onError: (err, variables) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'create', 'error');
    },
  });

  const { fn: putPageFn } = useCuratorConfigApi(putPage);
  const { mutateAsync: putPageMutation } = useMutation({
    mutationFn: putPageFn,
    onMutate: async (variables) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'update', 'processing');
    },
    onSuccess: (data, variables, context) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'update', 'success');
    },
    onError: (err, variables, context) => {
      const { pageName } = variables;
      updateStatus('page', pageName, 'update', 'error');
    },
  });

  const { fn: postWidgetFn } = useCuratorConfigApi(postWidget);
  const { mutateAsync: postWidgetMutation } = useMutation({
    mutationFn: postWidgetFn,
    onMutate: async (variables) => {
      const { body: { config: { name } } } = variables;
      updateStatus('widget', name, 'create', 'processing');
    },
    onSuccess: (data, variables, context) => {
      const { body: { config: { name } } } = variables;
      updateStatus('widget', name, 'create', 'success');
    },
    onError: (err, variables, context) => {
      const { body: { config: { name } } } = variables;
      updateStatus('widget', name, 'create', 'error');
    },
  });

  const { fn: putWidgetFn } = useCuratorConfigApi(putWidget);
  const { mutateAsync: putWidgetMutation } = useMutation({
    mutationFn: putWidgetFn,
    onMutate: async (variables) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'update', 'processing');
    },
    onSuccess: (data, variables, context) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'update', 'success');
    },
    onError: (err, variables, context) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'update', 'error');
    },
  });

  const { fn: patchWidgetFn } = useCuratorConfigApi(patchWidget);
  const { mutateAsync: patchWidgetMutation } = useMutation({
    mutationFn: patchWidgetFn,
    onMutate: async (variables) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'delete', 'processing');
    },
    onSuccess: (data, variables) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'delete', 'success');
    },
    onError: (err, variables) => {
      const { widgetName } = variables;
      updateStatus('widget', widgetName, 'delete', 'error');
    },
  });

  const handleSubmit = async () => {
    const requests = [];
    setLoading(true);

    if (existing) {
      const { widgets } = config;
      requests.push(putPageMutation({ body: { config: pageConfig }, pageName }));
      for (const widget of widgets) {
        if (existing.widgets.find((e) => e.config.name === widget.config.name)) {
          requests.push(putWidgetMutation({ body: { config: widget.config }, pageName, widgetName: widget.config.name }));
        } else {
          requests.push(postWidgetMutation({ body: { status: 'DRAFT', config: widget.config }, pageName }));
        }
      }
      if (remove) {
        for (const {config: { name }} of removedWidgets) {
          requests.push(patchWidgetMutation({ body: { status: 'DELETE' }, pageName, widgetName: name }));
        }
      }
    } else {
      const createPageRequest = postPageMutation({ body: { config: pageConfig }, pageName });
      requests.push(createPageRequest);
      await createPageRequest;
      for (const widget of widgets) {
        requests.push(postWidgetMutation({ body: { status: 'DRAFT', config: widget.config }, pageName }));
      }
    }

    Promise.allSettled(requests).then((results) => {
      if (results.some((r) => r.status === 'rejected')) {
        setComplete(true);
      } else {
        message.success({
          content: `Saved`,
          style: {
            marginTop: '58px'
          }
        });
        goToNext();
      }
      setLoading(false);
    });
  };

  return (
    <>
      {progress === null ?
        <>
          <DiffPreview
            existing={existing}
            config={config}
          />
          {removedWidgets.length > 0 &&
            <Checkbox
              onChange={handleRemoveChange}
              checked={remove}
              disabled={loading}
            >
              Delete widgets: {removedWidgetNames}
            </Checkbox>
          }
        </>
        :
        <ProgressDisplay progressMap={progress} />
      }

      <div className={styles.buttons}>
        <Button
          onClick={goBack}
        >
          Cancel
        </Button>
        <Button
          onClick={complete ? goToNext : handleSubmit}
          loading={loading}
          type="primary"
        >
          {complete ? 'Next' : 'Save'}
        </Button>
      </div>
    </>
  );
};

const DataLoader = ({ config }) => {
  const { config: pageConfig } = config;
  const { name: pageName } = pageConfig;

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isFetched } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    retry: (failureCount, error) => {
      if (isAPIError(error) && error.cause.status === 404) return false;
      return true;
    },
  });

  if (isFetched) return (
    <UpdateHandler
      config={config}
      existing={data}
    />
  );

  return <Spin />;
};

export default DataLoader;