import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const FancyLink = React.forwardRef(({ navigate, onClick, target, ...props }, ref) => {
  const handleClick = (event) => {
    try {
      if (onClick) onClick(event);
    } catch (err) {
      event.preventDefault();
      throw err;
    }

    if (
      event.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      if (!event.defaultPrevented) {event.preventDefault();}
      navigate();
    }
  };

  return (
    <Button href={props.to} {...props} onClick={handleClick} />
  );
});

const ButtonLink = ({ className = '', ...props }) => {
  return (
    <span className={`${className}`}>
      <Link {...props} component={FancyLink} />
    </span>
  );
};

export default ButtonLink;