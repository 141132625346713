// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-ezrI_TrsTpslZnWylgzE{margin-top:25px;margin-bottom:25px;text-align:center}.sophi-ezrI_TrsTpslZnWylgzE h1{font-weight:bold}@media(min-width: 840px){.sophi-ezrI_TrsTpslZnWylgzE{text-align:left}}", "",{"version":3,"sources":["webpack://./src/atoms/PageTitle/PageTitle.styles.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,kBAAA,CACA,iBAAA,CAEA,+BACE,gBAAA,CAGF,yBATF,4BAUI,eAAA,CAAA","sourcesContent":[".page-title {\n  margin-top: 25px;\n  margin-bottom: 25px;\n  text-align: center;\n  \n  h1 {\n    font-weight: bold;\n  }\n\n  @media(min-width: 840px) {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": "sophi-ezrI_TrsTpslZnWylgzE"
};
export default ___CSS_LOADER_EXPORT___;
