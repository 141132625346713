"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getInit = async ({ method, body, getTokenSilently }) => {
    const headers = new Headers();
    const token = await getTokenSilently();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', `application/json`);
    const init = {
        method,
        headers
    };
    if (body) {
        init.body = JSON.stringify(body);
    }
    return init;
};
exports.default = getInit;
