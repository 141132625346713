import React, { useState, useEffect, useMemo, useRef } from 'react';
import { TabStateContext } from './context';
import { useSprings } from 'react-spring';
import useEventListener from 'hooks/useEventListener';
import styles from './Tabs.styles.scss';

const keys = {
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46,
  enter: 13,
  space: 32
};

const direction = {
  37: -1,
  38: -1,
  39: 1,
  40: 1
};

const Tabs = (props) => {
  const [selected, setSelected] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [focused, setFocused] = useState(null);
  const tabLookup = useRef({});
  const tabContainerRef = useRef(null);

  // Handle the width of the panel. Required to get panel animations to act
  // appropriately on page resize
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
    set((i) => {
      const current = tabLookup.current[selected];
      const x = (i - current) * window.innerWidth;
      return { x };
    }); 
  });
  const tabWidth = tabContainerRef.current ? tabContainerRef.current.getBoundingClientRect().width : windowWidth;

  const [springProps, set] = useSprings(tabs.length, (i) => ({ x: i * tabWidth, sc: 1, display: 'block' }));

  useEffect(() => {
    let tabs = [];
    // Determine all of the tabs we have and in what order they appear
    React.Children.forEach(props.children, (child) => {
      if (child.type.displayName === 'TabHeader') {
        tabs = child.props.children;
      }
    });
    // Set first tab as the default tab
    tabs.length && setSelected(tabs[0].props.id);
    setTabs(tabs);
    
    // Mark the index of each tab. Used to determine direction to slide panels
    tabLookup.current = tabs.reduce((lu, tab, index) => {
      if (tab) {
        return {
          ...lu,
          [tab.props.id]: index,
        };
      }
      return lu;
    }, {});
  }, []);

  useEffect(() => {
    if (typeof props.onSet === 'function') {
      props.onSet(selected);
    }
  }, [selected]);

  const focusFirstTab  = () => setFocused(tabs[0].props.id);
  const focusLastTab = () => setFocused(tabs[tabs.length - 1].props.id);

  // Either focus the next, previous, first, or last tab
  // depending on key pressed
  const switchTabOnArrowPress = ({ keyCode, target }) => {
    if (direction[keyCode] && target) {
      const index = tabLookup.current[target.id];
      if (index !== undefined) {
        if (tabs[index + direction[keyCode]]) {
          setFocused(tabs[index + direction[keyCode]].props.id);
        } else if (keyCode === keys.left) {
          focusLastTab();
        } else if (keyCode === keys.right) {
          focusFirstTab();
        };
      };
    };
  };

  // Activates any given tab panel
  const activateTab = (tab, setFocus) => {
    setFocus = setFocus || true;
    setSelected(tab.id);
    
    // Slide the correct panel into view
    set((i) => {
      const current = tabLookup.current[tab.id];
      const x = (i - current) * tabWidth;
      return { x };
    });
    
    // Notify parent that selected panel has changed
    props.onChange && props.onChange(tab.id);
    
    // Set focus when required
    if (setFocus) {
      setFocused(tab.id);
    };
  };

  useEffect(() => {
    if (props.interval) {
      const callback = () => {
        const length = tabs.length;
        const index = tabs.findIndex((tab) => {
          return tab.props.id === selected;
        });

        if (index > -1) {
          if (index < length - 1) {
            const next = tabs[index + 1];
            activateTab({id: next.props.id}, false);
          } else {
            const next = tabs[0];
            activateTab({id: next.props.id}, false);
          }
        }
      };
      const interval = setInterval(callback, props.interval);
      return () => {
        clearInterval(interval);
      };
    }
  });

  /* Event Handlers */
  const clickEventListener = ({ target }) => {
    const { x } = target.parentElement.getBoundingClientRect();
    target.parentElement.scrollTo({ left: target.offsetLeft - x });
    activateTab(target, false);
  };

  const keydownEventListener = (event) => {
    switch (event.keyCode) {
      case keys.end:
        event.preventDefault();
        focusLastTab();
        break;
      case keys.home:
        event.preventDefault();
        focusFirstTab();
        break;
      // Up and down are in keydown
      // because we need to prevent page scroll
      case keys.up:
      case keys.down:
        event.preventDefault();
        break;
    };
  };

  const keyupEventListener = (event) => {
    switch (event.keyCode) {
      case keys.left:
      case keys.right:
        switchTabOnArrowPress(event);
        break;
      case keys.enter:
      case keys.space:
        activateTab(event.target);
        break;
    };
  };

  // Set the selected panel based on the direction of the swipe
  const handleSwipe = (direction) => {
    const currentIndex = tabLookup.current[selected];
    if (direction === 1 && currentIndex > 0) {
      setSelected(tabs[currentIndex - 1].props.id);
      props.onChange && props.onChange(tabs[currentIndex - 1].props.id);
    } else if (direction === -1 && (currentIndex < tabs.length -1)) {
      setSelected(tabs[currentIndex + 1].props.id);
      props.onChange && props.onChange(tabs[currentIndex + 1].props.id);
    }
  };

  // This gathers all of the state and props that children need to be aware of
  // but we do not want to live outside the component
  const contextValue = {
    // Component Attributes
    tabIndexLookup: tabLookup.current,
    width: tabWidth,
    responsiveHeight: props.responsiveHeight,
    // Tab State
    selected,
    focused,
    // Animation Props
    springProps,
    set,
    // Event Handlers
    onClick: clickEventListener,
    onKeyDown: keydownEventListener,
    onKeyUp: keyupEventListener,
    handleSwipe,
  };

  return (
    <TabStateContext.Provider value={contextValue}>
      <div ref={tabContainerRef} className={`${styles.tabs} ${props.responsiveHeight ? styles.responsiveHeight : ''} tabs`}>
        {props.children}
      </div>
    </TabStateContext.Provider>
  );
};

export default Tabs;
