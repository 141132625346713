// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-bDo0kHXq5vP3WzTcQmgA{display:grid;grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr;-webkit-column-gap:calc(.5*var(--space-1));-moz-column-gap:calc(.5*var(--space-1));column-gap:calc(.5*var(--space-1));row-gap:calc(.5*var(--space-1));-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}.sophi-ZpsXm3872fXEjhrPqRyK{grid-column:1/2}.sophi-zcxbpOxw05G6zJciMtU1{grid-column:2/3}.sophi-HjwYbP_F4BU5BxQ_fYJh{margin-bottom:calc(1.5*var(--space-1))}.sophi-e3ymonAtqjlPrEmDQRBV{text-align:center}.sophi-NR5JvtmwPONcO7dk8310{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:calc(.5*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/DiversityRule/Conditions.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,6CAAA,CAAA,qCAAA,CACA,0CAAA,CAAA,uCAAA,CAAA,kCAAA,CACA,+BAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAGF,4BACE,eAAA,CAEF,4BACE,eAAA,CAGF,4BACE,sCAAA,CAGF,4BACE,iBAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,qCAAA","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  column-gap: calc(0.5 * var(--space-1));\n  row-gap: calc(0.5 * var(--space-1));\n  align-items: baseline;\n}\n\n.label {\n  grid-column: 1 / 2;\n}\n.input {\n  grid-column: 2 / 3;\n}\n\n.or {\n  margin-bottom: calc(1.5 * var(--space-1));\n}\n\n.add {\n  text-align: center;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: calc(0.5 * var(--space-1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "sophi-bDo0kHXq5vP3WzTcQmgA",
	"label": "sophi-ZpsXm3872fXEjhrPqRyK",
	"input": "sophi-zcxbpOxw05G6zJciMtU1",
	"or": "sophi-HjwYbP_F4BU5BxQ_fYJh",
	"add": "sophi-e3ymonAtqjlPrEmDQRBV",
	"header": "sophi-NR5JvtmwPONcO7dk8310"
};
export default ___CSS_LOADER_EXPORT___;
