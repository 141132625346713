// Disabling auto focus rule because autofocus can be used in an accessible way
// if the input is the only / main control on the page
/* eslint-disable jsx-a11y/no-autofocus */

import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'sophi-design-system/src/components/Input';
import styles from './Autocomplete.styles.scss';

const KeyCode = {
  BACKSPACE: 8,
  DELETE: 46,
  DOWN: 40,
  END: 35,
  ESC: 27,
  HOME: 36,
  LEFT: 37,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  RETURN: 13,
  RIGHT: 39,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};

// An accessible auto complete component based off the W3c example
// https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html
const AutoComplete = ({
  id,
  label,
  children,
  autoFocus,
  onSubmit,
  results,
  onChange,
  defaultValue,
  clearOnSubmit,
  closeOnSubmit,
}) => {
  const inputRef = useRef(null);
  const comboboxRef = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showOptions, toggleShowOptions] = useState(false);

  // Collapse options list if click is outside component
  const checkHide = useCallback((evt) => {
    const clickOnInput = evt.target === inputRef.current;
    const clickOnMenu = comboboxRef.current && comboboxRef.current.contains(evt.target);
    if (clickOnInput || clickOnMenu) {
      return;
    }
    hideListbox();
  }, []);

  useEffect(() => {
    const clickListener = document.addEventListener('click', checkHide);
    return () => document.removeEventListener('click', clickListener);
  }, []);

  useEffect(() => {
    if (results && results.length > 0) {
      toggleShowOptions(true);
    }
  }, [results]);

  // Handle option selection
  const selectItem = (item) => {
    if (item) {
      onSubmit(item.innerHTML);
      setValue(clearOnSubmit ? '' : item.innerHTML);
    } else {
      onSubmit(value);
      clearOnSubmit && setValue('');
    }
    closeOnSubmit && hideListbox();
  };

  const getItemAt = (index) => document.getElementById(`${id}-result-item-${index}`);

  const clickItem = ({ target }) => {
    if (target && target.nodeName == 'LI') {
      selectItem(target);
    }
  };

  const checkSelection = () => {
    if (activeIndex < 0) return;
    // If an option is focused and the menu is exited, select the item
    selectItem(getItemAt(activeIndex));
  };

  const setActiveItem = (evt) => {
    var key = evt.which || evt.keyCode;
    // If user escapes, clear the options and input
    if (key === KeyCode.ESC) {
      hideListbox();
      setValue('');
      return;
    }

    switch (key) {
      case KeyCode.UP:
        if (activeIndex <= 0) {
          setActiveIndex(results.length - 1);
        }
        else {
          setActiveIndex(activeIndex - 1);
        }
        break;
      case KeyCode.DOWN:
        if (activeIndex === -1 || activeIndex >= results.length - 1) {
          setActiveIndex(0);
        }
        else {
          setActiveIndex(activeIndex + 1);
        }
        break;
      case KeyCode.RETURN:
        setActiveIndex(activeIndex);
        selectItem(getItemAt(activeIndex));
        return;
      case KeyCode.TAB:
        checkSelection();
        hideListbox();
        return;
      default:
        return;
    }

    evt.preventDefault();
  };

  const hideListbox = () => {
    toggleShowOptions(false);
    setActiveIndex(-1);
  };

  const updateResults = ({ target }) => {
    var searchString = (target && target.value !== undefined) ? target.value : value;
    onChange(searchString);
    hideListbox();

    if (searchString !== value) {
      setValue(searchString);
    }
  };

  return (
    <>
      <div className={styles.comboWapper}>
        <div
            className={styles.combobox}
            ref={comboboxRef}
            id={`${id}-combobox`}
            role="combobox"
            aria-controls={`${id}-input`}
            aria-expanded={showOptions}
            aria-owns={`${id}-listbox`}
            aria-haspopup="listbox"
        >
          <Input
            label={label}
            data-track-click="true"
            data-track-name={`${id}-input`}
            inputRef={inputRef}
            className={styles.input}
            autoFocus={autoFocus}
            type="text"
            id={`${id}-input`}
            value={value}
            onChange={(e) => updateResults(e)}
            onKeyDown={setActiveItem}
            onFocus={() => updateResults(false)}
            onBlur={checkSelection}
            aria-autocomplete="list"
            aria-controls={`${id}-listbox`}
            aria-activedescendant={activeIndex && activeIndex >= 0 ? `result-item-${activeIndex}` : ''}
          />
        </div>
        <ul
          id={`${id}-listbox`}
          className={`${styles.listbox} ${showOptions ? '' : styles.hidden}`}
          onClick={clickItem}
          onKeyDown={clickItem}
          role="listbox"
          aria-labelledby={`${id}-label`}
        >
          {results.map((result, i) => {
            return (
              <li
                key={`result-item-${i}`}
                id={`${id}-result-item-${i}`}
                className={`${styles.result} ${activeIndex === i ? styles.focused : ''}`}
                role="option"
                aria-selected={activeIndex === i}
              >
                {result}
              </li>
            );
          })}
        </ul>
        {children && children(() => selectItem())}
      </div>
    </>
  );
};

AutoComplete.defaultProps = {
  results: [],
  onChange: () => {},
  defaultValue: '',
  clearOnSubmit: true,
  closeOnSubmit: true,
};

AutoComplete.propTypes = {
  results: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  clearOnSubmit: PropTypes.bool,
  closeOnSubmit: PropTypes.bool,
};

export default AutoComplete;
