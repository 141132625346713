// Root
import React from 'react';
import { useSelector } from 'react-redux';

import AppLoading from 'molecules/AppLoading';

// Auth
import { Auth0Provider } from 'utils/Auth0Provider';

// Selectors
import { isConfigured, getDomain, getClientId, getConnection, getPermissionScope } from 'selectors/auth';

// Utils
import { getFromStorage } from 'utils/storage';

const BakedAuth0Provider = ({ children, history }) => {
  const domain = useSelector(getDomain).replace(/(^\w+:|^)\/\//, '');
  const clientId = useSelector(getClientId);
  const connection = useSelector(getConnection);
  const permission = useSelector(getPermissionScope);
  const configured = useSelector(isConfigured);

  const onRedirectCallback = () => {
    const lastRoute = getFromStorage('lastRoute');
    history.push(lastRoute ? lastRoute : window.location.pathname);
  };

  if (configured) {
    return (
      <Auth0Provider
        domain={domain}
        client_id={clientId}
        scope={`openid profile email ${permission.join(' ')} roles:delete roles:read roles:update users:read users:create users:update users:delete read:divebeta reports:pop reports:promo override:read override:create curator:read configurator:pages:read configurator:pages:create configurator:widgets:read configurator:widgets:create`}
        connection={connection ? connection.toLowerCase() : connection}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return (
      <AppLoading />
    );
  }
};

export default BakedAuth0Provider;
