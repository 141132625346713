import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styles from './Typography.styles.scss';

const Title = ({ className, tag, ...props }) => {
  const titleProps = {
    ...props,
    className: `${styles.title} ${className}`,
  };

  return <Base tag={tag} {...titleProps} />;
};

Title.defaultProps = {
  tag: 'p',
  className: '',
};

Title.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  className: PropTypes.string,
};

export default Title;
