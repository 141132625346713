export const setInStorage = (key, value) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    console.error('localStorage not available');
  }
};

export const getFromStorage = (key) => {
  if (localStorage) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      return null;
    }
  } else {
    console.error('localStorage not available');
    return null;
  }
};

export const removeFromStorage = (key) => {
  if (localStorage) {
    localStorage.removeItem(key);
  } else {
    console.error('localStorage not available');
  }
};

const setRangeConfig = (id, key, config) => {
  // Get most recent date preset
  const analysisPreset = getFromStorage(`${id}-preset`);

  if (analysisPreset) {
    config[`${key}_date_preset`] = analysisPreset;
    // Store range for Custom date range selection
    if (analysisPreset === 'Custom') {
      config[`${key}_date_range`] = getFromStorage(`${id}-range`);
    } else {
      config[`${key}_date_range`] = undefined;
    }
  }

  // Clean up old staging values
  removeFromStorage(`${id}-preset`);
  removeFromStorage(`${id}-range`);
};

export const setNowFilters = (filters) => {
  // Get existing stored filters
  const nowConfig = getFromStorage('now_config') || {};

  setRangeConfig('now-analysis-period-date-picker', 'analysis', nowConfig);
  setRangeConfig('now-published-period-date-picker', 'published', nowConfig);

  // Update filters
  nowConfig.filters = filters;

  setInStorage('now_config', nowConfig);
};

export const setDiveFilters = (filters) => {
  // Get existing stored filters
  const diveConfig = getFromStorage('dive_config') || {};

  setRangeConfig('dive-date-picker', 'dive', diveConfig);

  // Update filters
  diveConfig.filters = filters;

  setInStorage('dive_config', diveConfig);
};

/**
 * Set list of currently selected columns into local storage
 * @param {String} key      Key to store list in local storage
 * @param {Array}  columns  List of column names
 */
export const setColumnObject = (key, columns) => {
  if (typeof key === 'string' && columns) {
    setInStorage(key, columns);
  }
};

const colDisplayType = {
  headline: 'link',
  publishedDate: 'date',
};

/**
 * Fetch list of selected columns from local storage and revert back into columns
 * lookup object format
 * @param {String} key          Key to fetch values from local storage
 * @param {Object} defaultCols  Fallback default columns object. Used when no local storage value available
 */
export const getColumnObject = (key, defaultCols = {}) => {
  const savedSectionColumns = getFromStorage(key);

  if (savedSectionColumns && Array.isArray(savedSectionColumns)) {
    return savedSectionColumns;
  }

  return defaultCols;
};
