import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import Colors from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  default: {
    fontFamily: '"Montserrat-SemiBold", sans-serif',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(16),
    '&::selection': {
      color: Colors.grays[100],
    },
  },
  gray: {
    color: Colors.grays[800],
    '&:hover, &:focus': {
      color: Colors.blues[800],
    },
    '&:active': {
      color: Colors.grays[700]
    }
  },
  blue: {
    color: Colors.blues[700],
    '&:hover, &:focus': {
      color: Colors.grays[800],
    },
    '&:active': {
      color: Colors.grays[700]
    }
  },
  pink: {
    color: Colors.pinks[700],
    '&:hover, &:focus': {
      color: Colors.grays[800],
    },
    '&:active': {
      color: Colors.grays[700]
    }
  },
  white: {
    color: '#FFF',
    '&:hover, &:focus': {
      color: Colors.blues[200],
    },
    '&:active': {
      color: Colors.grays[200]
    }
  }
}));

const StyledLink = ({ type, className, native, children, ...props }) => {
  const classes = useStyles();
  if (native) {
    return <a className={`${classes.default} ${classes[type]} ${className || ''}`} {...props}>{children}</a>;
  }
  return (
    <Link className={`${classes.default} ${classes[type]} ${className || ''}`} {...props}>{children}</Link>
  );
};

StyledLink.propTypes = {
  type: PropTypes.oneOf(['gray', 'blue', 'pink']),
  native: PropTypes.bool,
};

StyledLink.defaultProps = {
  type: 'gray',
  native: false,
};

export default StyledLink;