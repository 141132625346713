import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import { useParams } from 'react-router-dom';

import styles from '../Page.styles.scss';

import WidgetConfigForm from '../../components/WidgetConfig';
import PageTitle from '../../components/PageTitle';
import ErrorList from '../../components/ErrorList';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';
import { getPage } from '../../api/pages';
import { getWidget, postWidget } from '../../api/widgets';

const WidgetCreate = () => {
  let history = useHistory();
  const { pageName, widgetName } = useParams();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data: pageConfig, isLoading: isPageConfigLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    }
  });

  const { fn: getWidgetQueryFn } = useCuratorConfigApi(getWidget, { pageName, widgetName });
  const { data: widgetConfig, isInitialLoading: isWidgetConfigLoading } = useQuery({
    enabled: !!widgetName,
    queryKey: [`widget`, pageName, widgetName],
    queryFn: getWidgetQueryFn,
    onError: (e) => {
      const content = `Could not copy settings from ${widgetName}. ${e?.cause?.status === 404 ? 'Widget not found.' : ''}`;
      message.warning({
        content,
        style: {
          marginTop: '58px'
        },
      });
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(postWidget);
  const { isLoading: isSaving, error, mutate: createWidget } = useMutation({
    mutationFn,
    onSuccess: () => {
      history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      message.success({
        content: widgetConfig ? `Copied` : `Saved`,
        style: {
          marginTop: '58px'
        }
      });
    }
  });
  
  const base = widgetConfig?.config ? {
    ...widgetConfig.config,
    name: '',
  } : undefined;

  const handleSubmit = (config) => {
    const body = {
      status: 'DRAFT',
      config
    };
    createWidget({ body, pageName });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Add a widget to page ${pageName}`} />
      <div className={styles.body}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(isPageConfigLoading || isWidgetConfigLoading) ? (
            <Spin />
          ) : 
            <div style={{ flexBasis: '600px' }}>
              <WidgetConfigForm
                onSubmit={handleSubmit}
                onCancel={history.goBack}
                disabled={isSaving}
                widgets={pageConfig.widgets}
                config={base}
              />
              {error?.messages &&
                <ErrorList messages={error.messages} />
              }
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default WidgetCreate;
