import { WIDGET_NAME_MAP } from 'apis/config';
import { ROUTE_NAMES } from 'utils/paths';

export const getHeaderTitle = (state) => state.overview.title;
export const getOverviewFilters = (state) => state.overview.filters;
export const getOverviewPagination = (state) => state.overview.pagination;
export const getDateRanges = (state) => state.overview.dateRanges;
export const getOverviewTable = (state) => state.overview.table;
export const getOverviewAllColumns = (state) => state.config[ROUTE_NAMES.nowContentView]?.[WIDGET_NAME_MAP.NOW_COLUMNS];
export const getOverviewOptions = (state) => state.overview.options;
export const getOverviewAutosuggest = (state) => state.overview.autosuggest;
export const getOverviewPotentialRowCount = (state) => state.overview.potentialResults;
// Subtypes
export const getSubtypesOptions = (state) => state.overview.options.subtypes.data;
export const getSubtypesSelection = (state) => state.overview.filters.subtypes;
// Credit Line
export const getCreditLineOptions = (state) => state.overview.options.creditLine.data;
export const getCreditLineSelection = (state) => state.overview.filters.creditLine;
// Ownership
export const getOwnershipOptions = (state) => state.overview.options.ownership.data;
export const getOwnershipSelection = (state) => state.overview.filters.ownership;
export const getAccessOptions = (state) => state.overview.options.access.data;
export const getAccessSelection = (state) => state.overview.filters.access;
// Results Table
export const getTableColumns = (state) => state.overview.table.columns;
