//TODO: Loads of repetition in this file. Should probably build a factory to handle the calls

import { call, cancel, put, retry, select, takeLatest, takeEvery, take, all } from 'redux-saga/effects';
import types from '../actions/contentTypes';
import api from '../apis/content';
import * as selectors from '../selectors/content';
import * as authSelectors from '../selectors/auth';
import * as routerSelectors from '../selectors/router';
import { isOutdated } from 'utils/saga';
import { getAuthValues } from './auth';
import { initialState } from '../reducers/content';
import { WIDGET_NAME_MAP } from '../apis/config';
import { awaitWidgetConfig } from './auth';

// How long we will keep data before re-fetching in milliseconds
const EXPIRY_IN_MS = ENV.DATA_EXPIRY_IN_MS;

export function* setContentId({ id }) {
  yield put({ type: types.SET_ID_SUCCESS, id });
}

export function* getContentId() {
  const id = yield select(selectors.getId);

  if (id) return id;

  if (!id) {
    // ID may be null, wait for it to set before making the API call
    yield take(types.SET_ID_SUCCESS);
    const newId = yield select(selectors.getId);
    return newId;
  }
}

export function* fetchAllTime() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);

    const response = yield retry(5, 5000, api.allTimeStats.fetchData, host, client, id, token);

    let pageName = yield select(routerSelectors.getRouteName);
    const widgetName = WIDGET_NAME_MAP.ALL_TIME_STATS;

    let config = yield awaitWidgetConfig({ pageName, widgetName });

    const data = api.allTimeStats.formatResponse(response, config.data);

    yield put({ type: types.FETCH_ALL_TIME_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_ALL_TIME_FAIL, error });
  }
}

export function* fetch7Day() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'dive');
    const id = yield call(getContentId);
    const response = yield retry(5, 5000, api.diveStats.fetchData, host, client, id, token);

    let pageName = yield select(routerSelectors.getRouteName);
    const widgetName = WIDGET_NAME_MAP.SEVEN_DAY_STATS;

    const config = yield awaitWidgetConfig({ pageName, widgetName });

    const data = api.diveStats.formatResponse(response, config.data);

    yield put({ type: types.FETCH_7_DAY_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_7_DAY_FAIL, error });
  }
}

export function* fetchPerformanceDataSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const dateRange = yield select(selectors.getDateRange);
    const timezone = yield select(authSelectors.getTimezone);
    const data = yield call(api.getPerformanceData, host, client, id, token, dateRange, timezone);
    data.noData ? yield put({ type: types.FETCH_PERFORMANCE_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_PERFORMANCE_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_PERFORMANCE_FAIL, error });
  }
}

export function* fetchTrafficDataSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const dateRange = yield select(selectors.getDateRange);
    const portrait = yield select(selectors.getPortrait);
    const timezone = yield select(authSelectors.getTimezone);
    const data = yield call(api.getTrafficData, host, client, id, token, dateRange, portrait, timezone);
    data.noData ? yield put({ type: types.FETCH_TRAFFIC_SUCCESS, data: { graph: 'noData', table: 'noData' } }) : yield put({ type: types.FETCH_TRAFFIC_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_TRAFFIC_FAIL, error });
  }
}

export function* fetchMostReaderSaga() {
  try {
    const lastFetched = yield select(selectors.getMostReaderLastFetched);
    const shouldFetch = isOutdated(lastFetched, EXPIRY_IN_MS);
    const { token, host, client } = yield call(getAuthValues, 'now');

    if (shouldFetch) {
      yield put({ type: types.FETCH_MOST_READERS_LOADING });
      const id = yield call(getContentId);
      const data = yield call(api.getMostReaderData, host, client, id, token);
      data.noData ? yield put({ type: types.FETCH_MOST_READERS_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_MOST_READERS_SUCCESS, data: data.response });
    } else {
      yield cancel();
    }
  } catch (error) {
    yield put({ type: types.FETCH_MOST_READERS_FAIL, error });
  }
}

export function* fetchTopReferrerDataSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const { page, size } = yield select(selectors.getTopReferrerPagination);
    const data = yield call(api.getTopReferrerData, host, client, id, token, page, size);
    data.noData
      ? yield put({ type: types.FETCH_TOP_REFERRER_SUCCESS, data: { results: 'noData' } })
      : yield put({ type: types.FETCH_TOP_REFERRER_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_TOP_REFERRER_FAIL, error });
  }
}

export function* fetchMinutesReadingSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const lastFetched = yield select(selectors.getMinutesReadingLastFetched);
    const shouldFetch = isOutdated(lastFetched, EXPIRY_IN_MS);

    if (shouldFetch) {
      yield put({ type: types.FETCH_MINUTES_READING_LOADING });
      const id = yield call(getContentId);      const data = yield call(api.getMinutesReading, host, client, id, token);
      data.noData ? yield put({ type: types.FETCH_MINUTES_READING_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_MINUTES_READING_SUCCESS, data: data.response });
    } else {
      yield cancel();
    }
  } catch (error) {
    yield put({ type: types.FETCH_MINUTES_READING_FAIL, error });
  }
}

export function* fetchMostEngagedSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const lastFetched = yield select(selectors.getMostEngagedLastFetched);
    const shouldFetch = isOutdated(lastFetched, EXPIRY_IN_MS);

    if (shouldFetch) {
      yield put({ type: types.FETCH_MOST_ENGAGED_LOADING });
      const id = yield call(getContentId);
      const data = yield call(api.getMostEngaged, host, client, id, token);
      data.noData ? yield put({ type: types.FETCH_MOST_ENGAGED_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_MOST_ENGAGED_SUCCESS, data: data.response });
    } else {
      yield cancel();
    }
  } catch (error) {
    yield put({ type: types.FETCH_MOST_ENGAGED_FAIL, error });
  }
}

export function* fetchPromotionSocial() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);

    const data = yield call(api.getPromotionSocial, host, client, id, token);
    data.noData ? yield put({ type: types.FETCH_PROMOTION_SOCIAL_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_PROMOTION_SOCIAL_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_PROMOTION_SOCIAL_FAIL, error });
  }
}

export function* fetchPromotionInternal() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const { page, size } = yield select(selectors.getPromotionInternalPagination);
    const data = yield call(api.getPromotionInternal, host, client, id, token, page, size);
    data.noData
      ? yield put({ type: types.FETCH_PROMOTION_INTERNAL_SUCCESS, data: { results: 'noData' } })
      : yield put({ type: types.FETCH_PROMOTION_INTERNAL_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_PROMOTION_INTERNAL_FAIL, error });
  }
}

export function* fetchVisitorsThatStay() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const { page, size } = yield select(selectors.getVisitorsThatStayPagination);
    const data = yield call(api.visitorsThatStay, host, client, id, token, page, size);
    if (data.noData) {
      const response = { results: 'noData', driver: initialState.visitors.driver };
      yield put({ type: types.FETCH_VISITORS_THAT_STAY_SUCCESS, data: response });
    } else {
      yield put({ type: types.FETCH_VISITORS_THAT_STAY_SUCCESS, data: data.response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_VISITORS_THAT_STAY_FAIL, error });
  }
}

export function* fetchScoreInternalPromotionsSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const dateRange = yield select(selectors.getDateRange);
    const timezone = yield select(authSelectors.getTimezone);
    const data = yield call(api.getInternalPromotionsTimeline, host, client, id, token, dateRange, timezone);
    data.noData ? yield put({ type: types.FETCH_INTERNAL_PROMOTIONS_TIMELINE_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_INTERNAL_PROMOTIONS_TIMELINE_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_INTERNAL_PROMOTIONS_TIMELINE_FAIL, error });
  }
}

export function* fetchScoreSocialPromotionsSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const dateRange = yield select(selectors.getDateRange);
    const timezone = yield select(authSelectors.getTimezone);
    const data = yield call(api.getSocialPromotionsTimeline, host, client, id, token, dateRange, timezone);
    data.noData ? yield put({ type: types.FETCH_SOCIAL_PROMOTIONS_TIMELINE_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_SOCIAL_PROMOTIONS_TIMELINE_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_SOCIAL_PROMOTIONS_TIMELINE_FAIL, error });
  }
}

export function* fetchPerformanceBreakdownSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const id = yield call(getContentId);
    const dateRange = yield select(selectors.getDateRange);
    const portrait = yield select(selectors.getPortrait);
    const data = yield call(api.getPerformanceBreakdown, host, client, id, token, dateRange, portrait);
    data.noData ? yield put({ type: types.FETCH_PERFORMANCE_BREAKDOWN_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_PERFORMANCE_BREAKDOWN_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.FETCH_PERFORMANCE_BREAKDOWN_FAIL, error });
  }
}

export function* fetchTrafficSourceSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const lastFetched = yield select(selectors.getTrafficSourceLastFetched);
    const shouldFetch = isOutdated(lastFetched, EXPIRY_IN_MS);

    if (shouldFetch) {
      yield put({ type: types.FETCH_TRAFFIC_SOURCE_LOADING });
      const id = yield call(getContentId);
      const platforms = yield select(authSelectors.getPlatforms);
      const data = yield call(api.getTrafficSourceData, host, client, id, token, platforms.map((p) => p.toLowerCase()));
      data.noData ? yield put({ type: types.FETCH_TRAFFIC_SOURCE_SUCCESS, data: 'noData' }) : yield put({ type: types.FETCH_TRAFFIC_SOURCE_SUCCESS, data: data.response });
    } else {
      yield cancel();
    }
  } catch (error) {
    yield put({ type: types.FETCH_TRAFFIC_SOURCE_FAIL, error });
  }
}

export function* fetchNewScoreDataSaga() {
  yield put({ type: types.FETCH_PERFORMANCE });
  yield put({ type: types.FETCH_PERFORMANCE_BREAKDOWN });
  yield put({ type: types.FETCH_INTERNAL_PROMOTIONS_TIMELINE });
  yield put({ type: types.FETCH_SOCIAL_PROMOTIONS_TIMELINE });
  yield put({ type: types.FETCH_TRAFFIC });
}

export default function* contentSaga() {
  yield takeLatest(types.SET_ID, setContentId);
  yield takeLatest(types.SET_DATE_RANGE, fetchNewScoreDataSaga); // TODO: We should find a new method to call performance here. Its too coupled.
  yield takeLatest(types.FETCH_ALL_TIME, fetchAllTime);
  yield takeLatest(types.FETCH_7_DAY, fetch7Day);
  yield takeLatest(types.FETCH_PROMOTION_SOCIAL, fetchPromotionSocial);
  yield takeLatest(types.FETCH_PROMOTION_INTERNAL, fetchPromotionInternal);
  yield takeLatest(types.FETCH_VISITORS_THAT_STAY, fetchVisitorsThatStay);
  yield takeLatest(types.FETCH_TRAFFIC, fetchTrafficDataSaga);
  yield takeLatest(types.FETCH_MOST_READERS, fetchMostReaderSaga);
  yield takeLatest(types.FETCH_PERFORMANCE, fetchPerformanceDataSaga);
  yield takeLatest(types.FETCH_TOP_REFERRER, fetchTopReferrerDataSaga);
  yield takeLatest(types.FETCH_MINUTES_READING, fetchMinutesReadingSaga);
  yield takeLatest(types.FETCH_MOST_ENGAGED, fetchMostEngagedSaga);
  yield takeLatest(
    types.FETCH_INTERNAL_PROMOTIONS_TIMELINE,
    fetchScoreInternalPromotionsSaga,
  );
  yield takeLatest(
    types.FETCH_PERFORMANCE_BREAKDOWN,
    fetchPerformanceBreakdownSaga,
  );
  yield takeLatest(types.FETCH_TRAFFIC_SOURCE, fetchTrafficSourceSaga);
  yield takeLatest(types.FETCH_SOCIAL_PROMOTIONS_TIMELINE, fetchScoreSocialPromotionsSaga);
}
