import uniq from 'lodash/uniq';

// Utils
import { queryString, formatTableRequestBody, pathBuilder, getResource } from 'utils/api';
import { convertToSeconds, getMostRecentTime } from 'utils/dateTime';
import { saveBlob } from 'utils/download';

/**
 * Construct Article Query
 * @param {Object} params  Filter Values
 * @param {Object} opts    Column and query string value
 */
const constructQuery = (params, opts) => {
  const { dateRange, page, size, sortConfig, displayColumns, timezone } = opts;

  const qs = {
    start: convertToSeconds(dateRange.startDate),
    end: convertToSeconds(getMostRecentTime(new Date(), dateRange.endDate)),
    page: page || 0,
    size: size || 10,
    sort: sortConfig ? sortConfig.sort : 'sophiScore',
    order: sortConfig ? sortConfig.sortOrder : 'desc',
    tzName: timezone
  };

  const body = formatTableRequestBody({ displayColumns }, params);

  return { body, qs };
};

export const getDiveArticleData = (host, client, token, params, opts) => {
  // Error Handling
  if (!opts || !opts.dateRange) {
    throw new Error('DateRanges required but not specified');
  } else if (!opts.dateRange.startDate || !opts.dateRange.endDate) {
    throw new Error('Some or all dateRange values are invalid');
  }

  // Columns always included in response
  const defaultColumns = ['contentId', 'sophiScore', 'headline'];
  opts.displayColumns = uniq([...defaultColumns, ...opts.displayColumns.filter((col) => !col.includes('__'))]);

  const { body, qs } = constructQuery(params, opts);

  const path = pathBuilder(host, {
    section: 'article',
    client,
    query: `table?${queryString(qs)}`
  });

  return getResource({
    method: 'POST',
    endpoint: path,
    token,
    body
  })
    .then((data) => ({
      response: {
        data: data.rows,
        columns: opts.displayColumns,
        count: data.size,
      }
    }));
};

export const getDiveArticleDownload = (host, client, token, params, opts) => {
  // Error Handling
  if (!opts || !opts.dateRange) {
    throw new Error('DateRanges required but not specified');
  } else if (!opts.dateRange.startDate || !opts.dateRange.endDate) {
    throw new Error('Some or all dateRange values are invalid');
  }

  // Columns always included in response
  const defaultColumns = ['sophiScore', 'headline'];
  opts.displayColumns = uniq([...defaultColumns, ...opts.displayColumns.filter((col) => !col.includes('__'))]);

  const { body, qs } = constructQuery(params, opts);
  delete qs.page;
  qs.responseFormat = 'csv';

  const path = pathBuilder(host, {
    section: 'article',
    client,
    query: `table?${queryString(qs)}`
  });

  const headers = {
    Authorization: 'Bearer ' + token,
  };

  const fetchParams = {
    method: 'POST',
    headers,
    body
  };

  return fetch(path, fetchParams)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) throw new Error('No Valid Response');
      return response.text();
    })
    .then((blob) => saveBlob(opts.fileName, blob));
};
