import filter from 'lodash/filter';

export const isConfigured = (state) => state.auth.configuration.isConfigured;
export const isAuthenticated = (state) => state.auth.authentication.isAuthenticated;
export const getMainHost = (state) => state.auth.configuration.mainHost;
export const getOrganizations = (state) => state.auth.configuration.organizations;
export const getAllowedOrganizations = (state) => state.auth.configuration.allowedOrganizations;
export const getMainHostID = (state) => state.auth.configuration.orgId;
export const getDiveAPI = (state) => getApiFromName(state, 'DIVE');
export const getNowAPI = (state) => getApiFromName(state, 'NOW');
export const getSectionAPI = (state) => getApiFromName(state, 'SECTION');
export const getConfigAPI = (state) => getApiFromName(state, 'TENANT_CONFIG_API');
export const getCuratorAPI = (state) => getApiFromName(state, 'CURATOR');
export const getCuratorAuomationAPI = (state) => getApiFromName(state, 'CURATOR_AUTOMATION');
export const getDomain = (state) => getApiFromName(state, 'AUTH0');
export const isUserManagementAvailable = (state) => !!getApiFromName(state, 'USER_MANAGEMENT');
export const getUserManagementAPI = (state) => getApiFromName(state, 'USER_MANAGEMENT');
export const getTableauDashboardUrl = (api) => (state) => getApiFromName(state, api);
export const getClientId = (state) => {
  if (state.auth.configuration.realm && state.auth.configuration.realm.spaClientId) {
    return state.auth.configuration.realm.spaClientId;
  }
  return null;
};
export const getConnection = (state) => state.auth.configuration.realm?.realm;
export const shouldTokenRefresh = (state) => state.auth.authentication.shouldTokenRefresh;
export const getToken = (state) => state.auth.authentication.token.value;
export const getTokenExp = (state) => state.auth.authentication.token.expiry;
export const getDisplayName = (state) => state.auth.configuration.displayName;
export const getHudLink = (state) => state.auth.configuration.chromeExtension && state.auth.configuration.chromeExtension.url;
export const getAvailableProducts = (state) => (state.auth.configuration.products || []).map((item) => item.name && item.name.toLowerCase());
export const getAvailableReports = (state) => {
  const reports = [];

  for (const product of state.auth.configuration.products) {
    if (Array.isArray(product.features)) {
      for (const feature of product.features) {
        if (feature.name.toLowerCase() === 'report' && feature.enabled) {
          reports.push(product.name.toLowerCase());
        }
      }
    }
  }

  return reports;
};
export const getEnabledNowProductFeatures = (state) => getProductFeatures(state, 'NOW', true);
export const getDisabledNowProductFeatures = (state) => getProductFeatures(state, 'NOW', false);
export const getEnabledDiveProductFeatures = (state) => getProductFeatures(state, 'DIVE', true);
export const getDisabledDiveProductFeatures = (state) => getProductFeatures(state, 'DIVE', false);
export const getEnabledCuratorProductFeatures = (state) => getProductFeatures(state, 'CURATOR', true);
export const getTimezone = (state) => state.auth.configuration.timezone;
export const getBanners = (state) => state.auth.configuration.banners && state.auth.configuration.banners[0];
export const getPlatforms = (state) => state.auth.configuration.platforms;
export const getTenantId = (state) => state.auth.configuration.tenantId;
export const getEnv = (state) => state.auth.configuration.env;
export const getServices = (state) => state.auth.configuration.services || [];
export const getPermissionScope = (state) => {
  const organizations = getOrganizations(state) || [];
  return organizations.map(({ id }) => `tenants:${getTenantId(state)}:organizations:${id}:webapps:read`);
};

const getApiFromName = (state, key) => {
  const allApis = getServices(state);
  const obj = allApis.find(({ identifier }) => identifier.toLowerCase() === key.toLowerCase());
  return obj ? obj.url : '';
};

/**
 * Method which will return an arry of feature names that match the enabled flag (matcher) for a particular product (key)
 * @param {Object} state - The state object
 * @param {String} key - The name you wish to find products for as a string
 * @param {Boolean} matcher - The type of features you wish to get eg. { enabled: true/false }
 */
const getProductFeatures = (state, key, matcher) => {
  const products = state.auth.configuration.products || [];
  const obj = products.find(({ name: product }) => product === key);
  const matchingObjects = obj ? filter(obj.features, {enabled: matcher}) : null;
  return matchingObjects ? matchingObjects.map((feature) => feature.name) : [];
};
