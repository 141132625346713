import React from 'react';
import Tabs from 'atoms/Tabs';
import Tab from 'atoms/Tabs/Tab';
import TabPanel from 'atoms/Tabs/TabPanel';
import TabHeader from 'atoms/Tabs/TabHeader';
import TabBody from 'atoms/Tabs/TabBody';
import FilterPanel from './FilterPanel';

const HorizontalFilterTabs = ({
  filterList,
  config,
  pendingFilters,
  options,
  scoreFilterSet,
  onChange,
  onTabClick
}) => {
  const handleTabClick = (tab) => {
    const filterName = tab.replace('-tab', '');
    onTabClick(filterName);
  };

  return (
    <div data-testid="horizontal-filter-tabs" style={{ height: '100%' }}>
      <Tabs onChange={handleTabClick} responsiveHeight>
        <TabHeader label="Filter Types">
          {filterList.map((filter) => (
            <Tab key={`${filter}-tab`} panelId={`${filter}-panel`} id={`${filter}-tab`}>
              {filter === 'score'
                ? `${config[filter]?.label || filter} ${scoreFilterSet ? '(1)' : ''}`
                : `${config[filter]?.label || filter} ${(pendingFilters?.[filter] && pendingFilters?.[filter].length) ? `(${pendingFilters?.[filter].length})` : ''}`
              }
            </Tab>
          ))}
        </TabHeader>
        <TabBody>
          {filterList.map((filter) => (
            <TabPanel key={`${filter}-panel`} id={`${filter}-panel`} tabId={`${filter}-tab`}>
              <div style={{ padding: '12px 0', display: 'flex', height: '100%' }}>
                <FilterPanel
                  loading={options?.[filter]?.loading}
                  type={config[filter].type}
                  selected={pendingFilters?.[filter] || []}
                  id={filter}
                  onChange={onChange}
                  data={options?.[filter]?.data}
                />
              </div>
            </TabPanel>
          ))}
        </TabBody>
      </Tabs>
    </div>
  );
};

export default HorizontalFilterTabs;
