import types from './diveTypes';
import configTypes from './configTypes';
import { ROUTE_NAMES } from 'utils/paths';
import { WIDGET_NAME_MAP } from 'apis/config';

// Dummy function. Should be deleted in next PR.
export const setNewDateRange = (dateObject) => ({
  type: types.DIVE_DATE_RANGE_SET , dateObject
});

export const setFilterValue = (filters) => ({
  type: types.DIVE_FILTER_SET,
  data: filters,
});

export const setSelectedDiveArticlesColumns = (columns) => ({
  type: types.SET_SELECTED_DIVE_ARTICLES_COLUMNS,
  columns,
});

export const setSelectedDiveAuthorsColumns = (columns) => ({
  type: types.SET_SELECTED_DIVE_AUTHORS_COLUMNS,
  columns,
});

export const setSelectedDiveSectionsColumns = (columns) => ({
  type: types.SET_SELECTED_DIVE_SECTIONS_COLUMNS,
  columns,
});

export const fetchComparisonChartData = () => ({
  type: types.DIVE_COMPARISON_CHART,
});

export const refetchDashboardData = () => ({
  type: types.REFETCH_DASHBOARD_DATA,
});

export const resetDashboardValues = () => ({
  type: types.RESET_DASHBOARD_VALUES,
});

export const fetchDiveFilterOptions = () => ({
  type: types.DIVE_FILTER_OPTIONS_FETCH,
});

export const fetchDiveAutosuggest = (query) => ({
  type: types.DIVE_FILTER_AUTOSUGGEST,
  query
});

export const clearDiveAutosuggest = () => ({
  type: types.CLEAR_DIVE_AUTOSUGGEST,
});

// Author Page Actions
export const fetchDiveAuthors = () => ({
  type: types.FETCH_DIVE_AUTHORS
});

export const fetchDiveAuthorsColumns = () => ({
  type: configTypes.FETCH_WIDGET_CONFIG,
  pageName: ROUTE_NAMES.diveAuthors,
  widgetName: WIDGET_NAME_MAP.AUTHOR_COLUMNS
});

export const setDiveAuthorPaginationValue = (paginationType, paginationValue) => ({
  type: types.UPDATE_DIVE_AUTHOR_PAGINATION,
  paginationType,
  paginationValue
});

export const setDiveAuthorSort = (sortValues) => ({
  type: types.UPDATE_DIVE_AUTHOR_SORT,
  sortValues,
});

// Article Page Actions
export const fetchDiveArticles = () => ({
  type: types.FETCH_DIVE_ARTICLES
});

export const fetchDiveArticlesColumns = () => ({
  type: configTypes.FETCH_WIDGET_CONFIG,
  pageName: ROUTE_NAMES.diveArticles,
  widgetName: WIDGET_NAME_MAP.ARTICLES_COLUMNS
});

export const setDiveArticlePaginationValue = (paginationType, paginationValue) => ({
  type: types.UPDATE_DIVE_ARTICLES_PAGINATION,
  paginationType,
  paginationValue
});

export const setDiveArticleSort = (sortValues) => ({
  type: types.UPDATE_DIVE_ARTICLES_SORT,
  sortValues,
});

// Author Page Action
export const fetchDiveAuthorArticles = (location) => {
  const authorName = location.pathname.split('/')[3];
  return {
    type: types.FETCH_DIVE_AUTHOR_ARTICLES,
    overrideFilters: {
      authors: [authorName],
    }
  };
};

// Section Page Actions
export const fetchDiveSections = () => ({
  type: types.FETCH_SECTIONS,
});

export const fetchDiveSectionsColumns = () => ({
  type: configTypes.FETCH_WIDGET_CONFIG,
  pageName: ROUTE_NAMES.diveSections,
  widgetName: WIDGET_NAME_MAP.SECTION_COLUMNS
});
