import React from 'react';
import { Tag } from 'antd';

import { FERuleType } from '../types';
import { getRuleCaption } from '../utils/copy';

const RuleTypeTag: React.FC<{
  rule: FERuleType | undefined;
  style?: React.CSSProperties;
  checkable?: boolean;
  checked?: boolean;
  verbose?: boolean;
  onClick?: () => void;
}> = ({ checkable, checked, rule, style, verbose, onClick }) => {
  let label: string, color: string;

  switch(rule) {
    case 'replace':
      label = 'Replace';
      color = 'warning';
      break;
    case 'out':
      label = 'Remove';
      color = 'error';
      break;
    case 'ban':
      label = 'Ban';
      color = 'default';
      break;
    case 'in':
    default:
      label = 'Add';
      color = 'processing';
      break;
  }

  if (verbose) label = getRuleCaption(rule);

  function handleClick() {
    if (typeof onClick === 'function') onClick();
  }

  return checkable
    ? <Tag.CheckableTag style={{ marginRight: 'auto', userSelect: 'none', ...style }} checked={checked || false} onClick={handleClick}>{label}</Tag.CheckableTag>
    : <Tag style={{ marginRight: 'auto', ...style }} color={color} onClick={handleClick}>{label}</Tag>;
};

export default RuleTypeTag;
