import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import Tag from '../Tag';
import styles from './Select.styles.scss';

const DropdownIndicator = (chevronProps) => {
  const { menuIsOpen } = chevronProps.selectProps;
  return (
    <span className={classNames([styles.dropdownIcon, { [styles.open]: menuIsOpen }])}>
      &#9662;
    </span>
  );
};

/* eslint-disable-next-line */
const MultiValue = ({ children, index, removeProps }) => (
  <Tag removeProps={removeProps} index={index} style={{ marginRight: 10 }}>
    {children}
  </Tag>
);

const Select = ({
  id,
  label,
  className,
  hideLabel,
  required,
  ...props
}) => (
  <div className={className}>
    <label id={`${id}-label`} data-hidden={`${hideLabel}`} className={styles.label} htmlFor={id}>{label}
      {required && <span aria-hidden="true" className={styles.requiredLabel}>*Required</span>}
    </label>
    <ReactSelect
      id={id}
      className={styles.select}
      classNamePrefix="custom-select"
      components={{
        MultiValue,
        DropdownIndicator
      }}
      aria-labelledby={`${id}-label`}
      {...props}
    />
  </div>
);

Select.defaultProps = {
  className: '',
  hideLabel: false,
  required: false,
};

// For an exhaustive list of what can be passed in ...props, please check the react-select documentation
// https://react-select.com/props
Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
};

export default Select;
