// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-BG22obn1IAz8XQu8avTk{list-style:none;-webkit-padding-start:0;padding-inline-start:0}.sophi-y4ojiCW9ApiBVvXkaKx5{padding-bottom:calc(.5*var(--space-1))}.sophi-Z9p3CNyyJl5rb1yTBOYm{padding-top:calc(.5*var(--space-1));font-size:1.25em;text-align:center}.sophi-iRLCT8KzTtXFf2HpJjd8{padding-top:calc(.5*var(--space-1));text-align:center}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/Filters/AndOr/AndOrFilter.styles.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,uBAAA,CAAA,sBAAA,CAGF,4BACE,sCAAA,CAGF,4BACE,mCAAA,CACA,gBAAA,CACA,iBAAA,CAGF,4BACE,mCAAA,CACA,iBAAA","sourcesContent":[".list {\n  list-style: none;\n  padding-inline-start: 0;\n}\n\n.item {\n  padding-bottom: calc(0.5 * var(--space-1));\n}\n\n.conjunction {\n  padding-top: calc(0.5 * var(--space-1));\n  font-size: 1.25em;\n  text-align: center;\n}\n\n.add {\n  padding-top: calc(0.5 * var(--space-1));\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "sophi-BG22obn1IAz8XQu8avTk",
	"item": "sophi-y4ojiCW9ApiBVvXkaKx5",
	"conjunction": "sophi-Z9p3CNyyJl5rb1yTBOYm",
	"add": "sophi-iRLCT8KzTtXFf2HpJjd8"
};
export default ___CSS_LOADER_EXPORT___;
