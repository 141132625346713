// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-OPGSoGjaql2XRHJfnxfa{background-color:#fff;padding:0.875rem 1.625rem}.sophi-OPGSoGjaql2XRHJfnxfa tbody [data-column=byline]{text-transform:capitalize}.sophi-OPGSoGjaql2XRHJfnxfa [data-column=score]{text-align:right}.sophi-OPGSoGjaql2XRHJfnxfa th[data-column=score] span{display:block}@media(min-width: 1024px){.sophi-OPGSoGjaql2XRHJfnxfa [data-column=score]{text-align:left}}@media(min-width: 1140px){.sophi-OPGSoGjaql2XRHJfnxfa{padding:1rem 1.625rem}}@media(min-width: 1920px){.sophi-OPGSoGjaql2XRHJfnxfa{padding:2.875rem 2.75rem}}", "",{"version":3,"sources":["webpack://./src/organisms/TopStoriesCard/TopStoriesCard.styles.scss"],"names":[],"mappings":"AAEA,4BACE,qBAAA,CACA,yBAAA,CAEA,uDACE,yBAAA,CAGF,gDACE,gBAAA,CAGF,uDACE,aAAA,CAGF,0BACE,gDACE,eAAA,CAAA,CAGJ,0BArBF,4BAsBI,qBAAA,CAAA,CAGF,0BAzBF,4BA0BI,wBAAA,CAAA","sourcesContent":["@import 'styles/util';\n\n.top-stories-card {\n  background-color: #FFF;\n  padding: pxToRem(14) pxToRem(26);\n\n  tbody [data-column=\"byline\"] {\n    text-transform: capitalize;  \n  }\n\n  [data-column=\"score\"] {\n    text-align: right;  \n  }\n\n  th[data-column=\"score\"] span {\n    display: block;\n  }\n\n  @media (min-width: $md) {\n    [data-column=\"score\"] {\n      text-align: left;  \n    }  \n  }\n  @media (min-width: $lg) {\n    padding: pxToRem(16) pxToRem(26);\n  }\n\n  @media (min-width: $xl) {\n    padding: pxToRem(46) pxToRem(44);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topStoriesCard": "sophi-OPGSoGjaql2XRHJfnxfa"
};
export default ___CSS_LOADER_EXPORT___;
