const types = {
  CONFIG_FETCH: 'CONFIG_FETCH',
  CONFIG_SUCCESS: 'CONFIG_SUCCESS',
  CONFIG_FAIL: 'CONFIG_FAIL',
  UPDATE_AUTH: 'UPDATE_AUTH',
  UPDATE_TOKEN_CACHE: 'UPDATE_TOKEN_CACHE',
  CACHE_TOKEN: 'CACHE_TOKEN',
  CACHE_TOKEN_SUCCESS: 'CACHE_TOKEN_SUCCESS',
  CACHE_TOKEN_FAILURE: 'CACHE_TOKEN_FAILURE',
  SET_GLOBAL_PLATFORMS: 'SET_GLOBAL_PLATFORMS',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
};

export default types;
