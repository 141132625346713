import React from 'react';
import PropTypes from 'prop-types';
import TabPanel from 'atoms/Tabs/TabPanel';
import TopStoriesCard from 'organisms/TopStoriesCard';
import BreakdownCard from 'atoms/BreakdownCard';
import formatBreakdownData from './formatBreakdownData';
import { getCellFormatter } from 'utils/table';
import ScoreBar from 'atoms/ScoreBar';
import styles from './TabPanels.styles.scss';
import useMedia from 'hooks/useMedia';

const TopStoriesTabPanel = ({ data, id, tabId, keyMetricName, keyMetricKey, showBreakdown, loading }) => {
  const loadingPanelContents = loading && (!data || !data.length);
  
  let scoreBreakdown = {};
  if (showBreakdown) {
    scoreBreakdown = formatBreakdownData(data);
  }

  const columns = [
    {
      Header: 'Rank',
      accessor: (row, index) => index + 1,
      Cell: ({ cell: { value }}) => (
        <span className={styles.rankCell}>{value}</span>
      ),
    },
    {
      Header: 'Headline',
      accessor: 'headline',
      Cell: getCellFormatter('headline', true)
    },
    {
      Header: 'Section',
      accessor: 'section',
      Cell: getCellFormatter('section', true),
      style: { textTransform: 'capitalize' }
    },
    {
      Header: 'Byline',
      accessor: 'byline',
      Cell: getCellFormatter('byline', true), 
    },
    {
      Header: 'Score',
      accessor: keyMetricKey,
      Cell: ({ cell, data }) => {
        return (cell.value && data[0]) ? <ScoreBar value={parseInt(cell.value)} max={data[0][keyMetricKey]} /> : '';
      }
    },
  ];

  const responsiveColumns = useMedia(
    ['(min-width: 768px)'],
    [columns],
    columns.slice(0, 2).concat({
      Header: 'Score',
      accessor: keyMetricKey,
      Cell: ({ cell: { value }}) => (
        value
          ? <span className={styles.simpleScoreCell}>{parseInt(value).toLocaleString()}</span>
          : ''
      ),
    })
  );

  const widths = responsiveColumns.length === 5 ? ['7%', '38%', '10%', '20%', '23%'] : ['5%', '85%', '10%'];

  return (

    <TabPanel id={id} tabId={tabId}>
      <TopStoriesCard
        id={`${id}-card`}
        data={data?.noData ? null : data}
        keyMetricName={keyMetricName}
        keyMetricKey={keyMetricKey}
        loading={loadingPanelContents}
        title={`Top ${keyMetricName} Stories`}
        columns={responsiveColumns}
        widths={widths}
      />
      {showBreakdown && (
        <BreakdownCard
          title="Score Breakdown"
          segments={scoreBreakdown.breakdownData}
          total={scoreBreakdown.total}
          displayFormat="summary"
          loading={loadingPanelContents}
        />
      )}
    </TabPanel>
  );
};

TopStoriesTabPanel.defaultProps = {
  data: [],
  showBreakdown: false,
  loading: false,
};

TopStoriesTabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  keyMetricName: PropTypes.string.isRequired,
  keyMetricKey: PropTypes.string.isRequired,
  data: PropTypes.array,
  showBreakdown:PropTypes.bool,
  loading: PropTypes.bool,
};

export default TopStoriesTabPanel;
