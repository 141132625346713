"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const parent_1 = require("../contexts/parent");
function useCuratorConfigApi(func, params) {
    const { service, tenant, host, getToken } = (0, parent_1.useParentContext)();
    return {
        fn: (data) => func({ ...params, ...data, service, tenant, host, getTokenSilently: getToken })
    };
}
;
exports.default = useCuratorConfigApi;
