import React from 'react';
import format from 'date-fns/format';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { FERuleType, Status } from '../types';

export function getRuleCaption(rule: FERuleType | undefined): string {
  switch (rule) {
    case 'replace':
      return 'Replace article in widget';
    case 'out':
      return 'Remove article from widget';
    case 'ban':
      return 'Ban article from page(s) / site';
    case 'in':
    default:
      return 'Add article to widget';
  }
}

export function getRuleDescription(rule: FERuleType | undefined): string {
  switch (rule) {
    case 'replace':
      return 'Replace an article in a widget with a different one; every other article stays in the same position.';
    case 'out':
      return 'Take an article out of a widget, though it could be promoted elsewhere on the page.';
    case 'ban':
      return 'Prevent an article from being promoted anywhere on the page.';
    case 'in':
    default:
      return 'Insert an article into a widget and shift the rest of the articles down so the last article drops out.';
  }
}

export function cardDateFormatter(date: string): string {
  try {
    return format(new Date(date), 'MMM do p');
  } catch {
    return '';
  }
}

export function getStatus(status: Record<Status, number>): {
  color: string;
  label: string;
  icon: React.ReactNode;
} {
  const { ACTIVE, FAILED, PENDING } = status;


  if (FAILED > 0) {
    if (PENDING > 0 || ACTIVE > 0) {
      return {
        label: 'Some failed',
        color: 'var(--red-700)',
        icon: <ExclamationCircleOutlined style={{ color: 'var(--orange-700)' }}/>,
      };
    }
    return {
      label: 'Failed',
      color: 'var(--red-700)',
      icon: <CloseCircleOutlined style={{ color: 'var(--red-700)' }}/>,
    };
  } else if (PENDING > 0) {
    return {
      label: 'Pending',
      color: 'var(--gray-600)',
      icon: <QuestionCircleOutlined style={{ color: 'var(--gray-600)' }}/>,
    };
  } else {
    return {
      label: 'Active',
      color: 'var(--green-700)',
      icon: <CheckCircleOutlined style={{ color: 'var(--green-700)' }}/>,
    };
  }
}


