import React from 'react';
import PropTypes from 'prop-types';
import styles from './Popover.styles.scss';
import 'wicg-inert';

const PopOver = ({ children, visible, ...rest }) => (
  <div id="popover" className={styles.popoverContainer} data-visible={visible} inert={visible ? null : ''} {...rest}>
    <div className={styles.popover} aria-hidden={`${!visible}`}>
      {children}
    </div>
  </div>
);

PopOver.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

export default PopOver;
