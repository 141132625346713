import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import styles from './PlaceholderText.styles.scss';

const PlaceholderText = ({
  className,
  children,
  iconName,
  heading,
  ...props
}) => (
  <div className={classNames([classNames, styles.wrapper])} {...props}>
    {iconName && <Icon name={iconName} type="circle" size="xl" />}
    {heading && <h1>{heading}</h1>}
    {children}
  </div>
);

PlaceholderText.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.node,
};

export default PlaceholderText;
