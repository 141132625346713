import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.styles.scss';

const Avatar = ({
  username,
  userImage,
  classes,
  ...rest
}) => {
  const displayName = useMemo(() => {
    if (!username) return '';
    return username.split('@')[0];
  }, [username]);

  return (<div className={styles.layout} {...rest}>
    <span className={`${styles.welcome} ${classes?.welcome || ''}`}>
      {`Welcome, ${displayName}`}
    </span>
    <img src={userImage} alt={'avatar'} className={`${styles.avatar} ${classes?.avatar || ''}`} />
  </div>);
};

Avatar.propTypes = {
  username: PropTypes.string,
  userImage: PropTypes.string,
  classes: PropTypes.shape({
    welcome: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

export default Avatar;
