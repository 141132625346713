import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Icon from '../Icon';
import styles from './IconButton.styles.scss';

const IconButton = ({
  size,
  icon,
  children,
  color,
  className,
  ...btnProps
}) => (
  <Button className={`${styles.iconButton} ${className || ''}`} size={size} {...btnProps}>
    <span>
      <Icon color={color} name={icon} size={size} type="basic" />
      {children && (
        <span className={styles.iconButtonText}>
          {children}
        </span>
      )}
    </span>
  </Button>
);

IconButton.defaultProps = {
  color: 'inherit'
};

IconButton.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  icon: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['teal', 'white', 'black', 'gray', 'inherit'])
};

export default IconButton;
