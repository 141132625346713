// Utils
import { pathBuilder, getResource } from 'utils/api';

/**
 * API call for Article Meta
 * @param {String} host - String representing the host
 * @param {String} client - String representing the clientID
 * @param {String} id - String representing the contentID
 * @param {String} token - String representing the access token
 */
export const getArticleInfo = (host, client, id, token, excludedFeatures) => {
  const path = pathBuilder(host, { section: 'content', client, contentId: id, query: 'meta' });

  const formatResponse = (response) => {
    if (Object.keys(response).includes('noData')) return ({ noData: true });
    const payload = {};
    Object.keys(response).forEach((key) => {
      if (response[key] && !excludedFeatures.includes(formatKey(key))) {
        payload[formatKey(key)] = formatValue(response[key], typeof response[key]);
      }
    });
    return payload;
  };

  const data = getResource({
    endpoint: path,
    token
  })
    .then((resp) => formatResponse(resp))
    .catch((error) => {
      throw new Error(error);
    });

  return data;
};

const formatKey = (key) => {
  const conversions = {
    datePublished: 'published',
    byline: 'author',
    sectionName: 'section',
    accessName: 'access',
    contentId: 'article-id',
  };
  return conversions[key] ? conversions[key] : key;
};

const formatValue = (value, type) => {
  if (type === 'object') {
    return value.join(', ');
  }
  if (type === 'number') {
    return parseInt(value) * 1000;
  }
  return value;
};

export default getArticleInfo;