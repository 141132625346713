// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-wOfFTjonjQTYrnxz41Ob{display:-webkit-box;display:-ms-flexbox;display:flex;gap:var(--space-1);-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.sophi-J87YXYz5eaHHgExE22ED{display:-webkit-box;display:-ms-flexbox;display:flex;gap:var(--space-1);-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.sophi-XTxQGXomPkuqIsnkI3Af{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/PageImport/Preview.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".switch {\n  display: flex;\n  gap: var(--space-1);\n  justify-content: flex-end;\n}\n\n.buttons {\n  display: flex;\n  gap: var(--space-1);\n  justify-content: flex-end;\n}\n\n.progress {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "sophi-wOfFTjonjQTYrnxz41Ob",
	"buttons": "sophi-J87YXYz5eaHHgExE22ED",
	"progress": "sophi-XTxQGXomPkuqIsnkI3Af"
};
export default ___CSS_LOADER_EXPORT___;
