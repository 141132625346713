// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-UvuL2TzMx3i2pNrasaEC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.sophi-GXaXftic0WB0nN68S8Mv{-ms-flex-preferred-size:800px;flex-basis:800px}.sophi-Vo43hGJ4fHqYDnd2EEE_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;gap:calc(.5*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/PageConfig/PageConfigForm.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAGF,4BACE,6BAAA,CAAA,gBAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,2BAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n}\n\n.wrapper {\n  flex-basis: 800px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: calc(0.5 * var(--space-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-UvuL2TzMx3i2pNrasaEC",
	"wrapper": "sophi-GXaXftic0WB0nN68S8Mv",
	"buttons": "sophi-Vo43hGJ4fHqYDnd2EEE_"
};
export default ___CSS_LOADER_EXPORT___;
