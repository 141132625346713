// Root
import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import LoadingState from 'sophi-design-system/src/components/LoadingState';

// Custom styles
import styles from './AppLoading.styles.scss';

const AppLoading = (props) => {

  const spanify = (string) => {
    return string.split('').map((c, i) => <span key={string + c + i}>{c}</span>);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.lockup}>
        <LoadingState customStyles={{}}/>
        <h1>{spanify('Loading')}</h1>
      </div>
    </div>
  );
};

AppLoading.propTypes = {
};

export default AppLoading;