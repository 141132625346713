export const ARTICLE_FETCH_DATA = 'ARTICLE_FETCH_DATA';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';
export const ARTICLE_FETCH_FAILED = 'ARTICLE_FETCH_FAILED';
export const ARTICLE_TOGGLE_METADATA = 'ARTICLE_TOGGLE_METADATA';

export const fetchArticleData = () => {
  return { type: ARTICLE_FETCH_DATA };
};

export const fetchArticleDataSuccess = (data) => {
  return { type: ARTICLE_FETCH_SUCCESS, data: data.article };
};

export const toggleMetadata = () => ({
  type: ARTICLE_TOGGLE_METADATA,
});
