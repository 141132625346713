import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from 'sophi-design-system/src/components/IconButton';
import MessageBar from 'sophi-design-system/src/components/MessageBar';
import SophiTable from 'sophi-design-system/src/components/Table';
import { useAuth0 } from 'utils/Auth0Provider';
import useMedia from 'hooks/useMedia';
import {
  getUsers,
  areUsersLoading,
  getUsersError,
  getUserAdminMessage,
  isUserActionLoading,
  getUserActionError,
  getPagination,
  getRoles,
  getEditRoles,
} from 'selectors/admin';
import { actions } from '../../reducers/admin';
import styles from './Admin.styles.scss';
import Modal from './AdminModal';
import { SHOW_DELETE, SHOW_CREATE, SHOW_EDIT } from './constants';

const Admin = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  // User List
  const data = useSelector(getUsers);
  const loading = useSelector(areUsersLoading);
  const error = useSelector(getUsersError);
  // Message Bar contents
  const message = useSelector(getUserAdminMessage);
  // User Actions (Create/Edit/Delete)
  const userActionLoading = useSelector(isUserActionLoading);
  const userActionError = useSelector(getUserActionError);
  const pagination = useSelector(getPagination);
  const userRoles = useSelector(getRoles);
  const userEditRoles = useSelector(getEditRoles);

  const [active, setActive] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (active && !userActionLoading && !userActionError) {
      setActive(null);
    }
  }, [userActionLoading]);

  useEffect(() => {
    dispatch(actions.getRoles(user.sub));
    dispatch(actions.getUsers());
  }, []);

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email Address',
      accessor: 'email',
    },
    {
      Header: 'Password',
      Cell: () => '****************'
    },
    {
      id: 'actions',
      Cell: ({ row : { original }}) => {
        const canDelete = user.email !== original.email;
        return (
          <div className={styles.btnContainer}>
            <IconButton
              onClick={() => handleEditModalOpen(original)}
              title="Edit User"
              color="teal"
              icon="Create"
            />
            <IconButton
              color={canDelete ? 'teal' : 'gray'}
              icon="Delete"
              disabled={!canDelete}
              onClick={() => handleDeleteModalOpen(original)}
              title="Delete User"
            />
          </div>
        );
      }
    },
  ];

  const tableColumns = useMedia(['(min-width: 768px)'], [columns], [columns[3], ...columns.slice(0, 3)]);

  const handleEdit = (user) => dispatch(actions.editUser({ userID: selectedUser.user_id, user}));

  const handleDelete = ({ user_id }) => dispatch(actions.deleteUser(user_id));

  const handleEditModalOpen = (user) => {
    setActive(SHOW_EDIT);
    dispatch(actions.getEditRoles(user.user_id));
    setSelectedUser(user);
  };

  const handleDeleteModalOpen = (user) => {
    setActive(SHOW_DELETE);
    setSelectedUser(user);
  };

  const handleCancel = () => {
    setActive(false);
    dispatch(actions.resetUserAdminState());
  };

  const handleSubmit = (type, body) => {
    if (type === 'create') {
      dispatch(actions.createUser(body));
    }
    if (type === 'delete') {
      handleDelete(body);
    }
    if (type === 'edit') {
      handleEdit(body);
    }
  };

  const handlePageChange = (page) => {
    dispatch(actions.setPagination({
      ...pagination,
      page
    }));
    dispatch(actions.getUsers());
  };

  const handlePageSizeChange = (size) => {
    const maxPage = Math.ceil(pagination.count / size) - 1;
    dispatch(actions.setPagination({
      ...pagination,
      size,
      page: pagination.page > maxPage ? maxPage : pagination.page,
    }));
    dispatch(actions.getUsers());
  };

  return (
    <>
      <div className={styles.admin}>
        <Modal
          active={active}
          onClose={handleCancel}
          onSubmit={handleSubmit}
          onPasswordReset={(email) => dispatch(actions.sendPasswordReset(email))}
          selectedUser={{
            ...selectedUser,
            roles: userEditRoles.map((role) => ({ value: role.id, label: role.name }))
          }}
          roles={userRoles}
          submitLoading={userActionLoading}
          error={userActionError}
        />
        <h2>Manage Users</h2>
        <div>
          <IconButton className={styles.addUserBtn} icon="Add" onClick={() => {
            dispatch(actions.getRoles(user.sub));
            setActive(SHOW_CREATE);
          }}>
            Add New User
          </IconButton>
        </div>
        <div>
          <SophiTable
            id="user-admin-table"
            variant="white"
            loading={loading}
            columns={tableColumns}
            data={data}
            hideFooter
            hideEditColumns
            error={error}
            showTopPagination
            pagination={pagination}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {message && (
        <MessageBar action={() => dispatch(actions.setUserAdminMessage(null))} actionLabel="Dismiss">
          An email has been sent to the user to reset their password.
        </MessageBar>
      )}
    </>
  );
};

export default Admin;
