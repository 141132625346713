import React, { useEffect, useState } from 'react';

import { Button, Input, Select, Space } from 'antd';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

import { useOverrides } from '../contexts/OverridesProvider';

const SortBy: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { setSortBy } = useOverrides();
  const [ ascending, setAscending ] = useState<boolean>(true);
  const [ sortBy, setSort ] = useState<'expirationDate' | 'creationDate'>('expirationDate');

  useEffect(() => {
    setSortBy({ asc: ascending, by: sortBy });
  }, [ ascending, sortBy ]);

  return (
    <Space>
      <label>Sort By</label>
      <Input.Group compact>
        <Select defaultValue={sortBy} onChange={(v) => setSort(v)}>
          <Select.Option value="expirationDate">Expires At</Select.Option>
          <Select.Option value="creationDate">Created At</Select.Option>
        </Select>
        <Button onClick={() => setAscending(!ascending)} icon={ascending ? <SortAscendingOutlined/> : <SortDescendingOutlined/> }/>
      </Input.Group>
      <>{children}</>
    </Space>
  );
};
export default SortBy;
