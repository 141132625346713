// Root
import React from 'react';
import uniq from 'lodash/uniq';
import findIndex from 'lodash/findIndex';

// Utils
import { formatTableRequestBody, pathBuilder, getResource } from 'utils/api';
import { format } from 'utils/number';
import { convertToSeconds } from 'utils/dateTime';
import { nonDeviceDiveSectionsColumns, deviceTopDiveSectionsColumns } from 'apis/config/widgets/table/dive';

// SVG
import { ReactComponent as ChevronDown } from 'assets/svg/icon-arrow-down.svg';
import { difference } from 'lodash';

const SUBROW_KEY = 'sectionChildren';

const generateColumnsFromData = (response, columns, collapsible = true) => {
  // Create the label and accessor for react-table columns
  // https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#table-options
  const dataColumns = columns.map((ck) => {
    const contentType = typeof response[0][ck];

    let Header = '';
    if (nonDeviceDiveSectionsColumns[ck]) {
      Header = nonDeviceDiveSectionsColumns[ck].label;
    } else if (deviceTopDiveSectionsColumns[ck]) {
      Header = ck === 'sophiScore' ? deviceTopDiveSectionsColumns[ck].label : `${deviceTopDiveSectionsColumns[ck].label} ${deviceTopDiveSectionsColumns[ck].platform}`;
    }

    return {
      Header,
      accessor: ck,
      Cell: (e) => {
        if (typeof e.cell.value === 'number') {
          return format(e.cell.value, 'number', { maximumFractionDigits: 0 });
        } else {
          return e.cell.value || '';
        }
      },
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => {
            if (contentType === 'number') {
              return info.rows.reduce((sum, row) => {
                return typeof row.values[ck] === 'number' ? row.values[ck] + sum : sum;
              }, 0);
            } else if (ck === 'subsectionName') {
              return 'TOTALS';
            }
            return '';
          },
          [info.rows]
        );

        return format(total, 'number', {maximumFractionDigits: 0 });
      },
      contentType,
    };
  });

  // Adds constant column for expander
  const renderExpander = (expanded) => {
    return (
      <div className={`control-container ${expanded ? 'open' : 'closed'}`}>
        <ChevronDown style={{ display: 'inline-block', color: '#6E6E6E' }} />
      </div>
    );
  };

  if (collapsible) {
    dataColumns.unshift({
      accessor: 'expander',
      Cell: ({ row }) => row.canExpand ? (
        <span
          {
          ...row.getToggleRowExpandedProps({})
          }
        >
          {renderExpander(row.isExpanded)}
        </span>
      ) : null,
      width: 62,
      minWidth: 62
    });
  };

  const widths = dataColumns.map((sk) => sk.accessor === 'expander' ? '64px' : '200px');

  return {
    columns: dataColumns,
    widths,
  };
};

/**
 * This method will add subRows (for react-table) based of the SUBROW_KEY
 * @param {Array} rows - Array of rows
 * @param {Array} columns - Array of columns
 * @returns {Array} Rows with subRows added
 */
const addSubRows = (rows, columns) => {
  return rows.map((row) => {
    const subRows = row[SUBROW_KEY] ? row[SUBROW_KEY].map((sc) => {
      const subRow = {};
      columns.forEach((column) => {
        if (column.accessor === 'expander') { return null; }
        column.accessor === 'subsectionName' ? subRow[column.accessor] = sc[sc.length - 1] : subRow[column.accessor] = <span className={'loading-spinner'} />;
      });
      return subRow;
    }) : [];

    return {
      ...row,
      subRows,
      loaded: false,
    };
  });
};

/**
 * Will recursively add newRows to currentTree based off the array of breadcrumbs
 * @param {Object} currentTree - Stored table state
 * @param {Array} breadcrumbs - Array of arrays matching subsectionName
 * @param {Array} newRows - New rows that should be added at the provided breadcrumb
 * @returns {Array} Array representing the new table rows
 */
const addSubRowsToParent = (currentTree, breadcrumbs, newRows) => {
  const indexPath = [];
  const updatedRows = currentTree && currentTree.rows ? [...currentTree.rows] : [];

  // Set up a reference to the array needed for generating the indexPath
  let rows = updatedRows;
  for (let i = 0; i < breadcrumbs.length; i++) {
    // Find the matching object index and store it
    const x = findIndex(rows, { subsectionName: breadcrumbs[i] });
    indexPath.push(x);
    // If the object exists, store a reference to it
    const crumb = rows[x];
    rows = crumb ? crumb.subRows : rows;
  }

  // Set up a reference to the array we want to inject into
  let tmp = updatedRows;
  indexPath.forEach((path, count) => {
    // First path entry is always applied to root, there after to subRows
    count === 0 ? tmp = tmp[path] : tmp = tmp.subRows[path];
    // If its the final entry in the path, append the newRows
    if (count === indexPath.length - 1) {
      tmp.subRows = newRows;
      tmp.loaded = true;
    };
  });
  return updatedRows;
};

const formatSectionData = (response, path, currentTable, columnKeys) => {
  response.forEach((r) => r.topLevel ? r.subsectionName = `${r.subsectionName} (Top Level)` : null);
  const { columns, widths } = generateColumnsFromData(response, columnKeys);
  // If there is nothing in the store we want to populate it for first render
  if (!currentTable) {
    const rows = addSubRows(response, columns);

    return {
      response: {
        columns,
        rows,
        widths,
        lastFetched: path || '',
      }
    };
  } else {
    // Otherwise we will only augment the stored tree with new response values

    // Path of tree minus last entry (new data)
    let breadcrumb = [];
    if (path && path.length) {
      breadcrumb = [...path[0]];
      breadcrumb.pop();
    }

    const rows = addSubRowsToParent(
      currentTable,
      breadcrumb,
      addSubRows(response, columns)
    );

    return {
      response: {
        ...currentTable,
        rows,
        lastFetched: path,
      }
    };
  }
};

export const getSectionData = (host, client, token, path, opts) => {
  const { dateRange, currentTable, filters, columns } = opts;

  const defaultColumns = ['subsectionName', 'articles', 'sophiScorePerArticle'];
  const displayColumns = difference(columns, defaultColumns).filter((c) => !c.includes('__'));

  const { authors, headlines, keyword, ownership, access, type, score } = filters || [];
  const params = { authors, headlines, keyword, ownership, access, type, score };

  if (path) {
    params.sections = path;
  };

  const body = formatTableRequestBody(
    { displayColumns },
    params
  );

  const url = pathBuilder(host, {
    client,
    query: `table/sections?start=${convertToSeconds(dateRange.startDate)}&end=${convertToSeconds(dateRange.endDate)}`
  });

  const data = getResource({
    method: 'POST',
    endpoint: url,
    body,
    token
  })
    .then((data) => {
      const rows = data && data.noData ? [] : data;
      return formatSectionData(rows, path, currentTable, uniq([...defaultColumns, ...displayColumns]));
    });

  return data;
};
