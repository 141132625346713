import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ScoreBar.styles.scss';

const ScoreBar = ({ value, max, thin }) => {
  return (
    <div className={classNames(styles.scoreBar, { [styles.thin]: thin })}>
      <div className={styles.scoreBarBackground} style={{ width: `${value / max * 100}%` }} />
      <span className={styles.scoreBarValue}>{value.toLocaleString()}</span>
    </div>
  );
};

ScoreBar.defaultProps = {
  thin: false,
};

ScoreBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  thin: PropTypes.bool,
};

export default ScoreBar;
