import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { Button, Dropdown, MenuProps, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { useFeedback } from '../contexts/FeedbackProvider';
import { useOverrides } from '../contexts/OverridesProvider';
import { useQueryClient } from '@tanstack/react-query';

import { FEOverride, Override } from '../types';

const DetailsCardActions: React.FC<{
  data: FEOverride[];
}> = ({ data }) => {
  const { modal, openMessage } = useFeedback();
  const { api, refreshOverrides } = useOverrides();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, requestId }: { id: string, requestId: string | null }) => {
      if (requestId) {
        return api.deleteOverrideByRequestId(requestId);
      }
      return api.deleteOverrideById(id);
    },
    onError: (err, request, context) => {
      refreshOverrides();

      openMessage({
        type: 'error',
        content: 'Unable to delete override',
        duration: 20,
      });
    },
    onSuccess: (err, request, context) => {
      refreshOverrides();

      openMessage({
        type: 'success',
        content: 'Deleted ',
      });
    },
  });

  function handleConfirm() {
    const { id, requestId } = data[0];
    mutateAsync({id, requestId});
  }

  function confirmDelete() {
    modal.confirm({
      title: 'Are you sure you would like to delete this override?',
      okType: 'danger',
      okText: 'Delete',
      onOk: handleConfirm,
      onCancel() {},
    });
  }

  const items: MenuProps['items'] = [
    {
      key: 'delete',
      label: <Typography.Text type="danger" onClick={confirmDelete}>Delete</Typography.Text>
    }
  ];

  return (
    <Dropdown menu={{items}} trigger={['click']} placement="bottomRight">
      <Button type="text" icon={<EllipsisOutlined/>}/>
    </Dropdown>
  );
}

export default DetailsCardActions;
