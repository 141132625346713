import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import debounce from 'lodash/debounce';
import Button from 'sophi-design-system/src/components/Button';
import Icon from 'sophi-design-system/src/components/Icon';
import FilterContext from './FilterContext';
import HorizontalFilterTabs from './HorizontalFilterTabs';
import VerticalFilterTabs from './VerticalFilterTabs';
import useMedia from 'hooks/useMedia';
import styles from './FilterModal.styles.scss';
import { getRouteName } from 'selectors/router';
import { ROUTE_NAMES } from 'utils/paths';
import { setDiveArticlePaginationValue, setDiveAuthorPaginationValue } from 'actions/dive';

export const CHECKBOX_LIST = 'checkbox';
export const TREE_VIEW = 'tree_view';
export const AUTOCOMPLETE = 'autocomplete';
export const RANGE_SLIDER = 'range';

const FilterModal = () => {
  const {
    selectors: {
      getFilters,
      getOptions,
      getAutosuggest,
      getRowCount,
      getDisabledFeatures,
    },
    actions: {
      clearAutosuggest,
      fetchSections,
      fetchSubtypes,
      fetchOwnership,
      fetchAccess,
      fetchType,
      fetchResultsTotal,
      setFilters,
    }
  } = useContext(FilterContext);

  const FILTER_CONFIG = {
    sections: {
      fetch: fetchSections,
      type: TREE_VIEW,
    },
    subtypes: {
      label: 'Editorial Classification',
      fetch: fetchSubtypes,
      type: CHECKBOX_LIST,
    },
    authors: {
      type: AUTOCOMPLETE,
    },
    ownership: {
      fetch: fetchOwnership,
      type: CHECKBOX_LIST,
    },
    creditLine: {
      label: 'Credit Line',
      type: AUTOCOMPLETE,
    },
    type: {
      label: 'Content Type',
      fetch: fetchType,
      type: CHECKBOX_LIST,
    },
    access: {
      label: 'Reader Access',
      fetch: fetchAccess,
      type: CHECKBOX_LIST,
    },
    keywords: {
      type: AUTOCOMPLETE,
    },
    headlines: {
      type: AUTOCOMPLETE,
    },
    score: {
      type: RANGE_SLIDER,
    }
  };

  const FILTER_LIST = useRef(Object.keys(FILTER_CONFIG));

  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const options = useSelector(getOptions);
  const autosuggest = useSelector(getAutosuggest);
  const disabledFeatures = useSelector(getDisabledFeatures);
  const { loading: resultsTotalLoading, total: resultsTotal } = useSelector(getRowCount);
  const route = useSelector(getRouteName);

  const [active, setActive] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [pendingFilters, setPendingFilters] = useState({});
  const filterList = useMemo(() => FILTER_LIST.current.filter((f) => !disabledFeatures || !disabledFeatures.includes(f)), [disabledFeatures]);
  const showVerticalTabs = useMedia(['(min-width: 768px)'], [true], false);

  useEffect(() => {
    return () => document.getElementById('sophi-app').style.filter = null;
  }, []);

  // Update pending filters every time store value for filters changes
  useEffect(() => { setPendingFilters(filters); }, [filters]);

  // Debounce updates to filter values in state so we don't send
  // an unreasonable requests to the back end for row counts
  const dispatchFilterUpdate = (filterObj) => {
    fetchResultsTotal && dispatch(fetchResultsTotal(filterObj));
  };

  const debouncedFilterUpdate = useRef(debounce(dispatchFilterUpdate, 500));
  useEffect(() => {
    debouncedFilterUpdate.current(pendingFilters);
  }, [pendingFilters]);

  const scoreFilterSet = useMemo(() => {
    if (pendingFilters?.score) {
      const [start, end] = pendingFilters.score;
      return parseInt(start) !== 0 || parseInt(end) !== 99999;
    }
    return false;
  }, [pendingFilters]);

/**
 * Either adds or remove a filter from the pending filters list
 * @param {String}  filter         Name of Filter
 * @param {String}  value          Value being set/unset
 * @param {Boolean} isBeingAdded   If value is being set or unset
 */
  const updatePendingFilters = (filter, value, isBeingAdded = true) => {
    const updatedFilters = {...pendingFilters};
    if (updatedFilters[filter]) {
      if (isBeingAdded) {
        updatedFilters[filter] = [...updatedFilters[filter], value];
      } else {
        updatedFilters[filter] = updatedFilters[filter].filter((f) => f !== value);
      }
    } else if (isBeingAdded) {
      updatedFilters[filter] = [value];
    }
    setPendingFilters(updatedFilters);
  };

  const totalFilters = useMemo(() => {
    let total = 0;
    filterList.forEach((key) => {
      if (Array.isArray(pendingFilters?.[key])) {
        if (key === 'score' && scoreFilterSet) {
          total += 1;
        } else if (key !=='score') {
          total += pendingFilters?.[key].length;
        }
      }
    });
    return total;
  }, [pendingFilters, scoreFilterSet]);

  // Modal Event Handlers
  const handleClose = () => {
    setActive(false);
    setSelectedFilter('');
    setPendingFilters(filters);
    document.getElementById('sophi-app').style.filter = null;
    clearAutosuggest && dispatch(clearAutosuggest());
  };

  const handleOpen = () => {
    setActive(true);
    setPendingFilters(filters);
    setSelectedFilter('sections');
    if (!options.sections.data && FILTER_CONFIG.sections.fetch) {
      dispatch(FILTER_CONFIG.sections.fetch());
    }
    document.getElementById('sophi-app').style.filter = 'blur(4px)';
  };

  const handleSubmit = () => {
    const filtersToDispatch = { ...pendingFilters };
    if (filtersToDispatch.score && filtersToDispatch.score[0] === 0 && filtersToDispatch.score[1] === 99999) {
      delete filtersToDispatch.score;
    }
    dispatch(setFilters(filtersToDispatch));
    switch (route) {
      case ROUTE_NAMES.diveAuthors:
      case ROUTE_NAMES.diveAuthorPage:
        dispatch(setDiveAuthorPaginationValue('page', 0));
        break;
      case ROUTE_NAMES.diveArticles:
        dispatch(setDiveArticlePaginationValue('page', 0));
        break;
    }
    handleClose();
  };

  const handleTabClick = (filter) => {
    if (FILTER_CONFIG[filter].fetch && !options?.[filter].data) {
      dispatch(FILTER_CONFIG[filter].fetch());
    } else if (autosuggest && autosuggest.length) {
      dispatch(clearAutosuggest());
    }
    setSelectedFilter(filter);
  };

  const handleFilterChange = (filter, values, config) => {
    const { type } = FILTER_CONFIG[filter];
    if (type === TREE_VIEW || type === RANGE_SLIDER) {
      setPendingFilters({
        ...pendingFilters,
        [filter]: values,
      });
    }
    if (type === AUTOCOMPLETE || type === CHECKBOX_LIST) {
      updatePendingFilters(filter, values, config);
    }
  };

  return (
    <div data-testid="sophi-filter">
      <Button variant="secondary" onClick={handleOpen}>
        <span style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Icon name="Filter" color="teal" /> Filter Results ({totalFilters})
        </span>
      </Button>
      <Modal
        isOpen={active}
        onRequestClose={handleClose}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            {!showVerticalTabs && (
              <button
                className={styles.iconCloseBtn}
                onClick={handleClose}
                title="Close Filter Modal"
                id="filter-close-btn"
              >
                <Icon name="Clear" color="teal" />
              </button>
            )}
            <h2>Filter Results</h2>
            <div className={styles.headerButtons}>
              <Button variant="primary" onClick={() => setPendingFilters({ score: [0, 99999]})}>Clear All</Button>
              <Button className={styles.closeModalTextBtn} variant="secondary" onClick={handleClose}>Cancel</Button>
            </div>
          </div>
          {!showVerticalTabs && (
            <HorizontalFilterTabs
              filterList={filterList}
              onTabClick={handleTabClick}
              config={FILTER_CONFIG}
              pendingFilters={pendingFilters}
              options={options}
              scoreFilterSet={scoreFilterSet}
              onChange={handleFilterChange}
            />
          )}
          {showVerticalTabs && (
            <VerticalFilterTabs
              filterList={filterList}
              pendingFilters={pendingFilters}
              options={options}
              config={FILTER_CONFIG}
              selectedFilter={selectedFilter}
              scoreFilterSet={scoreFilterSet}
              onTabClick={handleTabClick}
              onChange={handleFilterChange}
            />
          )}
          <div className={styles.modalFooter}>
            <Button
              id="filter-submit-btn"
              variant="default"
              onClick={handleSubmit}
            >
              Save{(resultsTotalLoading || resultsTotal === undefined) ? '' : ` (${resultsTotal})`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FilterModal;
