import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tag.styles.scss';

const Tag = ({
  children,
  removeProps,
  disabled,
  className,
  ...props
}) => (
  <div className={classNames([styles.tag, className, { [styles.disabled]: disabled }])} {...props}>
    {children}
    <button {...removeProps} disabled={disabled}>&#x2715;</button>
  </div>
);

Tag.defaultProps = {
  className: '',
};

Tag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default Tag;
