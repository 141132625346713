import types from '../actions/contentTypes';

export const initialState = {
  id: null,
  portrait: false,
  referrer: null,
  meta_allTimeStats: {
    data: null,
    loading: false,
    error: null,
  },
  meta_7DayStats: {
    data: null,
    loading: false,
    error: null,
  },
  score_dateRange: {
    startDate: null,
    endDate: null,
  },
  score_performance: {
    data: null,
    loading: false,
    error: null,
  },
  score_performanceBreakdown: {
    data: null,
    loading: false,
    error: null,
  },
  score_internalPromotions: {
    data: null,
    loading: false,
    error: null,
  },
  score_socialPromotions: {
    data: null,
    loading: false,
    error: null,
  },
  score_traffic: {
    data: null,
    loading: false,
    error: null,
  },
  traffic_topReferrer: {
    data: null,
    error: null,
    pagination: {
      page: 0, // zero-index
      size: 5,
    },
  },
  traffic_trafficSource: {
    data: null,
    loading: false,
    error: null,
  },
  traffic_mostReaders: {
    data: null,
    loading: false,
    error: null,
  },
  engagement_minutesReading: {
    data: null,
    loading: false,
    error: null,
  },
  engagement_mostEngaged: {
    data: null,
    loading: false,
    error: null,
  },
  promotion_social: {
    data: null,
    error: null,
    loading: false,
  },
  promotion_internal: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      page: 0, // zero-index
      size: 5,
    },
  },
  visitors: {
    data: null,
    error: null,
    loading: false,
    pagination: {
      page: 0, // zero-index
      size: 5,
    },
    driver: {}
  },
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ID_SUCCESS: {
      return {
        ...state,
        id: action.id,
      };
    }
    case types.SET_DEVICE_ORIENTATION: {
      return {
        ...state,
        portrait: action.data,
      };
    }
    case types.SET_REFERRER: {
      return {
        ...state,
        referrer: action.referrer,
      };
    }
    case types.SET_DATE_RANGE: {
      return {
        ...state,
        score_dateRange: {
          ...state.score_dateRange,
          ...action.data
        },
        score_performance: {
          ...state.score_performance,
          loading: true,
          data: null,
          error: null,
        }
      };
    }
    case types.RESET_CONTENT_STORE: {
      return initialState;
    }
    case types.FETCH_ALL_TIME: {
      return {
        ...state,
        meta_allTimeStats: {
          ...state.meta_allTimeStats,
          loading: true,
          error: null,
        },
      };
    }
    case types.FETCH_ALL_TIME_SUCCESS: {
      return {
        ...state,
        meta_allTimeStats: {
          ...state.meta_allTimeStats,
          loading: false,
          data: action.data,
        },
        visitors: {
          ...state.visitors,
          driver: {
            ...state.visitors.driver,
            count: action.data.visits?.stat.value
          }
        }
      };
    }
    case types.FETCH_ALL_TIME_FAIL: {
      return {
        ...state,
        meta_allTimeStats: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_7_DAY: {
      return {
        ...state,
        meta_7DayStats: {
          ...state.meta_7DayStats,
          loading: true,
          error: null,
        },
      };
    }
    case types.FETCH_7_DAY_SUCCESS: {
      let visitors = { ...state.visitors };
      if (action.data && action.data.visits) {
        visitors = {
          ...visitors,
          driver: {
            ...visitors.driver,
            count: action.data.visits.stat.value,
          },
        };
      }
      return {
        ...state,
        meta_7DayStats: {
          ...state.meta_7DayStats,
          loading: false,
          data: action.data,
        },
        visitors,
      };
    }
    case types.FETCH_7_DAY_FAIL: {
      return {
        ...state,
        meta_7DayStats: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_PERFORMANCE: {
      return {
        ...state,
        score_performance: {
          ...state.score_performance,
          loading: true,
          data: null,
          error: null
        }
      };
    }
    case types.FETCH_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        score_performance: {
          ...state.score_performance,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    }
    case types.FETCH_PERFORMANCE_FAIL: {
      return {
        ...state,
        score_performance: {
          ...state.score_performance,
          loading: false,
          data: null,
          error: action.error,
        },
      };
    }
    case types.FETCH_TRAFFIC: {
      return {
        ...state,
        score_traffic: {
          ...state.score_traffic,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_TRAFFIC_SUCCESS: {
      return {
        ...state,
        score_traffic: {
          ...state.score_traffic,
          loading: false,
          error: null,
          data: action.data,
        },
      };
    }
    case types.FETCH_TRAFFIC_FAIL: {
      return {
        ...state,
        score_traffic: {
          ...state.score_traffic,
          error: action.error,
          loading: false,
          data: null,
        },
      };
    }
    case types.FETCH_MOST_READERS_LOADING: {
      return {
        ...state,
        traffic_mostReaders: {
          ...state.traffic_mostReaders,
          loading: true,
          data: null,
          error: null,
        }
      };
    }
    case types.FETCH_MOST_READERS_SUCCESS: {
      return {
        ...state,
        traffic_mostReaders: {
          ...state.traffic_mostReaders,
          loading: false,
          data: action.data,
          error: null,
          lastFetched: new Date(),
        },
      };
    }
    case types.FETCH_MOST_READERS_FAIL: {
      return {
        ...state,
        traffic_mostReaders: {
          ...state.traffic_mostReaders,
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_TRAFFIC_SOURCE_LOADING: {
      return {
        ...state,
        traffic_trafficSource: {
          ...state.traffic_trafficSource,
          loading: true,
          error: null,
        },
      };
    }
    case types.FETCH_TRAFFIC_SOURCE_SUCCESS: {
      return {
        ...state,
        traffic_trafficSource: {
          ...state.traffic_trafficSource,
          loading: false,
          lastFetched: new Date(),
          data: action.data,
          error: null
        },
      };
    }
    case types.FETCH_TRAFFIC_SOURCE_FAIL: {
      return {
        ...state,
        traffic_trafficSource: {
          ...state.traffic_trafficSource,
          loading: false,
          data: null,
          error: action.error,
        },
      };
    }
    case types.FETCH_TOP_REFERRER: {
      return {
        ...state,
        traffic_topReferrer: {
          ...state.traffic_topReferrer,
          loading: true,
          error: null,
          pagination: {
            ...state.traffic_topReferrer.pagination,
            ...action.opts.pagination,
          },
        },
      };
    }
    case types.FETCH_TOP_REFERRER_SUCCESS: {
      return {
        ...state,
        traffic_topReferrer: {
          ...state.traffic_topReferrer,
          loading: false,
          data: action.data.results,
          pagination: {
            ...state.traffic_topReferrer.pagination,
            page: action.data.page,
            size: action.data.size,
            count: action.data.count,
          },
        },
      };
    }
    case types.FETCH_TOP_REFERRER_FAIL: {
      return {
        ...state,
        traffic_topReferrer: {
          ...state.traffic_topReferrer,
          data: null,
          error: action.error,
          loading: false,
        },
      };
    }
    case types.FETCH_MINUTES_READING_LOADING: {
      return {
        ...state,
        engagement_minutesReading: {
          ...state.engagement_minutesReading,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_MINUTES_READING_SUCCESS: {
      return {
        ...state,
        engagement_minutesReading: {
          ...state.engagement_minutesReading,
          lastFetched: new Date(),
          data: action.data,
          loading: false,
          error: null,
        },
      };
    }
    case types.FETCH_MINUTES_READING_FAIL: {
      return {
        ...state,
        engagement_minutesReading: {
          ...state.engagement_minutesReading,
          loading: false,
          data: null,
          error: action.error,
        },
      };
    }
    case types.FETCH_MOST_ENGAGED_LOADING: {
      return {
        ...state,
        engagement_mostEngaged: {
          ...state.engagement_mostEngaged,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_MOST_ENGAGED_SUCCESS: {
      return {
        ...state,
        engagement_mostEngaged: {
          ...state.engagement_mostEngaged,
          data: action.data,
          lastFetched: new Date(),
          loading: false,
          error: null,
        },
      };
    }
    case types.FETCH_MOST_ENGAGED_FAIL: {
      return {
        ...state,
        engagement_mostEngaged: {
          ...state.engagement_mostEngaged,
          loading: false,
          data: null,
          error: action.error,
        },
      };
    }
    case types.FETCH_PROMOTION_SOCIAL: {
      return {
        ...state,
        promotion_social: {
          ...state.promotion_social,
          loading: true,
          error: null,
        },
      };
    }
    case types.FETCH_PROMOTION_SOCIAL_SUCCESS: {
      return {
        ...state,
        promotion_social: {
          ...state.promotion_social,
          loading: false,
          data: action.data,
        },
      };
    }
    case types.FETCH_PROMOTION_SOCIAL_FAIL: {
      return {
        ...state,
        promotion_social: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_PROMOTION_INTERNAL: {
      return {
        ...state,
        promotion_internal: {
          ...state.promotion_internal,
          loading: true,
          error: null,
          pagination: {
            ...state.promotion_internal.pagination,
            ...action.opts.pagination,
          },
        },
      };
    }
    case types.FETCH_PROMOTION_INTERNAL_SUCCESS: {
      return {
        ...state,
        promotion_internal: {
          ...state.promotion_internal,
          loading: false,
          data: action.data.results,
          pagination: {
            ...state.promotion_internal.pagination,
            page: action.data.page,
            size: action.data.size,
            count: action.data.count,
          },
        },
      };
    }
    case types.FETCH_PROMOTION_INTERNAL_FAIL: {
      return {
        ...state,
        promotion_internal: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_VISITORS_THAT_STAY: {
      return {
        ...state,
        visitors: {
          ...state.visitors,
          loading: true,
          data: null,
          error: null,
          pagination: {
            ...state.visitors.pagination,
            ...action.opts.pagination,
          },
        },
      };
    }
    case types.FETCH_VISITORS_THAT_STAY_SUCCESS: {
      return {
        ...state,
        visitors: {
          ...state.visitors,
          loading: false,
          data: action.data.results,
          error: null,
          pagination: {
            ...state.visitors.pagination,
            page: action.data.page,
            size: action.data.size,
            count: action.data.count,
          },
          driver: {
            ...state.visitors.driver,
            ...action.data.driver
          }
        },
      };
    }
    case types.FETCH_VISITORS_THAT_STAY_FAIL: {
      return {
        ...state,
        visitors: {
          loading: false,
          error: action.error,
          data: null,
          driver: initialState.visitors.driver
        },
      };
    }
    case types.FETCH_INTERNAL_PROMOTIONS_TIMELINE: {
      return {
        ...state,
        score_internalPromotions: {
          ...state.score_internalPromotions,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_INTERNAL_PROMOTIONS_TIMELINE_SUCCESS: {
      return {
        ...state,
        score_internalPromotions: {
          ...state.score_internalPromotions,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    }
    case types.FETCH_INTERNAL_PROMOTIONS_TIMELINE_FAIL: {
      return {
        ...state,
        score_internalPromotions: {
          ...state.score_internalPromotions,
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_SOCIAL_PROMOTIONS_TIMELINE: {
      return {
        ...state,
        score_socialPromotions: {
          ...state.score_socialPromotions,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_SOCIAL_PROMOTIONS_TIMELINE_SUCCESS: {
      return {
        ...state,
        score_socialPromotions: {
          ...state.score_socialPromotions,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    }
    case types.FETCH_SOCIAL_PROMOTIONS_TIMELINE_FAIL: {
      return {
        ...state,
        score_socialPromotions: {
          ...state.score_socialPromotions,
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }
    case types.FETCH_PERFORMANCE_BREAKDOWN: {
      return {
        ...state,
        score_performanceBreakdown: {
          ...state.score_performanceBreakdown,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case types.FETCH_PERFORMANCE_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        score_performanceBreakdown: {
          ...state.score_performanceBreakdown,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    }
    case types.FETCH_PERFORMANCE_BREAKDOWN_FAIL: {
      return {
        ...state,
        score_performanceBreakdown: {
          ...state.score_performanceBreakdown,
          loading: false,
          data: null,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
