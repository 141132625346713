// Root
import React from 'react';
import Header from 'sophi-design-system/src/components/Typography/Header';

import styles from './Forbidden.styles.scss';

const Forbidden = () => {
  return (
    <section className={styles.container}>
      <Header tag="h1" className={styles.title}>You don't seem to have access</Header>
      <p>Your account permissions do not allow access to this page.</p>
    </section>
  );
};

export default Forbidden;
