// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-xTlVWehDbUUexLeqhwTr{margin:0 1px;padding:.2em 0;font-size:1.1em;background:var(--teal-200);border:1px solid var(--teal-300);border-radius:3px}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/EntityName/EntityName.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,cAAA,CACA,eAAA,CACA,0BAAA,CACA,gCAAA,CACA,iBAAA","sourcesContent":[".entityName {\n  margin: 0 1px;\n  padding: 0.2em 0;\n  font-size: 1.1em;\n  background: var(--teal-200);\n  border: 1px solid var(--teal-300);\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entityName": "sophi-xTlVWehDbUUexLeqhwTr"
};
export default ___CSS_LOADER_EXPORT___;
