// Root
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Action Types
import types from 'actions/contentTypes';
import { ARTICLE_FETCH_DATA } from 'actions/article';

// Atoms
import Section from 'atoms/Section';
import LoadingState from 'sophi-design-system/src/components/LoadingState';
import DynamicComponent from 'atoms/DynamicComponent';
const env = window._env_;
const sessionKey = 'referrer';

const Content = (props) => {
  const { match, history } = props;
  const contentId = useSelector((state) => state.content.id);
  const dispatch = useDispatch();

  useEffect(() => {
    const addOrientationListener = () => {
      window.addEventListener('resize', () => {
        const data = (window.innerHeight > window.innerWidth) && (window.innerWidth < 600);
        dispatch({ type: types.SET_DEVICE_ORIENTATION, data });
      });
    };

    // Only track device orientation when we think its a mobile device
    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
      const data = (window.innerHeight > window.innerWidth) && (window.innerWidth < 600);
      dispatch({ type: types.SET_DEVICE_ORIENTATION, data });
      addOrientationListener();
    } else {
      dispatch({ type: types.SET_DEVICE_ORIENTATION, data: false });
    }

    // Clear store so we:
    //   - Don't show flashes of old data
    //   - Ensure we don't debounce / ignore calls for newly selected articles
    return () => {
      dispatch({ type: types.RESET_CONTENT_STORE });
    };
  }, []);

  useEffect(() => {
    // Prevent multiple fires
    if (!contentId) {
      dispatch({ type: types.SET_ID, id: match.params.contentId });
    } else {
      if (match.params.contentId !== contentId) {
        dispatch({ type: types.SET_ID, id: match.params.contentId });
      }
    }
  }, [match]);

  useEffect(() => {
    const referrer = {
      hud: match.path === '/content/:contentId',
      now: match.path === '/content/:contentId',
      dive: match.path === '/historical/content/:contentId',
      fullPath: document.referrer
    };

    dispatch({ type: types.SET_REFERRER, referrer });
    // Get Article meta data
    dispatch({ type: ARTICLE_FETCH_DATA, referrer });
  }, [dispatch]);
  return (
    <>
      {!contentId ? (
        <LoadingState />
      ) : (
          <>
            {/* Meta */}
            <DynamicComponent placeholder={<Section title={'Article Title - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/ArticleMeta')}
            />
            {/* Score */}
            <DynamicComponent
              placeholder={<Section title={'Sophi Score Analysis - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/SophiScoreAnalysis')}
            />
            {/* Traffic */}
            <DynamicComponent
              placeholder={<Section title={'Traffic Analysis - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/TrafficAnalysis')}
            />
            {/* Engagement */}
            <DynamicComponent
              placeholder={<Section title={'Engagement Analysis - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/EngagementAnalysis')}
            />
            {/* Promotion */}
            <DynamicComponent
              placeholder={<Section title={'Promotion Analysis - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/PromotionAnalysis')}
            />
            {/* Visitors That Stay */}
            <DynamicComponent
              placeholder={<Section title={'Visitors That Stay - Loading'} loading={true}>{null}</Section>}
              component={() => import('organisms/VisitorsThatStay')}
            />
          </>
        )}
    </>
  );
};

export default Content;