
import { getDiveArticleData, getDiveArticleDownload } from './diveArticles';
import { getDiveAuthorData, getDiveAuthorsDownload } from './diveAuthors';
import { getComparisonChartData } from './comparisonChart';
import { getFilterOptions } from './filterOptions';
import { getFilterAutosuggest } from './filterAutosuggest';
import { getSectionData } from './sectionData';

export default {
  getDiveArticleData,
  getDiveArticleDownload,
  getDiveAuthorData,
  getDiveAuthorsDownload,
  getComparisonChartData,
  getFilterOptions,
  getFilterAutosuggest,
  getSectionData,
};