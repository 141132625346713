import { fork, cancel, put, delay, call, all, select, takeLatest } from 'redux-saga/effects';
import { zonedTimeToUtc } from 'date-fns-tz';
// Actions
import { HEADER_UPDATE } from '../actions/header';
import api from '../apis/overview';
import types from 'actions/homeTypes';
import authTypes from 'actions/authTypes';
// Selectors
import * as authSelectors from '../selectors/auth';
// Sagas
import { getAuthValues } from './auth';
// Utils
import { startOfTimezoneDay, subLocalDays } from 'utils/dateTime';

const REFETCH_INTERVAL = 300000;

/**
 * Fetch the table date for a given key metric (sorted descending by key metric)
 * @param {String} keyMetric Column name
 */
export function* overviewFetchSaga(keyMetric = 'score', filters = {}) {
  const pagination = { page: 0, size: 10 };
  const now = new Date();
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    let timezone = yield select(authSelectors.getTimezone);
    const analysisStart = zonedTimeToUtc(startOfTimezoneDay(now, timezone), timezone);
    const publishStart = zonedTimeToUtc(subLocalDays(analysisStart, 2, timezone), timezone);
    // Display todays scores for stories published up to 2 days ago
    const dateRanges = {
      analysis: {
        startDate: analysisStart,
        endDate: now,
      },
      publish: {
        startDate: publishStart,
        endDate: now,
      },
    };

    // Format column request to match existing content view API function
    const defaultColumns = [
      'headline', 'byline', 'score', 'section', 'scoreDriver', 'acquisition', 'retention', 'internalTotal',
      'directTotal', 'emailTotal', 'newsletterTotal', 'affiliateTotal', 'searchTotal', 'socialTotal', 'unknownTotal', keyMetric, 'loyalty'
    ];

    const table = {
      sort: keyMetric,
      sortOrder: 'desc',
      columns: defaultColumns
    };

    const opts = { dateRanges, table, raw: true };
    const data = yield call(api.getOverview, host, client, token, filters, pagination, opts);
    data.noData
      ? yield put({ type: types.HOMEPAGE_TABLE_DATA_SUCCESS, data: [], table: keyMetric })
      : yield put({ type: types.HOMEPAGE_TABLE_DATA_SUCCESS, data: data.response.data, table: keyMetric });
  }
  catch (error) {
    yield put({ type: types.HOMEPAGE_TABLE_DATA_FAIL, error });
  }
}

export function* getHomepageData(filters) {
  while (true) {
    try {
      yield put({ type: types.HOMEPAGE_TABLE_DATA_LOADING });
      const platforms = yield select(authSelectors.getPlatforms);
      const panels = ['score', 'internalTotal', 'searchTotal', 'socialTotal'];
      if (platforms.includes('app')) {
        panels.push('appGlobeScore');
      }
      const requests = panels.map((p) => call(overviewFetchSaga, p, filters));
      yield all(requests);
      yield put({ type: types.HOMEPAGE_TABLE_DATA_COMPLETE });
      // Pause before fetching data again
      yield delay(REFETCH_INTERVAL);
    } catch (err) {
      yield put({ type: types.HOMEPAGE_TABLE_DATA_FAIL });
      yield put({ type: types.HOMEPAGE_POLLING_STOP, err });
    }
  }
}

let pollWatcher;
let configWatcher;

function* startPollWatcher (action) {
  yield fork(function*() {
    yield call(stopPollWatcher);

    pollWatcher = yield fork(getHomepageData, action.data);
    yield call(startConfigWatcher, action);
  });
};

function* stopPollWatcher () {
  if (pollWatcher) {
    yield cancel(pollWatcher);
  }
};

function* restartWatchers (action) {
  yield stopPollWatcher();
  yield stopConfigWatcher();
  yield startPollWatcher(action);
};

function* startConfigWatcher(action) {
  yield stopConfigWatcher();

  configWatcher = yield fork(function* () {
    while (true) {
      yield takeLatest(authTypes.UPDATE_CONFIG, restartWatchers, action);
    }
  });
}

function* stopConfigWatcher () {
  if (configWatcher) {
    yield cancel(configWatcher);
  }
};

export function* updateHeaderTitle() {
  const title = yield select(authSelectors.getMainHost);
  yield put({ type: HEADER_UPDATE, title: (title || '').replace('www.', '') });
}

export function* stopWatchers() {
  yield stopPollWatcher();
  yield stopConfigWatcher();
}

export default function* homeSaga() {
  yield takeLatest(types.HOMEPAGE_POLLING_START, startPollWatcher);
  yield takeLatest(types.HOMEPAGE_POLLING_STOP, stopWatchers);
  yield takeLatest(authTypes.UPDATE_CONFIG, updateHeaderTitle);
}
