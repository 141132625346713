import React from 'react';
import format from 'date-fns/format';
import { Button, Space, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { useOverrides } from '../contexts/OverridesProvider';

const Reload: React.FC = () => {
  const { updatedAt, refreshOverrides } = useOverrides();

  function handleReload() {
    refreshOverrides();
  }

  return (
    <Space>
      <Button icon={<ReloadOutlined/>} onClick={handleReload}>Refresh</Button>
      <Typography.Text type="secondary" italic><small>Last refreshed at {format(new Date(updatedAt), 'p')}</small></Typography.Text>
    </Space>
  );
};

export default Reload;
