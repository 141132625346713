import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
// Components
import AuthenticationBar from 'sophi-design-system/src/components/AuthenticationBar';
import Link from 'atoms/Link';
// Selectors
import { isAuthenticated } from 'selectors/auth';
// Utils
import { useAuth0 } from 'utils/Auth0Provider';
import { setInStorage } from 'utils/storage';

const AuthBar = () => {
  const [username, setUsername] = useState(null);
  const [picture, setPicture] = useState('#');
  const { user, loginWithRedirect, logout, loading, isAdmin } = useAuth0();
  const isAuth = useSelector(isAuthenticated);

  useEffect(() => {
    if (user) {
      setUsername(user.name.split('@')[0]);
      setPicture(user.picture);
      // Define the user in Sentry as soon as we access it (usually first)
      Sentry.configureScope((scope) => {
        scope.setUser({ email: user.email });
      });
    }
  }, [user]);

  const handleLogin = (options) => {
    setInStorage('lastRoute', window.location.pathname);
    loginWithRedirect(options);
  };

  const popoverBody = useMemo(() => {
    if (isAdmin) {
      return (
        <>
          <Link to="/admin">Manage Users</Link>
        </>
      );
    }
    return null;
  }, [isAdmin]);

  if (loading) {
    return null;
  }

  return (
    <AuthenticationBar
      isAuthenticated={isAuth}
      login={() => handleLogin({})}
      logout={() => logout({
        returnTo: `${window.location.origin}/logout`
      })}
      userInfo={{
        name: username,
        picture
      }}
      popoverBody={popoverBody}
    />
  );
};

export default AuthBar;