import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import Icon from 'sophi-design-system/src/components/Icon';
import styles from './ValuePlaceholder.styles.scss';

const ValuePlaceholder = ({ dataRequested }) => (
  <Tooltip
    placement="bottom-start"
    content={dataRequested ? 'There is no available data for this field.' : 'Run search to get the updated values for this column.'}
  >
    <div data-testid="value-placeholder-trigger" className={styles.placeholderContainer}>
      <span className={styles.placeholderIcon}>
        <Icon size="sm" type="circle" name={dataRequested ? 'Info' : 'Refresh'} color="teal" />
      </span>
    </div>
  </Tooltip>
);

ValuePlaceholder.defaultProps = {
  dataRequested: false,
};

ValuePlaceholder.propTypes = {
  dataRequested: PropTypes.bool,
};

export default ValuePlaceholder;
