import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import { CreateRequest } from '../types';
import { useOverrides } from '../contexts/OverridesProvider';
import { useModalData } from '../contexts/ModalProvider';
import { useFeedback } from '../contexts/FeedbackProvider';

import ConfirmOverridePreview from './ConfirmOverridePreview';
import CreateOverridesForm from './CreateOverridesForm';
import { useQueryClient, useMutation } from '@tanstack/react-query';

const OverridesModal: React.FC<{
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}> = ({ open, onOk, onCancel }) => {
  const queryClient = useQueryClient()
  const { openMessage } = useFeedback();
  const { createOverride, refreshOverrides } = useOverrides();
  const { form, requestData, setArticle } = useModalData();
  const [ confirmSubmit, setConfirmSubmit ] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: CreateRequest) => createOverride(data),
    onError: (err, request, context) => {
      openMessage({
        type: 'error',
        content: 'Override failed to create',
        duration: 20,
      });
    },
    onSuccess: (data, request, context) => {
      const failedCount = data.filter((item) => item.status === 'FAILED').length;

      if (failedCount > 0) {
        openMessage({
          type: 'error',
          content: `Override was created but could not be applied to ${failedCount} pages. Try creating replace overrides to remove content from those pages.`,
          duration: 20,
        });
      } else {
        openMessage({
          type: 'success',
          content: 'Created',
        });
      }

      refreshOverrides();
      form.resetFields();
      setArticle({});
      setConfirmSubmit(false);
      onOk();
    },
  });

  function handleOk() {
    if (confirmSubmit && requestData !== null) {
      mutate(requestData);
    } else {
      form.submit();
    }
  }

  function handleCancel() {
    if (typeof onCancel === 'function') onCancel();
    setConfirmSubmit(false);
    setArticle({});
    form.resetFields();
  }

  function handleBack() {
    setConfirmSubmit(false);
  }

  function handleSubmit () {
    setConfirmSubmit(true);
  }

  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          onClick={confirmSubmit ? handleBack : handleCancel}
        >
          {confirmSubmit ? 'Back' : 'Cancel'}
        </Button>,
        <Button
          key="next"
          onClick={handleOk}
          type="primary"
          loading={isLoading}
          disabled={isLoading}
        >
          {confirmSubmit ? 'Submit' : 'Next'}
        </Button>,
      ]}
    >
      {
        confirmSubmit
          ? <ConfirmOverridePreview />
          : <CreateOverridesForm onSubmit={handleSubmit} />
      }
    </Modal>
  );
};

export default OverridesModal;
