import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Icon from 'sophi-design-system/src/components/Icon';
import { ReactComponent as DragDropIcon } from 'assets/svg/drag-drop-icon.svg';
import ColumnTile from './ColumnTile';
import styles from './EditColumns.styles.scss';
import Tooltip from 'atoms/Tooltip';
import { COLUMN_TOOLTIPS } from 'utils/table';

const DraggableColumns = ({
  singleOptions,
  perDeviceOptions,
  flattenedItems,
  selected,
  pinnedColumns,
  onChange,
  onClearAll,
  onSelectAll,
}) => {
  const [activeItem, setActiveItem] = useState(null);
  const [itemsDropActive, setItemsDropActive] = useState(false);
  const selectedContainer = useRef(null);
  const allSelected = [...selected, ...pinnedColumns];

  const onDragStart = ({ source }) => {
    if (source.droppableId !== 'ITEMS') {
      setItemsDropActive(true);
    } else {
      setActiveItem(source.index);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    setActiveItem(null);
    // dropped outside the list
    if (!destination) {
      return;
    };

    setItemsDropActive(false);

    switch (source.droppableId) {
      case destination.droppableId:
        if (destination.droppableId !== 'ITEMS') {
          const result = Array.from(selected);
          const [removed] = result.splice(source.index, 1);
          result.splice(destination.index, 0, removed);
          onChange(result);
        }
        break;
      case 'ITEMS': {
        const destClone = Array.from(selected);
        const item = [...flattenedItems.entries()][source.index];
        destClone.splice(destination.index, 0, item);
        onChange(destClone);
        break;
      }
      default: {
        const sourceClone = Array.from(selected);
        if (source.droppableId === 'SELECTED') {
          sourceClone.splice(source.index, 1);
          onChange(sourceClone);
        }
        break;
      }
    }
  };

  const handleClearOne = (index) => {
    const selectedClone = Array.from(selected);
    selectedClone.splice(index, 1);
    onChange(selectedClone);
  };

  const handleAdd = (item) => {
    onChange([...selected, item]);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className={styles.draggableColumns}>
        <Droppable droppableId="ITEMS" isDropDisabled={!itemsDropActive}>
          {(provided) => (
            <div className={styles.options} ref={provided.innerRef}>
              <div className={styles.sectionHeader}>
                <Icon type="circle" name="Done" size="md" color="teal" />
                <h3>Available Columns</h3>
              </div>
              <div className={styles.grid}>
                {singleOptions.map((item, index) => {
                  const alreadySelected = allSelected.some((col) => col[0] === item[0]);
                  return (
                    <ColumnTile
                      key={item[0]}
                      item={item}
                      index={index}
                      selected={alreadySelected}
                      disableTransform={itemsDropActive}
                      active={index === activeItem}
                      showClone
                      onAdd={handleAdd}
                    />
                  );
                })}
                </div>
                {perDeviceOptions.map(([id, { label, platforms }]) => (
                  <React.Fragment key={id}>
                    <div className={styles.gridHeader}>
                      <h4>{label}</h4>
                      {
                        !!(COLUMN_TOOLTIPS[label.toLowerCase()]) && (
                          <Tooltip placement="top-start" content={COLUMN_TOOLTIPS[label.toLowerCase()]}>
                            <div style={{ height: 'fit-content', marginLeft: '10px' }}>
                              <Icon type="circle" name="Info" color="teal" size="xs" />
                            </div>
                          </Tooltip>
                        )
                      }
                      <button
                        className={`${styles.linkBtn}`}
                        onClick={() => onSelectAll(platforms)}
                      >
                        Add All
                      </button>
                    </div>
                    <div className={styles.grid}>
                      {[...platforms.entries()].map(([device, key]) => {
                        const platformId = key;
                        const option = flattenedItems.get(platformId);
                        const index = [...flattenedItems.entries()].findIndex((item) => platformId === item[0]);
                        const alreadySelected = allSelected.some((col = []) => col[0] === platformId);
                        return (
                          <ColumnTile
                            key={platformId}
                            item={[platformId, option]}
                            index={index}
                            selected={alreadySelected}
                            disableTransform={itemsDropActive}
                            active={index === activeItem}
                            showClone
                            onAdd={handleAdd}
                          />
                        );
                      })}
                    </div>
                  </React.Fragment>
                ))}
            </div>
          )}
        </Droppable>
        <div ref={selectedContainer} className={styles.selectedContainer}>
          <div className={styles.sectionHeader}>
            <Icon type="circle" name="Done" size="md" color="teal" />
            <h3>Selected Columns</h3>
          </div>
          <div>
            {pinnedColumns.map((pinned) => (
              <div key={pinned[0]} className={`${styles.item} ${styles.pinned}`}>
                <span>{pinned[1].label}</span>
              </div>
            ))}
          </div>
          {(selected && selected.length > 0) && (
            <button
              className={`${styles.linkBtn} ${styles.clearBtn}`}
              onClick={onClearAll}
            >
              Clear Selection
            </button>
          )}
          <Droppable droppableId="SELECTED">
            {(provided) => (
              <div style={{ height: '100%' }} ref={provided.innerRef}>
                {(selected && selected.length > 0)
                  ? (
                    <>
                      {selected.map((item, index) => (
                        <ColumnTile
                          key={`${item[0]}-selected`}
                          item={item}
                          index={index}
                          selected={false}
                          idSuffix="-selected"
                          active
                          onClear={handleClearOne}
                        />
                      ))}
                      {provided.placeholder}
                    </>
                  )
                  : (
                    <div className={styles.selectedPlaceholder}>
                      <div>
                        <DragDropIcon />
                        <p>Drop column name here</p>
                      </div>
                    </div>
                  )
                }
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default DraggableColumns;
