// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-YWvcfEyLf6zyhbHNcqPm{padding-bottom:32px;display:grid;grid-template-columns:1fr;-webkit-box-align:center;-ms-flex-align:center;align-items:center;row-gap:15px}.sophi-YWvcfEyLf6zyhbHNcqPm .sophi-Gb0eZJDevdhP9ZV1qLeV{font-size:16px;margin:5px 0;text-align:center}@media(min-width: 1024px){.sophi-YWvcfEyLf6zyhbHNcqPm{-webkit-column-gap:30px;-moz-column-gap:30px;column-gap:30px;grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr;row-gap:30px}.sophi-YWvcfEyLf6zyhbHNcqPm .sophi-Gb0eZJDevdhP9ZV1qLeV{text-align:left}}@media(min-width: 1440px){.sophi-YWvcfEyLf6zyhbHNcqPm .sophi-Gb0eZJDevdhP9ZV1qLeV{font-size:18px}}", "",{"version":3,"sources":["webpack://./src/organisms/OverviewDatePicker/OverviewDatePicker.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CACA,YAAA,CACA,yBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CAEA,wDACE,cAAA,CACA,YAAA,CACA,iBAAA,CAGF,0BAbF,4BAcI,uBAAA,CAAA,oBAAA,CAAA,eAAA,CACA,6CAAA,CAAA,qCAAA,CACA,YAAA,CAEA,wDACE,eAAA,CAAA,CAIJ,0BACE,wDACE,cAAA,CAAA","sourcesContent":[".overview-date-picker {\n  padding-bottom: 32px;\n  display: grid;\n  grid-template-columns: 1fr;\n  align-items: center;\n  row-gap: 15px;\n\n  .title {\n    font-size: 16px;\n    margin: 5px 0;\n    text-align: center;\n  }\n  \n  @media (min-width: 1024px) {\n    column-gap: 30px;\n    grid-template-columns: max-content 1fr;\n    row-gap: 30px;\n\n    .title {\n      text-align: left;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    .title {\n      font-size: 18px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overviewDatePicker": "sophi-YWvcfEyLf6zyhbHNcqPm",
	"title": "sophi-Gb0eZJDevdhP9ZV1qLeV"
};
export default ___CSS_LOADER_EXPORT___;
