import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import 'react-datepicker/dist/react-datepicker.css';

// Utils
import { validateDateRange } from 'utils/dateTime';
import useMedia from 'hooks/useMedia';
import styles from './DateRangePicker.styles.scss';

const Picker = ({
  value,
  dateFormat,
  rangeMax,
  rangeMin,
  id,
  hideTime,
  altStyle,
  onChange,
}) => {
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const isMobile = useMedia(['(min-width: 840px)'], [false], true);

  // State
  const [localDate, setLocalDate] = useState({
    startDate: value.startDate ?
      value.startDate :
      new Date(),
    endDate: value.endDate ?
      value.endDate :
      new Date()
  });

  const [updated, setUpdated] = useState(false);
  
  useEffect(() => {
    setLocalDate({
      startDate: value.startDate,
      endDate: value.endDate
    });
    setUpdated(true);
    setTimeout(() => setUpdated(false), 1000);
  }, [value]);

  // This should only update local state
  const updateDate = (value, start, event) => {
    const submit = event && event.key && event.key === 'Enter';
    let startDate = start ? value : localDate.startDate;
    let endDate = start ? localDate.endDate : value;

    if (hideTime && value && start) {
      startDate = startOfDay(startDate);
    } else if (hideTime && value && !start) {
      endDate = endOfDay(endDate);
    }

    const datesObj = validateDateRange(startDate, endDate, start); 
    setLocalDate(datesObj);
    // If change should be submitted (ex. enter key press)
    // dispatch the update
    if (value && submit) {
      handleSubmit(datesObj);
    }
  };

  const handleSubmit = (dateRange) => {
    const datesValid = dateRange.startDate && dateRange.endDate;
    const dateUpdated = dateRange.startDate !== value.startDate || dateRange.endDate !== value.endDate;

    if (datesValid && dateUpdated) {
      onChange(dateRange);
    }
    // Revert if someone clears input and closes menu
    if (!datesValid) {
      setLocalDate({
        startDate: dateRange.startDate || value.startDate,
        endDate: dateRange.endDate || value.endDate,
      });
    }
  };

  const sharedProps = {
    showTimeSelect: !hideTime,
    minDate: rangeMin,
    maxDate: rangeMax,
    timeIntervals: 30,
    dateFormat,
    popperClassName: styles.sophiDatePickerPopper,
    endDate: (localDate.startDate && localDate.endDate) ? localDate.endDate : null,
    startDate: (localDate.endDate && localDate.startDate)? localDate.startDate : null,
  };

  return (
    <div
      title={'Unless otherwise indicated, dates ranges begin at 12:00 am & end at 11:59 pm'}
      className={`${styles.datePickerWrapper} ${updated ? styles.updated : ''} ${altStyle ? styles.alt : ''}`}
    >
      <div className={styles.dateRangeContainer}>
        <div className={`${styles.datePickerContainer} ${startOpen ? styles.popperOpen : ''}`}>
          <DatePicker
            {...sharedProps}
            id={`${id}-start-date`}
            className={styles.sophiDatePicker}
            placeholderText="Start Date"
            showMonthDropdown
            showYearDropdown
            selected={localDate.startDate}
            onChange={(date, e) => updateDate(date, true, e)}
            popperPlacement={isMobile ? 'bottom-start' : 'bottom-middle'}
            onCalendarOpen={() => setStartOpen(true)}
            onCalendarClose={() => {
              handleSubmit(localDate);
              setStartOpen(false);
            }}
          />
        </div>
        <span className={styles.dash}>
          &mdash;
          </span>
          <div className={`${styles.datePickerContainer} ${endOpen ? styles.popperOpen : ''}`}>
          <DatePicker
            {...sharedProps}
            id={`${id}-end-date`}
            className={styles.sophiDatePicker}
            placeholderText="End Date"
            showMonthDropdown
            showYearDropdown
            selected={localDate.endDate}
            onChange={(date, e) => updateDate(date, false, e)}
            popperPlacement={isMobile ? 'bottom-end' : 'bottom-middle'}
            onCalendarOpen={() => setEndOpen(true)}
            onCalendarClose={() => {
              handleSubmit(localDate);
              setEndOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

Picker.defaultProps = {
  dateFormat: 'eee MMM dd ʼyy h:mm aaaa',
  linked: false,
  dateRange: (e) => console.info('No \'dateRange\' prop:\n', e),
};

Picker.propTypes = {
  dateFormat: PropTypes.string,
  dateRange: PropTypes.func,
  dateScope: PropTypes.object,
  linked: PropTypes.bool,
  preset: PropTypes.object,
  publishDate: PropTypes.instanceOf(Date),
  style: PropTypes.object,
};

export default Picker;