// Root
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
// Actions
import {
  fetchAutosuggest,
  fetchOverview,
  setDateRange,
  fetchSections,
  fetchSubtypes,
  fetchCreditLine,
  fetchOwnership,
  fetchAccess,
  fetchType,
  clearAutosuggest,
  setOverviewFilters,
  fetchPotentialResults,
} from 'actions/overview';
import { setTitle } from 'actions/header';
import { setPageTitle } from 'utils/paths';
// Atoms
import Button from 'sophi-design-system/src/components/Button';
import PageTitle from 'atoms/PageTitle';
// Molecules
import Filters from 'molecules/Filters';
import FilterContext from 'molecules/Filters/FilterContext';
// Organisms
import OverviewDatePicker from 'organisms/OverviewDatePicker';
import OverviewTable from './OverviewTable';
//Utils
import * as selectors from 'selectors/overview';
import * as authSelectors from 'selectors/auth';

import { setNowFilters } from 'utils/storage';
import { getOverviewFilters, getDateRanges } from 'selectors/overview';
// Styles
import styles from './Overview.styles.scss';

const Overview = () => {
  const [filename, setFilename] = useState('Report');
  const filters = useSelector(getOverviewFilters);
  const dateRange = useSelector(getDateRanges);
  const dispatch = useDispatch();

  useEffect(() => {
    setPageTitle('Content View');
    dispatch(fetchOverview());
    dispatch(setTitle(''));
  }, []);

  useEffect(() => {
    setFilename(`Report for ${format(dateRange.publish.startDate, `d MMM yyyy`)} to ${format(dateRange.publish.endDate, `d MMM yyyy`)}`);
  }, [dateRange]);

  const handleSearch = (event) => {
    // this is a new request, reset page to 0
    dispatch(fetchOverview('page', 0));
    setNowFilters(filters);
  };

  const handleDateRangeChange = (range) => {
    dispatch(setDateRange(range));
  };

  const filterContextValue = {
    selectors: {
      getFilters: selectors.getOverviewFilters,
      getOptions: selectors.getOverviewOptions,
      getAutosuggest: selectors.getOverviewAutosuggest,
      getRowCount: selectors.getOverviewPotentialRowCount,
      getDisabledFeatures: authSelectors.getDisabledNowProductFeatures,
    },
    actions: {
      clearAutosuggest,
      fetchAutosuggest,
      fetchSections,
      fetchSubtypes,
      fetchCreditLine,
      fetchOwnership,
      fetchAccess,
      fetchType,
      fetchResultsTotal: fetchPotentialResults,
      setFilters: setOverviewFilters,
    }
  };

  return (
    <div className={styles.overview}>
      <PageTitle title="Overview" />
      <div className={styles.body}>
        <div className={styles.header}>
          <OverviewDatePicker dateRanges={handleDateRangeChange} />
          <div className={styles.btnContainer}>
            <div className={styles.btnGroup}>
              <FilterContext.Provider value={filterContextValue}>
                <Filters />
              </FilterContext.Provider>
              <Button
                onClick={handleSearch}
                data-track-click="true"
                data-track-name="content_view-run_search-btn"
                id="content_view-run_search-btn"
                variant="primary"
              >
                Submit Search
              </Button>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#FFF', padding: '70px 30px 40px' }}>
          <OverviewTable fileName={filename}/>
        </div>
      </div>
    </div>
  );
};

export default Overview;
