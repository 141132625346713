import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';

// Actions
import {
  fetchOverview,
  fetchOverviewColumns,
  fetchOverviewSort,
  setOverviewCols,
} from 'actions/overview';
import types from 'actions/overviewTypes';
// Molecules
import SophiTable from 'molecules/SophiTable';
import TimeoutError from 'molecules/TimeoutError';
// Selectors
import { getNowDownloadsDisabled } from 'selectors/global';
import { getOverviewAllColumns } from 'selectors/overview';
// Utils
import { setColumnObject } from 'utils/storage';
import { formatColumnsConfig, getCellFormatter } from 'utils/table';

const OverviewTable = ({ fileName }) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.overview.results);
  const tableConfig = useSelector((state) => state.overview.table);
  const pagination = useSelector((state) => state.overview.pagination);
  const platforms = useSelector((state) => state.auth.configuration.platforms);
  const timezone = useSelector((state) => state.auth.configuration.timezone);
  const mainHost = useSelector((state) => state.auth.configuration.id);
  const downloadDisabled = useSelector(getNowDownloadsDisabled);
  const overviewColumns = formatColumnsConfig(useSelector(getOverviewAllColumns), platforms);

  useEffect(() => {
    dispatch(fetchOverviewColumns());
  }, []);

  const columnConfig = useMemo(() => {
    const formattedColumns = uniq([ 'score', 'headline', ...tableConfig.columns ]);
    return formattedColumns.map((column) => {
      const overviewColumn = overviewColumns.nonDevice.get(column) || overviewColumns.deviceTop.get(column);
      if (overviewColumn) {
        return {
          accessor: column,
          Cell: getCellFormatter(column, tableData.columns && tableData.columns.includes(column), { host: mainHost, timezone }),
          Header: column === 'score' ? 'Score' : overviewColumn.label,
          ...overviewColumn,
        };
      }
    }).filter((c) => c);
  }, [overviewColumns, tableConfig.columns, tableData]);

  const handleColumnChange = (columns) => {
    dispatch(setOverviewCols(columns));
    setColumnObject('now-columns', columns);
  };

  const handleColumnSort = ({ column, direction }) => {
    dispatch(fetchOverviewSort({ sort: column, sortOrder: direction }));
  };
  /*
    TODO: Fix the overview states
    ======
    The below combination of checks is both confusing and difficult to manage.
    noData should probably be its out key in the state object.
    We might prefer to not nullify the content of data and have a less obtrusive message.
    Leaving this comment here as a flag for us to define these patterns and implement across all components
  */

  const overviewData = (!tableData.data || tableData.data.noData)
    ? []
    : tableData.data;

  return (
    <SophiTable
      errorAction={() => dispatch(fetchOverview())}
      fileName={fileName}
      id="content_view-table"
      columns={columnConfig}
      data={overviewData}
      error={tableData.error}
      errorContent={TimeoutError}
      loading={tableData.loading}
      pagination={pagination}
      onPageChange={(val) => dispatch(fetchOverview('page', val))}
      onPageSizeChange={(val) => dispatch(fetchOverview('size', val))}
      columnOptions={overviewColumns}
      columnSet={handleColumnChange}
      selectedColumns={tableConfig.columns}
      sortable={handleColumnSort}
      defaultSort={{ column: 'score', direction: 'desc' }}
      hideFooter
      downloadConfig={{
        handleDownload: () => dispatch({ type: types.OVERVIEW_FETCH_REPORT }),
        disabled: downloadDisabled || (!tableData.loading && !overviewData.length),
        info: 'Adjust the date-published range to download up to 14 days’ worth of data.',
      }}
      frozenColumn="headline"
    />
  );
};

export default OverviewTable;

