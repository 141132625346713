// Utils
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Internal Promotions Table
 * @param {String} id - String representing the contentID
 * @param {Integer} page - The current page
 * @param {Integer} size - The amount of record requested
 */
const getPromotionInternal = (host, client, id, token, page, size) => {
  if (paramsAreTruthy([id, page, size])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `promotion-analysis/internal?page=${page}&size=${size}`
      }
    );

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      return data;
    };

    return getResource({
      endpoint: path,
      token,
    })
      .then((resp) => ({
        response: {
          page, // zero-index
          size,
          count: resp.size || 0,
          results: formatResponse(resp.rows),
        }
      }))
      .catch((error) => ({ error }));
  }
};

export default getPromotionInternal;