export const nonDeviceDiveAuthorColumns = {
  author: { default: true, required: true, enabled: true, label: 'Byline' },
  articles: { default: true, required: false, enabled: true, label: '# of Articles' },
  sophiScorePerArticle: { default: true, required: false, enabled: true, label: 'Score/Article' },
  subscriptionCount: { default: false, required: false, enabled: true, label: 'Subscriptions' },
  wallEventCountTotal: { default: false, required: false, enabled: true, label: 'Paywall Hits' },
  medianTimeSpent: { default: false, required: false, enabled: true, label: 'Minutes Spent' },
  recirculationRatio: { default: false, required: false, enabled: true, label: 'Average Recirculation' },
};

export const nonDeviceDiveArticlesColumns = {
  headline: { default: true, required: true, enabled: true, label: 'Headline' },
  byline: { default: true, required: false, enabled: true, label: 'Byline' },
  datePublished: { default: true, required: false, enabled: true, label: 'Published Date' },
  scoreDriver: { default: true, required: false, enabled: true, label: 'Top Score Contributor' },
  sectionName: { default: true, required: false, enabled: true, label: 'Section' },
  ownership: { default: false, required: false, enabled: true, label: 'Ownership' },
  creditLine: { default: false, required: false, enabled: true, label: 'Credit Line' },
  contentType: { default: false, required: false, enabled: true, label: 'Content Type' },
  accessCategory: { default: false, required: false, enabled: true, label: 'Reader Access' },
  subscriptionCount: { default: false, required: false, enabled: true, label: 'Subscriptions' },
  wallEventCountTotal: { default: false, required: false, enabled: true, label: 'Paywall Hits' },
  medianTimeSpent: { default: false, required: false, enabled: true, label: 'Minutes Spent' },
  recirculationRatio: { default: false, required: false, enabled: true, label: 'Average Recirculation' },
  subtype: { default: false, required: false, enabled: true, label: 'Editorial Classification' },
  keywords: { default: false, required: false, enabled: true, label: 'Keywords' },
  contentId: { default: false, required: false, enabled: true, label: 'Content ID' },
};

export const nonDeviceDiveSectionsColumns = {
  subsectionName: { default: true, required: true, enabled: true, label: 'Section' },
  articles: { default: true, required: true, enabled: true, label: 'Number of Articles' },
  sophiScorePerArticle: { default: true, required: true, enabled: true, label: 'Score/Article' },
  loyaltyNewsletterSignupCount: { default: false, required: false, enabled: false, label: 'Newsletter Signups' },
  subscriptionCount: { default: false, required: false, enabled: true, label: 'Subscriptions' },
  visitCount: { default: false, required: false, enabled: true, label: 'Visits' },
  medianTimeSpent: { default: false, required: false, enabled: true, label: 'Total Median Time Spent' },
  wallEventCountTotal: { default: false, required: false, enabled: true, label: 'Paywall Hits' },
  recirculationRatio: { default: false, required: false, enabled: true, label: 'Average Recirculation' },
};

export const deviceTopDiveColumns = {
  sophiScore: { default: true, required: true, enabled: true, label: 'Sophi Score', platform: 'Total' },
  sophiScoreDesktop: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Desktop' },
  sophiScoreTablet: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Tablet' },
  sophiScoreMobile: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Mobile' },
  sophiScoreApp: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'App' },
  acquisition: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Total' },
  acquisitionDesktop: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Desktop' },
  acquisitionTablet: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Tablet' },
  acquisitionMobile: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Mobile' },
  acquisitionApp: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'App' },
  retention: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Total' },
  retentionDesktop: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Desktop' },
  retentionTablet: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Tablet' },
  retentionMobile: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Mobile' },
  retentionApp: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'App' },
  readerFocusedInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Total' },
  readerFocusedDesktopInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Desktop' },
  readerFocusedTabletInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Tablet' },
  readerFocusedMobileInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Mobile' },
  readerFocusedAppInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'App' },
  readerFocusedDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Total' },
  readerFocusedDesktopDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Desktop' },
  readerFocusedTabletDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Tablet' },
  readerFocusedMobileDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Mobile' },
  readerFocusedAppDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'App' },
  readerFocusedEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Total' },
  readerFocusedDesktopEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Desktop' },
  readerFocusedTabletEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Tablet' },
  readerFocusedMobileEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Mobile' },
  readerFocusedNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Total' },
  readerFocusedDesktopNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Desktop' },
  readerFocusedTabletNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Tablet' },
  readerFocusedMobileNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Mobile' },
  readerFocusedAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Total' },
  readerFocusedDesktopAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Desktop' },
  readerFocusedTabletAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Tablet' },
  readerFocusedMobileAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Mobile' },
  readerFocusedSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Total' },
  readerFocusedDesktopSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Desktop' },
  readerFocusedTabletSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Tablet' },
  readerFocusedMobileSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Mobile' },
  readerFocusedSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Total' },
  readerFocusedDesktopSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Desktop' },
  readerFocusedTabletSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Tablet' },
  readerFocusedMobileSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Mobile' },
  readerFocusedOther: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Total' },
  readerFocusedDesktopOther: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Desktop' },
  readerFocusedTabletOther: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Tablet' },
  readerFocusedMobileOther: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Mobile' },
  readerFocusedAppOther: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'App' },
  visitCount: { default: false, required: false, enabled: true, label: 'Total Visits', platform: 'Total' },
  visitCountDesktop: { default: false, required: false, enabled: true, label: 'Total Visits', platform: 'Desktop' },
  visitCountTablet: { default: false, required: false, enabled: true, label: 'Total Visits', platform: 'Tablet' },
  visitCountMobile: { default: false, required: false, enabled: true, label: 'Total Visits', platform: 'Mobile' },
  visitCountApp: { default: false, required: false, enabled: true, label: 'Total Visits', platform: 'App' },
};

export const deviceTopDiveSectionsColumns = {
  sophiScore: { default: true, required: true, enabled: true, label: 'Sophi Score', platform: 'Total' },
  sophiScoreDesktop: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Desktop' },
  sophiScoreTablet: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Tablet' },
  sophiScoreMobile: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Mobile' },
  sophiScoreApp: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'App' },

  loyalty: { default: false, required: false, enabled: false, label: 'Loyalty Score', platform: 'Total' },
  loyaltyDesktop: { default: false, required: false, enabled: false, label: 'Loyalty Score', platform: 'Desktop' },
  loyaltyTablet: { default: false, required: false, enabled: false, label: 'Loyalty Score', platform: 'Tablet' },
  loyaltyMobile: { default: false, required: false, enabled: false, label: 'Loyalty Score', platform: 'Mobile' },
  loyaltyApp: { default: false, required: false, enabled: false, label: 'Loyalty Score', platform: 'App' },

  acquisition: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Total' },
  acquisitionDesktop: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Desktop' },
  acquisitionTablet: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Tablet' },
  acquisitionMobile: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Mobile' },
  acquisitionApp: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'App' },

  retention: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Total' },
  retentionDesktop: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Desktop' },
  retentionTablet: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Tablet' },
  retentionMobile: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Mobile' },
  retentionApp: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'App' },

  readerFocusedInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Total' },
  readerFocusedDesktopInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Desktop' },
  readerFocusedTabletInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Tablet' },
  readerFocusedMobileInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Mobile' },
  readerFocusedAppInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'App' },

  readerFocusedDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Total' },
  readerFocusedDesktopDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Desktop' },
  readerFocusedTabletDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Tablet' },
  readerFocusedMobileDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Mobile' },
  readerFocusedAppDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'App' },

  readerFocusedEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Total' },
  readerFocusedDesktopEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Desktop' },
  readerFocusedTabletEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Tablet' },
  readerFocusedMobileEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Mobile' },

  readerFocusedNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Total' },
  readerFocusedDesktopNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Desktop' },
  readerFocusedTabletNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Tablet' },
  readerFocusedMobileNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Mobile' },

  readerFocusedAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Total' },
  readerFocusedDesktopAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Desktop' },
  readerFocusedTabletAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Tablet' },
  readerFocusedMobileAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Mobile' },

  readerFocusedSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Total' },
  readerFocusedDesktopSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Desktop' },
  readerFocusedTabletSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Tablet' },
  readerFocusedMobileSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Mobile' },

  readerFocusedSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Total' },
  readerFocusedDesktopSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Desktop' },
  readerFocusedTabletSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Tablet' },
  readerFocusedMobileSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Mobile' },
};
