const types = {
  SET_ID: 'SET_ID',
  SET_ID_SUCCESS: 'SET_ID_SUCCESS',
  SET_DEVICE_ORIENTATION: 'SET_DEVICE_ORIENTATION',
  SET_REFERRER: 'SET_REFERRER',
  RESET_CONTENT_STORE: 'RESET_CONTENT_STORE',
  // Meta > All Time Stats
  FETCH_ALL_TIME: 'FETCH_ALL_TIME',
  FETCH_ALL_TIME_SUCCESS: 'FETCH_ALL_TIME_SUCCESS',
  FETCH_ALL_TIME_FAIL: 'FETCH_ALL_TIME_FAIL',
  // Meta > 7 Day
  FETCH_7_DAY: 'FETCH_7_DAY',
  FETCH_7_DAY_SUCCESS: 'FETCH_7_DAY_SUCCESS',
  FETCH_7_DAY_FAIL: 'FETCH_7_DAY_FAIL',
  // Promotion > Social
  FETCH_PROMOTION_SOCIAL: 'FETCH_PROMOTION_SOCIAL',
  FETCH_PROMOTION_SOCIAL_SUCCESS: 'FETCH_PROMOTION_SOCIAL_SUCCESS',
  FETCH_PROMOTION_SOCIAL_FAIL: 'FETCH_PROMOTION_SOCIAL_FAIL',
  PROMOTION_SOCIAL_PAGE_UPDATE: 'PROMOTION_SOCIAL_PAGE_UPDATE',
  // Promotion > Internal
  FETCH_PROMOTION_INTERNAL: 'FETCH_PROMOTION_INTERNAL',
  FETCH_PROMOTION_INTERNAL_SUCCESS: 'FETCH_PROMOTION_INTERNAL_SUCCESS',
  FETCH_PROMOTION_INTERNAL_FAIL: 'FETCH_PROMOTION_INTERNAL_FAIL',
  PROMOTION_INTERNAL_PAGE_UPDATE: 'PROMOTION_INTERNAL_PAGE_UPDATE',
  // Sophi Score > Date Range
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_DATE_RANGE_SUCCESS: 'SET_DATE_RANGE_SUCCESS',
  // Sophi Score > Performance
  FETCH_PERFORMANCE: 'FETCH_PERFORMANCE',
  FETCH_PERFORMANCE_SUCCESS: 'FETCH_PERFORMANCE_SUCCESS',
  FETCH_PERFORMANCE_FAIL: 'FETCH_PERFORMANCE_FAIL',
  // Sophi Score > Performance Breakdown
  FETCH_PERFORMANCE_BREAKDOWN: 'FETCH_PERFORMANCE_BREAKDOWN',
  FETCH_PERFORMANCE_BREAKDOWN_SUCCESS: 'FETCH_PERFORMANCE_BREAKDOWN_SUCCESS',
  FETCH_PERFORMANCE_BREAKDOWN_FAIL: 'FETCH_PERFORMANCE_BREAKDOWN_FAIL',
  // Sophi Score > Internal Promotions Timeline
  FETCH_INTERNAL_PROMOTIONS_TIMELINE: 'FETCH_INTERNAL_PROMOTIONS_TIMELINE',
  FETCH_INTERNAL_PROMOTIONS_TIMELINE_SUCCESS:
    'FETCH_INTERNAL_PROMOTIONS_TIMELINE_SUCCESS',
  FETCH_INTERNAL_PROMOTIONS_TIMELINE_FAIL: 'FETCH_INTERNAL_PROMOTIONS_FAIL',
  // Sophi Score > Social Promotions Timeline
  FETCH_SOCIAL_PROMOTIONS_TIMELINE: 'FETCH_SOCIAL_PROMOTIONS_TIMELINE',
  FETCH_SOCIAL_PROMOTIONS_TIMELINE_SUCCESS:
    'FETCH_SOCIAL_PROMOTIONS_TIMELINE_SUCCESS',
  FETCH_SOCIAL_PROMOTIONS_TIMELINE_FAIL:
    'FETCH_SOCIAL_PROMOTIONS_TIMELINE_FAIL',
  // Sophi Score > Traffic
  FETCH_TRAFFIC: 'FETCH_TRAFFIC',
  FETCH_TRAFFIC_SUCCESS: 'FETCH_TRAFFIC_SUCCESS',
  FETCH_TRAFFIC_FAIL: 'FETCH_TRAFFIC_FAIL',
  // Traffic > Top Referrer
  FETCH_TOP_REFERRER: 'FETCH_TOP_REFERRER',
  FETCH_TOP_REFERRER_SUCCESS: 'FETCH_TOP_REFERRER_SUCCESS',
  FETCH_TOP_REFERRER_FAIL: 'FETCH_TOP_REFERRER_FAIL',
  // Traffic > Traffic Source
  FETCH_TRAFFIC_SOURCE: 'FETCH_TRAFFIC_SOURCE',
  FETCH_TRAFFIC_SOURCE_LOADING: 'FETCH_TRAFFIC_SOURCE_LOADING',
  FETCH_TRAFFIC_SOURCE_SUCCESS: 'FETCH_TRAFFIC_SOURCE_SUCCESS',
  FETCH_TRAFFIC_SOURCE_FAIL: 'FETCH_TRAFFIC_SOURCE_FAIL',
  // Traffic > Most Readers
  FETCH_MOST_READERS: 'FETCH_MOST_READERS',
  FETCH_MOST_READERS_LOADING: 'FETCH_MOST_READERS_LOADING',
  FETCH_MOST_READERS_SUCCESS: 'FETCH_MOST_READERS_SUCCESS',
  FETCH_MOST_READERS_FAIL: 'FETCH_MOST_READERS_FAIL',
  // Engagement > Minutes Reading
  FETCH_MINUTES_READING: 'FETCH_MINUTES_READING',
  FETCH_MINUTES_READING_LOADING: 'FETCH_MINUTES_READING_LOADING',
  FETCH_MINUTES_READING_SUCCESS: 'FETCH_MINUTES_READING_SUCCESS',
  FETCH_MINUTES_READING_FAIL: 'FETCH_MINUTES_READING_FAIL',
  // Engagement > Most Engaged
  FETCH_MOST_ENGAGED: 'FETCH_MOST_ENGAGED',
  FETCH_MOST_ENGAGED_LOADING: 'FETCH_MOST_ENGAGED_LOADING',
  FETCH_MOST_ENGAGED_SUCCESS: 'FETCH_MOST_ENGAGED_SUCCESS',
  FETCH_MOST_ENGAGED_FAIL: 'FETCH_MOST_ENGAGED_FAIL',
  // Visitors > Next Page
  FETCH_VISITORS_THAT_STAY: 'FETCH_VISITORS_THAT_STAY',
  FETCH_VISITORS_THAT_STAY_SUCCESS: 'FETCH_VISITORS_THAT_STAY_SUCCESS',
  FETCH_VISITORS_THAT_STAY_FAIL: 'FETCH_VISITORS_THAT_STAY_FAIL',
};

export default types;
