/**
 * Remove all null values from the top level of an object
 * @param {Object} obj The object to remove
 * @returns a new Object with the null/undefined properties removed
 */
export const removeEmpty = (obj) => {
  let newObj = obj;
  Object.keys(newObj).forEach((key) =>
    (newObj[key] == null) &&
    delete newObj[key]
  );
  return newObj;
};

export const isDefined = (val) => (
  val !== undefined && val !== null
);

/**
 * Applies provided prefix to object's keys
 * @param   {String} prefix Prefix to apply to the keys
 * @param   {Object} object Object to be updated
 * @returns {Object}        Prefixed object
 */
export const addKeyPrefix = (prefix, object = {}) => {
  if (!prefix || typeof prefix !== 'string') return object;
  
  const keys = Object.keys(object);
  return keys.reduce((obj, key) => {
    const newKey = `${prefix}${key}`;
    return {
      ...obj,
      [newKey]: object[key],
    };
  }, {});
};
