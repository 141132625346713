// Utils
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Top Referrer Table
 * @param {String} id - String representing the contentID
 * @param {Integer} page - The current page
 * @param {Integer} size - The amount of record requested
 */
const getTopReferrerData = (host, client, id, token, page = 0, size = 5) => {
  if (paramsAreTruthy([id, page, size])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `traffic-analysis/top-referrers?page=${page}&size=${size}`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response, page, size))
      .catch((error) => ({ error }));

    const formatResponse = (data, page, size) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const { rows, title, totals } = data;

      const processedRow = (row) => ({
        ...row,
        platforms: row.platforms.reduce((lu, r) => ({ ...lu, [r.platform.toLowerCase()]: r }), {}),
    });

      return {
        response: {
          count: data.size || 0,
          page,
          size,
          results: {
            rows: rows.map((r) => processedRow(r)),
            title,
            total: processedRow(totals)
          },
        },
      };
    };

    return data;
  }
};


export default getTopReferrerData;