// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-fhjMYYzCiV8xrnW9FduR{color:var(--grays-700);font-size:0.8125rem;padding:1rem 0;text-align:center;text-transform:uppercase;width:100%}", "",{"version":3,"sources":["webpack://./src/atoms/NoData/NoData.styles.scss"],"names":[],"mappings":"AAEA,4BACE,sBAAA,CACA,mBAAA,CACA,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,UAAA","sourcesContent":["@import 'styles/util';\n\n.no-data {\n  color: var(--grays-700);\n  font-size: pxToRem(13);\n  padding: pxToRem(16) 0;\n  text-align: center;\n  text-transform: uppercase;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": "sophi-fhjMYYzCiV8xrnW9FduR"
};
export default ___CSS_LOADER_EXPORT___;
