import React from 'react';
import PropTypes from 'prop-types';
import TabPanel from 'atoms/Tabs/TabPanel';
import TopStoriesCard from 'organisms/TopStoriesCard';
import { getCellFormatter } from 'utils/table';
import ScoreBar from 'atoms/ScoreBar';

const TopStoriesTabPanel = ({ data, id, tabId, keyMetricKey, loading, title }) => {
  const loadingPanelContents = loading && (!data || !data.length);
  
  const columns = [
    {
      Header: 'Headline',
      accessor: 'headline',
      Cell: getCellFormatter('headline', true)
    },
    {
      Header: 'Byline',
      accessor: 'byline',
      Cell: getCellFormatter('byline', true), 
    },
    {
      Header: 'Score',
      accessor: keyMetricKey,
      Cell: ({ cell, data }) => {
        return (cell.value && data[0]) ? <ScoreBar value={parseInt(cell.value)} max={data[0][keyMetricKey]} /> : '';
      }
    },
  ];

  const widths = ['60%', '20%', '15%'];

  return (
    <TabPanel id={id} tabId={tabId} style={ { height: '100%'} }>
      <TopStoriesCard
        id={`${id}-card`}
        data={data?.noData ? null : data}
        keyMetricKey={keyMetricKey}
        loading={loadingPanelContents}
        title={title}
        columns={columns}
        widths={widths}
      />
    </TabPanel>
  );
};

TopStoriesTabPanel.defaultProps = {
  data: [],
  showBreakdown: false,
  loading: false,
};

TopStoriesTabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  keyMetricName: PropTypes.string.isRequired,
  keyMetricKey: PropTypes.string.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default TopStoriesTabPanel;
