// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-cAbN602rbRgTbx8t7aHy{list-style:none;-webkit-padding-start:0;padding-inline-start:0;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:calc(.5*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/TagList/Display.styles.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,uBAAA,CAAA,sBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CAAA,cAAA,CACA,2BAAA","sourcesContent":[".list {\n  list-style: none;\n  padding-inline-start: 0;\n  display: flex;\n  flex-wrap: wrap;\n  gap: calc(0.5 * var(--space-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "sophi-cAbN602rbRgTbx8t7aHy"
};
export default ___CSS_LOADER_EXPORT___;
