import React from 'react';
import { getRoute, ROUTE_NAMES } from 'utils/paths';
import {
  getAvailableProducts,
  getEnabledCuratorProductFeatures
} from 'selectors/auth';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from 'utils/Auth0Provider';

const HomePage = function () {
  const products = useSelector(getAvailableProducts);
  const enabledCuratorFeatures = useSelector(getEnabledCuratorProductFeatures);
  const { scopes } = useAuth0();

  if (products.includes('now')) {
    return <Redirect  to={`${getRoute(ROUTE_NAMES.nowHome)}`} />;
  } else if (products.includes('dive')) {
    return <Redirect to={getRoute(ROUTE_NAMES.diveHome)} />;
  } else if (enabledCuratorFeatures.includes('CONFIG_UI') && scopes.includes('configurator:pages:create')) {
    return <Redirect to={`${getRoute(ROUTE_NAMES.curatorPagesList)}`} />;
  } else if (enabledCuratorFeatures.includes('OVERRIDE_UI') && scopes.includes('override:read')) {
    return <Redirect to={`${getRoute(ROUTE_NAMES.curatorOverrides)}`} />;
  } else {
    return <Redirect to={`${getRoute(ROUTE_NAMES.welcome)}`} />;
  };
};

export default HomePage;
