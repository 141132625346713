import types from 'actions/homeTypes';

export const initialState = {
  tables: {
    loading: false,
  }
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case types.HOMEPAGE_TABLE_DATA_LOADING: {
      return {
        ...state,
        tables: {
          ...state.tables,
          loading: true,
        }
      };
    }
    case types.HOMEPAGE_TABLE_DATA_COMPLETE: {
      return {
        ...state,
        tables: {
          ...state.tables,
          loading: false,
        }
      };
    }
    case types.HOMEPAGE_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: action.data,
        }
      };
    }
    default: {
      return state;
    }
  }
};