import React from 'react';
import PropTypes from 'prop-types';

import styles from './MessageBar.styles.scss';
import 'wicg-inert';

const MessageBar = ({
  children, action, actionLabel, visible
}) => (
    <section
      className={`${styles.messageBar} ${visible ? 'visible' : 'hidden'}`}
      {...{ inert: visible ? null : 'true' }}
    >
      <div className={styles.messageBarText}>
        {children}
      </div>
      <div className={styles.messageBarAction}>
        <button onClick={() => action()}>
          {actionLabel}
        </button>
      </div>
    </section>
);

MessageBar.defaultProps = {
  visible: true
};

MessageBar.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
};

export default MessageBar;
