import maxBy from 'lodash/maxBy';
import { scaleBand, scaleLinear, scaleTime } from 'd3-scale';
import { bisector, extent } from 'd3-array';
import { stack } from 'd3-shape';
import { roundToNearestMinutes } from 'utils/dateTime';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import { trimStart, trimEnd } from 'utils/arrays';

/**
 * Will return the specific object that hold the highest value specified by your key
 * @param {array} array - An Array of objects
 * @param {string} key - The key you wish to get the maximim value of
 */
export const getMax = (array, key) => maxBy(array, (o) => o[key]);

/**
 *
 * @param {object} data - Javascript object received from the API
 * @param {array} keys - An array of strings representing each bar grouping
 */
export const filterData = (data, keys) => {
  const filteredData = [];
  keys.forEach((key) => {
    filteredData.push({ source: key, ...data[key] });
  });
  return filteredData;
};

/**
 * Returns the maximum value found in an array on objects from specified keys
 * @param {array} data - An array of objects, the output of filteredData()
 * @param {array} keys - An array of strings representing each bar value
 */
export const getMaxFromKeys = (data, keys) => {
  const values = [];
  data.forEach((d) => {
    keys.forEach((k) => {
      values.push(parseFloat(d[k]));
    });
  });
  return Math.max(...values);
};

/**
 * Returns the maximum value calculated by summing the values of all keys per array item
 * @param {array} data - An array of objects, the output of filteredData()
 * @param {array} keys - An array of strings representing each bar value
 */
export const getAggregatedMaxByKeys = (data, keys) => {
  const values = [];
  data.forEach((d) => {
    let sum = null;
    keys.forEach((k) => {
      sum += parseFloat(d[k]);
    });
    values.push(sum);
  });
  return Math.max(...values);
};

/**
 * Get the last index that has value from the formatted chart response
 * @param {array} data - An array of object
 */
export const getLastIndexByAggregatedValues = (data) => {
  let index = null;
  // Reduce object and filter date
  const flatValues = data.map((e) => {
    const keys = Object.keys(e);
    let counter = 0;
    keys.forEach((k) => {
      if (k !== 'date') {
        counter += e[k];
      }
    });
    return counter;
  });
  // Return last index that has a value
  flatValues.forEach((e, i) => {
    if (e > 0) {
      index = i;
    }
  });
  return index;
};

/**
 * Returns a short hand for thousand without 'k' useful for axis ticks
 * @param {number} d - A given number value
 */
export const convertToK = (d) => {
  return d / 1000;
};

/**
 * Creates a group scale based on the data passed in to generate a group
 * bar chart
 * @param {string} sourceKey - String value fo the key that holds the source key
 * @param {number} minRange - Minimum range for the group scale
 * @param {number} maxRange - Maximum range for the group scale
 * @param {number} data
 */
export const createGroupScale = (sourceKey, minRange, maxRange, data) => {
  const groupScale = scaleBand()
    .range([minRange, maxRange])
    .paddingInner(0.2)
    .paddingOuter(0.1)
    .domain(data.map((d) => d[sourceKey]));

  return groupScale;
};

/**
 * Create a key scale based on the array passed it to generate a group
 * bar chart
 * @param {array} keys - Array of keys that match the groupscale
 * @param {number} minRange - Minimum range for the keyscale
 * @param {number} maxRange - Maximum range for the keyscale
 */
export const createKeyScale = (keys, minRange, maxRange, padding = 0.1) => {
  const keyScale = scaleBand()
    .paddingInner(padding)
    .domain(keys)
    .range([minRange, maxRange]);
  return keyScale;
};

/**
 * Create a timescale mapping a given range to the extent of the time domain
 * @param {*} data
 * @param {*} dateKey - Key that holds the time date field in the data object provided
 * @param {*} minRange - Minimum range for the time scale
 * @param {*} maxRange - Maximum range for the time scale
 */
export const createTimeScale = (data, dateKey, minRange, maxRange) => {
  const timeScale = scaleTime().range([minRange, maxRange]);
  const timeDomain = extent(data, (d) => d[dateKey]);
  timeScale.domain(timeDomain);
  return timeScale;
};

/**
 * Create a linear scale mapping a given range to domain
 * @param {number} minRange - Minimum range for the scale
 * @param {number} maxRange - Maximum range for the scale
 * @param {number} minDomain - Minimum domain for the scale
 * @param {number} maxDomain - Maximum domain for the scale
 */
export const createLinearScale = (
  minRange,
  maxRange,
  minDomain,
  maxDomain,
  padScale = true,
) => {
  const scale = scaleLinear()
    .range([minRange, maxRange])
    .domain([minDomain, padScale ? maxDomain * 1.05 : maxDomain]);
  return scale;
};

/**
 * Assuming every object has the same shape
 * Returns array of keys from Data object that exlude keys in filter
 * @param {array} data - The returned data object
 * @param {array} filter - An array of keys that should not be returned
 */
export const getKeys = (data, filter) => {
  return Object.keys(data[0]).filter((k) => {
    if (!filter.includes(k)) {
      return k;
    }
  });
};

/**
 * Uses lodash to get the largest possible value in the entire dataset
 * @param {array} data
 * @param {array} keys
 */
export const getAggregateMax = (data, keys) => {
  let total = 0;
  keys.forEach((k) => {
    const largest = maxBy(data, (o) => o[k]);
    total += largest[k];
  });
  return total;
};

/**
 * Returns the an array representing the stacked data based on the keys and data passed in
 * @param {array} data - Array of objects with the same shape
 * @param {array} keys - Array of strings of keys to generate stack data for
 */
export const createStackedData = (data, keys) => {
  const stackGenerator = stack().keys(keys);
  const stackedData = stackGenerator(data);
  return stackedData;
};

/**
 * Helper function that returns an array of date objects for every hour on a given period
 * @param {Object} dateRange - Object containing a startDate and endDate
 */
export const createTimeIntervalArray = (dateRange) => {
  const { startDate, endDate } = dateRange;
  const s = roundToNearestMinutes(startDate, 10);
  const e = roundToNearestMinutes(endDate, 10);
  const zData = [];
  const hourDelta = differenceInHours(e, s);
  let hours = 0;
  let minutesToAdd = 0;
  /**
   * This logic matches the response from the API. For charts with a time frame greater than 11 hours, the response is 30 minute intervals. Charts with a lesser time frame yields a response of 10 minute intervals.
   */
  if (hourDelta > 11) {
    hours = (hourDelta + 1) * 2;
    minutesToAdd = 30;
  } else {
    hours = (hourDelta + 1) * 6;
    minutesToAdd = 10;
  }  
  for (let i = 0; i < hours; i++) {
    zData.push({
      date: addMinutes(s, i * minutesToAdd),
    });
  }
  return zData;
};

/**
 * Returns a integer based on the date range passed in to represent
 * how frequent ticks should be drawn on the continuous axis
 * @param {Object} dateRange - Object containing both startDate and endDate keys
 */
export const getXTickFrequency = (dateRange) => {
  const hours = Math.abs(differenceInHours(dateRange.endDate, dateRange.startDate));
  const minutes = Math.abs(differenceInMinutes(dateRange.endDate, dateRange.startDate));
  let ticks = 0;
  // 1 day or less
  if (hours <= 24 ) {
    if (minutes <= 60) {
      ticks = minutes / 10; // Magic number 10 because we the smallest intervals we get is in 10min chunks from the API
    } else {
      ticks = hours;
    }
  }
  // 2 days
  if (hours > 24 && hours <= 72) { ticks = 12;}
  // 5 days
  if (hours > 72 && hours <= 120) { ticks = Math.trunc(hours / 12);}
  // 5 days to 14 days
  if (hours > 120 && hours <= 336) {ticks = Math.trunc(hours / 24) + 1;}
  // 14 days to 21 days
  if (hours > 336 && hours <= 504) { ticks = Math.trunc(hours / (24 * 2)) + 1;}
  // 21 days to 30 days
  if (hours > 504 && hours <= 720) { ticks = Math.trunc(hours / (24 * 7)) + 1;}
  // 30 days to 60 days
  if (hours > 720 && hours <= 1440) { ticks = Math.trunc(hours / (24 * 7)) + 1;}
  // 60 days to 90 days
  if (hours > 1440 && hours <= 2160) { ticks = Math.trunc(hours / (24 * 15)) + 1;}
  // Greater than 90 Days
  if (hours > 2160) { ticks = Math.trunc(hours / (24 * 31)) + 1;}
  return ticks;
};

/**
 * Gets bar label (appears above bar) x and y positions. Uses current animation progress to determine offset.
 * @param   {Object}  bar - Object containing the attributes of a given bar
 * @param   {Number}  animationPercent - Percent as a decimal that represents animation progress.
 * @param   {Boolean} hasVerticalLabels - If the labels being offset are vertical
 * @returns {Object}  The offset x and y positions of the text
 */
export const getTextPosition = (bar, animationPercent = 1, hasVerticalLabels = false) => {
  let textX = bar.textX;
  let textY = bar.y - 10;
  const offset = (1 - animationPercent) * bar.h;
  
  if (hasVerticalLabels) {
    textX -= offset;
  } else {
    textY += offset;
  }
  return { textX, textY };
};

/**
 * Created a function to invert a d3 group scale
 * @param   {Function} scale  d3 group scale instance
 * @returns {Function}        Inverted scale
 */
export const scaleBandInvert = (scale) => {
  const domain = scale.domain();
  const paddingOuter = scale(domain[0]);
  const eachBand = scale.step();
  return (value) => {
    const index = Math.floor(((value - paddingOuter) / eachBand));
    return domain[Math.max(0,Math.min(index, domain.length-1))];
  };
};

/**
 * Takes the currently hovered value and determines the closest intersecting tick
 * @param {String}  xKey     Key used for x axis values 
 * @param {Array}   data     Chart data
 * @param {Object}  hovered  Current hover position
 * @returns                  x tick closest to the current cursor position 
 */
export const getLinearBisect = (xKey, data, hovered) => {
  const bisect = bisector((d) => d[xKey]).right;
  const index = bisect(data, hovered, 1);
  const a = data[index - 1];
  const b = data[index];
  if (!a && !b) {
    return;
  } else if (!a) {
    return b;
  } else if (!b) {
    return a;
  } else {
    return hovered - a[xKey] > b[xKey] - hovered ? b : a;
  }
};

/**
 * Format date tick values
 * @param {Object} date  Date to be formatted
 * @param {Array} data   List of area data
 */
export const formatAxisDate = (date, data) => {
  let year = new Date().getFullYear();
  if (data && data.length > 0) {
    const start = data[0].date;
    const end = data[data.length - 1].date;
    if (differenceInMinutes(end, start) <= 60) {
      return format(date, 'h:mm');
    }
  }

  if (year !== parseInt(format(date, 'yyyy'))) {
    year = format(date, 'yyyy');
    return format(date, 'LLL yyyy');
  }

  if (date.getHours() === 0) {
    return format(date, 'd LLL');
  }

  if (date.getHours() === 12) {
    return format(date, 'h a');
  }

  return format(date, 'h');
};

/**
 * Trims off empty chart points from the beginning and end of
 * the chart data list
 * @param {Array}  data  List of chart data
 * @param {String} key   Key that must appear for entry to be valid
 * @returns {Array}      List of valid/required chart points
 */
export const trimChartData = (data, key) => {
  let trimmed = trimEnd(trimStart(data, key), key);
  
  // If we have a single point, we need to add back in the adjacent points
  // This is to ensure Area charts are able to render the single valid data point
  if (trimmed.length === 1) {
    const originalIndex = data.indexOf(trimmed[0]);
    let paddedList = [...trimmed];
    
    if (originalIndex - 1 >= 0) {
      paddedList = [data[originalIndex - 1], ...paddedList];
    }
    if (originalIndex + 1 <= data.length - 1) {
      paddedList = [...paddedList, data[originalIndex + 1]];
    }
    trimmed = paddedList;
  }

  return trimmed;
};
