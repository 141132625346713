/**
 * Helper function that with Capitalize any string
 * @param {String} string - String input
 */
// see pref at https://jsperf.com/capitalize-the-first-letter-of-string-in-javascript/19
export const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Converts camel case to string with spaces
 * Regex taken from https://gist.github.com/jonlabelle/5375315
 * @param   {String} string camel cased string
 * @returns {String}        string with spaces before camel cased words
 */
export const unCamelCase = (string) => (
  string.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
);

// Taken from https://stackoverflow.com/a/35373030
export class MeasureText {
  constructor() {
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d');
  }

  /**
   * Measures the rendered width of arbitrary text given the font size and font face
   * @param {string} text The text to measure
   * @param {number} fontSize The font size in pixels
   * @param {string} fontFace The font face ("Arial", "Helvetica", etc.)
   * @returns {number} The width of the text
   * */
  getWidth(text, fontSize, fontFace) {
    this.context.font = `${fontSize}px ${fontFace}`;
    return this.context.measureText(text).width;
  }
}
