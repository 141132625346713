import { call, put, select, takeLatest, takeEvery, delay, retry } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import types from 'actions/diveTypes';
import authTypes from 'actions/authTypes';
import api from 'apis/dive';
import overviewApi from 'apis/overview';
import * as selectors from 'selectors/dive';
import * as authSelectors from 'selectors/auth';
import { sortObjectByKey } from 'utils/table';
import { formatDate } from 'utils/dateTime';
import { getAuthValues } from './auth';
import { ROUTE_NAMES } from 'utils/paths';
import { getRouteName } from 'selectors/router';

const AUTOCOMPLETE_DELAY = 500;

export function* fetchTopArticlesData() {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    // TODO : Move the following to the store if we need to support editing columns
    // Use ![LABEL, TYPE] for additional presentational columns with data derived from the actual API call
    const displayColumns = ['headline', 'sectionName', 'byline', 'sophiScore'];
    const dateRange = yield select(selectors.getDateRange);
    const filters = yield select(selectors.getDiveFilters);
    const timezone = yield select(authSelectors.getTimezone);
    // TODO: Remove timezone here once we migrate these to the new sophi tables
    const data = yield call(api.getDiveArticleData, host, client, token, filters, { dateRange, displayColumns, timezone });
    yield put({ type: types.FETCH_TOP_ARTICLES_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.FETCH_TOP_ARTICLES_FAIL, error });
  }
}

export function* fetchTopAuthorsData(action) {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');

    // TODO : Move the following to the store if we need to support editing columns
    // Use ![LABEL, TYPE] for additional presentational columns with data derived from the actual API call
    const displayColumns = ['author', 'articles', 'sophiScore', 'sophiScorePerArticle'];
    const dateRange = yield select(selectors.getDateRange);
    const filters = yield select(selectors.getDiveFilters);

    const query = {
      dateRange,
      displayColumns,
      sortConfig: {
        sort: 'articles',
        sortOrder: 'desc'
      },
    };

    const data = yield call(api.getDiveAuthorData, host, client, token, filters, query);

    // Secondary sort by score / article
    const sortedRows = data.response.data
      .sort((a, b) => b.sophiScorePerArticle - a.sophiScorePerArticle);

    yield put({
      type: types.FETCH_TOP_AUTHORS_SUCCESS,
      response: {
        data: sortedRows
      }
    });

  } catch (error) {
    yield put({ type: types.FETCH_TOP_AUTHORS_FAIL, error });
  }
}

export function* fetchComparisonChartData() {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const dateRange = yield select(selectors.getDateRange);
    const data = yield call(api.getComparisonChartData, host, client, token, { dateRange });
    yield put({ type: types.DIVE_COMPARISON_CHART_SUCCESS, data: data.response });

  } catch (error) {
    yield put({ type: types.DIVE_COMPARISON_CHART_FAILURE, error });
  }
}

export function* fetchFilterOptionsData() {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const response = yield call(api.getFilterOptions, host, client, token);

    // Replace section filter data
    const { token: sectionToken, host: sectionHost, client: sectionClient } = yield call(getAuthValues, 'section');
    const dateRange = yield select(selectors.getDateRange);
    const data = yield call(overviewApi.getOverviewSections, sectionHost, sectionClient, sectionToken, dateRange);
    response.sections = { data: data.response };

    yield put({ type: types.DIVE_FILTER_OPTIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.DIVE_FILTER_OPTIONS_FAIL, error });
  }
}

export function* filterAutosuggest(action) {
  // Debounce delay
  yield delay(AUTOCOMPLETE_DELAY);
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const { field, term } = action.query;
    if (!term) { // If there is no term, then we reset the autosuggest
      throw new Error;
    }

    const { response } = yield call(api.getFilterAutosuggest, host, client, token, { field, term });
    yield put({ type: types.DIVE_FILTER_AUTOSUGGEST_SUCCESS, data: { response } });
  } catch (error) {
    yield put({ type: types.DIVE_FILTER_AUTOSUGGEST_SUCCESS, data: { response: null } });
  }
};

export function* refetchDashboardData() {
  const chartLoaded = yield select(selectors.getOverviewChartLoaded);
  const articlesLoaded = yield select(selectors.getArticlesLoaded);
  const authorsLoaded = yield select(selectors.getAuthorsLoaded);

  if (chartLoaded) {
    yield put({ type: types.DIVE_COMPARISON_CHART });
  }
  if (articlesLoaded) {
    yield put({ type: types.FETCH_TOP_ARTICLES });
  }
  if (authorsLoaded) {
    yield put({ type: types.FETCH_TOP_AUTHORS });
  }
}

export function* fetchDiveAuthorData(action) {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const displayColumns = yield select(selectors.getDiveAuthorsColumns);
    const dateRange = yield select(selectors.getDateRange);
    const filters = yield select(selectors.getDiveFilters);
    // Update table config based on action values
    let updatedConfig = yield select(selectors.getAuthorTableConfig);
    // Pagination
    if (action.paginationType) {
      updatedConfig[action.paginationType] = action.paginationValue;
    }

    // Sort
    if (action.sortValues) {
      updatedConfig.sortConfig = action.sortValues;
    }

    const query = {
      dateRange,
      displayColumns,
      ...updatedConfig,
    };

    const data = yield call(api.getDiveAuthorData, host, client, token, filters, query);
    yield put({ type: types.FETCH_DIVE_AUTHORS_SUCCESS, response: data.response, updatedConfig });

  } catch (error) {
    yield put({ type: types.FETCH_DIVE_AUTHORS_FAIL, error });
  }
}

export function* fetchDiveAuthorsReport(action) {
  const downloadHash = uuidv4();
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const displayColumns = yield select(selectors.getDiveAuthorsColumns);
    const dateRange = yield select(selectors.getDateRange);
    const filters = yield select(selectors.getDiveFilters);
    const timezone = yield select(authSelectors.getTimezone);
    // Update table config based on action values
    let updatedConfig = yield select(selectors.getAuthorTableConfig);

    // Sort
    if (action.sortValues) {
      updatedConfig.sortConfig = action.sortValues;
    }

    const startDate = formatDate(dateRange.startDate, `d MMM yyyy`, timezone);
    const endDate = formatDate(dateRange.endDate, `d MMM yyyy`, timezone);
    const fileName = `Authors Report for ${startDate} to ${endDate}.csv`;

    const query = {
      dateRange,
      displayColumns,
      fileName,
      timezone,
      ...updatedConfig,
    };

    yield put({
      type: types.FETCH_DIVE_AUTHORS_REPORT_LOADING,
      downloadMeta: {
        hash: downloadHash,
        filters,
        query,
        loading: true,
        error: false,
        complete: false,
      }
    });
    yield call(api.getDiveAuthorsDownload, host, client, token, filters, query);
    yield put({
      type: types.FETCH_DIVE_AUTHORS_REPORT_SUCCESS,
      hash: downloadHash
    });
    yield delay(15000);
    yield put({
      type: types.FETCH_DIVE_AUTHORS_REPORT_CLEAR,
      hash: downloadHash
    });
  } catch (error) {
    yield put({ type: types.FETCH_DIVE_AUTHORS_REPORT_ERROR, hash: downloadHash, error });
    yield delay(60000);
    yield put({
      type: types.FETCH_DIVE_AUTHORS_REPORT_CLEAR,
      hash: downloadHash
    });
  }
}

export function* fetchDiveArticleData(action) {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const displayColumns = yield select(selectors.getDiveArticlesColumns);
    const dateRange = yield select(selectors.getDateRange);
    let filters = yield select(selectors.getDiveFilters);
    // Update table config based on action values
    let updatedConfig = yield select(selectors.getArticleTableConfig);

    // Pagination
    if (action.paginationType) {
      updatedConfig[action.paginationType] = action.paginationValue;
    }

    // Sort
    if (action.sortValues) {
      updatedConfig.sortConfig = action.sortValues;
    }

    if (action.overrideFilters) {
      filters = {
        ...filters,
        ...action.overrideFilters,
      };
    }

    const query = {
      dateRange,
      displayColumns,
      ...updatedConfig,
    };

    const data = yield call(api.getDiveArticleData, host, client, token, filters, query);
    yield put({ type: types.FETCH_DIVE_ARTICLES_SUCCESS, response: data.response, updatedConfig });

  } catch (error) {
    yield put({ type: types.FETCH_DIVE_ARTICLES_FAIL, error });
  }
}

export function* fetchDiveArticleReport(action) {
  const downloadHash = uuidv4();
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const displayColumns = yield select(selectors.getDiveArticlesColumns);
    const dateRange = yield select(selectors.getDateRange);
    const timezone = yield select(authSelectors.getTimezone);
    let filters = yield select(selectors.getDiveFilters);
    // Update table config based on action values
    let updatedConfig = yield select(selectors.getArticleTableConfig);

    if (action.overrideFilters) {
      filters = {
        ...filters,
        ...action.overrideFilters,
      };
    }

    const startDate = formatDate(dateRange.startDate, `d MMM yyyy`, timezone);
    const endDate = formatDate(dateRange.endDate, `d MMM yyyy`, timezone);

    const fileName = `Articles Report for ${startDate} to ${endDate}.csv`;

    const query = {
      dateRange,
      displayColumns,
      fileName,
      timezone,
      ...updatedConfig,
      size: -1,
    };

    yield put({
      type: types.FETCH_DIVE_ARTICLES_REPORT_LOADING,
      downloadMeta: {
        hash: downloadHash,
        filters,
        query,
        loading: true,
        error: false,
        complete: false,
      }
    });
    yield call(api.getDiveArticleDownload, host, client, token, filters, query);
    yield put({
      type: types.FETCH_DIVE_ARTICLES_REPORT_SUCCESS,
      hash: downloadHash
    });
    yield delay(15000);
    yield put({
      type: types.FETCH_DIVE_ARTICLES_REPORT_CLEAR,
      hash: downloadHash
    });
  } catch (error) {
    yield put({ type: types.FETCH_DIVE_ARTICLES_REPORT_ERROR, hash: downloadHash, error });
    yield delay(60000);
    yield put({
      type: types.FETCH_DIVE_ARTICLES_REPORT_CLEAR,
      hash: downloadHash
    });
  }
}

export function* fetchSectionData({ parent }) {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const dateRange = yield select(selectors.getDateRange);
    const columns = yield select(selectors.getDiveSectionsColumns);
    const config = yield select(selectors.getSortConfig);

    const opts = {
      dateRange,
      columns
    };

    const data = yield call(api.getSectionData, host, client, token, parent, opts);
    yield put({ type: types.FETCH_SECTIONS_SUCCESS, data: data.response });
    yield put({ type: types.UPDATE_DIVE_SECTIONS_SORT, config });
  } catch (error) {
    yield put({ type: types.FETCH_SECTIONS_FAIL, error });
  }
}

export function* fetchSubSectionData({ parent }) {
  try {
    const { token, client, host } = yield call(getAuthValues, 'dive');
    const dateRange = yield select(selectors.getDateRange);
    const columns = yield select(selectors.getDiveSectionsColumns);
    const config = yield select(selectors.getSortConfig);

    const currentTable = yield select(selectors.getSectionTable);

    const opts = {
      dateRange,
      currentTable,
      columns
    };

    const data = yield retry(3, 1000, api.getSectionData, host, client, token, parent, opts);
    yield put({ type: types.FETCH_SUBSECTION_SUCCESS, data: data.response });
    yield put({ type: types.UPDATE_DIVE_SECTIONS_SORT, config });
  } catch (error) {
    yield put({ type: types.FETCH_SUBSECTION_FAIL, error });
  }
}

export function* sortSectionsTable() {
  const sortConfig = yield select(selectors.getSortConfig);
  const currentTable = yield select(selectors.getSectionTable);
  try {
    const sortedTable = sortObjectByKey(currentTable, sortConfig);
    yield put({
      type: types.DIVE_SECTIONS_SORT_SUCCESS,
      data: sortedTable,
    });
  } catch (error) {
    yield put({ type: types.DIVE_SECTIONS_SORT_FAIL, error });
  }
}

export function* refreshDivePage() {
  const route = yield select(getRouteName);
  yield put({ type: types.DIVE_REMOVE_APPLIED_FILTERS });
  yield put({ type: types.DIVE_FILTER_OPTIONS_FETCH });

  switch (route) {
    case ROUTE_NAMES.diveAuthors:
      yield put({ type: types.FETCH_DIVE_AUTHORS });
      break;
    case ROUTE_NAMES.diveArticles:
      yield put({ type: types.FETCH_DIVE_ARTICLES });
      break;
    case ROUTE_NAMES.diveSections:
      yield put({ type: types.FETCH_SECTIONS });
      break;
  }
}

export default function* diveSaga() {
  yield takeLatest(types.FETCH_TOP_ARTICLES, fetchTopArticlesData);
  yield takeLatest(types.FETCH_TOP_AUTHORS, fetchTopAuthorsData);
  yield takeLatest(types.DIVE_COMPARISON_CHART, fetchComparisonChartData);
  yield takeLatest([authTypes.UPDATE_CONFIG, types.REFETCH_DASHBOARD_DATA], refetchDashboardData);
  yield takeLatest(authTypes.UPDATE_CONFIG, refreshDivePage);
  yield takeLatest(types.DIVE_FILTER_OPTIONS_FETCH, fetchFilterOptionsData);
  yield takeLatest(types.DIVE_FILTER_AUTOSUGGEST, filterAutosuggest);
  yield takeLatest(
    [types.FETCH_DIVE_AUTHORS, types.UPDATE_DIVE_AUTHOR_PAGINATION, types.UPDATE_DIVE_AUTHOR_SORT],
    fetchDiveAuthorData
  );
  yield takeLatest(
    [types.FETCH_DIVE_ARTICLES, types.UPDATE_DIVE_ARTICLES_PAGINATION, types.UPDATE_DIVE_ARTICLES_SORT, types.FETCH_DIVE_AUTHOR_ARTICLES],
    fetchDiveArticleData,
  );
  yield takeLatest(types.FETCH_SECTIONS, fetchSectionData);
  yield takeLatest(types.FETCH_SUBSECTION, fetchSubSectionData);
  yield takeLatest(types.UPDATE_DIVE_SECTIONS_SORT, sortSectionsTable);
  yield takeEvery(types.FETCH_DIVE_ARTICLES_REPORT, fetchDiveArticleReport);
  yield takeEvery(types.FETCH_DIVE_AUTHORS_REPORT, fetchDiveAuthorsReport);
}
