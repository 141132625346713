"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPageTitle = exports.getRouteName = exports.getRoute = exports.ALL_ROUTES = exports.ROUTE_NAMES = void 0;
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
/**
 * Names of routes
 */
exports.ROUTE_NAMES = {
    root: 'root',
    admin: 'admin',
    bigBoard: 'bigBoard',
    deprecatedContentView: 'deprecatedContentView',
    diveArticles: 'diveArticles',
    diveAuthors: 'diveAuthors',
    diveAuthorPage: 'diveAuthorPage',
    diveHome: 'diveHome',
    diveSections: 'diveSections',
    diveArticlePage: 'diveArticlePage',
    diveBetaArticles: 'diveBetaArticles',
    diveBetaAuthors: 'diveBetaAuthors',
    diveBetaAuthorPage: 'diveBetaAuthorPage',
    diveBetaHome: 'diveBetaHome',
    diveBetaSections: 'diveBetaSections',
    diveBetaArticlePage: 'diveBetaArticlePage',
    reportsHome: 'reportsHome',
    reportsPop: 'reportsPop',
    reportsPromo: 'reportsPromo',
    nowContentView: 'nowContentView',
    nowHome: 'nowHome',
    nowArticlePage: 'nowArticlePage',
    notFound: 'notFound',
    logout: 'logout',
    wallDisplay: 'wallDisplay',
    curatorRoot: 'curatorRoot',
    curatorPagesList: 'curatorPagesList',
    curatorPageView: 'curatorPageView',
    curatorPageCreate: 'curatorPageCreate',
    curatorPageImport: 'curatorPageImport',
    curatorPageEdit: 'curatorPageEdit',
    curatorPagePublish: 'curatorPagePublish',
    curatorPagePreview: 'curatorPagePreview',
    curatorPageUnpublish: 'curatorPageUnpublish',
    curatorPageDelete: 'curatorPageDelete',
    curatorWidgetCreate: 'curatorWidgetCreate',
    curatorWidgetEdit: 'curatorWidgetEdit',
    curatorWidgetDelete: 'curatorWidgetDelete',
    curatorOverrides: 'curatorOverrides',
    welcome: 'welcome',
};
/**
 * Static map of application routes
 */
exports.ALL_ROUTES = {
    [exports.ROUTE_NAMES.root]: '/',
    [exports.ROUTE_NAMES.admin]: '/admin',
    [exports.ROUTE_NAMES.deprecatedContentView]: '/content-view',
    [exports.ROUTE_NAMES.diveArticles]: '/historical/articles',
    [exports.ROUTE_NAMES.diveAuthors]: '/historical/authors',
    [exports.ROUTE_NAMES.diveAuthorPage]: '/historical/author/:author',
    [exports.ROUTE_NAMES.diveHome]: '/historical',
    [exports.ROUTE_NAMES.diveSections]: '/historical/sections',
    [exports.ROUTE_NAMES.diveArticlePage]: '/historical/content/:contentId',
    [exports.ROUTE_NAMES.diveBetaArticles]: '/historical/beta/articles',
    [exports.ROUTE_NAMES.diveBetaAuthors]: '/historical/beta/authors',
    [exports.ROUTE_NAMES.diveBetaAuthorPage]: '/historical/beta/author/:author',
    [exports.ROUTE_NAMES.diveBetaHome]: '/historical/beta',
    [exports.ROUTE_NAMES.diveBetaSections]: '/historical/beta/sections',
    [exports.ROUTE_NAMES.diveBetaArticlePage]: '/historical/beta/content/:contentId',
    [exports.ROUTE_NAMES.reportsHome]: '/reports',
    [exports.ROUTE_NAMES.reportsPop]: '/reports/pop',
    [exports.ROUTE_NAMES.reportsPromo]: '/reports/promo',
    [exports.ROUTE_NAMES.nowContentView]: '/live/content-view',
    [exports.ROUTE_NAMES.nowHome]: '/live',
    [exports.ROUTE_NAMES.nowArticlePage]: '/content/:contentId',
    [exports.ROUTE_NAMES.notFound]: '/404',
    [exports.ROUTE_NAMES.logout]: '/logout',
    [exports.ROUTE_NAMES.wallDisplay]: '/display(/)?*(/)?',
    [exports.ROUTE_NAMES.curatorRoot]: '/site-automation',
    [exports.ROUTE_NAMES.curatorPagesList]: '/site-automation/pages',
    [exports.ROUTE_NAMES.curatorPageImport]: '/site-automation/page/import',
    [exports.ROUTE_NAMES.curatorPageCreate]: '/site-automation/page/add/:pageName?',
    [exports.ROUTE_NAMES.curatorPageView]: '/site-automation/page/:pageName/view',
    [exports.ROUTE_NAMES.curatorPageEdit]: '/site-automation/page/:pageName/edit',
    [exports.ROUTE_NAMES.curatorPagePublish]: '/site-automation/page/:pageName/publish',
    [exports.ROUTE_NAMES.curatorPagePreview]: '/site-automation/page/:pageName/preview',
    [exports.ROUTE_NAMES.curatorPageUnpublish]: '/site-automation/page/:pageName/unpublish',
    [exports.ROUTE_NAMES.curatorPageDelete]: '/site-automation/page/:pageName/delete',
    [exports.ROUTE_NAMES.curatorWidgetCreate]: '/site-automation/page/:pageName/widgets/add/:widgetName?',
    [exports.ROUTE_NAMES.curatorWidgetEdit]: '/site-automation/page/:pageName/widgets/edit/:widgetName',
    [exports.ROUTE_NAMES.curatorWidgetDelete]: '/site-automation/page/:pageName/widgets/delete/:widgetName',
    [exports.ROUTE_NAMES.curatorOverrides]: '/site-automation/overrides',
    [exports.ROUTE_NAMES.welcome]: '/welcome',
};
/**
 * Returns a string of either a valid path or the default 404 route
 * @param {String} route - String of the path requires
 * @param {Object} [params] - Route params
 */
const getRoute = (route, params) => {
    const path = exports.ALL_ROUTES[route];
    if (path === undefined) {
        return exports.ALL_ROUTES[exports.ROUTE_NAMES.notFound];
    }
    if (params) {
        return (0, react_router_1.generatePath)(path, params);
    }
    return path;
};
exports.getRoute = getRoute;
/**
 * Returns the name of the key for this path, or an empty string if there's no match
 * @param {String} path - Path of the route
 */
const getRouteName = (path) => {
    const keys = Object.keys(exports.ALL_ROUTES);
    const match = keys.find((key) => (0, react_router_dom_1.matchPath)(path, {
        path: exports.ALL_ROUTES[key],
        exact: true
    }));
    return match ? match : '';
};
exports.getRouteName = getRouteName;
/**
 * Updates the page title to whatever string is passed in
 * @param {String} title - String that the page title should be
 */
const setPageTitle = (title) => {
    const currentTitle = document.title.split('-');
    document.title = `${currentTitle[0]} - ${title}`;
};
exports.setPageTitle = setPageTitle;
