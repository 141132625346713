import { WIDGET_NAME_MAP } from 'apis/config';

import {
  nonDeviceDiveArticlesColumns,
  nonDeviceDiveAuthorColumns,
  nonDeviceDiveSectionsColumns,
  deviceTopDiveColumns,
  deviceTopDiveSectionsColumns,
} from './dive';

import {
  nonDeviceNowColumns,
  deviceTopNowColumns,
} from './now';

function getTableColumns(widgetName) {
  let nonDevice = {};
  let deviceTop = {};

  switch (widgetName) {
    case WIDGET_NAME_MAP.NOW_COLUMNS:
      nonDevice = nonDeviceNowColumns;
      deviceTop = deviceTopNowColumns;
      break;
    case WIDGET_NAME_MAP.ARTICLES_COLUMNS:
      nonDevice = nonDeviceDiveArticlesColumns;
      deviceTop = deviceTopDiveColumns;
      break;
    case WIDGET_NAME_MAP.AUTHOR_COLUMNS:
      nonDevice = nonDeviceDiveAuthorColumns;
      deviceTop = deviceTopDiveColumns;
      break;
    case WIDGET_NAME_MAP.SECTION_COLUMNS:
      nonDevice = nonDeviceDiveSectionsColumns;
      deviceTop = deviceTopDiveSectionsColumns;
      break;
  }

  return { ...nonDevice, ...deviceTop };
};

function generateFields(columns = {}) {
  const keys = Object.keys(columns);

  return keys.map((dataKey, index) => {
    const attributes = [
      { name: 'enabled', value: columns[dataKey].enabled || null },
      { name: 'required', value: columns[dataKey].required || null },
      { name: 'default', value: columns[dataKey].default || null },
      { name: 'type', value: (!!(columns[dataKey].platform) ? 'deviceTop' : 'nonDevice') },
    ];

    if (columns[dataKey].platform) {
      attributes.push({ name: 'platform', value: columns[dataKey].platform });
    }

    return {
      index,
      dataKey,
      label: columns[dataKey].label,
      attributes,
    };
  });
}

export function generateTableConfigResponse(widgetName) {
  const fields = generateFields(getTableColumns(widgetName));
  return {
    type: 'TABLE',
    name: widgetName,
    fields,
  };
}
