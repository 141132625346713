// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-TaBnU8IoqqVJMam5xwMn{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%}", "",{"version":3,"sources":["webpack://./src/atoms/ValuePlaceholder/ValuePlaceholder.styles.scss"],"names":[],"mappings":"AACA,4BACE,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,UAAA","sourcesContent":["// Placeholder Tooltip Styles\n.placeholder-container {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholderContainer": "sophi-TaBnU8IoqqVJMam5xwMn"
};
export default ___CSS_LOADER_EXPORT___;
