// Root
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageTitle from 'atoms/PageTitle';

// Organisms
import DiveFilterHeader from 'organisms/DiveFilterHeader';

// Pages
import Dashboard from 'pages/dive/Dashboard';
import Articles from 'pages/dive/Articles';
import Authors from 'pages/dive/Authors';
import AuthorPage from 'pages/dive/Author';
import Sections from 'pages/dive/Sections';

// Utils
import { getRoute } from 'utils/paths';

import styles from './Home.styles.scss';

const DiveHome = (props) => {
  const routes = {
    home: getRoute('diveHome'),
    authors: getRoute('diveAuthors'),
    author: getRoute('diveAuthorPage'),
    articles: getRoute('diveArticles'),
    sections: getRoute('diveSections'),
  };

  const getPageTitle = () => {
    const { pathname } = window.location;
    switch (pathname) {
      case `${routes.home}`:
        return 'Home';
      case `${routes.authors}`:
        return 'Authors';
      case `${routes.articles}`:
        return 'Articles';
      case `${routes.sections}`:
        return 'Sections';
    }

    if (pathname.includes('/historical/author/')) {
      const name = decodeURIComponent(pathname.split('/')[3]);
      return `Author: ${name}`;
    }
  };

  return (
    <div className={styles.diveHome}>
      <PageTitle title="Historical" subtitle={getPageTitle()}/>
      <div className={styles.container}>
        <DiveFilterHeader />
        <div className={styles.body}>
          <Switch>
            <Route exact path={routes.home} component={Dashboard} />
            <Route exact path={routes.authors} component={Authors} />
            <Route exact path={routes.author} component={AuthorPage} />
            <Route exact path={routes.articles} component={Articles} />
            <Route exact path={routes.sections} component={Sections} />
            <Route render={() => <Redirect to={getRoute('notFound')} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

DiveHome.propTypes = {
  // PropTypes
};

export default DiveHome;