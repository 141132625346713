import React, { useEffect, useState } from 'react';
// MUI
import Input from 'sophi-design-system/src/components/Input';
import Slider from '@material-ui/core/Slider';
// Atoms
import styles from './RangeSlider.styles.scss';

const RangeSlider = ({ data, onChange, max, id }) => {
  const [[rangeStart, rangeEnd], setRange] = useState([0, max || 50000]);

  useEffect(() => {
    if (Array.isArray(data) && data.length === 2) {
      setRange(data);
    } else {
      // Dispatch missing range to parent
      onChange([rangeStart, rangeEnd]);
    }
  }, [data, max]);
  const handleChange = (type, val) => {
    const updatedRange = type === 'start' ? [val, rangeEnd] : [rangeStart, val];
    if (val === '') return setRange(updatedRange);

    const numerical = parseInt(val);
    const invalidNumber = isNaN(numerical) || numerical < 0;
    if (invalidNumber || numerical > max) return;
    return onChange(updatedRange.map((v) => parseInt(v)));
  };

  const handleStartChange = (val) => handleChange('start', val);
  const handleEndChange = (val) => handleChange('end', val);

  const generateWarningMessage = () => {
    if (rangeStart === '' || rangeEnd === '') {
      return <p className={styles.validationMessage}>Please provide a start and end value.</p>;
    } else if (rangeStart > rangeEnd) {
      return <p className={styles.validationMessage}>Invalid range.</p>;
    }
    return null;
  };

  return (
    <section className={styles.layout}>
      <div className={styles.sliderContainer}>
        <Input
          className={styles.input}
          label="Min Value"
          id={`${id}-range-start`}
          data-track-click="true"
          data-track-name={`${id}-range-start-input`}
          value={rangeStart}
          type="number"
          onChange={({ target: { value } }) => handleStartChange(value)}
          invalid={rangeStart === '' || rangeStart > rangeEnd}
        />
        <Slider
          className={styles.slider}
          value={[rangeStart || 0, rangeEnd || max]}
          onChange={(e, val) => {
            e.stopPropagation();
            e.preventDefault();
            setRange(val);
            onChange(val);
          }}
          step={1}
          max={max}
        />
        <Input
          className={styles.input}
          label="Max Value"
          id={`${id}-range-start`}
          data-track-click="true"
          data-track-name={`${id}-range-end-input`}
          value={rangeEnd}
          type="number"
          onChange={({ target: { value } }) => handleEndChange(value)}
          invalid={rangeEnd === '' || rangeStart > rangeEnd}
        />
      </div>
      {generateWarningMessage()}
    </section>
  );
};

export default RangeSlider;