import React, { useEffect } from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useParams, useHistory } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import pageStyles from '../Page.styles.scss';
import styles from './PageUnpublish.styles.scss';

import PageTitle from '../../components/PageTitle';
import EntityName from '../../components/EntityName';
import { Button, message, Typography, Spin } from 'antd';
import { getPage, patchPage } from '../../api/pages';

const PageUnpublish = () => {
  const queryClient = useQueryClient();
  const { pageName } = useParams();
  let history = useHistory();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(patchPage);
  const { isLoading: isSaving, error, mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`page`, pageName] });
      const route = ROUTE_NAMES.curatorPageView;
      history.push(getRoute(route, { pageName }));
      message.success({
        content: `Unpublished`,
        style: {
          marginTop: '58px'
        }
      });
    }
  });
  
  const handleSubmit = () => {
    mutate({ body: { status: 'VALIDATED' }, pageName });
  };

  const goBack = () => {
    history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
  };

  useEffect(() => {
    if (!isLoading && data?.status !== 'ACTIVE') {
      history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      console.log(data);
    }
  }, [data]);

  return (
    <>
      <PageTitle title="Site automation" subtitle="Unpublish page"/>
      <div className={pageStyles.body}>
        {isLoading  && <Spin />}

        {!isLoading &&
          <div className={styles.layout}>
            <Typography.Paragraph>
              Content on page <EntityName name={pageName} /> will stop updating. Current content of the page will continue to be available and may become outdated.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Do you want to unpublish this page?
            </Typography.Paragraph>
            <div className={styles.actions}>
              <Button disabled={isSaving} onClick={goBack}>Cancel</Button>
              <Button disabled={isSaving} onClick={handleSubmit} type="primary">Unpublish</Button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default PageUnpublish;
