// Root
import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import Button from 'atoms/Button';

// Styles
import style from './Banner.styles.scss';

const Banner = (props) => {
  const { children, accept, action, dismissed } = props;
  return (
    <div className={`${style.banner} ${dismissed ? style.dismissed : ''}`}>
      <span>
        {children}
      </span>
      {
        accept && (
          <span className={style.button}>
            <Button onClick={(e) => action(e)}>{accept}</Button>
          </span>
        )
      }
    </div>
  );
};

Banner.defaultProps = {
  accept: 'Accept',
  action: () => console.log('button clicked'),
  dismissed: true,
};

Banner.propTypes = {
  accept: PropTypes.string,
  action: PropTypes.func
};

export default (Banner);