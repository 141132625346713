import React from 'react';
import styles from './Tabs.styles.scss';

const TabBody = ({ children }) => (
  <div className={`${styles.tabBody} tab-body`}>
    {children}
  </div>
);

export default TabBody;
