// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-QUQP686gPcb5P8xKiSZM{container-type:inline-size;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.sophi-uQFuM6f62fIp7SdPRGTI{-ms-flex-preferred-size:100%;flex-basis:100%;display:grid;grid-template-columns:1fr;gap:calc(.5*var(--space-1));max-width:1000px}@container (min-width: 400px){.sophi-uQFuM6f62fIp7SdPRGTI{grid-template-columns:1fr 1fr}}.sophi-xvNeAMOGoP_TibbuLU39{grid-column:1/-1}@container (min-width: 400px){.sophi-HhYC19fvdNQiNZDob0K4{justify-self:end}}.sophi-U7h4z6M1rq8y8Sj0dtJQ{max-width:200px}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/WidgetConfig/Display.styles.scss"],"names":[],"mappings":"AAAA,4BACE,0BAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAGF,4BACE,4BAAA,CAAA,eAAA,CACA,YAAA,CACA,yBAAA,CACA,2BAAA,CACA,gBAAA,CAGF,8BACE,4BACE,6BAAA,CAAA,CAIJ,4BACE,gBAAA,CAGF,8BACE,4BACE,gBAAA,CAAA,CAIJ,4BACE,eAAA","sourcesContent":[".container {\n  container-type: inline-size;\n  display: flex;\n  justify-content: center;\n}\n\n.grid {\n  flex-basis: 100%;\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: calc(0.5 * var(--space-1));\n  max-width: 1000px;\n}\n\n@container (min-width: 400px) {\n  .grid {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n.fullWidth {\n  grid-column: 1 / -1;\n}\n\n@container (min-width: 400px) {\n  .label {\n    justify-self: end;\n  }\n}\n\n.slider {\n  max-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-QUQP686gPcb5P8xKiSZM",
	"grid": "sophi-uQFuM6f62fIp7SdPRGTI",
	"fullWidth": "sophi-xvNeAMOGoP_TibbuLU39",
	"label": "sophi-HhYC19fvdNQiNZDob0K4",
	"slider": "sophi-U7h4z6M1rq8y8Sj0dtJQ"
};
export default ___CSS_LOADER_EXPORT___;
