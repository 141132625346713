import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import PagesList from '../PagesList';
import PageView from '../PageView';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageImport from '../PageImport';
import PagePublish from '../PagePublish';
import PagePreview from '../PagePreview';
import PageUnpublish from '../PageUnpublish';
import PageDelete from '../PageDelete';
import WidgetCreate from '../WidgetCreate';
import WidgetEdit from '../WidgetEdit';
import WidgetDelete from '../WidgetDelete';

import theme from './curatorAntdTheme.js';
import styles from './Home.styles.scss';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ParentContext from '../../contexts/parent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(2000 * 2 ** attemptIndex, 30000)
    },
    mutations: {
      retryDelay: (attemptIndex) => Math.min(2000 * 2 ** attemptIndex, 30000)
    }
  }
});

const CuratorConfigHome = (props) => {
  return (
    <ParentContext {...props}>
      <div className={styles.container}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={theme}>
            <Switch>
              <Route exact path={getRoute(ROUTE_NAMES.curatorPagesList)} component={PagesList} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageView)} component={PageView} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageCreate)} component={PageCreate} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageEdit)} component={PageEdit} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageImport)} component={PageImport} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPagePublish)} component={PagePublish} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPagePreview)} component={PagePreview} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageUnpublish)} component={PageUnpublish} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorPageDelete)} component={PageDelete} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorWidgetCreate)} component={WidgetCreate} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorWidgetEdit)} component={WidgetEdit} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorWidgetDelete)} component={WidgetDelete} />
              <Route exact path={getRoute(ROUTE_NAMES.curatorRoot)} render={() => <Redirect to={getRoute(ROUTE_NAMES.curatorPagesList)} />} />
              <Route render={() => <Redirect to={getRoute(ROUTE_NAMES.notFound)} />} />
            </Switch>
          </ConfigProvider>
        </QueryClientProvider>
      </div>
    </ParentContext>
  );
};

export default CuratorConfigHome;
