import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorState from 'sophi-design-system/src/components/ErrorState';
import ThemeRoot from 'styles/ThemeRoot';
import Header from 'organisms/Header';
import { getRoute } from 'utils/paths';

const ThemedHeader = ThemeRoot(Header);
const ThemedErrorState = ThemeRoot(ErrorState);

class ErrorBoundary extends Component {
  state = { eventId: null };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  getGoBackLink = () => {
    // add curator here

    const { pathname } = window.location;
    const nowContentViewPath = getRoute('nowContentView');
    const diveHomePath = getRoute('diveHome');

    if (pathname === nowContentViewPath || pathname === diveHomePath) {
      return { text: 'go back to the homepage', url: '/' };
    }
    if (pathname.includes(diveHomePath)) {
      return { text: 'go back to the Sophi Historical homepage', url: diveHomePath };
    }
    return { text: 'go back to content view', url: nowContentViewPath };
  };

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div>
          {this.props.showHeader ? <ThemedHeader /> : null}
          <ThemedErrorState
            action={() => window.location.reload()}
            link={this.getGoBackLink()}
            onReportIssueClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          />
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
