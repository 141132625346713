// Root
import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import LoadingState from 'sophi-design-system/src/components/LoadingState';
import ErrorState from 'sophi-design-system/src/components/ErrorState';
import NoData from 'atoms/NoData';


const Container = ({ children, noData, section, timePeriod, loading, loadingHeight, error, errorAction }) => {
  if (loading) {
    return (<LoadingState height={loadingHeight ? loadingHeight : null} />);
  }

  if (error) {
    return <ErrorState action={() => errorAction()} />;
  }

  if (noData) {
    return <NoData section={section} timePeriod={timePeriod} />;
  }
  return children;
};

Container.defaultProps = {
  title: null,
};

Container.propTypes = {
  title: PropTypes.string,
};

export default Container;
