import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const DynamicComponent = (props) => {
  const { placeholder, component, children, containerStyles, ...rest } = props;
  const [Component, setComponent] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    if (loaded) {
      setComponent(lazy(component));
    }
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [loaded]);

  
  useEffect(() => {
    const io = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        setLoaded(true);
      }
    }, { rootMargin: '0px' });
    
    io.observe(container.current);
  }, []);

  return (
    <>
      <section ref={container} style={containerStyles ? containerStyles : { minHeight: '35vh' }}>
        <Suspense fallback={placeholder} >
          {Component ? <Component {...rest} /> : null}
        </Suspense>
      </section>
    </>
  );
};

DynamicComponent.proptypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default DynamicComponent;