import React from 'react';
import format from 'date-fns/format';
import { Button } from 'antd';

// Providers
import { useOverrides } from '../contexts/OverridesProvider';
import { DownloadOutlined } from '@ant-design/icons';

const DownloadCsv: React.FC = () => {
  const { allReasons, overrides } = useOverrides();

  function onDownload() {
    const dataType = 'text/csv;charset=utf-8';
    const columns = ['Override Type', 'Article ID', 'Page Name', 'Widget Name', 'Position', 'Created By', 'Created Datetime', 'Expiration Datetime', 'Reason for Override'].join(',');
    const data = overrides.map(({ ruleType, articleId, page, widgetName, position, creationDate, expirationDate, requestedUserName, overrideReasonId }) => {
      const reason = allReasons.find(({ id }) => id === overrideReasonId)?.displayLabel || '';
      return [ruleType, articleId, page, widgetName, position, requestedUserName, creationDate, expirationDate, reason].join(',');
    });

    const blobContent = [columns, ...data].join('\n');
    const blob = new Blob([blobContent], {type: dataType});

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `overrides_${format(new Date(), 'dd_MM_yyyy')}.csv`);
    document.body.prepend(link);

    // Download then reset link
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
  }

  return (
    <Button icon={<DownloadOutlined/>} onClick={onDownload}>Download .csv</Button>
  );
}

export default DownloadCsv;
