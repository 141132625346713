// Utils
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Social Promotions Table
 * @param {String} id - String representing the contentID
 */
const getPromotionSocial = (host, client, id, token) => {
  if (paramsAreTruthy([id])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `promotion-analysis/social`
      }
    );

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      return { response: data };
    };

    return getResource({
      endpoint: path,
      token,
    })
      .then(formatResponse)
      .catch((error) => ({ error }));
  }
};

export default getPromotionSocial;