import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.styles.scss';
import { basicIcons, circleIcons } from './iconImports';
import useCancellablePromise from '../../hooks/useCancelablePromise';
import { capitalize } from '../../utils/string';

const Icon = ({
  name,
  type,
  color,
  size,
  className,
  ...iconProps
}) => {
  const [icon, setIcon] = useState(null);
  const { cancellablePromise } = useCancellablePromise();

  const getBasicIcon = async (iconName) => {
    try {
      const { ReactComponent } = await cancellablePromise(basicIcons[iconName]());
      setIcon(<ReactComponent data-name={iconName} className={`${styles.basicIcon} ${styles[color]} ${styles[`basicIcon${capitalize(size)}`]} ${className || ''}`} {...iconProps} />);
    } catch (err) {
      console.warn('Invalid icon selection', err); /* eslint-disable-line */
    }
  };

  const getCircleIcon = async (iconName) => {
    try {
      const { ReactComponent } = await cancellablePromise(circleIcons[iconName]());
      setIcon(<ReactComponent data-name={iconName} className={`${styles.circleIcon} ${styles[color]} ${styles[`circleIcon${capitalize(size)}`]} ${className || ''}`} {...iconProps} />);
    } catch (err) {
      console.warn('Invalid icon selection', err); /* eslint-disable-line */
    }
  };

  // Dynamically import just the icon you need
  useEffect(() => {
    if (type === 'basic' && basicIcons[name]) {
      getBasicIcon(name);
    } else if (type === 'circle' && circleIcons[name]) {
      getCircleIcon(name);
    }
  }, [name, type, color, size]);

  return icon || <i />;
};

Icon.defaultProps = {
  type: 'basic',
  color: 'teal',
  size: 'md',
  className: '',
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(basicIcons)).isRequired,
  type: PropTypes.oneOf(['basic', 'circle']),
  color: PropTypes.oneOf(['teal', 'white', 'black', 'gray', 'inherit']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
};

export default Icon;
