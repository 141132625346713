// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-zcbgJLbdBo_SEUU4W1u2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:var(--space-1);max-width:60ch;margin:auto}.sophi-wCSaOMkCd8CKOU4XCxXg{display:-webkit-box;display:-ms-flexbox;display:flex;gap:var(--space-1);-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/PagePreview/PagePreview.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".layout {\n  display: flex;\n  flex-direction: column;\n  gap: var(--space-1);\n  max-width: 60ch;\n  margin: auto;\n}\n\n.actions {\n  display: flex;\n  gap: var(--space-1);\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "sophi-zcbgJLbdBo_SEUU4W1u2",
	"actions": "sophi-wCSaOMkCd8CKOU4XCxXg"
};
export default ___CSS_LOADER_EXPORT___;
