import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import styles from '../Page.styles.scss';

import PageTitle from '../../components/PageTitle';
import WidgetConfigForm from '../../components/WidgetConfig';
import ErrorList from '../../components/ErrorList';
import { useHistory, Redirect } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { message, Spin } from 'antd';
import { getPage } from '../../api/pages';
import { getWidget, putWidget } from '../../api/widgets';

const WidgetEdit = () => {
  const queryClient = useQueryClient();
  const { pageName, widgetName } = useParams();
  let history = useHistory();

  const { fn: getPageQueryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data: pageConfig, isLoading: isPageConfigLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn: getPageQueryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    }
  });

  const { fn: getWidgetQueryFn } = useCuratorConfigApi(getWidget, { pageName, widgetName });
  const { data, isLoading } = useQuery({
    queryKey: [`widget`, pageName, widgetName],
    queryFn: getWidgetQueryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(putWidget);
  const { isLoading: isSaving, error, mutate } = useMutation({
    mutationFn,
    onMutate: async (request) => {
      await queryClient.cancelQueries({ queryKey: [`page`, pageName] });

      const previous = {
        pages: queryClient.getQueryData({ queryKey: [`pages`] }),
        page: queryClient.getQueryData({ queryKey: [`page`, pageName] }),
        widget: queryClient.getQueryData({ queryKey: [`widget`, pageName, widgetName] }),
      };

      const updated = {
        page: {
          ...previous.page,
          widgets: previous.page.widgets.map((w) => w.name === widgetName ? request.body : w)
        },
        widget: request.body,
      };

      if (previous.pages) {
        updated.pages = {
          ...previous.pages,
          items: previous.pages.items.map((p) => p.name === pageName ? updated.page : p)
        };
        queryClient.setQueryData([`pages`], () => updated.pages);
      }

      queryClient.setQueryData([`page`, pageName], () => updated.page);
      queryClient.setQueryData([`widget`, pageName, widgetName], () => updated.widget);

      return { previous };
    },
    onSuccess: () => {
      history.push(getRoute(ROUTE_NAMES.curatorPagePublish, { pageName, widgetName }));
      message.success({
        content: `Saved`,
        style: {
          marginTop: '58px'
        }
      });
    },
    onError: (err, request, context) => {
      if (context.previous.pages) queryClient.setQueryData([`pages`], context.previous.pages);
      queryClient.setQueryData([`page`, pageName], context.previous.page);
      queryClient.setQueryData([`widget`, pageName, widgetName], context.previous.page);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [`pages`] });
      queryClient.invalidateQueries({ queryKey: [`page`, pageName] });
      queryClient.invalidateQueries({ queryKey: [`widget`, pageName, widgetName] });
    }
  });
  
  const handleSubmit = (config) => {
    mutate({ body: { ...data, config }, pageName, widgetName });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Edit widget ${widgetName}`} />
      <div className={styles.body}>
        {error?.cause?.status === 404 &&
          <Redirect to={getRoute(ROUTE_NAMES.curatorPageView, { pageName })} />
        }
        {(isLoading || isPageConfigLoading) && (!data || !pageConfig) && 
          <Spin />
        }
        {!isLoading && pageConfig && data &&
          <WidgetConfigForm
            onSubmit={handleSubmit}
            onCancel={history.goBack}
            disabled={isSaving}
            config={data.config}
            widgets={pageConfig.widgets}
          />
        }
        {error?.messages &&
          <ErrorList messages={error.messages} />
        }
      </div>
    </>
  );
};

export default WidgetEdit;
