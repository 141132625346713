
import React, { createContext, useContext } from 'react';

import { message, Modal } from 'antd';
import { ArgsProps } from 'antd/es/message';

import { FeedbackProvider as FeedbackProviderInterface } from '../types';

const FeedbackContext = createContext<FeedbackProviderInterface>({} as FeedbackProviderInterface);

export const FeedbackProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [api, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();

  function openMessage(args: ArgsProps) {
    api.open({
      ...args,
      style: {
        marginTop: '75px'
      },
    });
  }

  return (
    <FeedbackContext.Provider value={{
      openMessage,
      modal
    }}>
      {contextHolder}
      {modalContextHolder}
      {children}
    </FeedbackContext.Provider>
  );
}

export const useFeedback = () => useContext(FeedbackContext);
export default FeedbackProvider;
