import React from 'react';
import { theme, Tree } from 'antd';

import { PageWidgetTree } from '../types';

const ListTreeFilter: React.FC<{
  data: PageWidgetTree[];
  localData: string[];
  onChange?: (data: (string|number)[]) => void;
}> = ({ data, localData, onChange }) => {
  const { token } = theme.useToken();
  function handleTreeCheck (data: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[]; }) {
    const checkedKeys = Array.isArray(data) ? data : data.checked;
    if (typeof onChange === 'function') onChange(checkedKeys);
  }

  return (
    <Tree
      className="page-widget-tree"
      rootStyle={{ backgroundColor: token.colorFillSecondary }}
      height={205}
      checkable
      checkedKeys={localData}
      defaultCheckedKeys={localData}
      defaultExpandedKeys={localData.length ? localData : [data[0].key]}
      onCheck={handleTreeCheck}
      treeData={data}
      virtual={false}
    />
  );
};

export default ListTreeFilter;
