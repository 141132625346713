// Lodash
import find from 'lodash/find';

// Date-fns
import isAfter from 'date-fns/isAfter';
import differenceInMinutes from 'date-fns/differenceInMinutes';

// Utils
import { convertToSeconds, convertSecondsToDate } from 'utils/dateTime';
import { getResource, pathBuilder, paramsAreTruthy, getDevices } from 'utils/api';
import { getOrderedArray } from 'utils/arrays';
import { trimChartData } from 'utils/chart';

/**
 * API call for Score Traffic Chart
 * @param {String} id - String representing the contentID
 * @param {Object} dateRange - Object with start and end time
 */
const getTrafficData = (host, client, id, token, dateRange, portrait, timezone) => {
  if (paramsAreTruthy([id, dateRange])) {
    const { startDate, endDate } = dateRange;
    const now = new Date();
    const end = isAfter(endDate, now) ? now : endDate;

    const minutesAccumulated = differenceInMinutes(end, startDate);
    const accumulatedScore = `${Math.floor(minutesAccumulated / 60)}h ${Math.floor(minutesAccumulated % 60)}m`;

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `score-analysis/traffic?start=${convertToSeconds(startDate)}&end=${convertToSeconds(end)}`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (response) => {
      if (Object.keys(response).includes('noData')) return ({ noData: true });
      const chartResponse = response.timeseries;
      const mediums = getDevices(response.timeseries);
      if (mediums.length < 1) return ({ noData: true });
      const graph = [];

      const trimmedChartData = trimChartData(chartResponse, 'total');
      if (trimmedChartData.length === 0) return { noData: true };

      trimmedChartData.forEach((d) => {
        const date = convertSecondsToDate(d.timestamp, timezone);
        const obj = {};
        mediums.forEach((c) => {
          const r = d.platforms ? find(d.platforms, { platform: c }) : null;
          obj[c.toLowerCase()] = r ? r.total : 0;
        });
        graph.push({
          ...obj,
          date,
        });
      });

      const unorderedPlatforms = response.breakdown.reduce((lu, r) => ({ ...lu, [r.platform.toLowerCase()]: r }), {});
      const reorderedRow = getOrderedArray(Object.keys(unorderedPlatforms))
        .reverse()
        .map((r) => unorderedPlatforms[r]);

      return {
        response: {
          graph,
          table: reorderedRow,
          total: unorderedPlatforms.totals,
          accumulatedScore,
        },
      };
    };
    return data;
  }
};

export default getTrafficData;