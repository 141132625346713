// Root
import React, { useState, useEffect, useMemo } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import ThemeRoot from 'styles/ThemeRoot';

// Components
import ProtectedRoute from './router/protected.route';

// Molecules
import Header from 'organisms/Header';
import Footer from 'atoms/Footer';
import PrivacyPolicyNotice from 'molecules/PrivacyPolicyNotice';
import AppLoading from 'molecules/AppLoading';
import DownloadCenter from 'molecules/DownloadCenter';

// Templates
import Content from 'templates/Content';
import DiveHome from 'templates/dive/Home';

// Pages
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import Live from 'pages/Live';
import Welcome from 'pages/Welcome';
import Overview from 'pages/Overview';
import Display from 'pages/Display';
import Logout from 'pages/Logout';
import Admin from 'pages/Admin';
import DiveBeta from 'pages/DiveBeta';
import Reports from 'pages/Reports';
import CuratorConfig from 'pages/CuratorConfig';
import CuratorOverrides from 'pages/CuratorOverrides';
// Styles
import './App.css';
import { createCustomProps } from 'styles/colors';

// Paths
import { getRoute, ROUTE_NAMES } from 'utils/paths';

// Selectors
import { getRouteName, getSearch } from 'selectors/router';
import {
  shouldTokenRefresh,
  getAvailableProducts,
  isUserManagementAvailable,
  isAuthenticated as isAuthFromStore,
  getAvailableReports,
  getOrganizations,
  getPermissionScope,
  getServices,
  getEnabledCuratorProductFeatures
} from 'selectors/auth';

// Utils
import { ScrollToTop } from 'utils/router';
import { parseOrganization } from 'utils/multiHost';
import { useAuth0 } from 'utils/Auth0Provider';

// Types
import authTypes from 'actions/authTypes';
import { getFromStorage, setInStorage } from 'utils/storage';

const App = () => {
  const routeName = useSelector(getRouteName);
  const search = useSelector(getSearch);
  const isAuthForRoutes = useSelector(isAuthFromStore);
  const shouldRefresh = useSelector(shouldTokenRefresh);
  const allOrganizations = useSelector(getOrganizations);
  const permissionScopes = useSelector(getPermissionScope);
  const services = useSelector(getServices);
  const dispatch = useDispatch();
  const { isAuthenticated, getTokenSilently, scopes, loading } = useAuth0();
  const products = useSelector(getAvailableProducts);
  const enabledCuratorFeatures = useSelector(getEnabledCuratorProductFeatures);
  const showUserAdmin = useSelector(isUserManagementAvailable);
  const reports = useSelector(getAvailableReports);

  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const searchOrg = queryParams.get('org');

  const getReportRedirectRoute = () => {
    const report = reports[0];
    const route = 'reports' + report.charAt(0).toUpperCase() + report.slice(1);
    return getRoute(route);
  };

  useEffect(() => {
    isAuthenticated !== undefined && dispatch({ type: authTypes.UPDATE_AUTH, data: isAuthenticated });
    isAuthenticated && dispatch({ type: authTypes.UPDATE_TOKEN_CACHE });
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && shouldRefresh) {
      getTokenSilently()
        .then((token) => dispatch({ type: authTypes.CACHE_TOKEN, data: token }))
        .catch((error) => dispatch({ type: authTypes.CACHE_TOKEN_FAILURE, error }));
    }
    // eslint-disable-next-line
  }, [shouldRefresh]);

  useEffect(() => {
    if (scopes.length) {
      let currentOrganization = null;
      const storedMainHost = getFromStorage('mainHost');
      const allowedOrganizations = [];
      permissionScopes.forEach((permission, index) => {
        if (scopes.includes(permission)) {
          const organization = allOrganizations[index];
          if (!currentOrganization) currentOrganization = organization;
          const { hosts } = organization;
          const { fqdn } = hosts[0];
          // Overwrite current organization if there is a stored main host
          if (searchOrg) {
            if (organization.id === searchOrg) currentOrganization = organization;
          } else if (storedMainHost && fqdn === storedMainHost) currentOrganization = organization;
          // Push fqdn to allowed organization list
          allowedOrganizations.push(fqdn);
        }
      });

      const data = {
        allowedOrganizations,
        ...parseOrganization(currentOrganization, services),
      };
      if (data.mainHost) {
        setInStorage('mainHost', data.mainHost);
        history.replace({ search: `?org=${data.orgId}` });
      }
      dispatch({ type: authTypes.UPDATE_CONFIG, data });
    }
  }, [scopes]);

  useEffect(() => {
    createCustomProps();
  }, []);

  const showAppShell = (() => {
    const noAppShellRoutes = [ROUTE_NAMES.wallDisplay];
    if (noAppShellRoutes.includes(routeName)) {
      return false;
    }
    return true;
  })();

  if (loading) {
    return <AppLoading />;
  };

  return (
    <Grid container spacing={0} style={{ minHeight: '100vh' }}>
      <PrivacyPolicyNotice />
      <Grid style={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
        {showAppShell ? <Header /> : null}
        <div style={{ minHeight: '50%' }}>
          <ScrollToTop />
          <Switch>
            <ProtectedRoute
              exact
              path={`${getRoute(ROUTE_NAMES.root)}`}
              component={Home}
              isAvailable={true}
            />
            <ProtectedRoute
              exact
              path={`${getRoute(ROUTE_NAMES.nowHome)}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('now')}
              component={Live}
            />
            <ProtectedRoute
              exact
              path={`${getRoute(ROUTE_NAMES.nowContentView)}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('now')}
              component={Overview}
            />
            <ProtectedRoute
              path={getRoute('nowArticlePage')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('now')}
              component={Content}
            />
            {/* DIVE BETA */}
            <ProtectedRoute
              exact
              path={getRoute('diveBetaHome')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveBeta}
              requiredScope="read:divebeta"
            />
            <ProtectedRoute
              exact
              path={getRoute('diveBetaSections')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveBeta}
              requiredScope="read:divebeta"
            />
            <ProtectedRoute
              exact
              path={getRoute('diveBetaArticles')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveBeta}
              requiredScope="read:divebeta"
            />
            <ProtectedRoute
              exact
              path={getRoute('diveBetaAuthors')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveBeta}
              requiredScope="read:divebeta"
            />
            <ProtectedRoute
              exact
              path={getRoute('diveBetaAuthorPage')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveBeta}
              requiredScope="read:divebeta"
            />
            {/* DIVE */}
            <ProtectedRoute
              exact
              path={getRoute('diveArticlePage')}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={Content}
            />
            <ProtectedRoute
              path={`${getRoute('diveHome')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('dive')}
              component={DiveHome}
            />
            {/* Reports */}
            <ProtectedRoute
              exact
              strict
              path={`${getRoute('reportsPop')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={reports.includes('pop')}
              component={Reports}
              requiredScope="reports:pop"
            />
            <ProtectedRoute
              exact
              strict
              path={`${getRoute('reportsPromo')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={reports.includes('promo')}
              component={Reports}
              requiredScope="reports:promo"
            />
            {/* CURATOR */}
            <ProtectedRoute
              path={`${getRoute(ROUTE_NAMES.curatorOverrides)}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={enabledCuratorFeatures.includes('OVERRIDE_UI')}
              requiredScope="override:read"
              component={CuratorOverrides}
            />
            <ProtectedRoute
              path={`${getRoute('curatorRoot')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={enabledCuratorFeatures.includes('CONFIG_UI')}
              requiredScope="configurator:pages:create"
              component={CuratorConfig}
            />
            {/* WALL DISPLAYS */}
            <ProtectedRoute
              exact
              strict
              path={`${getRoute('wallDisplay')}`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={products.includes('now')}
              component={Display}
            />
            {/* Admin */}
            <ProtectedRoute
              exact
              strict
              path={`${getRoute('admin')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={showUserAdmin}
              component={Admin}
              requiredScope="users:read"
            />
            {/* AUTH */}
            <Route
              exact
              strict
              path={`${getRoute('logout')}(/)?`}
              render={(props) => <Logout {...props} />}
            />
            <ProtectedRoute
              exact
              path={`${getRoute(ROUTE_NAMES.welcome)}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable={true}
              component={() => <Welcome />}
            />
            {reports.length &&
              <ProtectedRoute
                exact
                strict
                path={`${getRoute('reportsHome')}(/)?`}
                isAuthenticated={isAuthForRoutes}
                isAvailable
                component={() => <Redirect to={getReportRedirectRoute()} />}
              />
            }
            <ProtectedRoute
              exact
              strict
              path={`${getRoute('deprecatedContentView')}(/)?`}
              isAuthenticated={isAuthForRoutes}
              isAvailable
              component={() => <Redirect to={getRoute('nowContentView')} />}
            />
            <Route render={(props) => <NotFound {...props} />} />
          </Switch>
          {showAppShell ? <Footer /> : null}
          <DownloadCenter />
        </div>
      </Grid>
    </Grid>
  );
};

export default ThemeRoot(App);
