import React from 'react';
import PropTypes from 'prop-types';
import styles from './header.styles.scss';

const Header = ({ children, position, style }) => (
  <header className={`${styles.header} position-${position}`} style={style}>
    <div>
      {children}
    </div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(['sticky', 'relative']),
  style: PropTypes.object,
};

export default Header;
