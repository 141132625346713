import React from 'react';

import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';

import ButtonLink from '../ButtonLink';
import Display from '../WidgetConfig/Display';
import Accordion from '../Accordion';
import styles from './FilteredWidgetsList.styles.scss';

import useListSort from '../../hooks/useListSort';
import useTextFilter from '../../hooks/useTextFilter';
import { Form, Input, Button, Select } from 'antd';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { statusLabels } from '../../copy';

export default ({ pageName, scoreWindowIntervalInMins, data }) => {
  const [ form ] = Form.useForm();

  const sortOptions = [
    { label: 'Date created', accessor: 'createdOn' },
    { label: 'Last updated', accessor: (item) => item.updatedOn || item.createdOn },
    { label: 'Status', accessor: (item) => statusLabels[item.status] },
    { label: 'Name', accessor: (item) => item.config.name }
  ];

  const selectOptions = sortOptions.map(({ label }, index) => ({ value: index, label }));

  const {
      onSortByChange,
      onAscendingChange,
      ascending,
      sorted,
      selected: selectedSort
  } = useListSort({ list: data, options: sortOptions, storageKey: 'sa.page.widgets.sort' });

  const {
    onChange: handleSearchInput,
    query,
    filtered: filteredByName
  } = useTextFilter({
    list: sorted,
    accessor: (item) => item.config.name,
    storageKey: 'sa.page.widgets.name',
    label: 'Name',
    name: 'nameSearch'
  });

  const initialValues ={ sortBy: selectedSort, nameSearch: query };

  return (
    <>
      {data && data.length > 0 &&
      <>
        <Form form={form} initialValues={initialValues}>
          <div className={styles.controls}>
            <Form.Item label="Sort by" colon={false}>
              <Input.Group compact>
                <Form.Item name="sortBy" noStyle>
                  <Select
                    onChange={onSortByChange}
                    options={selectOptions}
                    style={{ width: '150px' }}
                  />
                </Form.Item>
                <Button
                  onClick={onAscendingChange}
                  icon={ascending ? <SortAscendingOutlined/> : <SortDescendingOutlined/> }
                />
              </Input.Group>
            </Form.Item>
  
            <Form.Item label="Name" name="nameSearch" colon={false}>
              <Input allowClear suffix={ <SearchOutlined/> } onChange={handleSearchInput} value={query} />
            </Form.Item>
          </div>
        </Form>

        <Accordion>
          {
            filteredByName.map(({ config }) => {
              const { name } = config;
              return (
                <Accordion.Item key={name}>
                  <Accordion.Details>
                    <Accordion.Summary>
                      <div className={styles.item}>
                        <span>{name}</span>
                        <div className={styles.buttons}>
                          <ButtonLink
                            to={getRoute(ROUTE_NAMES.curatorWidgetEdit, { pageName, widgetName: name })}
                          >
                            Edit
                          </ButtonLink>
                          <ButtonLink
                            to={getRoute(ROUTE_NAMES.curatorWidgetCreate, { pageName, widgetName: name })}
                          >
                            Copy
                          </ButtonLink>
                          <ButtonLink
                            to={getRoute(ROUTE_NAMES.curatorWidgetDelete, { pageName, widgetName: name })}
                            danger={true}
                          >
                            Delete
                          </ButtonLink>
                        </div>
                      </div>
                    </Accordion.Summary>
                    <div>
                      <Display
                        scoreWindowIntervalInMins={scoreWindowIntervalInMins}
                        config={config}
                      />
                    </div>
                  </Accordion.Details>
                </Accordion.Item>
              );
            })
          }
        </Accordion>
      </>}

      {(!data || data.length === 0) &&
        <>
          <p>No widgets yet</p>
        </>
      }
    </>
  );
};
