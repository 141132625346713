const isTrackingAvailable = () => window && Array.isArray(window.dataLayer);

/**
 * Create an impression event for elements that change contents dynamically
 * @param {String} label  Label shown for event in GA
 */
export const trackCustomImpression = (label) => {
  if (isTrackingAvailable()) {
    window.dataLayer.push({
      event: 'customEvent',
      category: 'interaction',
      action: 'impression',
      label
    });
  }
};

export const trackCustomInteraction = (type, label) => {
  if (isTrackingAvailable()) {
    window.dataLayer.push(({
      event: 'customEvent',
      category: 'interaction',
      action: type,
      label,
    }));
  }
};

export const trackCustomEvent = (values) => {
  if (isTrackingAvailable()) {
    window.dataLayer.push(({
      event: 'customEvent',
      ...values,
    }));
  }
};
