import React from 'react';
import PropTypes from 'prop-types';
import style from './Checkbox.styles.scss';

const Checkbox = ({
  id,
  label,
  checked,
  onChange,
  disabled,
  type,
}) => (
  <div className={style.checkbox}>
    <input
      className={style.checkboxInput}
      onChange={() => onChange(!checked, id)}
      id={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
    />
    <label className={`${style.checkboxLabel} ${style[type]}`} htmlFor={id}>{label}</label>
  </div>
);

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => null,
  type: 'check',
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['check', 'dash'])
};

export default Checkbox;
