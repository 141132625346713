// Root
import React from 'react';
import styles from './NoData.styles.scss';

const NoData = ({ section, timePeriod }) => (
  <section className={styles.noData}>
    There is no {section} data{timePeriod ? ' for this time period' : ''}
  </section>
);

export default NoData;