// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-yo5KEvDWIf1llyCGbDYw{width:90vw;max-width:92.5rem;margin:5em auto;padding:7em 1em;background-color:var(--white);border:1px solid rgba(117,117,117,.7)}.sophi-yo5KEvDWIf1llyCGbDYw h2{font-weight:normal;font-size:1.3em}@media screen and (max-width: 1024px){.sophi-yo5KEvDWIf1llyCGbDYw{margin-top:5vw;padding:4em 1em}}", "",{"version":3,"sources":["webpack://./src/pages/PermissionDenied/PermissionDenied.styles.scss"],"names":[],"mappings":"AAEA,4BACI,UAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,6BAAA,CACA,qCAAA,CAEA,+BACI,kBAAA,CACA,eAAA,CAGJ,sCAbJ,4BAcQ,cAAA,CACA,eAAA,CAAA","sourcesContent":["@import 'styles/util';\n\n.container {\n    width: 90vw;\n    max-width: pxToRem(1480);\n    margin: 5em auto;\n    padding: 7em 1em;\n    background-color: var(--white);\n    border: 1px solid rgba(117, 117, 117, 0.7);\n\n    h2 {\n        font-weight: normal;\n        font-size: 1.3em;\n    }\n\n    @media screen and (max-width: $md) {\n        margin-top: 5vw;\n        padding: 4em 1em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-yo5KEvDWIf1llyCGbDYw"
};
export default ___CSS_LOADER_EXPORT___;
