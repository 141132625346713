import React, { useMemo } from 'react';
import useLocalStored from './useLocalStored';

const useListSort = function ({ list, options, storageKey }) {
  const [selectedIndex, setSelectedIndex] = useLocalStored({ key: `${storageKey}.by`, initialValue: 0 });
  const [ascending, setAscending] = useLocalStored({ key: `${storageKey}.asc`, initialValue: false });

  const onSortByChange = (v) => setSelectedIndex(v);
  const onAscendingChange = () => setAscending(!ascending);

  const sorted = useMemo(() => {
    if (!Array.isArray(list)) return [];

    return [...list].sort((a, b) => {
      const accessor = options[selectedIndex].accessor;
      const lower = (val) => typeof val === 'string' ? val.toLocaleLowerCase() : val;
      const valA = lower((accessor instanceof Function) ? accessor(a) : a[accessor]);
      const valB = lower((accessor instanceof Function) ? accessor(b) : b[accessor]);

      if (valA > valB) {
        return ascending ? 1 : -1;
      } else if (valA < valB) {
        return ascending ? -1 : 1;
      }
      return 0;
    });
  }, [list, options]);

  return {
    onSortByChange,
    onAscendingChange,
    ascending,
    sorted,
    selected: selectedIndex
  };
};

export default useListSort;