// Date-fns
import isAfter from 'date-fns/isAfter';
import differenceInMinutes from 'date-fns/differenceInMinutes';

// Utils
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';
import { convertToSeconds } from 'utils/dateTime';
import { capitalize } from 'utils/string';
import { getOrderedArray, listToString } from 'utils/arrays';

const formatResponse = (response, accumulatedScore) => {
  if (response.noData) return ({ noData: true });
  const { drivers, components } = response;

  const processedRow = (row) => ({
      ...row,
      platforms: row.platforms.reduce((lu, r) => ({ ...lu, [r.platform.toLowerCase()]: r }), {}),
  });

  const { total, segments } = components.reduce((lu, row) => {
    if (row.component === 'sophi') return { ...lu, total: processedRow(row) };
    return {
      ...lu,
      segments: {
        ...lu.segments,
        [row.component]: processedRow(row),
      },
    };
  }, { segments: {}, total: null });

  const reorderedRow = getOrderedArray(Object.keys(segments)).reverse();
  const rows = reorderedRow.map((row) => segments[row]);

  // Format Driver
  let driverObj = {};
  let driverStr = '';
  drivers.forEach((driver) => {
    const arr = driver.toLowerCase().split(' ');
    if (Object.keys(driverObj).includes(arr[0])) {
      driverObj[arr[0]][arr[1]] = true;
    } else {
      driverObj[arr[0]] = {};
      driverObj[arr[0]][arr[1]] = true;
    }
  });
  
  Object.keys(driverObj).forEach((driver, index) => {
    driverStr += `${capitalize(driver)} on `;
    const platforms = Object.keys(driverObj[driver]);
    driverStr += `${listToString(platforms, 'conjunction', 'long')}${index < Object.keys(driverObj).length - 1 ? ', ' : ''}`;
  });

  return {
    response: {
      table: rows,
      total,
      drivers: driverStr,
      accumulatedScore,
    },
  };
};

/**
 * API call for Performance Breakdown Table
 * @param {String} id - String representing the contentID
 * @param {Object} dateRange - Object with start and end time
 */
const getPerformanceBreakdown = (host, client, id, token, dateRange, portrait) => {
  if (paramsAreTruthy([id, dateRange])) {
    const { startDate, endDate } = dateRange;
    const now = new Date();
    let end = isAfter(endDate, now) ? now : endDate;

    const minutesAccumulated = differenceInMinutes(end, startDate);
    const accumulatedScore = `${Math.floor(minutesAccumulated / 60)}h ${Math.floor(minutesAccumulated % 60)}m`;

    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `score-analysis/performance-breakdown?start=${convertToSeconds(startDate)}&end=${convertToSeconds(end)}`
      }
    );

    return getResource({ endpoint: path, token })
      .then((response) =>  formatResponse(response, accumulatedScore))
      .catch((error) => ({ error }));
  }
};

export default getPerformanceBreakdown;