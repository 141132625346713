import React from 'react';
import useLocalStored from './useLocalStored';

function fuzzy_match(value, query) {
  const special = ['[', '.', '*', '+', '?', '^', '$', '{', '}', '(', ')', '|', '[', ']', '\\', ']'];
  const pattern = query.split('').reduce(function(acc, curr) {
    const escaped = special.includes(curr) ? ('\\' + curr) : curr;
    return acc + '.*' + escaped;
  }, '');
  return (new RegExp(pattern)).test(value);
};

const useTextFilter = ({ list, accessor, storageKey, fuzzy = false }) => {
  const lower = (val) => typeof val === 'string' ? val.toLowerCase() : val;
  const [query, setQuery] = useLocalStored({ key: storageKey, initialValue: '' });

  const filtered = list.filter((item) => {
    const value = lower(accessor instanceof Function ? accessor(item) : item[accessor]);
    return fuzzy ? fuzzy_match(value, lower(query)) : value.includes(lower(query));
  });

  const onChange = (arg) => {
    if (typeof arg === 'string') setQuery(arg);
    else setQuery(arg.target.value);
  };

  return {
    onChange,
    query,
    filtered
  };
};

export default useTextFilter;