// Utils
import {
  getResource,
  pathBuilder,
  paramsAreTruthy
} from 'utils/api';


/**
 * API call for Minutes Reading Chart
 * @param {String} id - String representing the contentID
 */
const getMinutesReading = (host, client, id, token) => {
  if (paramsAreTruthy([id])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `engagement-analysis/minutes-reading`
      }
    );

    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (data) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const graph = data['visitsByMinutesSpent'].map((visits, index) => ({
        visits: visits,
        minutes: index,
      }));
      const stats = {
        wordCount: {
          title: `Article Word Count`,
          stat: {
            value: data['wordCount'],
            type: `number`,
          },
        },
        expectedReadTime: {
          title: `Expected Engagement Time`,
          stat: {
            value: data['expectedReadTime'],
            type: `minutes`,
          },
        },
        averageReadTime: {
          title: `Median Engagement Time`,
          stat: {
            value: data['medianReadTime'],
            type: `minutes`,
          },
        },
        visitorsCompleted: {
          title: `Visitors Completed`,
          stat: {
            value: data['longEngagementPercentage'],
            type: `percent`,
          },
        },
      };
      const indicators = [
        {
          label: 'Expected',
          value: data['expectedReadTime'].toFixed(2),
          short: true,
        },
        {
          label: 'Median',
          value: data['medianReadTime'].toFixed(2),
        },
      ];
      return {
        response: {
          graph,
          indicators,
          stats,
        },
      };
    };
    return data;
  }
};

export default getMinutesReading;
