// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 768px){.sophi-S6FGeBWuhNNIsAIEON08{padding:30px 6vw}.sophi-S6FGeBWuhNNIsAIEON08 .sophi-ScGDk98W3nEyGAEg8Sa9{border:1px solid rgba(117,117,117,.7)}}.sophi-S6FGeBWuhNNIsAIEON08 .sophi-ScGDk98W3nEyGAEg8Sa9{background-color:var(--white)}.sophi-S6FGeBWuhNNIsAIEON08 .sophi-GpQ5oV5ybq5Ti9g0rQRU{padding:70px 30px 40px}", "",{"version":3,"sources":["webpack://./src/templates/dive/Home.styles.scss"],"names":[],"mappings":"AACE,yBADF,4BAEI,gBAAA,CAEA,wDACE,qCAAA,CAAA,CAIJ,wDACE,6BAAA,CAGF,wDACE,sBAAA","sourcesContent":[".dive-home {\n  @media (min-width: 768px) {\n    padding: 30px 6vw;\n\n    .container {\n      border: 1px solid rgba(117, 117, 117, 0.7);\n    }\n  }  \n\n  .container {\n    background-color: var(--white);\n  }\n\n  .body {\n    padding: 70px 30px 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diveHome": "sophi-S6FGeBWuhNNIsAIEON08",
	"container": "sophi-ScGDk98W3nEyGAEg8Sa9",
	"body": "sophi-GpQ5oV5ybq5Ti9g0rQRU"
};
export default ___CSS_LOADER_EXPORT___;
