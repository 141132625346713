import types from 'actions/authTypes';

export const initialState = {
  authentication: {
    isAuthenticated: undefined,
    shouldTokenRefresh: false,
    token: {
      expiry: null,
      value: null
    }
  },
  configuration: {
    isConfigured: false,
  }
};

/**
 * This function parses the data from the new v2 tenant config to
 * map it to the old data points so we don't have to change the
 * existing code too much.
 *
 * @param {Object} response
 * @returns {Object}
 */
function parseV2ConfigResponse(response) {
  const { tenant = {}, banners, baseUri, chromeApp, env } = response;
  const { id, authRealm, timezone, services, organizations } = tenant;
  const { hosts } = organizations[0];
  const { products, platforms } = hosts[0];

  const data = {
    env: env.id,
    tenantId: id,
    timezone,
    realm: {
      ceaClientId: env.chromeAppOAuth2ClientId,
      spaClientId: env.webAppOAuth2ClientId,
      realm: authRealm,
    },
    banners,
    services,
    products,
    platforms,
    organizations,
    chromeExtension: {
      hostUrl: baseUri,
      id: chromeApp.publishedId,
      url: chromeApp.downloadUrl
    }
  };

  return data;
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONFIG_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          isConfigured: true,
          ...parseV2ConfigResponse(action.response)
        }
      };
    }
    case types.CONFIG_FAIL: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          isConfigured: false,
        }
      };
    }
    case types.SET_GLOBAL_PLATFORMS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          platforms: action.data,
        }
      };
    }
    case types.UPDATE_CONFIG: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.data,
        }
      };
    }
    case types.UPDATE_AUTH: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          isAuthenticated: !!action.data,
        }
      };
    }
    case types.UPDATE_TOKEN_CACHE: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          shouldTokenRefresh: true,
        }
      };
    }
    case types.CACHE_TOKEN_SUCCESS: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          shouldTokenRefresh: false,
          token: {
            ...state.authentication.token,
            ...action.data
          }
        }
      };
    }
    default: {
      return state;
    }
  }
};
