import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'sophi-design-system/src/components/Icon';
import Checkbox from 'sophi-design-system/src/components/Checkbox';
import styles from './EditColumns.styles.scss';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as Chevron } from 'assets/svg/chevron-circle.svg';
import ColumnTile from './ColumnTile';
import { COLUMN_TOOLTIPS } from 'utils/table';
import Tooltip from 'atoms/Tooltip';

const ClickableColumns = ({
  singleOptions,
  perDeviceOptions,
  flattenedItems,
  onChange,
  selected,
  pinnedColumns,
  onClearAll,
  onSelectAll,
}) => {
  const [accordionActive, setAccordionActive] = useState(false);
  const [showDroppable, setShowDroppable] = useState(false);

  const allSelected = [...selected, ...pinnedColumns];

  const handleAccordionToggle = () => {
    if (accordionActive) {
      setShowDroppable(false);
    } else {
      // Give the accordion 500ms to slide up before rendering the sortable list
      setTimeout(() => setShowDroppable(true), 500);
    }
    setAccordionActive(!accordionActive);
  };

  const handleOptionClick = (option, alreadySelected) => {
    if (!alreadySelected) {
      onChange([...selected, option]);
    } else {
      const index = selected.findIndex((item) => item[0] === option[0]);
      const updatedSelected = [...selected];
      updatedSelected.splice(index, 1);
      onChange(updatedSelected);
    }
  };

  const handleReorder = ({ source, destination }) => {
    const selectedClone = [...selected];
    const [moved] = selectedClone.splice(source.index, 1);
    selectedClone.splice(destination.index, 0, moved);
    onChange(selectedClone);
  };

  const handleClear = (index) => {
    const selectedClone = [...selected];
    selectedClone.splice(index, 1);
    onChange(selectedClone);
  };

  return (
    <>
      <div className={styles.clickableColumns}>
        <div className={styles.options}>
          <div className={styles.sectionHeader}>
            <Icon type="circle" name="Done" size="sm" color="teal" />
            <h3 id="available-options">Available Columns</h3>
          </div>
          <div
            role="group"
            aria-labelledby="available-options"
            className={styles.clickableColumnsOptions}
          >
            <div className={styles.grid}>
              {singleOptions.map((option) => {
                const [id, { label }] = option;
                const alreadySelected = allSelected.some((item) => item[0] === id);
                const pinned = pinnedColumns.some((item) => item[0] === id);
                return (
                  <Checkbox
                    key={id}
                    id={id}
                    label={label}
                    checked={alreadySelected}
                    onChange={() => handleOptionClick(option, alreadySelected)}
                    disabled={pinned}
                  />
                );
              })}
            </div>
            {perDeviceOptions.map(([id, { label, platforms = [] }]) => (
              <React.Fragment key={id}>
                <div className={styles.gridHeader}>
                  <h4>{label}</h4>
                  {
                    !!(COLUMN_TOOLTIPS[label.toLowerCase()]) && (
                      <Tooltip placement="top-start" content={COLUMN_TOOLTIPS[label.toLowerCase()]}>
                        <div style={{ height: 'fit-content', marginLeft: '10px' }}>
                          <Icon type="circle" name="Info" color="teal" size="xs" />
                        </div>
                      </Tooltip>
                    )
                  }
                  <button
                    className={styles.linkBtn}
                    onClick={() => onSelectAll(platforms)}
                  >
                    Add All
                  </button>
                </div>
                <div className={styles.grid}>
                  {[...platforms.entries()].map(([device, key]) => {
                    const deviceID = key;
                    const option = flattenedItems.get(deviceID);
                    const alreadySelected = allSelected.some((selected) => selected[0] === deviceID);
                    const pinned = pinnedColumns.some((item) => item[0] === deviceID);
                    return (
                      <Checkbox
                        key={deviceID}
                        id={deviceID}
                        label={option.label}
                        checked={alreadySelected}
                        onChange={() => handleOptionClick([deviceID, option], alreadySelected)}
                        disabled={pinned}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* Placeholder for accordion header so scrollable section above doesn't get cut off */}
        <div className={styles.selectedContainerClone}>
          <div className={styles.sectionHeader}>
            <Icon type="circle" name="Done" size="sm" color="teal" />
            <h3>Selected Columns</h3>
          </div>
        </div>
      </div>
      <div
        className={classNames([styles.selectedContainer, styles.selectedAccordion, {
          [styles.active]: accordionActive,
        }])}
      >
        <button title={`Toggle column menu ${showDroppable ? 'closed' : 'open'}`}onClick={handleAccordionToggle} className={styles.toggleBtn}>
          <Chevron />
        </button>
        <div className={styles.contents}>
          <div className={styles.sectionHeader}>
            <Icon type="circle" name="Done" size="sm" color="teal" />
            <h3>Selected Columns ({allSelected.length})</h3>
          </div>
            {showDroppable && (
              <div className={styles.scrollContainer}>
                <div className={styles.optionsContainer}>
                  {pinnedColumns.map((pinned) => (
                    <div key={pinned[1].label} className={classNames([styles.item, styles.pinned])}>
                      <span>{pinned[1].label}</span>
                    </div>
                  ))}
                  {(selected && selected.length > 0) && (
                    <button
                      className={classNames([styles.linkBtn, styles.clearBtn])}
                      onClick={onClearAll}
                    >
                      Clear Selection
                    </button>
                  )}
                </div>
                <DragDropContext onDragEnd={handleReorder}>
                  <Droppable droppableId="SELECTED">
                    {(provided) => (
                      <div className={styles.optionsContainer} ref={provided.innerRef}>
                        {(selected && selected.length > 0) && (
                          <>
                            {selected.map((item, index) => (
                              <ColumnTile
                                key={`${item[0]}-selected`}
                                item={item}
                                index={index}
                                selected={false}
                                idSuffix="-selected"
                                active
                                onClear={handleClear}
                              />
                            ))}
                            {provided.placeholder}
                          </>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ClickableColumns;
