import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useMutation } from '@tanstack/react-query';

import { useHistory, useParams } from 'react-router-dom';

import pageStyles from '../Page.styles.scss';
import styles from './WidgetDelete.styles.scss';

import PageTitle from '../../components/PageTitle';
import EntityName from '../../components/EntityName';
import { Button, message, Typography, Spin } from 'antd';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { getPage } from '../../api/pages';
import { patchWidget } from '../../api/widgets';

const WidgetDelete = () => {
  const { pageName, widgetName } = useParams();
  let history = useHistory();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(patchWidget);
  const { isLoading: isSaving, error, mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      history.push(getRoute(ROUTE_NAMES.curatorPageView, { pageName }));
      message.success({
        content: `Deleted`,
        style: {
          marginTop: '58px'
        }
      });
    }
  });
  
  const handleSubmit = () => {
    mutate({ body: { status: 'DELETE' }, pageName, widgetName });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Delete widget ${widgetName}`}/>
      <div className={pageStyles.body}>
        <div className={styles.layout}>
          {isLoading  && <Spin />}

          {!isLoading && data &&
            <>
              <Typography.Paragraph>
                Do you want to delete widget <EntityName name={widgetName} /> from page <EntityName name={pageName} />?
              </Typography.Paragraph>
              <div className={styles.actions}>
                <Button disabled={isSaving} onClick={history.goBack}>Cancel</Button>
                <Button disabled={isSaving} onClick={handleSubmit} type="primary" danger={true}>Delete</Button>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default WidgetDelete;
