import types from './overviewTypes';
import configTypes from './configTypes';
import { ROUTE_NAMES } from 'utils/paths';
import { WIDGET_NAME_MAP } from 'apis/config';

// NOTE: Don't remove `event` as its used for pagination
export const fetchOverview = (type, value) => {
  let opts = {};
  if (type === 'page') {
    // next\previous page is only integer
    opts = {
      pagination: {
        page: value,
      },
    };
  } else if (type === 'size') {
    // perPage is an object
    opts = {
      pagination: {
        size: parseInt(value),
        page: 0, // Reset to page = 0
      },
    };
  }
  return { type: types.OVERVIEW_FETCH, opts };
};

export const fetchOverviewSort = (table) => ({
  type: types.OVERVIEW_FETCH, table
});

export const fetchSections = () => ({
  type: types.OVERVIEW_SECTIONS_FETCH
});

export const fetchSectionsSuccess = (response) => ({
  type: types.OVERVIEW_SECTIONS_FETCH,
  response
});

export const fetchSubtypes = () => ({
  type: types.OVERVIEW_SUBTYPES_FETCH
});

export const fetchSubtypesSuccess = (response) => ({
  type: types.OVERVIEW_SUBTYPES_SUCCESS,
  response
});

export const fetchCreditLine = () => ({
  type: types.OVERVIEW_CREDITLINE_FETCH
});

export const fetchCreditLineSuccess = (response) => ({
  type: types.OVERVIEW_CREDITLINE_SUCCESS,
  response
});

export const fetchOwnership = () => ({
  type: types.OVERVIEW_OWNERSHIP_FETCH
});

export const fetchOwnershipSuccess = (response) => ({
  type: types.OVERVIEW_OWNERSHIP_SUCCESS,
  response
});

export const toggleFilters = () => ({
  type: types.OVERVIEW_TOGGLE_FILTERS
});


export const fetchOverviewColumns = () => ({
  type: configTypes.FETCH_WIDGET_CONFIG,
  pageName: ROUTE_NAMES.nowContentView,
  widgetName: WIDGET_NAME_MAP.NOW_COLUMNS
});

export const fetchType = () => ({
  type: types.OVERVIEW_TYPE_FETCH
});

export const fetchTypeSuccess = (response) => ({
  type: types.OVERVIEW_TYPE_SUCCESS,
  response
});

export const fetchAccess = () => ({
  type: types.OVERVIEW_ACCESS_FETCH
});

export const fetchAccessSuccess = (response) => ({
  type: types.OVERVIEW_ACCESS_SUCCESS,
  response
});

// Filters
export const resetFilters = () => ({
  type: types.OVERVIEW_FILTER_RESET
});

export const setDateRange = (dateRanges) => ({
  type: types.OVERVIEW_DATE_RANGE_SET,
  dateRanges
});

export const fetchAutosuggest = (autosuggest) => ({
  type: types.OVERVIEW_AUTOSUGGEST,
  autosuggest
});

export const clearAutosuggest = () => ({
  type: types.CLEAR_OVERVIEW_AUTOSUGGEST,
});

export const setOverviewCols = (columns) => ({
  type: types.OVERVIEW_COLS_SET,
  columns
});

export const setOverviewFilters = (filters) => ({
  type: types.SET_OVERVIEW_FILTERS,
  filters,
});

export const fetchPotentialResults = (filters) => ({
  type: types.OVERVIEW_FETCH_POTENTIAL_RESULTS,
  filters,
});

// Export default the filter actions
export const filterActions = {
  resetFilters,
};
