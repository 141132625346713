// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-YHIxl8k31TObwC0MlWeh{display:block;text-align:center;width:100%}.sophi-VcdrTFJ81RGGx8QHHuH_{font-family:\"Montserrat-Bold\";color:#000}", "",{"version":3,"sources":["webpack://./src/pages/Live/TabPanels.styles.scss"],"names":[],"mappings":"AAAA,4BACE,aAAA,CACA,iBAAA,CACA,UAAA,CAGF,4BACE,6BAAA,CACA,UAAA","sourcesContent":[".rank-cell {\n  display: block;\n  text-align: center;\n  width: 100%;\n}\n\n.simple-score-cell {\n  font-family: 'Montserrat-Bold';\n  color: #000;\n};\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rankCell": "sophi-YHIxl8k31TObwC0MlWeh",
	"simpleScoreCell": "sophi-VcdrTFJ81RGGx8QHHuH_"
};
export default ___CSS_LOADER_EXPORT___;
