import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import BakedAuth0Provider from '../auth0';

const ProviderWrapper = ({ history, store, children }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BakedAuth0Provider history={history}>
        {children}
      </BakedAuth0Provider>
    </ConnectedRouter>
  </Provider>
);

export default ProviderWrapper;