import React from 'react';
import TableauPageTemplate from 'molecules/TableauPageTemplate';

const DiveBeta = () => {
  return (
    <TableauPageTemplate title="Historical" />
  );
};

export default DiveBeta;
