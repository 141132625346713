import { WIDGET_NAME_MAP } from 'apis/config';
import { ROUTE_NAMES } from 'utils/paths';

export const getDateRange = (state) => state.dive.global.dateRange;
export const getReportDownloadDisabled = (state) => state.dive.global.dateRange.downloadDisabled;
export const getOverviewChartData = (state) => state.dive.dashboard.comparisonChart.data;
export const getOverviewChartLoading = (state) => state.dive.dashboard.comparisonChart.loading;
export const getOverviewChartNoData = (state) => state.dive.dashboard.comparisonChart.noData;
export const getOverviewChartError = (state) => state.dive.dashboard.comparisonChart.error;
export const getOverviewChartLoaded = (state) => (
  state.dive.dashboard.comparisonChart.data
  || state.dive.dashboard.comparisonChart.loading
  || state.dive.dashboard.comparisonChart.noData
  || state.dive.dashboard.comparisonChart.error
);
export const getArticlesLoaded = (state) => (
  state.dive.dashboard.articles.rows
  || state.dive.dashboard.articles.loading
  || state.dive.dashboard.articles.columns
  || state.dive.dashboard.articles.error
);
export const getAuthorsLoaded = (state) => (
  state.dive.dashboard.authors.rows
  || state.dive.dashboard.authors.loading
  || state.dive.dashboard.authors.columns
  || state.dive.dashboard.authors.error
);
export const getDiveAuthorsColumns = (state) => state.dive.authors.selectedColumns;
export const getDiveArticlesColumns = (state) => state.dive.articles.selectedColumns;
export const getDiveSectionsColumns = (state) => state.dive.sections.selectedColumns;

export const getDiveAuthorsAllColumns = (state) => state.config[ROUTE_NAMES.diveAuthors]?.[WIDGET_NAME_MAP.AUTHOR_COLUMNS];
export const getDiveArticlesAllColumns = (state) => state.config[ROUTE_NAMES.diveArticles]?.[WIDGET_NAME_MAP.ARTICLES_COLUMNS];
export const getDiveSectionsAllColumns = (state) => state.config[ROUTE_NAMES.diveSections]?.[WIDGET_NAME_MAP.SECTION_COLUMNS];

// Filters
export const getFilterDataFor = (state) => state.dive.global.filterOptions.getDataFor;
export const getDiveFilters = (state) => state.dive.global.filters;
export const getDiveFilterOptions = (state) => state.dive.global.filterOptions;
export const getDiveFilterAutosuggest = (state) => state.dive.global.filterOptions.autosuggest;
// Authors Page
export const getAuthorTableConfig = (state) => ({
  page: state.dive.authors.table.page,
  size: state.dive.authors.table.size,
  sortConfig: state.dive.authors.table.sortConfig,
});
// Articles Page
export const getArticleTableConfig = (state) => ({
  page: state.dive.articles.table.page,
  size: state.dive.articles.table.size,
  sortConfig: state.dive.articles.table.sortConfig,
});
export const getShowRunSearch = (state) => state.dive.global.showRunSearch;
// Sections
export const getSectionsTableLoaded = (state) => (
  state.dive.sections.table
  || state.dive.sections.status.loading
);
export const getSectionTable = (state) => state.dive.sections.table;
export const getSortConfig = (state) => state.dive.sections.sortConfig;
