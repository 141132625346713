export const PERFORMANCE_BREAKDOWN_LABELS = {
  acquisition: 'acquisition',
  retention: 'retention',
  direct: 'direct',
  affiliate: 'affiliate',
  newsletter: 'newsletter',
  internal: 'internal',
  search: 'search',
  social: 'social',
  unknown: 'other',
};

export const PLATFORMS = ['desktop', 'tablet', 'mobile', 'app'];
