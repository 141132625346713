import React from 'react';
import addHours from 'date-fns/addHours';
import { Typography } from 'antd';

import { useModalData } from '../contexts/ModalProvider';

import DetailsCard from './DetailsCard';
import { CreateRequest, AddOverride, RemoveOverride, ReplaceOverride, BanOverride, Override } from '../types';
import { transformToFEOverride } from '../utils/functions';

const transformRequestDataToOverride = (requestData: CreateRequest): Override => {
  const expirationDate = addHours(new Date(), requestData.expirationHourOfDay).toISOString();
  const creationDate = new Date().toISOString();

  const replacedArticleId = '';
  const overrideReasonId = typeof requestData.overrideReasonId === 'number' ? requestData.overrideReasonId : undefined;
  const expirationHour = requestData.expirationHourOfDay;
  const hostConfigId = '';
  const id = '';
  const isArticleIdVerified = true;
  const page = '';
  const status = 'PENDING';
  const tenantId = '';
  const requestId = requestData.requestId;
  const ruleType = requestData.ruleType;
  const requestedUserName = requestData.requestedUserName;
  const position = 'position' in requestData ? requestData.position : undefined;
  const widgetName = 'widgetName' in requestData ? requestData.widgetName : null;
  const articleId = requestData.articleId;

  switch (ruleType) {
    case 'out': {
      if (widgetName === null) {
        return {
          position: null,
          replacedArticleId: null,
          ruleType,
          widgetName,
          articleId,
          creationDate,
          expirationDate,
          expirationHour,
          hostConfigId,
          id,
          isArticleIdVerified,
          overrideReasonId,
          page,
          requestedUserName,
          requestId,
          status,
          tenantId,
        } as BanOverride
      } else {
        return {
          position: null,
          replacedArticleId: null,
          ruleType,
          widgetName,
          articleId,
          creationDate,
          expirationDate,
          expirationHour,
          feRequestId: requestId,
          hostConfigId,
          id,
          isArticleIdVerified,
          overrideReasonId,
          page,
          requestedUserName,
          requestId,
          status,
          tenantId,
        } as RemoveOverride
      }
    }
    case 'in': {
      return {
        position,
        replacedArticleId: null,
        ruleType,
        widgetName,
        articleId,
        creationDate,
        expirationDate,
        expirationHour,
        hostConfigId,
        id,
        isArticleIdVerified,
        overrideReasonId,
        page,
        requestedUserName,
        requestId,
        status,
        tenantId,
      } as AddOverride;
    }
    case 'replace':
    default: {
      return {
        position,
        replacedArticleId,
        ruleType,
        widgetName,
        articleId,
        creationDate,
        expirationDate,
        expirationHour,
        hostConfigId,
        id,
        isArticleIdVerified,
        overrideReasonId,
        page,
        requestedUserName,
        requestId,
        status,
        tenantId,
      } as ReplaceOverride;
    }
  }
}

const ConfirmOverridePreview: React.FC = () => {
  const { requestData } = useModalData();
  if (requestData === null) return null;

  const requests = requestData.pages.map(page => {
    const formatted = transformToFEOverride(transformRequestDataToOverride(requestData));
    formatted.page = page;
    return formatted;
  });

  return (
    <div>
      <Typography.Title style={{ textAlign: 'center' }} level={4}>
        Confirm Override Request<br/>
      </Typography.Title>
      <p style={{ textAlign: 'center' }}>Does this look okay?</p>
      <DetailsCard preview data={requests}/>
    </div>
  );
};

export default ConfirmOverridePreview;
