
import React, { createContext, useContext, useMemo, useState } from 'react';
import { Form } from 'antd';

import { useOverrides } from './OverridesProvider';

import { ModalDataProvider as ModalDataProviderInterface, Article, FERuleType, CreateFormValues, CreateRequest, CreateRequestAdd, CreateRequestBan, CreateRequestRemove, CreateRequestReplace } from '../types';
import { minToMs, widgetDelimiter } from '../utils/functions';
import { ruleTypeMap } from '../api/curator';
import { v4 as uuid } from 'uuid';

const ModalContext = createContext<ModalDataProviderInterface>({} as ModalDataProviderInterface);

const OVERRIDE_TYPES_MAP: {[key in FERuleType]: string } = {
  in: 'ADD',
  replace: 'REPLACE',
  out: 'REMOVE',
  ban: 'BAN',
};

export const ModalProvider: React.FC<{
  rule: FERuleType;
  children: React.ReactNode;
}> = ({ rule, children }) => {
  const { allReasons, user } = useOverrides();
  const [ form ] = Form.useForm<CreateFormValues>();
  const [ formData, setFormData ] = useState<CreateFormValues | null>(null);
  const [ article, setArticle ] = useState<Article>({});
  const [ articleInputType, setArticleInputType ] = useState<'headline' | 'id'>('headline');

  const fallbackReason = useMemo(() => {
    return allReasons.length ? allReasons[allReasons.length - 1].id : 0;
  }, [ allReasons ]);

  const reasons = useMemo(() => {
    return allReasons.length ? allReasons.filter(({ allowedOverrideTypes }) => allowedOverrideTypes.includes(OVERRIDE_TYPES_MAP[rule])).map(({ id, displayLabel }) => ({ value: id, label: displayLabel })) : [];
  }, [ allReasons, rule ]);

  const requestData: CreateRequest | null = useMemo(() => {
    if (formData === null) return null;

    const articleId = formData.articleId;
    const expirationHourOfDay = formData.expirationHourOfDay;
    const ruleType = rule;
    const requestedUserName = user.nickname;
    const overrideReasonId = formData.overrideReasonId || fallbackReason;
    const pages = ruleType === 'ban' ? (formData.pages as string[][]).map(p => p[0]) : [(formData.widgetName as [string, string])[0]];
    const widgetName = ruleType !== 'ban' ? (formData.widgetName as [string, string])[1].split(widgetDelimiter)[1] : undefined;
    const requestId = uuid();
    const position = formData.position;

    if (ruleType === 'ban') {
      return {
        ruleType: ruleTypeMap[ruleType] as "out",
        articleId,
        expirationHourOfDay,
        requestedUserName,
        pages: pages as [string],
        requestId,
        overrideReasonId,
      } as CreateRequestBan
    } else {
      return {
        ruleType: ruleTypeMap[ruleType],
        articleId,
        expirationHourOfDay,
        requestedUserName,
        pages,
        requestId,
        position,
        widgetName,
        overrideReasonId,
      } as CreateRequestAdd | CreateRequestRemove | CreateRequestReplace
    }
  }, [ formData ]);

  return (
    <ModalContext.Provider value={{
      article,
      form,
      rule,
      reasons,
      requestData,
      setArticle,
      setFormData,
      articleInputType,
      setArticleInputType,
    }}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModalData = () => useContext(ModalContext);
