"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const widgetConfigLabels = {
    name: 'Name',
    scoreLookupIntervalInMins: 'Score lookup interval',
    isDeduplicationEnabled: 'Deduplicate with other widgets',
    contentLookupIntervalInMins: 'Time since publication',
    minContentLookupIntervalInMins: 'Min. content lookup interval',
    priorityRank: 'Priority',
    scoreWindowIntervalInMins: 'Score interval',
    slotSize: 'Size',
    widgetBoostConfigs: 'Boosts',
    widgetFilters: 'Filters',
    metadataDiversityRules: 'Diversity rules',
    promoCapHours: '',
    manualWidgetNames: 'Deduplication rules',
};
exports.default = widgetConfigLabels;
