"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const pluralize = (count, singular, plural) => {
    if (count === 0 || count > 1) {
        if (plural)
            return plural;
        else
            return singular + 's';
    }
    return singular;
};
exports.default = pluralize;
