// Root
import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from 'atoms/Tabs';
import Tab from 'atoms/Tabs/Tab';
import TabHeader from 'atoms/Tabs/TabHeader';
import TabBody from 'atoms/Tabs/TabBody';
import TabPanels from './TabPanels';
import Logo from 'sophi-design-system/src/components/Logo';

// Utils
import types from 'actions/homeTypes';
import { getPlatforms } from 'selectors/auth';
import { setPageTitle } from 'utils/paths';
import { useParams } from 'react-router-dom';

import styles from './Display.styles.scss';

const Display = () => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.home.tables);
  const platforms = useSelector(getPlatforms);
  const showApp = platforms.includes('app');
  const params = useParams();
  const filters = {};
  let section = '';
  if (params[1]) {
    const sections = params[1].split('/').filter((param) => param !== '');
    section = sections.at(-1);
    filters.sections = [sections.join(':')];
  }

  useEffect(() => {
    setPageTitle('Live');
  }, []);

  useEffect(() => {
    dispatch({ type: types.HOMEPAGE_POLLING_START, data: filters });
    return () => dispatch({ type: types.HOMEPAGE_POLLING_STOP });
  }, []);
 
  const getTitle = (metricName) => {
    let title = '';
    if (section) {
      title = section + ': ';
    }
    title = title + `Top ${metricName} Stories`;
    return title;
  };

  const [title, setTitle] = useState('');
  const onTabSet = (panel) => {
    setTitle(getTitle(panel));
  };

  const renderTabs = () => {
    const children = [];
    const tabData = [
      { panelId: 'score-tab', id: 'score', text: 'Overall' },
      { panelId: 'internal-tab', id: 'internal', text: 'Internal' },
      { panelId: 'search-tab', id: 'search', text: 'Search' },
      { panelId: 'social-tab', id: 'social', text: 'Social' }
    ];

    if (showApp) {
      tabData.push({ panelId: 'app-tab', id: 'app', text: 'App' });
    }

    for (const {panelId, id, text} of tabData) {
      children.push(<Tab panelId={panelId} id={id}>{text}</Tab>);
    }
    return children;
  };

  return (
    <div className={styles.viewport}>
      <header class={styles.header}>
        <Logo alt="Go to homepage" />
        <p className={styles.title}>{title}</p>
      </header>
      <div className={styles.page}>
        <section className={styles.topStoriesSection}>
          <Tabs interval={15000} onSet={onTabSet}>
            <TabHeader label="Top Scores by Key Metric">
              {renderTabs()}
            </TabHeader>
            <TabBody>
              <TabPanels
                id="score-tab"
                tabId="score"
                data={tableData.score}
                keyMetricKey="score"
                loading={tableData.loading}
              />
              <TabPanels
                id="internal-tab"
                tabId="internal"
                data={tableData.internalTotal}
                keyMetricKey="internalTotal"
                loading={tableData.loading}
              />
              <TabPanels
                id="search-tab"
                tabId="search"
                data={tableData.searchTotal}
                keyMetricKey="searchTotal"
                loading={tableData.loading}
              />
              <TabPanels
                id="social-tab"
                tabId="social"
                data={tableData.socialTotal}
                keyMetricKey="socialTotal"
                loading={tableData.loading}
              />
              {showApp && (
                <TabPanels
                  id="app-tab"
                  tabId="app"
                  data={tableData.appGlobeScore}
                  keyMetricKey="appGlobeScore"
                  loading={tableData.loading}
                />
              )}
            </TabBody>
          </Tabs>
        </section>
      </div>
    </div>
  );
};

export default Display;