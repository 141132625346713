// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-Nlz17JxHmULUXj8Xg555{margin:2em 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:2em}.sophi-Nlz17JxHmULUXj8Xg555 h2{font-size:24px;margin:0}.sophi-Nlz17JxHmULUXj8Xg555 p{font-size:18px;margin:0}", "",{"version":3,"sources":["webpack://./src/molecules/TimeoutError/TimeoutError.styles.scss"],"names":[],"mappings":"AAAA,4BACI,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,OAAA,CAEA,+BAAA,cAAA,CAAA,QAAA,CACA,8BAAA,cAAA,CAAA,QAAA","sourcesContent":[".error {\n    margin: 2em 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2em;\n\n    h2 { font-size: 24px; margin: 0 }\n    p { font-size: 18px; margin: 0 }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "sophi-Nlz17JxHmULUXj8Xg555"
};
export default ___CSS_LOADER_EXPORT___;
