import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TreeNavigation.styles.scss';
import Branch from './Branch';
import Checkbox from 'sophi-design-system/src/components/Checkbox';
import Button from 'sophi-design-system/src/components/Button';
import SearchInput from './SearchInput';
import TreeNavigationContext from './TreeNavigationContext';
import { ReactComponent as CaretUp } from 'assets/svg/caret-up.svg';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';

const TreeNavigation = ({ data, selected, onChange, childrenKey, labelKey, valueKey, addTopLevel }) => {
  // Adds flattened list of children under a parent node
  const flattenChildren = (data) => {
    if (data[childrenKey] && data[childrenKey].length) {
      data.children = data[childrenKey].map(((row) => flattenChildren(row)));
      // Add a top level selection if the addTopLevel prop is set and the branch is
      // not just a placeholder (isParent flag)
      if (addTopLevel && !data.isParent) {
        data.children.unshift({
          [labelKey]: `${data[labelKey]} (Top Level)`,
          [valueKey]: data[valueKey],
          label: `${data[labelKey]} (Top Level)`,
          value: data[valueKey]
        });
      }

      const flattenedChildren = data.children.map((obj) => {
        if (obj.flattenedChildren) {
          return obj.flattenedChildren;
        }
        return { label: obj[labelKey], value: obj[valueKey], ...obj } ;
      });
      return { label: data[labelKey], value: data[valueKey], flattenedChildren: flattenedChildren.flat(), ...data };
    }
    return { ...data, label: data[labelKey], value: data[valueKey] };
  };

  const [filterValue, setFilterValue] = useState('');
  const [forceBranchesOpen, setForceBranchesOpen] = useState(false);
  const [forceBranchesClosed, setForceBranchesClosed] = useState(false);

  // Adds flattened lists of children to each node
  const treeData = useMemo(() => data.map((section) => flattenChildren(section)), [data]);

  // Gets flattened list of all leaf nodes in the tree
  const allOptions = useMemo(() => treeData.reduce((list, branch) => {
    if (branch.flattenedChildren) {
      return [...list, ...branch.flattenedChildren];
    }
    return [...list, branch];
  }, []), [treeData]);

  /**
   * Update selection status of the tree and call onChange callback
   * @param {Array}  values    List of values being added or removed
   * @param {String} override  Add or delete selection without checking
   */
  const handleSelection = (values, override) => {
    const updatedList = new Set(selected);
    let updateType;

    if (override) {
      updateType = updatedList.has(values[0]) ? 'delete' : 'add';
    }

    values.forEach((value) => {
      if (updateType) {
        updatedList[updateType](value);
      } else if (updatedList.has(value)) {
        updatedList.delete(value);
      } else {
        updatedList.add(value);
      }
    });
    onChange([...updatedList]);
  };

  const handleSelectVisible = () => {
    const visibleOptions = allOptions.filter(({ label }) => label.match(filterRegex));      
    const updatedList = new Set(selected);
    visibleOptions.forEach(({ value }) => updatedList.add(value));

    onChange([...updatedList]);
  };

  const handleToggleCheck = () => {
    if (selected.length === allOptions.length) {
      onChange([]);
    } else {
      onChange(allOptions.map((option) => option.value));
    }
  };
  
  const handleCollapseAll = () => {
    if (forceBranchesOpen) {
      setForceBranchesOpen(false);
    }
    setForceBranchesClosed(true);
  };

  const handleExpandAll = () => {
    if (forceBranchesClosed) {
      setForceBranchesClosed(false);
    }
    setForceBranchesOpen(true);
  };

  // Regex used for filtering out nodes
  const filterRegex = useMemo(() => new RegExp(filterValue, 'ig'), [filterValue]);

  const contextValues = {
    setForceBranchesClosed,
    forceBranchesClosed,
    setForceBranchesOpen,
    forceBranchesOpen,
    labelKey,
    valueKey,
    childrenKey,
  };

  const handleSearchChange = (val) => {
    setFilterValue(val);
    if (val) {
      setForceBranchesOpen(true);
      setForceBranchesClosed(false);
    } else {
      setForceBranchesOpen(false);
    }
  };

  return (
    <TreeNavigationContext.Provider value={contextValues}>
      <div className={styles.treeHeader}>
        <div className={styles.topRow}>
          <div className={styles.selectAllCheck}>
            {!filterValue && (
              <>
                <Checkbox
                  label="Select All Categories"
                  value="select-all"
                  id="tree-nav-select-all"
                  checked={selected.length === allOptions.length}
                  onChange={handleToggleCheck}
                />
              </>
            )}
            {filterValue && (
              <Button
                variant="primary"
                size="sm"
                onClick={handleSelectVisible}
              >
                Select Visible
              </Button>
            )}
          </div>
          <SearchInput
            id="tree-nav-filter-input"
            onChange={handleSearchChange}
          />
        </div>
        {allOptions.length !== treeData.length && (
          <div className={styles.toggleAllContainer}>
            <Button
              variant="secondary"
              size="sm"
              disabled={filterValue}
              onClick={handleCollapseAll}
            >
              <span>
                <CaretUp /> Collapse All 
              </span>
            </Button>
            <Button
              variant="secondary"
              size="sm"
              disabled={filterValue}
              onClick={handleExpandAll}
            >
              <span>
                <CaretDown /> Expand All 
              </span>
            </Button>
          </div>
        )}
      </div>
      <ul className={styles.treeSelection}>
        {treeData.map((section) => (
          <Branch
            key={section.value}
            section={section}
            filterValue={filterRegex}
            onChange={handleSelection}
            selected={selected}
          />
        ))}
      </ul>
    </TreeNavigationContext.Provider>
  );
};

TreeNavigation.defaultProps = {
  selected: [],
  childrenKey: 'children',
  labelKey: 'label',
  valueKey: 'value',
  addTopLevel: false,
};

TreeNavigation.propTypes = {
  selected: PropTypes.array,
  childrenKey: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  addTopLevel: PropTypes.bool,
};

export default TreeNavigation;
