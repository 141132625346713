import { call, delay, put, select, takeLatest, spawn } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
// apis
import api from '../apis/overview';
// actions
import types from '../actions/overviewTypes';
import authTypes from 'actions/authTypes';
// selectors
import * as cache from '../selectors/cache';
import * as router from '../selectors/router';
import * as selectors from '../selectors/overview';
import * as authSelectors from '../selectors/auth';
// routes
import { getRoute, ROUTE_NAMES } from 'utils/paths';
import { listToString } from 'utils/arrays';
import { formatDate, toISODate } from 'utils/dateTime';
import { getAuthValues } from './auth';
// sagas
import { addToCache, cleanCache } from '../sagas/cache';

const AUTOCOMPLETE_DELAY = 500;

// Helper method to convert SearchParams object to a JS Object
const paramsToObject = (entries) => {
  let result = {};
  for (let entry of entries) { // each 'entry' is a [key, value] tuple
    const [key, value] = entry;
    result[key] = value.split(',');
  }
  return result;
};

export const getCacheKey = (args) => {
  const { filters, pagination, opts } = args;
  const optsCopy = {
    ...opts,
    dateRanges: {
      analysis: {
        startDate: toISODate(opts.dateRanges.analysis.startDate),
        endDate: toISODate(opts.dateRanges.analysis.endDate)
      },
      publish: {
        startDate: toISODate(opts.dateRanges.publish.startDate),
        endDate: toISODate(opts.dateRanges.publish.endDate)
      }
    }
  };
  const key = {
    filters,
    opts: optsCopy,
  };
  if (pagination) {
    const paginationCopy = {
      ...pagination
    };
    delete paginationCopy.count;
    key.pagination = paginationCopy;
  }
  const stringified = JSON.stringify(key);
  return stringified;
};

export function* overviewFetchCacheSaga(service, args) {
  try {
    const key = getCacheKey(args);
    const { host, client, token, filters, pagination, opts } = args;
    const endpointId = `overview_fetch_${client}`;

    const cacheHit = yield select(cache.getFromCache, endpointId, key);
    let data;
    if (cacheHit) {
      data = cacheHit;
      yield delay(200);
    } else {
      data = yield call(service, host, client, token, filters, pagination, opts);
      if (data && !data.error) {
        yield spawn(addToCache, endpointId, key, { ...data, client });
      }
    }
    yield spawn(cleanCache, endpointId);
    return data;
  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_CACHE_FAIL, error });
  }
}

export function* overviewFetchCountCacheSaga(service, args) {
  const key = getCacheKey(args);
  const { host, client, token, filters, opts } = args;
  const endpointId = `overview_count_${client}`;

  try {
    const cacheHit = yield select(cache.getFromCache, endpointId, key);
    let data;
    if (cacheHit) {
      data = cacheHit;
      yield delay(200);
    } else {
      data = yield call(service, host, client, token, filters, opts);
      if (data && !data.error) {
        yield spawn(addToCache, endpointId, key, data);
      }
    }
    yield spawn(cleanCache, endpointId);
    return data;
  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_COUNT_CACHE_FAIL, error });
  }
}

export function* overviewFetchSaga() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const pagination = yield select(selectors.getOverviewPagination);
    const filters = yield select(selectors.getOverviewFilters);
    const dateRanges = yield select(selectors.getDateRanges);
    const table = yield select(selectors.getOverviewTable);
    // TODO: Remove this when we migrate the new sophi table
    const timeZone = yield select(authSelectors.getTimezone);
    const opts = { dateRanges, table, timeZone };
    const args = { host, client, token, filters, pagination, opts };

    const data = yield call(overviewFetchCacheSaga, api.getOverview, args);
    if (data.noData) {
      yield put({ type: types.OVERVIEW_FETCH_SUCCESS, response: 'noData' });
    } else {
      yield put({ type: types.OVERVIEW_FETCH_SUCCESS, response: data.response });
      // No need to fetch the count if page = 0
      // Note: this might be an issue if we save a filter AND page #
      if (pagination.page === 0) {
        yield* overviewFetchCountSaga(host, client, token, filters, opts);
      }
    }

    if (pagination.page === 0) {
      const withSelections = Object.keys(filters).reduce((lu, filter) => {
        if (filters[filter] && filters[filter].length) {
          return { ...lu, [filter]: filters[filter] };
        }
        return lu;
      }, {});
      let filtersUrl = new URLSearchParams(withSelections).toString();
      if (filtersUrl !== window.location.search.split('?')[1]) {
        yield put(push(`${getRoute('nowContentView')}?${filtersUrl}`));
      }
    }
  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_FAIL, error });
  }
}

export function* overviewFetchReportSaga() {
  const downloadHash = uuidv4();
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const filters = yield select(selectors.getOverviewFilters);
    const dateRanges = yield select(selectors.getDateRanges);
    const table = yield select(selectors.getOverviewTable);
    const timeZone = yield select(authSelectors.getTimezone);
    const startDate = formatDate(dateRanges.analysis.startDate, `d MMM yyyy`, timeZone);
    const endDate = formatDate(dateRanges.analysis.endDate, `d MMM yyyy`, timeZone);
    const fileName = `Report for ${startDate} to ${endDate}.csv`;
    const opts = { dateRanges, table, timeZone, fileName };

    yield put({
      type: types.OVERVIEW_FETCH_REPORT_LOADING,
      downloadMeta: {
        hash: downloadHash,
        filters,
        query: opts,
        loading: true,
        error: false,
        complete: false,
      }
    });
    yield call(api.getOverviewReport, host, client, token, filters, opts);
    yield put({
      type: types.OVERVIEW_FETCH_REPORT_SUCCESS,
      hash: downloadHash
    });
    yield delay(15000);
    yield put({
      type: types.OVERVIEW_FETCH_REPORT_CLEAR,
      hash: downloadHash
    });

  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_REPORT_ERROR, hash: downloadHash, error });
    yield delay(60000);
    yield put({
      type: types.OVERVIEW_FETCH_REPORT_CLEAR,
      hash: downloadHash
    });

  }
}

export function* overviewFetchCountSaga(host, client, token, filters, opts) {
  try {
    const args = { host, client, token, filters, opts };
    const data = yield call(overviewFetchCountCacheSaga, api.getOverviewCount, args);
    yield put({ type: types.OVERVIEW_FETCH_COUNT_SUCCESS, response: { count: data.size ? parseInt(data.size) : 0 } });
  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_COUNT_FAIL, error });
  }
}

// Fetch the row count for a set of filters and given date range
// Used by filter modal to display potential results
export function* overviewFetchCountWithFilters(action) {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);
    const table = yield select(selectors.getOverviewTable);
    const opts = { dateRanges, table };

    const data = yield call(api.getOverviewCount, host, client, token, action.filters, opts);
    yield put({ type: types.OVERVIEW_FETCH_POTENTIAL_RESULTS_SUCCESS, response: data.size || 0 });
  } catch (error) {
    yield put({ type: types.OVERVIEW_FETCH_POTENTIAL_RESULTS_FAIL, error });
  }
}

// Fetch valid section options
export function* overviewFetchSections() {
  try {
    const apiName = 'section';
    const filters = yield select(selectors.getOverviewFilters);
    const { sections: savedSections } = filters;

    const { token, host, client } = yield call(getAuthValues, apiName);
    const dateRanges = yield select(selectors.getDateRanges);

    const data = yield call(api.getOverviewSections, host, client, token, dateRanges.minMax);
    if (data.noData) {
      yield put({ type: types.OVERVIEW_SECTIONS_SUCCESS, response: 'noData' });
    } else {
      const args = { response: data.response };
      yield put({ type: types.OVERVIEW_SECTIONS_SUCCESS, ...args });

      // discard sections saved in local storage that use old naming convention
      const cleanedSections = [];
      if (Array.isArray(savedSections)) {
        for (const saved of savedSections) {
          const match = data.response.some((current) => {
            return current.id === saved;
          });
          if (match) {
            cleanedSections.push(saved);
          }
        }
      }
      yield put({ type: types.SET_OVERVIEW_FILTERS, filters: { ...filters, sections: cleanedSections } });
    }
  } catch (error) {
    yield put({ type: types.OVERVIEW_SECTIONS_FAIL, error });
  }
}

// Warn when date is set and some filters are already selected
function* warnOnDateSet() {
  /**
   * Helper function used to ensure user selected filters are valid after a date range change
   * @param {JSON} response - API Response
   * @param {JSON} error - API Response
   * @param {JSON} noData - API Response
   * @param {Function} userSelection - Selector for user filter selection in state object
   * @param {String} label - String label for filter used for messaging
   */
  const handleInvalidSelection = (response, error, noData, userSelection, label) => {
    // Track which selections should be removed.
    const selectionsToPop = [];
    if (response) {
      userSelection.forEach((selection) => {
        if (!response.includes(selection)) {
          selectionsToPop.push(selection);
        }
      });
      // Filter
      const filter = userSelection.filter((selection) => !selectionsToPop.includes(selection));
      const removed = userSelection.filter((selection) => selectionsToPop.includes(selection));
      // If we can, automatically remove and notify the user
      if (removed.length > 0) {
        // TODO: Create a "error message"
        alert(`The ${label} filter "${listToString(removed, 'conjunction', 'long')}" ${removed.length > 1 ? 'have' : 'has'} been removed from your selection.`);
      }
      return filter;
    }
    // If there is something wrong with the call, notify the user
    if (error || noData) {
      // TODO: Create a "error message"
      alert(`The ${label} filters you have selected may not exist in the selected date range. Please update the ${label} filter before running a search.`);
    }
    return null;
  };

  const subtypesSelection = yield select(selectors.getSubtypesSelection);
  const creditLineSelection = yield select(selectors.getCreditLineSelection);
  const ownershipSelection = yield select(selectors.getOwnershipSelection);
  const accessSelection = yield select(selectors.getAccessSelection);
  // Subtypes
  if (subtypesSelection) {
    const dateRanges = yield select(selectors.getDateRanges);
    const { response, error, noData } = yield call(api.getOverviewSubtypes, dateRanges.publish);
    const filter = handleInvalidSelection(response, error, noData, subtypesSelection, 'subtypes');
    filter ? yield put({ type: types.OVERVIEW_FILTER_SUBTYPES, filter }) : null;
  }
  // Credit Line
  if (creditLineSelection) {
    const dateRanges = yield select(selectors.getDateRanges);
    const { response, error, noData } = yield call(api.getOverviewCreditLine, dateRanges.publish);
    const filter = handleInvalidSelection(response, error, noData, creditLineSelection, 'creditLine');
    filter ? yield put({ type: types.OVERVIEW_FILTER_CREDITLINE, filter }) : null;
  }
  // Ownership
  if (ownershipSelection) {
    const dateRanges = yield select(selectors.getDateRanges);
    const { response, error, noData } = yield call(api.getOverviewOwnership, dateRanges.publish);
    const filter = handleInvalidSelection(response, error, noData, ownershipSelection, 'ownership');
    filter ? yield put({ type: types.OVERVIEW_FILTER_OWNERSHIP, filter }) : null;
  }
  // Access
  if (accessSelection) {
    const dateRanges = yield select(selectors.getDateRanges);
    const { response, error, noData } = yield call(api.getOverviewAccess, dateRanges.publish);
    const filter = handleInvalidSelection(response, error, noData, accessSelection, 'access');
    filter ? yield put({ type: types.OVERVIEW_FILTER_ACCESS, filter }) : null;
  }
}

// Fetch subtypes section options
export function* overviewFetchSubtypes() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);
    const data = yield call(api.getOverviewSubtypes, host, client, token, dateRanges.publish);
    data.noData ? yield put({ type: types.OVERVIEW_SUBTYPES_SUCCESS, response: 'noData' }) : yield put({ type: types.OVERVIEW_SUBTYPES_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.OVERVIEW_SUBTYPES_FAIL, error });
  }
}

// Fetch credit line options
export function* overviewFetchCreditLine() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);
    const data = yield call(api.getOverviewCreditLine, host, client, token, dateRanges.publish);
    data.noData ? yield put({ type: types.OVERVIEW_CREDITLINE_SUCCESS, response: 'noData' }) : yield put({ type: types.OVERVIEW_CREDITLINE_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.OVERVIEW_CREDITLINE_FAIL, error });
  }
}

// Fetch ownership section options
export function* overviewFetchOwnership() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);
    const data = yield call(api.getOverviewOwnership, host, client, token, dateRanges.publish);
    data.noData ? yield put({ type: types.OVERVIEW_OWNERSHIP_SUCCESS, response: 'noData' }) : yield put({ type: types.OVERVIEW_OWNERSHIP_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.OVERVIEW_OWNERSHIP_FAIL, error });
  }
}

export function* overviewFetchType() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);

    const data = yield call(api.getOverviewType, host, client, token, dateRanges.publish);
    data.noData ? yield put({ type: types.OVERVIEW_TYPE_SUCCESS, response: 'noData' }) : yield put({ type: types.OVERVIEW_TYPE_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.OVERVIEW_TYPE_FAIL, error });
  }
}

export function* overviewFetchAccess() {
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const dateRanges = yield select(selectors.getDateRanges);
    const data = yield call(api.getOverviewAccess, host, client, token, dateRanges.publish);
    data.noData ? yield put({ type: types.OVERVIEW_ACCESS_SUCCESS, response: 'noData' }) : yield put({ type: types.OVERVIEW_ACCESS_SUCCESS, response: data.response });

  } catch (error) {
    yield put({ type: types.OVERVIEW_ACCESS_FAIL, error });
  }
}

export function* initialFilterStateFromUrl() {
  const search = yield select(router.getSearch);
  const sp = new URLSearchParams(search);
  const filters = paramsToObject(sp.entries());
  // Remove Auth0 query params
  delete filters.code;
  delete filters.state;
  // Make sure score is integer not a string
  if (filters.score && Array.isArray(filters.score)) {
    filters.score = filters.score.map((val) => parseInt(val));
  }
  yield put({ type: types.OVERVIEW_FILTER_SET, filters });
}

export function* overviewAutosuggest(action) {
  yield delay(AUTOCOMPLETE_DELAY);
  try {
    const { token, host, client } = yield call(getAuthValues, 'now');
    const { field, term } = action.autosuggest;
    const data = yield call(api.getOverviewAutosuggest, host, client, token, { field, term });
    yield put({ type: types.OVERVIEW_AUTOSUGGEST_SUCCESS, data: data.response });
  } catch (error) {
    yield put({ type: types.OVERVIEW_AUTOSUGGEST_SUCCESS, data: undefined });
  }
};

export function* refreshOverviewPage() {
  const route = yield select(router.getRouteName);
  
  yield put({ type: types.OVERVIEW_FILTER_RESET });

  switch (route) {
    case ROUTE_NAMES.nowContentView:
      yield put({ type: types.OVERVIEW_FETCH });
      break;
  }
}

export default function* overviewSaga() {
  yield call(initialFilterStateFromUrl);
  yield takeLatest(authTypes.UPDATE_CONFIG, refreshOverviewPage);
  yield takeLatest(types.OVERVIEW_FETCH, overviewFetchSaga);
  yield takeLatest(types.OVERVIEW_DATE_RANGE_SET, warnOnDateSet);
  // Anything below this comment only needs to run based on user interaction
  yield takeLatest(types.OVERVIEW_SECTIONS_FETCH, overviewFetchSections);
  yield takeLatest(types.OVERVIEW_SUBTYPES_FETCH, overviewFetchSubtypes);
  yield takeLatest(types.OVERVIEW_CREDITLINE_FETCH, overviewFetchCreditLine);
  yield takeLatest(types.OVERVIEW_OWNERSHIP_FETCH, overviewFetchOwnership);
  yield takeLatest(types.OVERVIEW_TYPE_FETCH, overviewFetchType);
  yield takeLatest(types.OVERVIEW_ACCESS_FETCH, overviewFetchAccess);
  yield takeLatest(types.OVERVIEW_AUTOSUGGEST, overviewAutosuggest);
  yield takeLatest(types.OVERVIEW_FETCH_REPORT, overviewFetchReportSaga);
  yield takeLatest(types.OVERVIEW_FETCH_POTENTIAL_RESULTS, overviewFetchCountWithFilters);
}
