import {
  ARTICLE_FETCH_DATA,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_FETCH_FAILED,
  ARTICLE_TOGGLE_METADATA,
} from '../actions/article';

export const initialState = {
  meta: {
    toggle: false,
    loading: false,
    error: '',
  },
};

export default function articleReducer(state = initialState, action) {
  switch (action.type) {
    case ARTICLE_FETCH_DATA: {
      return {
        ...state,
        meta: {
          ...state.meta,
          data: null,
          loading: true,
        },
      };
    }
    case ARTICLE_FETCH_SUCCESS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          data: action.data,
        },
      };
    }
    case ARTICLE_FETCH_FAILED: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          error: action.error,
        },
      };
    }
    case ARTICLE_TOGGLE_METADATA: {
      return {
        ...state,
        meta: {
          ...state.meta,
          toggle: !state.meta.toggle,
        },
      };
    }
    default: {
      return state;
    }
  }
}
