// Root
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Atoms
import LoadingState from 'sophi-design-system/src/components/LoadingState';
import ErrorState from 'sophi-design-system/src/components/ErrorState';

import { addKeyPrefix } from 'utils/objects';

const styles = (theme) => ({
  paper: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 30,
    marginTop: 10,
    padding: 10,
    minHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 40,
      marginRight: 40,
      marginTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
      padding: 20,
      marginTop: 30,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 60,
      marginRight: 60,
      marginBottom: 50,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 60,
      marginTop: 40,
      padding: 30,
    },
    [theme.breakpoints.up('xxl')]: {
      marginLeft: 200,
      marginRight: 200,
      marginBottom: 90,
      marginTop: 50,
      padding: 40,
    },
  }
});

const useStyles = makeStyles(styles);

const Section = ({ title, children, loading, loadingHeight, error, errorAction, dataAttributes }) => {
  const classes = useStyles();
  const prefixedDataAttributes = addKeyPrefix('data-', dataAttributes);
  return (<Paper className={classes.paper} {...prefixedDataAttributes}>
    {/* TODO: One add `id={title}` or and HOC so we can create anchors for the 'sections' */}
    {title && <Typography variant="h2">{title}</Typography>}
    {children}
    {loading ? <LoadingState height={loadingHeight ? loadingHeight : null} /> : null}
    {error ? <ErrorState action={() => errorAction()} /> : null}
  </Paper>);
};

Section.defaultProps = {
  title: null,
  dataAttributes: {},
};

Section.propTypes = {
  title: PropTypes.string,
  dataAttributes: PropTypes.object,
};

export default Section;
