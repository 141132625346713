import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

const AnimatedNumber = ({ className, value, formatNumber }) => {
  const props = useSpring({ value });

  return (
    <animated.span className={className} style={{ fontVariantNumeric: 'tabular-nums' }}>
      {props.value.interpolate((x) => formatNumber(x).toLocaleString())}
    </animated.span>
  );
};

AnimatedNumber.defaultProps = {
  value: 0,
  formatNumber: (x) => parseInt(x),
  className: '',
};

AnimatedNumber.props = {
  value: PropTypes.number,
  formatNumber: PropTypes.func,
  className: PropTypes.string,
};

export default AnimatedNumber;
