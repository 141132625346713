import React, { useMemo, useState } from "react";
import { List, Space, Badge, Button, Descriptions, Tooltip, BadgeProps } from "antd";
import { useOverrides } from "../contexts/OverridesProvider";
import { sortAlphabetically } from "../utils/functions";
import { FEOverride } from "../types";

const DetailsCardPages = ({ data }: { data: FEOverride[] }) => {
  const { overrides } = useOverrides();
  const requestId = data[0].requestId;
  const [showAll, setShowAll] = useState<boolean>(data.length > 3 ? false : true);

  const pages = useMemo(() => {
    const sorted = data.map(o => o.page).sort(sortAlphabetically((p) => p.toLocaleLowerCase()));
    const trimmed = showAll ? sorted : sorted.slice(0, 3);
    return trimmed;
  }, [data, showAll]);

  function getOverrideStatus(p: string): { type: BadgeProps['status'], message: string } {
    const match = overrides.find(o => o.page === p && o.requestId === requestId);
  
    switch (match?.status) {
      case 'ACTIVE':
        return {
          type: 'success',
          message: 'Active'
        };
      case 'PENDING':
        return {
          type: 'processing',
          message: 'Pending'
        };
      case 'FAILED':
        return {
          type: 'error',
          message: 'Override not active for this page. Try creating a replace override instead.'
        };
      default:
        return {
          type: 'default',
          message: ''
        };
    }
  };

  return (
    <Descriptions
      labelStyle={{ fontSize: '.85em' }}
      size="small"
      layout="vertical"
      colon={false}
      column={{ xs: 3 }}
    >
      {data.length === 1 &&
        <Descriptions.Item label="Page">
          {data[0].page}
        </Descriptions.Item>
      }
      {data.length > 1 &&
        <Descriptions.Item label={`Page${pages.length > 1 ? 's' : ''}`}>
          <Space direction="vertical">
            <List
              size="small"
              split={false}
              dataSource={pages}
              renderItem={(item) => (
                <List.Item style={{ padding: '0' }}>
                  <Space>
                    <Tooltip title={getOverrideStatus(item).message}>
                      <Badge status={getOverrideStatus(item).type}/>
                    </Tooltip>
                    {item}
                  </Space>
                </List.Item>
              )}
            />
            {data.length - 3 > 0 &&
              <Button
                size="small"
                type="text"
                block
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? `Show less` : `+ ${data.length - 3} more`}
              </Button>
            }
          </Space>
        </Descriptions.Item>
      }
  </Descriptions>
  );
}

export default DetailsCardPages;