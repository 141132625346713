export const getUsers = (state) => state.admin.users;
export const areUsersLoading = (state) => state.admin.usersLoading;
export const getUsersError = (state) => state.admin.usersError;

export const getUserAdminMessage = (state) => state.admin.userAdminMessage;

export const isUserActionLoading = (state) => state.admin.userActionLoading;
export const getUserActionError = (state) => state.admin.userActionError;

export const getPagination = (state) => state.admin.pagination;

export const getRoles = (state) => state.admin.userRoles;
export const getEditRoles = (state) => state.admin.userEditRoles;
