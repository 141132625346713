// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-ud2gvfCmp5AKMA5eXb2h{width:100vw}.sophi-bxcTcAaaiNFX8svM6pmI{width:100%;background-color:var(--white)}@media(min-width: 768px){.sophi-bxcTcAaaiNFX8svM6pmI{border:1px solid rgba(117,117,117,.7)}}.sophi-WHsApVpfTW_NhruTI2Aj{height:100%;padding:30px 0}@media(min-width: 768px){.sophi-WHsApVpfTW_NhruTI2Aj{padding:30px 6vw}}.sophi-EMeA3iD4MstjeSilNmWj{display:-webkit-box;display:-ms-flexbox;display:flex;height:200px;width:100%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/molecules/TableauPageTemplate/TableauPageTemplate.styles.scss"],"names":[],"mappings":"AAAA,4BACI,WAAA,CAEA,4BACE,UAAA,CACA,6BAAA,CACA,yBAHF,4BAII,qCAAA,CAAA,CAEF,4BACE,WAAA,CACA,cAAA,CACA,yBAHF,4BAII,gBAAA,CAAA,CAKN,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".tableau-page {\n    width: 100vw;\n  \n    &__card {\n      width: 100%;\n      background-color: var(--white);\n      @media (min-width: 768px) {\n        border: 1px solid rgba(117, 117, 117, 0.7);\n      }\n      &-container {\n        height: 100%;\n        padding: 30px 0;\n        @media (min-width: 768px) {\n          padding: 30px 6vw;\n        }\n      }\n    }\n  \n    &__placeholder {\n      display: flex;\n      height: 200px;\n      width: 100%;\n      align-items: center;\n      justify-content: center;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableauPage": "sophi-ud2gvfCmp5AKMA5eXb2h",
	"tableauPageCard": "sophi-bxcTcAaaiNFX8svM6pmI",
	"tableauPageCardContainer": "sophi-WHsApVpfTW_NhruTI2Aj",
	"tableauPagePlaceholder": "sophi-EMeA3iD4MstjeSilNmWj"
};
export default ___CSS_LOADER_EXPORT___;
