import { takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

function bubbleErrors(error) {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }
  
  if (error.error) {
    Sentry.captureException(error.error);
    Sentry.setExtra('action', error.type);
  } else {
    Sentry.captureMessage(error.type);
  }
  // throw error;
}

export default function* errorSaga() {
  yield takeEvery((action) => action.type.includes('_FAIL'), bubbleErrors);
}