// Root
import { all, spawn } from 'redux-saga/effects';

// Sagas
import adminSaga from './admin';
import articleSaga from './article';
import contentSaga from './content';
import homeSaga from './home';
import overviewSaga from './overview';
import diveSaga from './dive';
import errorSaga from './error';
import authSaga from './auth';

export default function* rootSaga() {
  yield all([
    spawn(adminSaga),
    spawn(authSaga),
    spawn(errorSaga),
    spawn(articleSaga),
    spawn(contentSaga),
    spawn(overviewSaga),
    spawn(diveSaga),
    spawn(homeSaga),
  ]);
}
