// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-h4W4jVr5UjwwnEGw5AfE{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin-bottom:calc(2*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/pages/PageEdit/PageEdit.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,oCAAA","sourcesContent":[".import {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: calc(2 * var(--space-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"import": "sophi-h4W4jVr5UjwwnEGw5AfE"
};
export default ___CSS_LOADER_EXPORT___;
