// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-g4KKEKRmXdW7FFkKVpfo{-ms-flex-negative:0;flex-shrink:0}.sophi-hmDRMUxnskfDgyyOBevU{display:block;width:1.5em;height:1.5em;margin:0 .75em -0.3em .75em}.sophi-lxGU_vOPGmtK14lyACx2{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;max-width:250px}.sophi-lnOv8Q3xg0kHl7tNtxxQ{padding:0 calc(.2*var(--space-1)) 0 calc(1*var(--space-1));font-family:monospace;-ms-flex-negative:0;flex-shrink:0}.sophi-llDThVxtRPIX9W1jH1Mf{text-decoration:line-through}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/Filters/Comparators/LikeEquality/LikeEqualityFilter.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,aAAA,CAGF,4BACE,aAAA,CACA,WAAA,CACA,YAAA,CACA,2BAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CAAA,cAAA,CACA,eAAA,CAGF,4BACE,0DAAA,CACA,qBAAA,CACA,mBAAA,CAAA,aAAA,CAGF,4BACE,4BAAA","sourcesContent":[".label {\n  flex-shrink: 0;\n}\n\n.symbol {\n  display: block;\n  width: 1.5em;\n  height: 1.5em;\n  margin: 0 0.75em -0.3em 0.75em;\n}\n\n.values {\n  display: flex;\n  flex-wrap: wrap;\n  max-width: 250px;\n}\n\n.case {\n  padding: 0 calc(0.2 * var(--space-1)) 0 calc(1 * var(--space-1));\n  font-family: monospace;\n  flex-shrink: 0;\n}\n\n.insensitive {\n  text-decoration: line-through;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "sophi-g4KKEKRmXdW7FFkKVpfo",
	"symbol": "sophi-hmDRMUxnskfDgyyOBevU",
	"values": "sophi-lxGU_vOPGmtK14lyACx2",
	"case": "sophi-lnOv8Q3xg0kHl7tNtxxQ",
	"insensitive": "sophi-llDThVxtRPIX9W1jH1Mf"
};
export default ___CSS_LOADER_EXPORT___;
