import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button, DatePicker, Input, theme } from 'antd';
import { CheckOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';

import { useOverrides } from '../contexts/OverridesProvider';
import { useFeedback } from '../contexts/FeedbackProvider';

import {
  FEOverride,

  UpdateByRequestIdRequest,
  UpdateByRequestIdRequestAdd,
  UpdateByRequestIdRequestRemove,
  UpdateByRequestIdRequestReplace,
  UpdateByRequestIdRequestBan,

  UpdateByIdRequest,
  UpdateByIdRequestAdd,
  UpdateByIdRequestRemove,
  UpdateByIdRequestReplace,
  UpdateByIdRequestBan,
} from '../types';
import { cardDateFormatter } from '../utils/copy';
import { range } from '../utils/functions';
import { ruleTypeMap } from '../api/curator';

const ExpirationDate: React.FC<{
  data: FEOverride[];
  editable?: boolean;
}> = ({ data, editable }) => {
  const queryClient = useQueryClient();
  const { expirationDate, creationDate } = data[0];
  const { token } = theme.useToken();
  const { openMessage } = useFeedback();
  const { api, refreshOverrides } = useOverrides();
  const [ editMode, setEditMode ] = useState<boolean>(false);
  const [ updatedHours, setUpdatedHours ] = useState<number | null>(null);
  const { mutate, isLoading } = useMutation({
    mutationFn: ({ data, hours }: {data: FEOverride[], hours: number}) => {
      const { id, requestId } = data[0];
      if (requestId) {
        return api.updateExpirationDateByRequestId(requestId, formatByRequestIdRequest(data, hours))
      }
      return api.updateExpirationDateById(id, formatByIdRequest(data[0], hours))
    },
    onError: (err, request, context) => {
      refreshOverrides();
      openMessage({
        type: 'error',
        content: 'Could not update override',
        duration: 20,
      });
    },
    onSuccess: (data, request, context) => {
      refreshOverrides();
      openMessage({
        type: 'success',
        content: 'Updated',
      });
    },
  });

  function handleSubmit() {
    if (typeof updatedHours === 'number') {
      mutate({data, hours: updatedHours});
      setEditMode(false);
    }
  }

  function handleChange(date: Dayjs | null): void {
    if (date === null) {
      setUpdatedHours(null);
    } else {
      const hours = date.diff(new Date(), 'hours');
      setUpdatedHours(hours + 1);
    }
  }

  function disabledDate(current: Dayjs): boolean {
    return current && ((current < dayjs(new Date()) && current < dayjs(new Date(creationDate)).add(1, 'hour')) || current > dayjs(new Date()).add(7, 'weeks'));
  }

  function disabledTime() {
    const currentExpirationMinute = new Date(expirationDate).getMinutes();
    return {
      disabledMinutes: () => range(0, 60).filter(minute => minute !== currentExpirationMinute),
    };
  }

  return (<>
    {
      editMode ? (<Input.Group compact>
        <DatePicker
          size="small"
          format="MMM D h:mm A"
          defaultValue={dayjs(new Date(expirationDate))}
          style={{ width: `calc(100% - ${token.sizeLG * 2}px)` }}
          showTime={{ format: 'HH:mm' }}
          showNow={false}
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          placement="bottomLeft"
          onChange={handleChange}
        />
        <Button size="small" onClick={handleSubmit} icon={<CheckOutlined/>} disabled={typeof updatedHours !== 'number' || isLoading} loading={isLoading}/>
        <Button size="small" onClick={()=>setEditMode(false)} icon={<CloseOutlined/>}/>
      </Input.Group>) : (cardDateFormatter(expirationDate))
    }
    {(editable && !editMode) && <Button type="link" size="small" style={{ marginLeft: token.sizeXS }} onClick={()=>setEditMode(true)} icon={<FormOutlined/>}/>}
  </>);
}

function formatByRequestIdRequest(data: FEOverride[], updatedHours: number): UpdateByRequestIdRequest {
  const first = data[0];
  
  const feRuleType = first.ruleType;
  const ruleType = ruleTypeMap[first.ruleType];
  const articleId = first.articleId;
  const widgetName = first.widgetName;
  const pages = data.map(override => override.page);
  const position = first.position;
  const requestedUserName = first.requestedUserName;
  const replacedArticleId = first.replacedArticleId;
  const tenantId = first.tenantId;
  const hostConfigId = first.hostConfigId;
  const status = first.status;
  const overrideReasonId = first.overrideReasonId;
  const requestId = first.requestId;

  const expirationHourOfDay = updatedHours;
  
  switch (feRuleType) {
    case 'in': {
      return {
        articleId,
        expirationHourOfDay,
        hostConfigId,
        overrideReasonId,
        pages,
        position,
        replacedArticleId,
        requestId,
        requestedUserName,
        ruleType,
        status,
        tenantId,
        widgetName,
      } as UpdateByRequestIdRequestAdd
    }
    case 'out': {
      return {
        articleId,
        expirationHourOfDay,
        hostConfigId,
        overrideReasonId,
        pages,
        position,
        replacedArticleId,
        requestId,
        requestedUserName,
        ruleType,
        status,
        tenantId,
        widgetName,
      } as UpdateByRequestIdRequestRemove
    }
    case 'replace': {
      return {
        articleId,
        expirationHourOfDay,
        hostConfigId,
        overrideReasonId,
        pages,
        position,
        replacedArticleId,
        requestId,
        requestedUserName,
        ruleType,
        status,
        tenantId,
        widgetName,
      } as UpdateByRequestIdRequestReplace
    }
    case 'ban':
    default: {
      return {
        articleId,
        expirationHourOfDay,
        hostConfigId,
        overrideReasonId,
        pages,
        position,
        replacedArticleId,
        requestId,
        requestedUserName,
        ruleType,
        status,
        tenantId,
        widgetName,
      } as UpdateByRequestIdRequestBan
    }
  }
}

function formatByIdRequest(data: FEOverride, updatedHours: number): UpdateByIdRequest {
  const expirationHourOfDay = updatedHours;
  const feRuleType = data.ruleType;
  const ruleType = ruleTypeMap[data.ruleType];
  const articleId = data.articleId;
  const widgetName = data.widgetName;
  const page = data.page;
  const position = data.position;
  const requestedUserName = data.requestedUserName;
  const replacedArticleId = data.replacedArticleId;
  const tenantId = data.tenantId;
  const hostConfigId = data.hostConfigId;
  const status = data.status;
  const overrideReasonId = data.overrideReasonId;

  switch (feRuleType) {
    case 'in': {
      return {
        articleId,
        page,
        expirationHourOfDay,
        overrideReasonId,
        status,
        hostConfigId,
        tenantId,
        requestedUserName,
        ruleType,
        widgetName,
        position,
        replacedArticleId,
      } as UpdateByIdRequestAdd
    }
    case 'out': {
      return {
        articleId,
        page,
        expirationHourOfDay,
        overrideReasonId,
        status,
        hostConfigId,
        tenantId,
        requestedUserName,
        ruleType,
        widgetName,
        position,
        replacedArticleId,
      } as UpdateByIdRequestRemove
    }
    case 'replace': {
      return {
        articleId,
        page,
        expirationHourOfDay,
        overrideReasonId,
        status,
        hostConfigId,
        tenantId,
        requestedUserName,
        ruleType,
        widgetName,
        position,
        replacedArticleId,
      } as UpdateByIdRequestReplace
    }
    case 'ban':
    default: {
      return {
        articleId,
        page,
        expirationHourOfDay,
        overrideReasonId,
        status,
        hostConfigId,
        tenantId,
        requestedUserName,
        ruleType,
        widgetName,
        position,
        replacedArticleId,
      } as UpdateByIdRequestBan
    }
  }
}

export default ExpirationDate;
