import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
// Atoms
import Section from 'atoms/Section';
import DynamicComponent from 'atoms/DynamicComponent';
// Actions
import types from 'actions/diveTypes';
import { fetchDiveArticlesColumns, setSelectedDiveArticlesColumns } from 'actions/dive';
import { setTitle } from 'actions/header';
// Selectors
import { getMainHostID, getPlatforms, getTimezone } from 'selectors/auth';
import { getDiveArticlesAllColumns, getDiveArticlesColumns } from 'selectors/dive';
import { getDiveDownloadsDisabled } from 'selectors/global';
// Utils
import { setPageTitle } from 'utils/paths';
import { formatColumnsConfig, getCellFormatter } from 'utils/table';

const AuthorPage = (props) => {
  const dispatch = useDispatch();
  const authorName = props.match.params.author;
  const selectedColumns = useSelector(getDiveArticlesColumns);
  const downloadDisabled = useSelector(getDiveDownloadsDisabled);
  const platforms = useSelector(getPlatforms);
  const timezone = useSelector(getTimezone);
  const mainHost = useSelector(getMainHostID);
  const articleColumns = formatColumnsConfig(useSelector(getDiveArticlesAllColumns), platforms);

  useEffect(() => {
    setPageTitle(`Historical - Author - ${authorName}`);
    dispatch(setTitle(''));
    dispatch(fetchDiveArticlesColumns());
  }, [authorName]);

  const overrideFilters = { authors: [authorName] };

  const requestedColumns = useSelector((state) => state.dive.articles.table.columns);

  const handleColumnChange = (columns) => dispatch(setSelectedDiveArticlesColumns(columns));

  const handlePaginationChange = (type, value) => dispatch({
    type: types.UPDATE_DIVE_ARTICLES_PAGINATION,
    paginationType: type,
    paginationValue: value,
    overrideFilters,
  });

  const handleSortChange = ({ column, direction }) => dispatch({
    type: types.UPDATE_DIVE_ARTICLES_SORT,
    sortValues: { sort: column, sortOrder: direction },
    overrideFilters,
  });

  const columnConfig = useMemo(() => {
    const formattedColumns = uniq([ 'sophiScore', ...selectedColumns ]);
    return formattedColumns.map((column) => {
      const articleColumn = articleColumns.nonDevice.get(column) || articleColumns.deviceTop.get(column);
      if (articleColumn) {
        return {
          accessor: column,
          Cell: getCellFormatter(column, requestedColumns && requestedColumns.includes(column), { host: mainHost, timezone, dive: true }),
          Header: column === 'sophiScore' ? 'Score' : articleColumn.label,
          ...articleColumn,
        };
      }
    }).filter((c) => c);
  }, [selectedColumns, requestedColumns]);

  return (
    <div>
      <DynamicComponent
        placeholder={
          <Section
            title={'Articles - Loading'}
            loading={true}>
            {null}
          </Section>
        }
        component={() => import('organisms/DiveTable')}
        overrideFilters={overrideFilters}
        // Any props needed by the component can be placed as a prop of the Dynamic Component
        action={types.FETCH_DIVE_AUTHOR_ARTICLES}
        pageType="article"
        tableValues={(state) => (state.dive.articles.table)}
        count={(state) => (state.dive.articles.count)}
        selectedColumns={getDiveArticlesColumns}
        tableColumns={articleColumns}
        onColumnChange={handleColumnChange}
        onPaginationChange={handlePaginationChange}
        onSort={handleSortChange}
        id="dive-author-articles"
        columns={columnConfig}
        downloadConfig={{
          handleDownload: () => (
            dispatch({
              type: types.FETCH_DIVE_ARTICLES_REPORT,
              overrideFilters
            })
          ),
          disabled: downloadDisabled,
          info: 'Adjust the date-published range to download up to 31 days’ worth of data.',
        }}
        frozenColumn="headline"
      />
    </div>
  );
};

export default AuthorPage;
