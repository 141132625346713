// Root
import React from 'react';

// Components
import PlaceholderText from 'sophi-design-system/src/components/PlaceholderText';

// Custom styles
import styles from './PermissionDenied.styles.scss';

const PermissionDenied = () => {
  return (
    <div className={styles.container}>
      <PlaceholderText iconName="Lock" heading="Looks like you do not have permission to view this dashboard.">
        <h2>Please contact your organization’s Sophi admin if you would like to view this page.</h2>
      </PlaceholderText>
    </div>
  );
};

export default PermissionDenied;
