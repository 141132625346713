// From the set of single div loading spinners by Luke Haas
// https://projects.lukehaas.me/css-loaders/
import React from 'react';
import styles from './LoadingSpinner.styles.scss';

const LoadingSpinner = ({ width, className }) => (
  <div style={{ fontSize: width }} className={`${styles.loader} ${className || ''}`}>Loading...</div>
);

export default LoadingSpinner;
