// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-FbU4ssSjmi62OyYCUFun{padding:30px 6vw;text-align:center}.sophi-AiIQQSFGHj0EjpyhzhME{margin:25px 0}", "",{"version":3,"sources":["webpack://./src/pages/Forbidden.styles.scss"],"names":[],"mappings":"AAAA,4BACE,gBAAA,CACA,iBAAA,CAEF,4BACE,aAAA","sourcesContent":[".container {\n  padding: 30px 6vw;\n  text-align: center;\n}\n.title {\n  margin: 25px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-FbU4ssSjmi62OyYCUFun",
	"title": "sophi-AiIQQSFGHj0EjpyhzhME"
};
export default ___CSS_LOADER_EXPORT___;
