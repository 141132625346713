import React from 'react';
import Icon from 'sophi-design-system/src/components/Icon';

import styles from './TimeoutError.styles.scss';

const TimeoutError = () => <div className={styles.error}>
    <Icon name="Time" size="xl" type="circle" />
    <h2>This is taking longer than expected.</h2>
    <p>Please set a shorter time period and run your search again.</p>
</div>;

export default TimeoutError;