// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-Sz4XIuq89pHdld0qZPO8{container-type:inline-size}.sophi-OOO9igyC0ljGQubTmeAA{display:grid;grid-template-columns:1fr;-webkit-column-gap:calc(.5*var(--space-1));-moz-column-gap:calc(.5*var(--space-1));column-gap:calc(.5*var(--space-1));row-gap:calc(.5*var(--space-1));margin-bottom:calc(1*var(--space-1));-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}.sophi-c8xnEIEN61CzWEqw6ZgS{grid-column:1/2}.sophi-Yfj26gDqCYk2v8zwinsS{grid-column:2/3}.sophi-pla4zsQjW74t6UO9SZSY{text-align:center}.sophi-Cx2h8U11NXrsuqhU9Qq2{grid-column:1/-1}@container (min-width: 400px){.sophi-OOO9igyC0ljGQubTmeAA{grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}.sophi-pla4zsQjW74t6UO9SZSY{text-align:left;grid-column:2/3;margin-bottom:calc(1*var(--space-1))}}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/DiversityRule/Form.styles.scss"],"names":[],"mappings":"AAAA,4BACE,0BAAA,CAGF,4BACE,YAAA,CACA,yBAAA,CACA,0CAAA,CAAA,uCAAA,CAAA,kCAAA,CACA,+BAAA,CACA,oCAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAGF,4BACE,eAAA,CAEF,4BACE,eAAA,CAGF,4BACE,iBAAA,CAGF,4BACE,gBAAA,CAGF,8BACE,4BACE,6CAAA,CAAA,qCAAA,CAEF,4BACE,eAAA,CACA,eAAA,CACA,oCAAA,CAAA","sourcesContent":[".container {\n  container-type: inline-size;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr;\n  column-gap: calc(0.5 * var(--space-1));\n  row-gap: calc(0.5 * var(--space-1));\n  margin-bottom: calc(1 * var(--space-1));\n  align-items: baseline;\n}\n\n.label {\n  grid-column: 1 / 2;\n}\n.input {\n  grid-column: 2 / 3;\n}\n\n.when {\n  text-align: center;\n}\n\n.condition {\n  grid-column: 1 / -1;\n}\n\n@container (min-width: 400px) {\n  .grid {\n    grid-template-columns: max-content 1fr;\n  }\n  .when {\n    text-align: left;\n    grid-column: 2 / 3;\n    margin-bottom: calc(1 * var(--space-1));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-Sz4XIuq89pHdld0qZPO8",
	"grid": "sophi-OOO9igyC0ljGQubTmeAA",
	"label": "sophi-c8xnEIEN61CzWEqw6ZgS",
	"input": "sophi-Yfj26gDqCYk2v8zwinsS",
	"when": "sophi-pla4zsQjW74t6UO9SZSY",
	"condition": "sophi-Cx2h8U11NXrsuqhU9Qq2"
};
export default ___CSS_LOADER_EXPORT___;
