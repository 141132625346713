import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../Avatar';
import Button from '../Button';
import PopOver from '../Popover';
import Icon from '../Icon';
import useOnOutsideClick from '../../hooks/useOnClickOutsideClick';

import styles from './AuthenticationBar.styles.scss';

const AuthenticationBar = ({
  isAuthenticated, login, logout, userInfo, popoverBody
}) => {
  const [popoverVisible, setVisible] = useState(false);
  const node = useRef();

  useOnOutsideClick(node, () => setVisible(false));

  const handleVisibility = () => {
    setVisible(!popoverVisible);
  };

  useEffect(() => {
    const keyListener = (e) => {
      if (e.keyCode === 27) {
        setVisible(false);
      }
    };
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  }, [popoverVisible]);

  if (isAuthenticated) {
    const avatar = (
      <Avatar
        username={userInfo?.name}
        userImage={userInfo?.picture}
        onClick={handleVisibility}
        classes={{
          welcome: styles.username,
        }}
      />
    );

    return (
      <section className={styles.bar} ref={node}>
        <span>
          {(!popoverBody && !logout) && avatar}
          {(popoverBody || logout) && (
            <Button
              variant={'clear'}
              size={'natural'}
              onClick={handleVisibility}
              className={styles.toggleBtn}
              title="Toggle user menu"
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {avatar}
                <Icon className={styles.toggleBtnIcon} name="CaretDown" color="inherit" size="sm" />
              </span>
            </Button>
          )}
        </span>
        {(popoverBody || logout) && (
          <PopOver
            visible={popoverVisible}
            style={{ marginRight: 9 }}
          >
            <>
              {popoverBody && (
                <div className={styles.popoverBody}>
                  {popoverBody}
                </div>
              )}
              {(popoverBody && logout) && <hr />}
              {logout && (
                <div className={styles.popoverFooter}>
                  <Button style={{ minWidth: 190 }} onClick={logout}>Sign out</Button>
                </div>
              )}
            </>
          </PopOver>
        )}
      </section>
    );
  }

  return <section className={styles.bar}><Button onClick={login}>Sign In</Button></section>;
};

AuthenticationBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  login: PropTypes.func,
  logout: PropTypes.func,
  userInfo: PropTypes.object,
  popoverBody: PropTypes.node,
};

export default AuthenticationBar;
