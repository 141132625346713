import { Article, CreateRequest, Override, RuleType, FERuleType, PageWidgetTree, UpdateByRequestIdRequest, UpdateByIdRequest, FEOverride } from '../types';
import { transformToFEOverride, sortAlphabetically, widgetDelimiter } from '../utils/functions';

export const ruleTypeMap: {[key in FERuleType]: RuleType;} = {
  'in': 'in',
  'replace': 'replace',
  'out': 'out',
  'ban': 'out'
};

export class CuratorApi {
  host: string;
  client: string;
  getToken: () => Promise<string>;

  constructor(host: string = '', client: string = '', getToken: () => Promise<string>) {
    this.host = host;
    this.client = client;
    this.getToken = getToken;
  }

  async fetchWithToken(url: RequestInfo | URL, init?: RequestInit) {
    return await this.getToken().then((token) => {
      const headers = new Headers(init?.headers);
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Content-Type', 'application/json');

      return fetch(url, {
        ...init,
        headers
      });
    }).then((res) => {
      if (res.status === 200) return res.json();
      throw res;
    });
  }

  // Endpoints
  async getPageWidgets(): Promise<PageWidgetTree[]> {
    const json: Array<{
      name: string;
      widgetConfigs: Array<{
        name: string;
        slotSize: number;
      }>;
    }> = await this.fetchWithToken(
      `${this.host}/v1/curatedHosts/${this.client}/pages`
    ).catch((e) => { return [] });

    return json.map(({ name, widgetConfigs = [] }) => {
      const children = widgetConfigs.map(({ name: title, slotSize}) => ({
        key: `${name}${widgetDelimiter}${title}`,
        title,
        slotSize
      })).sort(sortAlphabetically(({ title }) => title.toLowerCase() ));

      return { key: name, title: name, children };
    }).sort(sortAlphabetically(({ title }) => title.toLowerCase()));
  }

  async getOverrides(type: FERuleType): Promise<Override[]> {
    const url = new URL(`${this.host}/v1/hosts/${this.client}/overrides`);
    url.searchParams.append('ruleType', type);

    return await this.fetchWithToken(url).catch((e) => { return [] });
  }

  async getAllOverrides(): Promise<FEOverride[]> {
    return await Promise.all([
        this.getOverrides('in'),
        this.getOverrides('replace'),
        this.getOverrides('out')
      ])
      .then((values) => {
        return [
          ...values[0],
          ...values[1],
          ...values[2]
        ].map(transformToFEOverride) as FEOverride[];
      });
  }

  async validateArticleId(id: string): Promise<Article | false> {
    // no /v1 in path here
    // not found response is 204
    return await this.fetchWithToken(
      `${this.host}/hosts/${this.client}/articles/${id}`
    ).catch((e) => { return false });
  }

  async searchForArticle(value: string): Promise<Article[]> {
    return await this.fetchWithToken(
      `${this.host}/hosts/${this.client}/articles/getArticleByHeadline?headline=${value}`,
    ).then((res: Article[]) => {
      return res.sort(({ publishedDate: aDate = 0 }, { publishedDate: bDate = 0 }) => bDate - aDate);
    });
  }

  async createOverride(data: CreateRequest): Promise<Override[]> {
    const formatRequest = (d: CreateRequest) => {
      const formatted = {
        ...d,
        ruleType: ruleTypeMap[d.ruleType]
      };
      return JSON.stringify(formatted);
    };
    const init: RequestInit = {
      method: 'POST',
      body: formatRequest(data),
    };
    return this.fetchWithToken(
      `${this.host}/v2/hosts/${this.client}/overrides`,
      init
    );
  }

  async getOverrideReasons() {
    return await this.fetchWithToken(`${this.host}/v1/overrideReasons`);
  }

 async updateExpirationDateById(id: string, data: UpdateByIdRequest): Promise<any> {
  const init: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(data),
  };
  return await this.fetchWithToken(
    `${this.host}/v1/hosts/${this.client}/overrides/${id}`,
    init
  );
 }

 async updateExpirationDateByRequestId(requestId: string, data: UpdateByRequestIdRequest): Promise<any> {
  const init: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(data),
  };
  return await this.fetchWithToken(
    `${this.host}/v2/hosts/${this.client}/overrides/requestId/${requestId}`,
    init
  );
 }

 async deleteOverrideById(id: string): Promise<any> {
  const init: RequestInit = { method: 'DELETE' };
  return await this.fetchWithToken(
    `${this.host}/v1/hosts/${this.client}/overrides/${id}`,
    init
  );
 }

 async deleteOverrideByRequestId(requestId: string): Promise<any> {
  const init: RequestInit = { method: 'DELETE' };
  return await this.fetchWithToken(
    `${this.host}/v2/hosts/${this.client}/overrides/requestId/${requestId}`,
    init
  );
 }
}
