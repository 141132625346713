export const nonDeviceNowColumns = {
  headline: { default: true, required: true, enabled: true, label: 'Headline' },
  byline: { default: false, required: false, enabled: true, label: 'Byline' },
  publishedDate: { default: false, required: false, enabled: true, label: 'Published Date' },
  scoreDriver: { default: false, required: false, enabled: true, label: 'Top Score Contributor' },
  section: { default: false, required: false, enabled: true, label: 'Section' },
  ownership: { default: false, required: false, enabled: true, label: 'Ownership' },
  creditLine: { default: false, required: false, enabled: true, label: 'Credit Line' },
  contentType: { default: false, required: false, enabled: true, label: 'Content Type' },
  access: { default: false, required: false, enabled: true, label: 'Reader Access' },
  subscriptionCount: { default: false, required: false, enabled: true, label: 'Subscriptions' },
  visitCount: { default: false, required: false, enabled: true, label: 'Visits' },
  subsection: { default: false, required: false, enabled: true, label: 'Subsection' },
  keywords: { default: false, required: false, enabled: true, label: 'Keywords' },
  contentId: { default: false, required: false, enabled: true, label: 'Content ID' },
};

export const deviceTopNowColumns = {
  score: { default: true, required: true, enabled: true, label: 'Sophi Score', platform: 'Total' },
  desktopGlobeScore: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Desktop' },
  tabletGlobeScore: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Tablet' },
  mobileGlobeScore: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'Mobile' },
  appGlobeScore: { default: false, required: false, enabled: true, label: 'Sophi Score', platform: 'App' },
  acquisition: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Total' },
  acquisitionDesktop: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Desktop' },
  acquisitionTablet: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Tablet' },
  acquisitionMobile: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'Mobile' },
  acquisitionApp: { default: false, required: false, enabled: true, label: 'Acquisition Score', platform: 'App' },
  retention: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Total' },
  retentionDesktop: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Desktop' },
  retentionTablet: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Tablet' },
  retentionMobile: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'Mobile' },
  retentionApp: { default: false, required: false, enabled: true, label: 'Retention Score', platform: 'App' },
  internalTotal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Total' },
  desktopInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Desktop' },
  tabletInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Tablet' },
  mobileInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'Mobile' },
  appInternal: { default: false, required: false, enabled: true, label: 'Internal Score', platform: 'App' },
  directTotal: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Total' },
  desktopDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Desktop' },
  tabletDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Tablet' },
  mobileDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'Mobile' },
  appDirect: { default: false, required: false, enabled: true, label: 'Direct Score', platform: 'App' },
  emailTotal: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Total' },
  desktopEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Desktop' },
  tabletEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Tablet' },
  mobileEmail: { default: false, required: false, enabled: true, label: 'Email Score', platform: 'Mobile' },
  newsletterTotal: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Total' },
  desktopNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Desktop' },
  tabletNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Tablet' },
  mobileNewsletter: { default: false, required: false, enabled: true, label: 'Newsletter Score', platform: 'Mobile' },
  affiliateTotal: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Total' },
  desktopAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Desktop' },
  tabletAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Tablet' },
  mobileAffiliate: { default: false, required: false, enabled: true, label: 'Affiliate Score', platform: 'Mobile' },
  searchTotal: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Total' },
  desktopSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Desktop' },
  tabletSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Tablet' },
  mobileSearch: { default: false, required: false, enabled: true, label: 'Search Score', platform: 'Mobile' },
  socialTotal: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Total' },
  desktopSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Desktop' },
  tabletSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Tablet' },
  mobileSocial: { default: false, required: false, enabled: true, label: 'Social Score', platform: 'Mobile' },
  unknownTotal: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Total' },
  desktopUnknown: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Desktop' },
  tabletUnknown: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Tablet' },
  mobileUnknown: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'Mobile' },
  appUnknown: { default: false, required: false, enabled: true, label: 'Other Score', platform: 'App' },
};
