import React from 'react';
import PropTypes from 'prop-types';

import styles from './image.styles.scss';

const Image = ({
  link,
  images,
  style,
  classes,
  alt
}) => {
  // Generates source set string in the shape "[value] [key]," from the images prop
  const structureSourceSet = () => (images ? Object.keys(images).map((size) => `${images[size]} ${size}`).join(',') : '');
  // Universal image tag
  const image = (
    <img
      alt={alt}
      className={`${classes && classes.join(' ')} ${styles.imageImage}`}
      srcSet={structureSourceSet()}
      style={style}
    />
  );

  // Conditionally wrap the image in an anchor tag, if a link prop is provided
  if (link) {
    return (
      <a href={link} className={styles.imageLink}>
        {image}
      </a>
    );
  }

  return image;
};

Image.propTypes = {
  link: PropTypes.string,
  images: PropTypes.shape({
    NameOfSize_2x: PropTypes.string
  }),
  style: PropTypes.object,
  alt: PropTypes.string,
  classes: PropTypes.array,
};

export default Image;
