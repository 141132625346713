import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as SortIcon } from '../../assets/svg/icon-sort-down.svg';
import { ReactComponent as SortIconOff } from '../../assets/svg/icon-sort-off.svg';

import styles from './Table.styles.scss';

const TableHeader = ({
  id,
  height,
  headerGroups,
  sortable,
  sort,
  handleSort,
  columns,
}) => {
  const renderSortIcon = (column) => {
    if (column.id === 'expander') return null;
    return column.id === sort.column ? <SortIcon /> : <SortIconOff />;
  };

  return (
    <thead className={classNames(styles.head, { [styles.sortable]: sortable })}>
      {headerGroups.map((headerGroup, i) => (
        <tr
          style={{ ...(height && { height }) }}
          key={`header-group-${i}`}
          className={styles.headerRow}
          {...columns.length > 0 ? headerGroup.getHeaderGroupProps() : null}
        >
          {
            headerGroup.headers.map((column) => {
              const { style, className, ...thProps } = column.getHeaderProps([
                {
                  className: column.className,
                  style: column.style,
                }
              ]);

              const headerValue = column.render('Header');

              return (
                <th
                  key={column.id}
                  className={classNames(styles.headerCell, sort.direction, className, {
                    [styles.headerSortActive]: sort.column === column.id
                  })}
                  onClick={sortable ? () => handleSort(column) : null}
                  data-track-click="true"
                  data-track-name={`${id}-sort_header-${headerValue}`}
                  data-column={typeof column.id === 'string' ? column.id : ''}
                  data-content-type={column.contentType}
                  {...thProps}
                  style={{ ...style, borderRight: undefined }} // Remove any additional right borders added in column defs
                >
                  <span>
                    {headerValue}
                    {sortable ? (renderSortIcon(column)) : null}
                  </span>
                </th>
              );
            })
          }
        </tr>
      ))}
  </thead>
  );
};

TableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
  headerGroups: PropTypes.array,
  sortable: PropTypes.func,
  sort: PropTypes.shape({
    direction: PropTypes.string,
    column: PropTypes.string,
  }),
  handleSort: PropTypes.func,
  columns: PropTypes.array.isRequired,
};

export default TableHeader;
