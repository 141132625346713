import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import styles from './Tooltip.styles.scss';

const Tooltip = ({ content, placement, children }) => (
  <Tippy
    className={styles.tooltip}
    placement={placement}
    theme="light-border"
    content={content}
  >
    {children}
  </Tippy>
);

export default Tooltip;
