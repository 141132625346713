import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Slider, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useOverrides } from '../contexts/OverridesProvider';
import ListTreeFilter from './ListTreeFilter';
import RuleTypeTag from './RuleTypeTag';

import { FilterValuesType, FERuleType } from '../types';

const defaultRules: FERuleType[] = ['in','replace','out','ban'];

const ListFilters: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  const [ form ] = Form.useForm();
  const { getFilters, setFilters, pageWidgets } = useOverrides();
  const storedFilters = getFilters();
  const [ allFiltersValues, setAllFiltersValues ] = useState<FilterValuesType>(storedFilters);
  const [ rules, setRules ] = useState<{
    rule: FERuleType;
    checked: boolean;
  }[]>(defaultRules.map(rule => ({ rule, checked: true })));

  useEffect(() => {
    const { ruleType = [] } = storedFilters;
    const newRules = defaultRules.map((rule) => ({
      rule,
      checked: ruleType.length ? ruleType.includes(rule) : true,
    }));
    setRules(newRules);
  }, []);

  useEffect(() => {
    form.setFieldsValue(allFiltersValues);
    if (typeof setFilters === 'function') setFilters(allFiltersValues as FilterValuesType);
  }, [allFiltersValues]);

  function handleTreeCheck (data: React.Key[]) {
    setAllFiltersValues({
      ...allFiltersValues,
      pageWidget: data,
    } as FilterValuesType);
  }

  function handleRuleCheck(rule: FERuleType) {
    const index = rules.findIndex(({ rule: ruleType }) => ruleType === rule);
    rules[index].checked = !rules[index].checked;

    setRules([...rules]);
    const newRules = [...rules].filter(({ checked }) => checked).map(({ rule }) => rule);
    setAllFiltersValues({ ...allFiltersValues, ruleType: newRules } as FilterValuesType);
  }

  function handleChange(changedValues: Partial<FilterValuesType>, allValues: FilterValuesType) {
    setAllFiltersValues({ ...allFiltersValues, ...allValues });
  }

  function handleReset() {
    form.resetFields();
    setAllFiltersValues({} as FilterValuesType);
    setRules(defaultRules.map(rule => ({ rule, checked: true })));
  }

  return (
    <Form
      style={{ ...style }}
      form={form}
      layout="vertical"
      onValuesChange={handleChange}
    >
      {
        !!(pageWidgets.length) && (
          <Form.Item label="Page / Widget" name="pageWidget">
            <ListTreeFilter data={pageWidgets} localData={allFiltersValues?.pageWidget || []} onChange={handleTreeCheck}/>
          </Form.Item>
        )
      }
      <Form.Item label="Override Types" name="ruleType" shouldUpdate>
        <Space>
          {
            rules.map(({ rule, checked }) => <RuleTypeTag
              key={rule}
              rule={rule}
              checkable
              checked={checked}
              onClick={() => handleRuleCheck(rule)}
            />)
          }
        </Space>
      </Form.Item>
      <Form.Item label="Expires Between" name="expirationDate" initialValue={[0, 100]}>
        <Slider
          style={{ marginRight: '16px' }}
          max={8}
          marks={{ 0: 'Now', 8: 'Max' }}
          range={{ draggableTrack: true }}
          tooltip={{ formatter: sliderTooltip }}
        />
      </Form.Item>
      <Form.Item label="Article ID" name="articleId">
        <Input allowClear suffix={<SearchOutlined/>}/>
      </Form.Item>
      <Form.Item label="Created By" name="requestedUserName">
        <Input allowClear suffix={<SearchOutlined/>}/>
      </Form.Item>
      <Button type="primary" block onClick={handleReset}>Reset Filters</Button>
    </Form>
  );
};

function sliderTooltip(value: number | undefined) {
  switch (value) {
    case 0:
      return 'Now';
    case 8:
      return '+7 day(s)';
    default:
      return `${value} day(s)`;
  }
}

export default ListFilters;
