import React, { useContext, useMemo } from 'react';
import useWindowSize from 'sophi-design-system/src/hooks/useWindowSize';
import styles from './Tabs.styles.scss';
import { TabStateContext } from './context';

// Container for all Tab instances
const TabHeader = ({ children, label }) => {
  const { selected } = useContext(TabStateContext);
  const { width } = useWindowSize();

  const selectedTabPosition = useMemo(() => {
    if (!selected) return {};

    if (document.getElementById(selected)) {
      const elem = document.getElementById(selected);
      return {
        width: elem.getBoundingClientRect().width,
        offsetLeft: elem.offsetLeft
      };
    }
    return {};
  }, [selected, width]);

  const offset = useMemo(() => width < 1024 ? 30 : 0, [width]);

  return (
    <div
      className={`${styles.tabList} tab-list`}
      role="tablist"
      aria-label={label}
    >
      {children}
      <span style={{ transform: `translateX(${selectedTabPosition.offsetLeft - offset}px)`, width: selectedTabPosition.width }} className={styles.tabIndicator} />
    </div>
  );
};

TabHeader.displayName = 'TabHeader';

export default TabHeader;
