import { createSelector } from 'reselect';
import { getReportDownloadDisabled } from './dive';

export const getDownloads = (state) => state.global.downloads;

export const getDiveDownloadsDisabled = createSelector(
  [ getDownloads, getReportDownloadDisabled ],
  (downloads, dateRangeInvalid) => {
    if (dateRangeInvalid) return true;
    return Array.isArray(downloads) && downloads.filter((download) => !!download.loading).length >= 5;
  }
);

export const getNowDownloadsDisabled = createSelector(
  [getDownloads],
  (downloads) => {
    return Array.isArray(downloads) && downloads.filter((download) => !!download.loading).length >= 5;
  }
);
