import React, { useCallback, useContext, useRef, useState } from 'react';
import styles from './Tabs.styles.scss';
import { TabStateContext } from './context';
import { useDrag } from 'react-use-gesture';
import { animated } from 'react-spring';
import 'wicg-inert';

const TabPanel = ({ children, id, tabId }) => {
  const { selected, handleSwipe, tabIndexLookup, springProps, set, width, responsiveHeight } = useContext(TabStateContext);
  const panelRef = useRef(null);
  const [height, setHeight] = useState(0);
  // Callback ref is used in place of main ref to ensure we capture the height
  // on mount. This fixes an intermittent issue where the panel would appear with
  // no or limited height until user interacted with the tabs
  const setRef = useCallback((node) => {
    panelRef.current = node;
    node && !responsiveHeight && setHeight(node.clientHeight);
  }, []);

  const bind = useDrag(({ down, movement: [xDelta], direction: [xDir], distance, cancel }) => {
    if (window.innerWidth > 768) return;
    if (down && Math.abs(xDelta) > width / 2) {
      cancel(handleSwipe(xDir < 0 ? -1 : 1));
    }

    set((i) => {
      const current = tabIndexLookup[selected];
      const x = (i - current) * width + (down ? xDelta : 0);
      return { x };
    });    
  }, {});

  if (!springProps.length) return null;

  const hidden = selected !== tabId;
  const index = tabIndexLookup[tabId];
  const { x } = springProps[index];

  return (
    <>
      <div style={{ pointerEvents: 'none', position: 'relative', display: hidden ? 'none': 'block', height }} />
      <animated.div
        ref={setRef}
        key={index}
        className={styles.tabPanel}
        tabIndex="0"
        role="tabpanel"
        id={id}
        aria-labelledby={tabId}
        hidden={hidden}
        style={{ transform: x && x.interpolate((x) => `translate3d(${x}px,0,0)`) }}
        {...{ inert: hidden ? 'true' : null }}
        {...bind()}
      >
        {children}
      </animated.div>
    </>
  );
};

TabPanel.displayName = 'TabPanel';

export default TabPanel;
