import React from 'react';

import useCuratorConfigApi from '../../api/useCuratorConfigApi';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import pageStyles from '../Page.styles.scss';
import styles from './PageDelete.styles.scss';

import PageTitle from '../../components/PageTitle';
import { Button, message, Typography, Spin } from 'antd';
import EntityName from '../../components/EntityName';
import { useHistory } from 'react-router-dom';
import { getRoute, ROUTE_NAMES } from '../../../../utils/paths';
import { getPage, patchPage } from '../../api/pages';

const PageDelete = () => {
  const { pageName } = useParams();
  let history = useHistory();
  const queryClient = useQueryClient();

  const { fn: queryFn } = useCuratorConfigApi(getPage, { pageName });
  const { data, isLoading } = useQuery({
    queryKey: [`page`, pageName],
    queryFn,
    onError: (e) => {
      if (e?.cause?.status === 404) history.push(getRoute(ROUTE_NAMES.curatorPagesList));
    }
  });

  const { fn: mutationFn } = useCuratorConfigApi(patchPage);
  const { isLoading: isSaving, error, mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      const route = ROUTE_NAMES.curatorPagesList;
      history.push(getRoute(route, { pageName }));
      message.success({
        content: `Deleted`,
        style: {
          marginTop: '58px'
        }
      });
      queryClient.removeQueries({ queryKey: [`page`, pageName] });
    }
  });
  
  const handleSubmit = () => {
    mutate({ body: { status: 'DELETE' }, pageName });
  };

  return (
    <>
      <PageTitle title="Site automation" subtitle={`Delete page ${pageName}`}/>
      <div className={pageStyles.body}>
        <div className={styles.layout}>
          {isLoading && <Spin />}

          {!isLoading && data &&
            <>
              <Typography.Paragraph>
                If the page was previously published, the last content version will continue to be available. Content will no longer be updated and may become outdated. Page widgets will also be deleted.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Do you want to delete page <EntityName name={pageName} />?
              </Typography.Paragraph>
              <div className={styles.actions}>
                <Button disabled={isSaving} onClick={history.goBack}>
                  Cancel
                </Button>
                <Button disabled={isSaving} onClick={handleSubmit} type="primary" danger={true}>
                  Delete
                </Button>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default PageDelete;
