import React from 'react';
import PropTypes from 'prop-types';
import styles from './Placeholder.styles.scss';

const Placeholder = ({ className, ...props }) => (
  <div className={`${styles.placeholder} ${className}`} {...props} />
);

Placeholder.propTypes = {
  className: PropTypes.string,
};

export default Placeholder;
