import React from 'react';
import styles from './Version.styles.scss';

const Version = () => {
  const version = ENV.VERSION;
  const commit = (ENV.COMMIT || '').substring(0, 9);
  const regex = new RegExp(/-\d{1,}/);
  const isFeatureBranch = regex.test(version);
  const hrefStem = 'https://sophi-inc.atlassian.net/browse/';

  if (isFeatureBranch) {
    return (
      <p className={styles.version}>Version <a href={hrefStem + version} target="_blank" rel="noopener">{version}</a> {commit}</p>
    );
  }
  return (
    <p className={styles.version}>Version {version}</p>
  );
};

export default Version;
