import React from 'react';
import PropTypes from 'prop-types';
import styles from './banner.styles.scss';

const Banner = ({ message, severity }) => {
  const createAnchor = (url, text) => (<a href={url} target={'_blank'} rel="noreferrer">{text}</a>);
  /**
   * This method will identify if a markdown formatted link is in the text passed in and replace it with an HTML anchor tag which will open in a new window.
   * @param {String} text - The text that may contain a link
   */
  const constructHtml = (text) => {
    const rawLink = text.match(/\[([^[]+)\](\(.*\))/gm);
    if (rawLink) {
      const originalText = text.split(rawLink[0]);
      originalText.splice(1, 0, createAnchor(rawLink[0].match(/\((.*?)\)/)[1], rawLink[0].match(/\[(.*?)\]/)[1]));
      return originalText;
    }
    return text;
  };

  return (
    <aside className={styles.banner} data-severity={severity.toLowerCase()} role={'banner'}>
      <p>
        {constructHtml(message)}
      </p>
    </aside>
  );
};

Banner.defaultProps = {
  severity: 'warning'
};

Banner.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(['warning', 'error'])
};

export default Banner;
