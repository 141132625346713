// Utils
import { getResource, getUniqByKey, pathBuilder, paramsAreTruthy } from 'utils/api';
import filter from 'lodash/filter';

/**
 * API call for Most Readers Chart
 * @param {String} id - String representing the contentID
 */
const getMostReaderData = (host, client, id, token) => {
  if (paramsAreTruthy([id])) {
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `traffic-analysis/most-readers`
      }
    );
    const data = getResource({
      endpoint: path,
      token,
    })
      .then((response) => formatResponse(response))
      .catch((error) => ({ error }));

    const formatResponse = (response) => {
      if (Object.keys(data).includes('noData')) return ({ noData: true });
      const { visitors, heading, platforms } = response;
      const allDevices = getUniqByKey(visitors, 'platform');
      const allVisitorTypes = getUniqByKey(visitors, 'visitorType');
      const visitorGraph = {};
      allVisitorTypes.forEach((type) => {
        visitorGraph[type] = {};
        allDevices.forEach((device) => {
          const formattedDevice = device.toLowerCase();
          visitorGraph[type][formattedDevice] = 0;
        });
        const entry = filter(visitors, { visitorType: type });
        entry.forEach((point) => {
          const platform = point['platform'].toLowerCase();
          visitorGraph[type][platform] = point['total'];
        });
      });

      const totals = platforms.reduce((lu, platform) => ({
        ...lu,
        [platform['platform'].toLowerCase()]: platform['total']
      }), {});

      const graphData = Object.entries(visitorGraph)
        .map(([segment, values]) => {
          const total = Object.values(values).reduce((total, val) => total + val, 0);
          return { ...values, total, segment };
        })
        .sort((a, b) => {
          if (a.total > b.total) return -1;
          if (a.total < b.total) return 1;
          return 0;
        });
  
      return {
        response: {
          graph: {
            data: graphData,
            totals
          },
          title: heading,
        },
      };
    };
    return data;
  }
};

export default getMostReaderData;