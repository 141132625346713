// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-B9XwlOZ5UVmUDH8AiW_4{display:grid;row-gap:20px;padding:30px 0}.sophi-B9XwlOZ5UVmUDH8AiW_4 .sophi-taOTC2vl2m8_i8JbjMFK{color:var(--grays-800);display:inline-block;font-size:16px;font-weight:bold;line-height:1;text-align:center}@media(min-width: 1280px){.sophi-B9XwlOZ5UVmUDH8AiW_4{-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-column-gap:30px;-moz-column-gap:30px;column-gap:30px;grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}.sophi-B9XwlOZ5UVmUDH8AiW_4 .sophi-taOTC2vl2m8_i8JbjMFK{font-size:18px;text-align:left}}", "",{"version":3,"sources":["webpack://./src/organisms/DiveDatePicker/DiveDatePicker.styles.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,YAAA,CACA,cAAA,CAEA,wDACE,sBAAA,CACA,oBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CAGF,0BAdF,4BAeI,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,eAAA,CACA,6CAAA,CAAA,qCAAA,CAEA,wDACE,cAAA,CACA,eAAA,CAAA","sourcesContent":[".dive-date-picker {\n  display: grid;\n  row-gap: 20px;\n  padding: 30px 0;\n\n  .label {\n    color: var(--grays-800);\n    display: inline-block;\n    font-size: 16px;\n    font-weight: bold;\n    line-height: 1;\n    text-align: center;\n  }\n\n  @media (min-width: 1280px) {\n    align-items: center;\n    column-gap: 30px;\n    grid-template-columns: max-content 1fr;\n\n    .label {\n      font-size: 18px;\n      text-align: left;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diveDatePicker": "sophi-B9XwlOZ5UVmUDH8AiW_4",
	"label": "sophi-taOTC2vl2m8_i8JbjMFK"
};
export default ___CSS_LOADER_EXPORT___;
