// Utils
import { getResource, pathBuilder, paramsAreTruthy } from 'utils/api';

/**
 * API call for Next Page Visits Table
 * @param {String} id - String representing the contentID
 * @param {Integer} page - The current page
 * @param {Integer} size - The amount of record requested
 */
const visitorsThatStay = (host, client, id, token, page, size) => {
  if (paramsAreTruthy([id, page, size])) {
    // TODO: This used to be recirculation-analysis (now: visitors-that-stay)
    const path = pathBuilder(
      host,
      {
        section: 'content',
        client,
        contentId: id,
        query: `recirculation-analysis/next-page-visits?page=${page}&size=${size}`
      }
    );

    const formatResponse = (data) => {
      if (!data) return [];
      return data.map((d) => {
        const row = { ...d };
        row.platforms = row.platforms.reduce((lu, p) => ({ ...lu, [p.platform.toLowerCase()]: p }), {});
        return row;
      });
    };
   

    // If page = 0, its the first page so we'll set the main driver
    // If sort happens, this logic will have to change.

    return getResource({
      endpoint: path,
      token,
    })
      .then((resp) => {
        if (resp.noData) return resp;

        const response = {
          page, // zero-index
          size,
          count: resp.size || 0,
          results: formatResponse(resp.rows),
        };

        if (page === 0) {
          response.driver = {
            percentage: resp.totals.percentage,
            count: resp.totals.total,
            name: resp.rows[0].name,
            url: resp.rows[0].url,
          };
        }
        return { response };
      })
      .catch((error) => ({ error }));
  }
};

export default visitorsThatStay;