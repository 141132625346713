import isNil from 'lodash/isNil';

export function format(number, type = 'number', opts) {
  switch (type) {
    case 'percent': {
      return formatPercent(number, opts);
    }
    case 'minutes': {
      return formatMinutes(number);
    }
    default:
      return formatNumber(number, opts);
  }
}

/**
 * Method that will return the appropriate contracted numerical value
 * @param {Int} value - Value as int
 * @param {Object} opts { fraction: 0, maximumFractionDigits: undefined, contract: false }
 */
const contract = (value, opts) => {
  const between = (n, min, max) => {
    return n >= min && n <= max;
  };

  const log10 = (n) => {
    return Math.floor(Math.round(100 * Math.log(n) / Math.log(10)) / 100);
  };

  if (!opts.contract || between(log10(value), 0, 2)) {
    return { value, postfix: '' };
  }

  const { thousands, millions, billions } = opts.contract || {};

  // Thousands
  if (between(log10(value), 3, 5) && thousands) {
    return { value: value / 1e3, postfix: 'k' };
  }

  // Millions
  if (between(log10(value), 6, 8) && millions) {
    return { value: value / 1e6, postfix: 'm' };
  }

  // Billions
  if (between(log10(value), 9, 12) && billions) {
    return { value: value / 1e9, postfix: 'b' };
  }

  return { value, postfix: '' };
};

/**
 * Method that will return a faction as a local string
 * @param {Object} transformed { value: Int, prefix: String}
 * @param {Object} opts { fraction: 0, maximumFractionDigits: undefined, contract: false }
 */
const localeFraction = (transformed, opts) => {
  return transformed ? (transformed.value).toLocaleString(
    undefined, // leave undefined to use the browser's locale,
    {
      minimumFractionDigits: opts.fraction,
      maximumFractionDigits: opts.maximumFractionDigits,
    }
  ) : '0';
};

/**
 * @param {*} number
 * @param {*} [opts={ fraction: 0, maximumFractionDigits: undefined, contract: {thousands: false, millions: false, billions: false} }]
 * @returns
 */
export function formatNumber(
  number,
  opts = {
    fraction: 0,
    maximumFractionDigits: undefined,
    contract: {
      thousands: false,
      millions: false,
      billions: false,
    }
  }, ) {
  if (isNil(number)) return '0';
  const transformed = contract(number, opts);
  const value = localeFraction(transformed, opts);

  return `${value}${transformed ? transformed.postfix : ''}`;
};

/**
 * @param {Number} number
 * @param {Object} [opts={ decimal: 0 }]
 * @returns {String} The string
 */
export function formatPercent(number, opts = { decimal: 0 }) {
  return !isNil(number) && typeof number === 'number'
    ? number.toFixed(opts.decimal) + '%'
    : '';
}

/**
 * Given the minutes, it'll prove a String representation
 * @param {Number} minutes
 * @returns {String} A String representation of minutes in base 60
 */
export function formatMinutes(minutes) {
  let startingValue = minutes;

  if (typeof minutes !== 'number') {
    startingValue = parseFloat(minutes);
  }

  // If minutes is non-numerical, infinite or negative return an empty string
  if (Number.isNaN(startingValue) || !Number.isFinite(startingValue) || startingValue < 0) return '';

  let wholeMinutes = Math.floor(startingValue);
  // Round decimal to 3 places to prevent issue with repeating decimals
  const secondsAsDecimal = Math.round((startingValue - wholeMinutes) * 1000) / 1000;
  let secondsAsBase60 = Math.floor(secondsAsDecimal * 60);

  if (secondsAsBase60 < 10) {
    secondsAsBase60 = `0${secondsAsBase60}`;
  } else if (secondsAsBase60 === 60) {
    secondsAsBase60 = `00`;
    wholeMinutes++;
  }

  return `${wholeMinutes}:${secondsAsBase60}`;
}

/**
 * Helper function that will map a value within one range to another.
 * Example: If a value may fall between 0 and 100, but you require a value between 0 and 1, mapToRange(50, 0, 100, 0, 1) === 0.5
 * @param {Number} value - The value you wish to map
 * @param {Number} in_min - The minimum input value
 * @param {Number} in_max - The maximum input value
 * @param {Number} out_min - The minimum output value
 * @param {Number} out_max - The maximum output value
 */
export const mapToRange = (value ,in_min, in_max, out_min, out_max) => {
  return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};