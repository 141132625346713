// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sophi-fPde5x3V_e5O_25i87Ih{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.sophi-FHjT5k8PZM0jMr1jAqMH{-ms-flex-preferred-size:800px;flex-basis:800px}.sophi-JUcGPgfbbekT1MamqKaQ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;gap:calc(.5*var(--space-1))}", "",{"version":3,"sources":["webpack://./src/config-ui/src/components/WidgetConfig/WidgetConfigForm.styles.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAGF,4BACE,6BAAA,CAAA,gBAAA,CAGF,4BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,2BAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n}\n\n.wrapper {\n  flex-basis: 800px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: calc(0.5 * var(--space-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sophi-fPde5x3V_e5O_25i87Ih",
	"wrapper": "sophi-FHjT5k8PZM0jMr1jAqMH",
	"buttons": "sophi-JUcGPgfbbekT1MamqKaQ"
};
export default ___CSS_LOADER_EXPORT___;
