import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styles from './Typography.styles.scss';

const Subheader = ({ className, tag, ...props }) => {
  const subheaderProps = {
    ...props,
    className: `${styles.subheader} ${className || ''}`
  };

  return <Base tag={tag} {...subheaderProps} />;
};

Subheader.defaultProps = {
  tag: 'p',
  className: '',
};

Subheader.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
  className: PropTypes.string,
};

export default Subheader;
