import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Table.styles.scss';

const TableRow = ({
  row,
  hovered,
  selectedRow,
  handleUserExpand,
  onRowMouseEnter,
  onRowMouseLeave,
  rowHeight
}) => (
  <tr
    onMouseEnter={onRowMouseEnter ? () => onRowMouseEnter(row) : undefined}
    onMouseLeave={onRowMouseLeave ? () => onRowMouseLeave(row) : undefined}
    className={classNames(styles.bodyRow, styles[`rowDepth${row.depth}`], {
      [styles.isExpandedRow]: row.canExpand && row.isExpanded,
      [styles.isSubRow]: row.depth > 0,
      'jss-sub-row': row.depth > 0,
      'jss-parent-row': row.depth === 0,
      [styles.total]: row.values.subsectionName === 'Totals',
      [styles.selectedRow]: selectedRow && selectedRow(row),
      [styles.hovered]: hovered,
    })}
    style={{ height: rowHeight }}
    {...row.getRowProps()}
  >
    {
      row.cells.map(
        (cell) => (
          <td
            key={`${cell.column.id}-${cell.row.id}`}
            className={
              classNames([
                styles.bodyCell, `cell-depth-${row.depth}`,
                {
                  [styles.cellExpanded]: row.canExpand && row.isExpanded,
                  [styles.expander]: cell.column.id === 'expander',
                  [styles.truncate]: cell.column.truncate
                }
              ])
            }
            data-column={typeof cell.column.id === 'string' ? cell.column.id : ''}
            data-content-type={cell.column.contentType}
            onClick={() => handleUserExpand(row.values.subsectionName, cell.column.id)}
            {...cell.getCellProps([
              {
                className: cell.column.className,
                style: cell.column.style,
              }
            ])}
          >
            <span>
              {cell.render('Cell')}
            </span>
          </td>
        )
      )
    }
  </tr>
);

TableRow.propTypes = {
  row: PropTypes.object,
  hovered: PropTypes.bool,
  selectedRow: PropTypes.func,
  handleUserExpand: PropTypes.func,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
  rowHeight: PropTypes.number,
};

export default TableRow;
