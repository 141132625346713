// Utils
import {
  pathBuilder,
  paramsAreTruthy,
  getResource
} from 'utils/api';
import camelCase from 'lodash/camelCase';
import { formatNumber } from 'utils/number';

const formatResponse = (data, pageConfig) => {
  if (Object.keys(data).includes('noData')) return 'noData';

  const rankDate = data.rankedArticlePubdate;
  const rankTotal = data.rankedArticleCount;
  const template = {
    score: {
      default: true,
      title: `Sophi Score`,
      stat: {
        value: formatNumber(data.score, { maximumFractionDigits: 0, fraction: 0 }) || 0,
        type: 'number',
      },
      rank: {
        type: 'ranking',
        text: `stories on`,
        self: data.scoreRank,
        total: rankTotal,
        date: rankDate,
      },
    },
    visits: {
      default: true,
      title: `Visits`,
      stat: {
        value: data.visitCount,
        type: 'number',
      },
      rank: {
        type: 'ranking',
        text: `stories on`,
        self: data.visitCountRank,
        total: rankTotal,
        date: rankDate,
      },
    },
    subscriberVisits: {
      default: true,
      title: `Subscriber Visits`,
      stat: {
        value: parseInt(data.subscriberVisitCount),
        type: 'number',
      },
      rank: {
        type: 'ranking',
        text: `stories on`,
        self: data.subscriberVisitCountRank,
        total: rankTotal,
        date: rankDate,
      },
    },
    registeredUserVisits: {
      default: false,
      title: `Registered User Visits`,
      stat: {
        value: parseInt(data.registeredUserVisitCount),
        type: 'number',
      },
      rank: {
        type: 'ranking',
        text: `stories on`,
        self: data.registeredUserVisitCountRank,
        total: rankTotal,
        date: rankDate,
      },
    },
    subscriptions: {
      default: true,
      title: `Subscriptions`,
      stat: {
        value: formatNumber(data.subscriptionCount, { maximumFractionDigits: 2 }),
        type: 'number',
      },
      rank: {
        total: {
          value: data.wallHitCount,
        },
        text: `paywall encounters`,
      },
    },
    recirculation: {
      default: true,
      title: `Recirculation`,
      stat: {
        value: data.recirculationPercentage,
        type: `percent`,
      },
      rank: {
        total: {
          value: Math.abs(data.recirculationIndexPercentage),
          type: `percent`,
        },
        text: `${data.recirculationIndexPercentage < 0 ? 'below average' : 'above average'}`,
      },
    },
    engagement: {
      default: true,
      title: `Engagement`,
      stat: {
        value: data.medianEngagementTime,
        type: `minutes`,
      },
      rank: {
        total: {
          value: data.longEngagementTimePercentage,
          type: `percent`,
        },
        text: `completed article`,
      },
    },
    newsletterSignups: {
      default: false,
      title: `Newsletter Signups`,
      stat: {
        value: formatNumber(data.newsletterSignupTotalCredits, { maximumFractionDigits: 2 }),
        type: 'number'
      },
    },
  };

  const response = {};

  let fields = pageConfig?.fields;
  if (fields) {
    fields = fields.filter((field) => {
      return field.attributes.some((attribute) => {
        return attribute.name === 'enabled' && attribute.value === true;
      });
    }).sort((a, b) => {
      if (a.index > b.index) return 1;
      if (a.index < b.index) return -1;
      return 0;
    });

    for (const field of fields) {
      if (template[field.dataKey]) {
        response[field.dataKey] = {
          ...template[field.dataKey],
          title: field.label
        };
      }
    }
  } else {
    for (const field of Object.keys(template)) {
      if (template[field].default) {
        response[field] = {
          ...template[field]
        };
      }
    }
  }

  if (Object.keys(response).length === 0) {
    return 'noData';
  }

  return response;
};

/**
 * API call for All Time Stats
 * @param {String} host - String representing the host
 * @param {String} client - String representing the clientID
 * @param {String} id - String representing the contentID
 * @param {String} token - String representing the access token
 */

const fetchData = (host, client, id, token) => {
  if (paramsAreTruthy([id])) {
    const path = pathBuilder(host, { section: 'content', client, contentId: id, query: 'lifetime-statistics' });

    return getResource({
      endpoint: path,
      token
    })
      .then((response) => response)
      .catch((error) => ({ error }));
  }
};

export default {
  fetchData,
  formatResponse
};