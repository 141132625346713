import React from 'react';
import { sortArrayByKey } from './arrays';
import { formatNumber } from 'utils/number';
import { isDefined } from 'utils/objects';
import { formatShortDateTime } from 'utils/dateTime';
import Link from 'atoms/Link';
import ValuePlaceholder from 'atoms/ValuePlaceholder';

/**
 * Method that will consume a table object and return it with sorted arrays per config
 * @param {Object} obj - Object representing a table
 * @param {Object} config - Object of { column, direction } representing the sort configuration
 */
export const sortObjectByKey = (obj, config) => {
  const { rows } = obj || {};
  if (!rows) throw new TypeError('Object rows or config are invalid');
  const payload = {
    ...obj,
    rows: sortArrayByKey(rows, config),
  };
  return payload;
};

// Returns a placeholder if data is missing and has/has not been requested
// If data is available it uses the given formatter
const wrapFormatter = (formatter, dataRequested) => (cell) => {
  if (!isDefined(cell.value)) return <ValuePlaceholder dataRequested={dataRequested} />;
  return formatter(cell);
};

const defaultFormatter = ({ value = '' }) => typeof value === 'number' ? formatNumber(value, { maximumFractionDigits: 2 }) : value;

const leftPad = (val) => val < 10 ? `0${val}` : val;

/**
 * Gets the cell formatter that matches the key passed in.
 * @param {String}  key         Either the cell accessor or specific formatter type name
 * @param {Boolean} requested   If column was requested in last BE fetch
 * @param {Object}  config      Any additional values required to configure this column's cell
 * @returns {Function} Cell formatter function
 */
export const getCellFormatter = (key, requested, config = {}) => {
  const cellFormatters = {
    score: ({ value }) => formatNumber(value, { maximumFractionDigits: 0 }),
    sophiScore: ({ value }) => formatNumber(value, { maximumFractionDigits: 0 }),
    headline: ({ row }) => <Link to={`${config.dive ? `/historical` : ''}/content/${row.original.contentId}${config.host ? `?org=${config.host}`: ''}`}>{row.original.headline}</Link>,
    scoreDriver: ({ value }) => value.replace('|', ' ').replace('_', ' '),
    datePublished: ({ value }) => formatShortDateTime(new Date(value * 1000), config.timezone),
    byline: ({ value }) => (value && Array.isArray(value)) ? value.map((val) => val.toLowerCase()).join(', ') : '',
    articles: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 0 }),
    sophiScorePerArticle: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 0 }),
    adjustedSubscriptionCount: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 2, fraction: 2 }),
    subscriptionCount: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 2, fraction: 2 }),
    visitCount: ({ value }) => formatNumber((value || 0)),
    wallEventCountTotal: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 0 }),
    recirculationRatio: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 2, fraction: 2 }),
    medianTimeSpent: ({ value }) => {
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value - (hours * 3600)) / 60);
      const seconds = Math.floor(value - (hours * 3600) - (minutes * 60));

      return `${hours ? `${hours}:` : ''}${leftPad(minutes)}:${leftPad(seconds)}`;
    },
    author: ({ value }) => (
      <Link style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }} to={`/historical/author/${encodeURIComponent(value)}`}>
        {value.toLowerCase()}
      </Link>
    ),
    noDecimals: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 0 }),
    twoDecimals: ({ value }) => formatNumber((value || 0), { maximumFractionDigits: 2, fraction: 2 }),
    scoreBar: ({ value, rows }) => {
      const percentValue = Math.floor(value / rows[0].values.sophiScore * 100);
      let message = `This article has a score ${100 - percentValue}% lower than the highest scoring article.`;
      if (percentValue === 0) {
        message = 'This article has the highest score for the chosen time range.';
      }
      return (
        <div
          title={message}
          style={{ height: 25, width: `${percentValue}%`, backgroundColor: '#17819b' }}
        />
      );
    },
    scorePerArticleBar: ({ value, rows }) => {
      const percentValue = value / rows[0].values.sophiScorePerArticle * 100;
      let message = `This article has a score per article ${100 - percentValue}% lower than the highest scoring article.`;
      if (percentValue === 0) {
        message = 'This article has the highest score per article for the chosen time range.';
      }
      return (
        <div
          style={{ height: 25, width: `${percentValue}%`, backgroundColor: '#9bcedb' }}
          title={message}
        />
      );
    },
    publishedDate: ({ value }) => formatShortDateTime(new Date(value * 1000), config.timezone),
  };

  return wrapFormatter(cellFormatters[key] || defaultFormatter, requested);
};

export function formatColumnsConfig(response = {}, platforms = []) {
  const nonDevice = new Map();
  const deviceTop = new Map();
  const requiredColumns = [];
  const defaultColumns = [];

  if (response.data) {
    const { fields = [] } = response.data;
    fields.forEach(({ attributes, dataKey: propertyKey, label: displayLabel, index }) => {
      const enabled = attributes.find(({ name }) => name === 'enabled').value;

      if (enabled) {
        // Add field to the appropriate group
        const type = attributes.find(({ name }) => name === 'type').value;
        const required = attributes.find(({ name }) => name === 'required').value;

        if (type === 'deviceTop') {
          const platform = (attributes.find(({ name }) => name === 'platform') || { value: '' }).value;
          if ([...platforms.map((p) => p.toLowerCase()), 'total'].includes(platform.toLowerCase())) deviceTop.set(propertyKey, {
            id: propertyKey,
            index,
            label: `${displayLabel} ${platform}`,
            group: displayLabel,
            platform,
            contentType: 'number',
            ...(otherProperties[propertyKey] && otherProperties[propertyKey]),
            ...(required && { always: true }),
          });
        } else {
          nonDevice.set(propertyKey, {
            id: propertyKey,
            index,
            label: displayLabel,
            ...(otherProperties[propertyKey] && otherProperties[propertyKey]),
            ...(required && { always: true }),
          });
        }

        // Determine default and required fields
        if (required) requiredColumns.push(propertyKey);
        const defaultValue = attributes.find(({ name }) => name === 'default').value;
        if (defaultValue) defaultColumns.push(propertyKey);
      }
    });
  }

  return { nonDevice, deviceTop, requiredColumns, defaultColumns };
}

const otherProperties = {
  headline: { style: { minWidth: 350 } },
  byline: { style: { minWidth: 200, textTransform: 'capitalize' } },
  datePublished: { style:{ minWidth: 175 } },
  scoreDriver: { style: { minWidth: 175, textTransform: 'capitalize' } },
  sectionName: { style:{ textTransform: 'capitalize' } },
  ownership: { style:{ textTransform: 'capitalize' } },
  creditLine: { style:{ textTransform: 'capitalize' } },
  contentType: { style:{ textTransform: 'capitalize' } },
  accessCategory: { style:{ textTransform: 'capitalize' } },
  author: { style: { minWidth: 200, textTransform: 'capitalize' } },
  articles: { contentType: 'number' },
  sophiScorePerArticle: { contentType: 'number' },
  subscriptionCount: { contentType: 'number' },
  wallEventCountTotal: { contentType: 'number' },
  medianTimeSpent: { contentType: 'number' },
  recirculationRatio: { contentType: 'number' },
};

export const COLUMN_TOOLTIPS = {
  'acquisition score': 'The part of the score pertaining to acquiring new subscribers.',
  'retention score': 'The part of the score pertaining to retaining existing subscribers.',
  'internal score': 'The part of the score pertaining to on-site traffic.',
  'direct score': 'The part of the score pertaining to traffic from direct visits.',
  'email score': 'The part of the score pertaining to email traffic.',
  'newsletter score': 'The part of the score pertaining to newsletter traffic.',
  'affiliate score': 'The part of the score pertaining to affiliated website traffic.',
  'search score': 'The part of the score pertaining to search engine traffic.',
  'social score': 'The part of the score pertaining to social media traffic.',
  'other score': 'The part of the score pertaining to traffic from other sources.',
};

export const OVERVIEW_COLUMN_CONFIG = {
  default: ['score', 'headline', 'byline', 'publishedDate', 'scoreDriver', 'section'],
  required: ['score', 'headline']
};

export const DIVE_SECTIONS_CONFIG = {
  default: [
    'subsectionName',
    'articles',
    'sophiScorePerArticle',
    'sophiScore',
  ],
  required: [
    'subsectionName',
    'articles',
    'sophiScorePerArticle',
    'sophiScore',
  ],
};

export const DIVE_ARTICLES_CONFIG = {
  default: [
    'sophiScore',
    'headline',
    'byline',
    'datePublished',
    'scoreDriver',
    'sectionName',
  ],
  required: [
    'sophiScore',
    'headline',
  ],
};

export const DIVE_AUTHORS_CONFIG = {
  default: [
    'author',
    'sophiScore',
    'articles',
    'sophiScorePerArticle',
  ],
  required: [
    'author',
    'sophiScore',
  ],
};
