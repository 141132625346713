import types from '../actions/configTypes';

export const widgetConfigTemplate = {
  data: null,
  loading: false,
  error: null,
};

const initialState = {};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_WIDGET_CONFIG: {
      const { pageName, widgetName } = action;
      const pageConfig = state[pageName] || {};
      const widgetConfig = state[pageName]?.[widgetName] || widgetConfigTemplate;

      return {
        ...state,
        [pageName]: {
          ...pageConfig,
          [widgetName]: {
            ...widgetConfig,
            error: null,
            loading: true,
          }
        }
      };
    }
    case types.FETCH_WIDGET_CONFIG_SUCCESS: {
      const { pageName, widgetName } = action;
      const pageConfig = state[pageName] || {};

      return {
        ...state,
        [pageName]: {
          ...pageConfig,
          [widgetName]: {
            data: action.data,
            error: null,
            loading: false,
          }
        }
      };
    }
    case types.FETCH_WIDGET_CONFIG_FAIL: {
      const { pageName, widgetName } = action;
      const pageConfig = state[pageName] || {};
      const widgetConfig = state[pageName]?.[widgetName] || widgetConfigTemplate;

      return {
        ...state,
        [pageName]: {
          ...pageConfig,
          [widgetName]: {
            ...widgetConfig,
            error: action.error,
            loading: false,
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
